import React from 'react';
import { useColorTheme } from '../hooks';
import { logNetRequest, scrollToTop } from '../utils/usefulFuncs';
import { Body1, Body5, Container, H5, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import { TrackingEvent, truncate } from '../libs/nvstr-utils.es';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import styled from 'styled-components';
import { sendApiDownloadRequest, sendApiRequest } from '../services/api';
import { useSearchParams } from 'react-router-dom';
import { TranscriptReader } from '../containers/DigitalAnalyst/TranscriptReader';
import { useDoc } from '../hooks/features/useDoc';
import { FlatButton } from '../components/buttons';
import { LiveIndicator } from '../components/UI/LiveIndicator';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
const AudioCallLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;

  svg {
    height: 15px;
    width: 15px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

function Loading() {
  const colorTheme = useColorTheme();
  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <Container top={40} bottom={80}>
            <Container centerAll>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.primaryCtaButton} />
              <Container left={12}>
                <Body1>Loading...</Body1>
              </Container>
            </Container>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}

const useDocViewer = (id, isLive) => {
  const [doc, setDoc] = React.useState(null);

  const getTranscript = async () => {
    const URL = `v1/genai_earnings_transcripts_get?fid=${id}`;
    const { status, data } = await sendApiRequest('get', URL);
    if (status === 200) {
      setDoc(data);
      return { ok: true, data };
    } else {
      return {
        error: 'Something went wrong, please try again.',
      };
    }
  };

  React.useEffect(() => {
    if (id !== 'null') {
      getTranscript();
    }
  }, [id]);

  React.useEffect(() => {
    const POLL_TIME = 1000 * 30;
    let interval = null;

    if (isLive) {
      interval = setInterval(() => {
        getTranscript();
      }, POLL_TIME);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isLive]);

  return {
    isLoading: !doc,
    doc,
  };
};

const PDFViewer = ({ doc }) => {
  const [error, setError] = React.useState(null);
  const { id, blob_id, filename } = doc;

  const onDL = async () => {
    try {
      const URL = `v1/genai_as_user_doc/${blob_id}`;

      const response = await sendApiDownloadRequest(URL);
      const { status, data } = response;
      logNetRequest(status, data, URL);
      if (status === 200) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'document.pdf'; // Replace with desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setError(error || 'Something went wrong downloading the file, please try again later.');
      }
    } catch (error) {
      setError(error || 'Something went wrong downloading the file, please try again later.');
      console.error('Error downloading the file:', error);
    }
  };

  React.useEffect(() => {
    onDL();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <Container>
            <Container>
              <Body1 bold>{filename}</Body1>
            </Container>
            {error && (
              <Container top={24}>
                <Body5>{error}</Body5>
              </Container>
            )}
            <Container top={24}>
              <FlatButton onClick={onDL}>Download</FlatButton>
            </Container>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
};

const TranscriptViewer = ({ doc }) => {
  const { id, is_live } = doc;
  const { isLoading, doc: transcriptData } = useDocViewer(id, is_live);

  if (isLoading) {
    return <Loading />;
  }

  if (!transcriptData || !transcriptData.transcript) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <Container top={40} bottom={80}>
              <Body5>Transcript is not currently available</Body5>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  const { transcript, audio, id: finnhubId, participant, time, title, symbol } = transcriptData;

  const heading = symbol ? `${symbol} - ${truncate(title, 50)}` : `${truncate(title, 50)}`;
  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page width={'1600px'}>
          <Container>
            {is_live && (
              <Container bottom={8}>
                <LiveIndicator />
              </Container>
            )}
            <Container>
              <H5>{heading}</H5>
            </Container>

            <Container top={8}>
              {audio ? (
                <AudioCallLink href={audio} target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M10,16.5L16,12L10,7.5V16.5Z" />
                  </svg>
                  Play Audio Recording
                </AudioCallLink>
              ) : null}
            </Container>
            <Container top={24}>
              <TranscriptReader transcript={transcript} />
            </Container>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
};

function ExternalDocViewer({ doc }) {
  const {
    filename,
    display_category,
    security_name: stockName,
    security_symbol: ticker,
    document_url: docUrl,
  } = doc || {};

  const headingText = ticker ? `${ticker} - ${truncate(stockName, 30)} - ${display_category}` : filename;

  const open = () => {
    window.open(docUrl, '_blank');
  };

  React.useEffect(() => {
    open();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <Container>
            <Container>
              <H5>{headingText}</H5>
            </Container>
            <Container top={8}>
              <Body5>Document is available externally.</Body5>
              <Container top={16}>
                <FlatButton onClick={open}>Take me there</FlatButton>
              </Container>
            </Container>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}

export function DocumentViewer() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const doc = useDoc(id);
  const isLoading = !doc;

  React.useEffect(() => {
    scrollToTop();

    const logSourceDocumentView = async () => {
      TrackingEvent.create('View Source Document', {
        Id: id,
      });
      const URL = 'v1/genai_doc_source_view';
      const { status, data, error } = await sendApiRequest('post', URL, {
        doc_id: id,
        platform: 'web',
      });
      if (status !== 200) {
        console.error({ status, data, error });
      }
    };
    logSourceDocumentView();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!doc) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <Container top={40} bottom={80}>
              <Body1>Document is not currently available</Body1>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  const { blob_id, is_transcript_available: isTranscript, document_url: isExternal } = doc;

  if (blob_id) {
    return <PDFViewer doc={doc} />;
  }

  if (isTranscript) {
    return <TranscriptViewer doc={doc} />;
  }

  if (isExternal) {
    return <ExternalDocViewer doc={doc} />;
  }

  if (!blob_id && !isTranscript && !isExternal) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <Container>
              <Body5>
                Viewing this original document is not currently available. Support may be added in the future.
              </Body5>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <Container>
            <Body5>Something went wrong, the document is not currently available. Please try again later.</Body5>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}
