export const LOCAL_API_DOMAIN = 'https://dev-api.tornado.com';
export const API_URL = process.env.REACT_APP_API_URL || LOCAL_API_DOMAIN;
export const BASEURL = API_URL;

export const extendedFetch = (url, method, body, params_headers) => {
  // const username = 'nvstr';
  // const password = 'investbettertomorrow';
  const headers = new Headers();

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  // headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
  params_headers?.forEach((e) => {
    headers.set(e[0], e[1]);
  });

  const requestData = {
    method: method.toUpperCase(),
    credentials: 'include',
    headers,
  };
  if (method.toUpperCase() !== 'GET') {
    requestData.body = body;
  }
  return fetch(url, requestData);
};
