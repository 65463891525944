import { Body1, Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import React from 'react';
import styled, { css } from 'styled-components';
import { convertHexToRGBA } from '../../../libs/nvstr-utils.es';
import { handle400Statuses, sendApiRequest } from '../../../services/api';
import { logger, logNetRequest } from '../../../utils/usefulFuncs';
import { useColorTheme } from '../../../hooks';
import { TransparentFlatButton } from '../../../components/buttons';
import { DocumentContext } from '../../../providers/DocumentProvider';
import { AI_TOOLS } from '../../../constants';

const Paragraph = styled.p`
  word-break: break-word;
`;
const DocumentHeaderWrapper = styled.div`
  word-break: break-word;
`;

const TextPhraseWrapper = styled.span`
  ${({ $IsMatched, theme }) =>
    $IsMatched
      ? css`
          border-radius: 2px;
          background: ${convertHexToRGBA(theme.themeColors.text, 0.1)};
        `
      : ``};
`;

const _getDocumentNameFromContext = (id, context) => {
  if (!context) return null;

  const foundDoc = context.filter((d) => d.id === id)[0];
  if (foundDoc) {
    return foundDoc.filename;
  } else {
    return null;
  }
};

const DocumentHeader = ({ docId }) => {
  const { documents } = React.useContext(DocumentContext);
  const documentName = _getDocumentNameFromContext(docId, documents);

  if (!documentName) return null;

  return (
    <DocumentHeaderWrapper>
      <Body1 bold>{documentName}</Body1>
    </DocumentHeaderWrapper>
  );
};

function Result({ result, searchTerm }) {
  const { doc_id, header, text } = result;
  return (
    <Paragraph>
      <Container>
        <DocumentHeader docId={doc_id} />
      </Container>
      <Container top={6} bottom={8}>
        <Body5>{result.speaker}</Body5>
      </Container>
      <TextSearchAnswerItem text={text} searchTerm={searchTerm} />
    </Paragraph>
  );
}

function splitIncludeDelimiter(text, delimiter) {
  if (!text) return [];

  const result = [];
  let prevEndIndex = 0;
  let startIndex = 0;
  const delimiterLength = delimiter.length;

  while (true) {
    const iter = text.slice(startIndex, startIndex + delimiterLength);
    if (iter.toLowerCase() === delimiter.toLowerCase()) {
      const prevIter = text.slice(prevEndIndex, startIndex);
      if (prevIter.length > 0) {
        result.push(prevIter);
      }
      result.push(iter);
      startIndex = startIndex + delimiterLength;
      prevEndIndex = startIndex;
    } else {
      startIndex = startIndex + 1;
    }

    if (startIndex > text.length) {
      result.push(text.slice(prevEndIndex, text.length));
      break;
    }
  }

  return result;
}

function TextSearchAnswerItem({ text, searchTerm }) {
  const terms = splitIncludeDelimiter(text, searchTerm);
  return (
    <Container>
      {terms.map((t, i) => {
        const isMatch = t.toLowerCase() === searchTerm.toLowerCase();
        return (
          <TextPhraseWrapper key={i} $IsMatched={isMatch}>
            <Body5 bold={isMatch}>{t}</Body5>
          </TextPhraseWrapper>
        );
      })}
    </Container>
  );
}

function TextSearchAnswer({ results, searchTerm }) {
  return (
    <Container>
      {results.map((s, i) => (
        <Container key={i}>
          <Result result={s} searchTerm={searchTerm} />
        </Container>
      ))}
    </Container>
  );
}

export const TextSearchResults = ({ searchTerm, selectedDocs, index }) => {
  const colorTheme = useColorTheme();

  const [answer, setAnswer] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [collapse, setCollapse] = React.useState(false);
  const [showSources, setShowSources] = React.useState(false);

  const handleShowSources = () => setShowSources(true);
  const handleHideSources = () => setShowSources(false);

  React.useEffect(() => {
    const scrollToAnswer = () => {
      try {
        const delay = 300;
        const id = 'first-answer-top';
        const yOffset = -26;
        const element = document.getElementById(id);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          setTimeout(() => {
            window.scrollTo({ top: y, behavior: 'smooth' });
          }, delay);
        }
      } catch (e) {
        logger('error', e);
      }
    };

    if (index === 0 && answer) {
      scrollToAnswer();
    }
  }, [answer, index]);

  React.useEffect(() => {
    const init = async () => {
      const form = {
        search_for_text: searchTerm,
        doc_list: selectedDocs.map((d) => d.id),
        need_context: false,
        user_mode: AI_TOOLS.TEXT_SEARCH,
        use_inferred_metadata: true,
      };
      const URL = 'v1/genai_text_search';
      const { status, data, error } = await sendApiRequest('post', URL, form);
      logNetRequest(status, data, error);
      handle400Statuses(status);
      if (status === 200) {
        setAnswer(data);
      } else {
        setError('Something went wrong, please try again.');
      }
    };
    init();
  }, []);

  if (error) {
    return (
      <Container>
        <Body5>An error occurred</Body5>
      </Container>
    );
  }
  if (!answer) {
    return (
      <Container top={40} bottom={40}>
        <Container centerAll>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.primaryCtaButton} />
          <Container left={12}>
            <Body1>Searching for terms...</Body1>
          </Container>
        </Container>
      </Container>
    );
  }
  if (answer.results.length === 0) {
    return (
      <Container top={0} bottom={40}>
        <Container>
          <Body5>Term: </Body5>
          <Body1 bold>{answer.search_for}</Body1>
        </Container>
        <Container top={36}>
          <Body5>Search term was not found in the source documents</Body5>
        </Container>
      </Container>
    );
  }
  return (
    <Container>
      <a id={'first-answer-top'} />
      <Container row spaceBetween verticallyCenter>
        <Container>
          <Body5>Term: </Body5>
          <Body1 bold>{answer.search_for}</Body1>
        </Container>
        <Container>
          {collapse ? (
            <TransparentFlatButton onClick={() => setCollapse(false)}>Show</TransparentFlatButton>
          ) : (
            <TransparentFlatButton onClick={() => setCollapse(true)}>Hide</TransparentFlatButton>
          )}
        </Container>
      </Container>

      {collapse ? null : (
        <>
          <Container top={16}>
            <TextSearchAnswer results={answer.results} searchTerm={answer.question} />
          </Container>
          {showSources ? (
            <Container top={16}>
              <Container style={{ textAlign: 'right' }}>
                <TransparentFlatButton onClick={handleHideSources}>Hide Sources</TransparentFlatButton>
              </Container>
              <Container top={16}>
                {answer.context?.split('###').map((line, i) => (
                  <p key={i}>
                    <Body5>{line}</Body5>
                  </p>
                ))}
              </Container>
            </Container>
          ) : (
            <Container style={{ textAlign: 'right' }}>
              <TransparentFlatButton onClick={handleShowSources}>Show Sources</TransparentFlatButton>
            </Container>
          )}
        </>
      )}
    </Container>
  );
};
