import { constantsActions } from '../constants/actionTypes';

const defaultState = {
  docTypes: {},
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case constantsActions.updateDocTypes: {
      return {
        docTypes: action.payload,
      };
    }

    default:
      return state;
  }
}
