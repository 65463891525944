import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body1, Body5, Container, H1 } from '../libs/nvstr-common-ui.es';
import Footer from '../components/layout/Footer';
import { EmailSignUp } from '../containers/authentication/EmailSignUp';

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.header};
  height: 100vh;
`;

const Body = styled.div`
  background: ${({ theme }) => theme.themeColors.header};

  max-width: 1880px;
  margin: 0 auto;
`;

const HeadingWrapper = styled.div`
  text-transform: uppercase;

  h1 {
    display: inline;
  }
`;
const DescriptionWrapper = styled.div`
  padding-top: 28px;
  padding-right: 8px;

  max-width: ${({ width }) => (isNaN(width) ? '100%' : width + 'px')};
`;

const LeftPanel = styled.div`
  max-width: 50%;
  padding-right: 80px;
`;

const RightPanel = styled.div`
  flex: 1;
`;

const JumboImageWrapper = styled.div`
  max-width: 1000px;
  img {
    width: 100%;
  }
`;

const EmailSignUpWrapper = styled.div`
  padding-top: 36px;
  max-width: 460px;
`;

const Separator = styled.div`
  border-bottom: 1px solid black;
  margin: 0px 60px 80px 60px;
`;

const BodyWrapper = styled.div`
  padding: 24px 60px 80px 60px;
  height: 700px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1300px) {
    padding-left: 36px;
    padding-right: 36px;

    ${LeftPanel} {
      padding-right: 40px;
    }
  }
  @media (max-width: 1180px) {
    flex-direction: column;
    gap: 100px;

    padding-top: 40px;
    height: auto;

    ${LeftPanel} {
      padding: 0;
      max-width: 1000px;
      width: 98%;
      margin: 0 auto;
    }
  }

  @media (max-width: 710px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

function DescriptionSizer({ children, headerRef }) {
  const [width, setWidth] = React.useState(null);

  React.useEffect(() => {
    window.elRef = headerRef?.current;
    const handleResize = () => {
      const elWidth = headerRef?.current?.offsetWidth;

      if (!isNaN(elWidth)) {
        if (elWidth !== width) {
          setWidth(elWidth);
        }
      }
    };
    window.addEventListener('resize', handleResize, true);

    const elWidth = headerRef?.current?.offsetWidth;
    if (!isNaN(elWidth)) {
      handleResize();
    }

    return () => window.removeEventListener('resize', handleResize, true);
  }, [headerRef, width]);

  return <DescriptionWrapper width={width}>{children}</DescriptionWrapper>;
}

const RowFeaturesWrapper = styled.div`
  padding-top: 48px;
  margin-left: 10%;
`;
const RowFeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr; /* Creates a row for each element */
  gap: 16px; /* Space between rows within a feature */

  border-top: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  padding: 36px 24px;

  width: 100%;
  max-width: 500px;
`;
const RowFeatureIconBorder = styled.div`
  border: 2px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;

  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const RowFeatureIconWrapper = styled.div`
  svg {
    fill: ${({ theme }) => theme.themeColors.text};

    height: 24px;
    width: 24px;
  }
`;
const RowFeatureNumberWrapper = styled.div`
  padding-left: 16px;
  font-weight: bold;

  * {
    font-size: 14px !important;
    line-height: 18px !important;
  }
`;
const RowFeatureTextWrapper = styled.div`
  * {
    line-height: 1.1 !important;
  }
`;

function RowFeature({ text, index, icon }) {
  return (
    <RowFeatureWrapper>
      <RowFeatureIconBorder>
        <RowFeatureIconWrapper>{icon}</RowFeatureIconWrapper>
      </RowFeatureIconBorder>
      <RowFeatureNumberWrapper>
        <Body1>0{index}</Body1>
      </RowFeatureNumberWrapper>
      <RowFeatureTextWrapper>
        <Body1>{text}</Body1>
      </RowFeatureTextWrapper>
    </RowFeatureWrapper>
  );
}

function Lock() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
    </svg>
  );
}
function DocStorage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z" />
    </svg>
  );
}
function DataIngestion() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
    </svg>
  );
}

const SectionWrapper = styled.div`
  padding: 24px 60px 80px 60px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  h3 {
    font-size: 64px;
    line-height: 74px;
    letter-spacing: -3px;
  }

  @media (max-width: 1300px) {
    padding-left: 36px;
    padding-right: 36px;

    h3 {
      font-size: 64px;
      line-height: 74px;
      letter-spacing: -3px;
    }

    ${LeftPanel} {
      padding-right: 40px;
    }
  }
  @media (max-width: 1180px) {
    flex-direction: column;
    gap: 100px;

    padding-top: 40px;
    height: auto;

    h3 {
      font-size: 48px;
      line-height: 58px;
      letter-spacing: -2px;
    }

    ${LeftPanel} {
      padding: 0;
      max-width: 1000px;
      width: 98%;
      margin: 0 auto;
    }
  }

  @media (max-width: 710px) {
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;

    h3 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -1px;
    }

    ${RowFeaturesWrapper} {
      margin-left: 8px;
    }
  }

  @media (max-width: 500px) {
    padding-left: 8px;
    padding-right: 8px;

    h3 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -1px;
    }

    ${RowFeaturesWrapper} {
      margin-left: -8px;
    }
  }
`;
const TrustedByHeadingWrapper = styled.div`
  padding-bottom: 36px;
  text-align: left;
  width: 100%;
`;
function TrustedBySection() {
  return (
    <SectionWrapper>
      <TrustedByHeadingWrapper>
        <h3>Trusted by firms with $150bn+&nbsp;in&nbsp;AUM</h3>
      </TrustedByHeadingWrapper>
    </SectionWrapper>
  );
}

function Section() {
  return (
    <SectionWrapper>
      <div>
        <h3>Secure, compliant AI synthesis layer for financial institutions.</h3>
        <Container top={24}>
          <Body1>
            Seamlessly integrate your existing document stores, sellside research, CIMs, and other sources. Harness the
            power of artificial intelligence to connect the dots, and derive unique insights from millions of pages
            across multiple feeds.
          </Body1>
        </Container>
        <Container top={8}>
          <Body1>Streamline research, enhance decision-making, and vastly scale dealflow and idea generation.</Body1>
        </Container>
        <RowFeaturesWrapper>
          <RowFeature
            index={1}
            text={'Secure ring-fencing with configurable information walls and compliance workflows'}
            icon={<Lock />}
          />
          {/*<RowFeature index={2} text={'Integration with major document storage systems'} icon={<DocStorage />} />*/}
          <RowFeature
            index={2}
            text={'Continuous data ingestion with custom, automated analyses and reporting'}
            icon={<DataIngestion />}
          />
        </RowFeaturesWrapper>
      </div>
    </SectionWrapper>
  );
}

function Splash() {
  const headerRef = React.useRef(null);

  return (
    <PageWrapper>
      <Body>
        <Header largeWidth forceTornado />
        <BodyWrapper>
          <LeftPanel>
            <HeadingWrapper>
              <h1 ref={headerRef}>GET ANSWERS FROM COMPANY FILINGS AND RESEARCH IN SECONDS</h1>
            </HeadingWrapper>
            <DescriptionSizer headerRef={headerRef}>
              <Body1>
                Digest information from thousands of public filings and your own documents with a versatile AI analyst,
                plus the reliability of full source attribution.
              </Body1>
            </DescriptionSizer>
            <EmailSignUpWrapper>
              <EmailSignUp />
            </EmailSignUpWrapper>
          </LeftPanel>
          <RightPanel>
            <JumboImageWrapper>
              <img src={'images/ai-tool-jumbo-img-alt-gold-light.png'} />
            </JumboImageWrapper>
          </RightPanel>
        </BodyWrapper>
        <Separator />
        <Section />
        <Separator />
        <TrustedBySection />
      </Body>
      <Footer />
    </PageWrapper>
  );
}

export default Splash;
