import styled from 'styled-components';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import React from 'react';

const CARD_HEIGHT = 92;
const CARD_WIDTH = 270;
const CARD_GAP = 8;
const SCROLL_BUTTON_WIDTH = 20;

const defaultQuestions = [
  'Which companies are the biggest beneficiaries of growth in demand for infrastructure required for artificial intelligence?',
  'Which companies generate the highest percentage of their revenue from supplying Tesla.',
  'Which companies supply parts for the manufacturing of electric vehicles?',
  'Summarize companies that manufacture electric vehicles giving details that a stock analyst would be interested in.',
  'Which companies are the biggest beneficiaries of growth in the space industry and why?',
  'Which banks are most exposed to Commercial Real Estate?',
  'What companies will benefit from the Inflation Reduction Act?',
  'Which companies generate revenue from alternative energy?',
  'Which companies generate revenue from supplying fossil fuels?',

  // 'What companies supply parts or components to Apple, Inc?',
  // 'Which companies are the biggest beneficiaries of the aging population and why?',
  // 'Which companies generate the highest percentage of their revenue from supplying Apple?',
  // 'What companies generate over 25% of revenue from China, from highest to lowest?',
  // 'What companies generate over 25% of revenue from artificial intelligence, from highest to lowest?',
  // 'What companies generate over 50% of revenue from one customer?',
  // 'What companies source over 50% of their product or service from one supplier?',
  // 'What companies generate over 50% of revenue outside the United States, from highest to lowest?',
  // 'Which companies are most exposed to the US consumer?',

  // Poor answers:
  // 'What companies have high exposure to changes in interest rates?',
  // 'Which companies are the biggest beneficiaries of the growth of India?',
  // 'Which companies are the biggest beneficiaries of the rising global temperature?',
];

function shuffleArray(array) {
  let arrayCopy = array.slice();
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }

  return arrayCopy;
}

const getDefaultQuestions = () => {
  return shuffleArray(defaultQuestions);
};

const DefaultQuestionsSelectionWrapper = styled.div`
  padding-top: 16px;

  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const ScrollWrapper = styled.div`
  overflow: scroll;
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${() => `${CARD_GAP}px`};
  flex-wrap: wrap;

  padding-bottom: 10px;
`;

const QuestionCardWrapper = styled.div`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  cursor: pointer;
  transition: all 150ms;

  height: ${() => `${CARD_HEIGHT}px`};
  width: ${() => `${CARD_WIDTH}px`};

  * {
    font-size: 13px !important;
    line-height: 16px !important;
  }

  &:hover {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;

const ScrollButtonWrapper = styled.div`
  height: ${() => `${CARD_HEIGHT}px`};
  width: ${() => `${SCROLL_BUTTON_WIDTH}px`};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 5px;

  cursor: pointer;
  transition: all 150ms;

  svg {
    height: 14px;
    width: 14px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    * {
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;

const ScrollButton = ({ onClick, left, right }) => {
  const handleClick = () => {
    onClick(left ? -1 : 1);
  };
  return (
    <ScrollButtonWrapper onClick={handleClick}>
      {left ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
        </svg>
      )}
    </ScrollButtonWrapper>
  );
};

const QuestionCard = ({ question, handleClick }) => {
  const onClick = () => {
    handleClick(question);
  };

  return (
    <QuestionCardWrapper onClick={onClick}>
      <Body5>{question}</Body5>
    </QuestionCardWrapper>
  );
};

export const DefaultQuestionsSelection = ({ handleQuestionClick }) => {
  const [questions] = React.useState(getDefaultQuestions());
  const scrollContainerRef = React.useRef(null);

  const width = questions.length * CARD_WIDTH + (questions.length - 1) * CARD_GAP;

  const scroll = (direction) => {
    scrollContainerRef.current.scrollBy({ left: direction * CARD_WIDTH + CARD_GAP, behavior: 'smooth' });
  };

  return (
    <DefaultQuestionsSelectionWrapper>
      <ScrollButton onClick={scroll} left />
      <ScrollWrapper ref={scrollContainerRef}>
        <RowWrapper style={{ width: width + 'px' }}>
          {questions.map((q) => (
            <QuestionCard key={q} question={q} handleClick={handleQuestionClick} />
          ))}
        </RowWrapper>
      </ScrollWrapper>
      <ScrollButton onClick={scroll} right />
    </DefaultQuestionsSelectionWrapper>
  );
};
