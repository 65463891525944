import React from 'react';
import styled from 'styled-components';
import { Gear } from '../../assets/icons/svgs';
import { Body1, Body7, Container } from '../../libs/nvstr-common-ui.es';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../../constants/actionTypes';
import {
  AdvancedSettingsFields,
  useAdvancedSettings,
  useAdvancedSettingsFields,
  useShowAdvancedSettings,
} from '../../hooks/application/useAdvancedSettings';
import { Modal } from '../../components/layout/Modal';
import { CloseButton } from '../../components/buttons/CloseButton';
import { useHasBetaFeaturesEnabled } from '../../hooks/user/useCurrentUser';
import { gptModels } from '../../constants';

const AdvancedSettingsButtonWrapper = styled.div`
  position: absolute;
  top: ${({ Top }) => Top + 16 + 'px'};
  right: 16px;
  z-index: 100;
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;
const AdvancedSettingsWrapper = styled.div`
  padding: 0;

  min-width: 340px;
`;
const CloseButtonWrapper = styled.div`
  cursor: pointer;

  z-index: 1116;

  * {
    z-index: 1116;
  }

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const GearIconWrapper = styled.div`
  svg {
    height: 18px;
    width: 18px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export function AdvancedSettingsButton({ fields, top }) {
  const dispatch = useDispatch();

  const onOpenClick = () => {
    dispatch({ type: applicationActionTypes.showAdvancedSettings, payload: fields });
  };
  return (
    <AdvancedSettingsButtonWrapper Top={top || 0}>
      <GearIconWrapper onClick={onOpenClick}>
        <Gear />
      </GearIconWrapper>
    </AdvancedSettingsButtonWrapper>
  );
}

const Wrapper = styled.div`
  //background: ${({ theme }) => theme.themeColors.appBackground};
  //border: 1px solid ${({ theme }) => theme.themeColors.border};
  padding: 24px 0 24px 0;
`;

const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const Label = styled.div`
  padding-bottom: 8px;
`;

function showField(field, fields) {
  if (!fields) return false;
  return fields.includes(field);
}

export function AdvancedSettingsModal() {
  const dispatch = useDispatch();
  const enableBetaFeatures = useHasBetaFeaturesEnabled();
  const gptModelItems = Object.values(gptModels);

  const isShowing = useShowAdvancedSettings();
  const fields = useAdvancedSettingsFields();
  const state = useAdvancedSettings();
  const {
    enableFollowUpQuestions,
    enableJS,
    enableSmoothing,
    enableDailySummaries,
    enableHeadlines,
    enableTextSearch,
    includeUserDocs,
    includeInferredMetadata,
    model,
    maxContextLength,
    maxAnswerLength,
    numOfPages,
    overrideQuestion,
    seeContext,
    recommendedModel,
    maxContextChunks,

    seed,
    temperature,
    topP,
  } = state;

  const setValue = (k, v) => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: {
        [k]: v,
      },
    });
  };

  const onSeedChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('seed', value);
  };
  const onTemperatureChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('temperature', value);
  };
  const onTopPChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('topP', value);
  };

  const onGPTModelChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('model', value);
  };
  const onOverrideQuestionChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('overrideQuestion', value);
  };
  const onMaxContextLengthChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('maxContextLength', value);
  };
  const onNumOfPagesChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('numOfPages', value);
  };
  const onMaxContextChunksChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('maxContextChunks', value);
  };
  const setToggleEnableTextSearch = () => {
    setValue('enableTextSearch', !enableTextSearch);
  };

  const setToggleIncludeUserDocs = () => {
    setValue('includeUserDocs', !includeUserDocs);
  };
  const setToggleIncludeInferredMetadata = () => {
    setValue('includeInferredMetadata', !includeInferredMetadata);
  };
  const onEnableDailySummariesToggle = () => {
    setValue('enableDailySummaries', !enableDailySummaries);
  };
  const onEnableHeadlinesToggle = () => {
    setValue('enableHeadlines', !enableHeadlines);
  };
  const onSeeContextChange = () => {
    setValue('seeContext', !seeContext);
  };
  const onEnableFollowUpQuestionsChange = () => {
    setValue('enableFollowUpQuestions', !enableFollowUpQuestions);
  };
  const onEnableJSChange = () => {
    setValue('enableJS', !enableJS);
  };
  const onEnableSmoothingChange = () => {
    setValue('enableSmoothing', !enableSmoothing);
  };
  const onMaxAnswerLengthChange = (v) => {
    setValue('maxAnswerLength', v);
  };

  const onMaxAnswerLengthChangeEffect = (e) => {
    const maxValue = 4096;
    const value = e.target.value;
    const intValue = parseInt(value, 10);
    if (intValue > maxValue) {
      onMaxAnswerLengthChange(maxValue.toString());
    } else {
      onMaxAnswerLengthChange(value);
    }
  };

  const onCloseClick = () => dispatch({ type: applicationActionTypes.hideAdvancedSettings });

  if (!isShowing) return null;

  return (
    <Modal onDismiss={onCloseClick}>
      <AdvancedSettingsWrapper>
        <Container row verticallyCenter spaceBetween>
          <Container>
            <Body1 bold>Advanced Settings</Body1>
          </Container>
          <CloseButtonWrapper>
            <CloseButton onClick={onCloseClick} />
          </CloseButtonWrapper>
        </Container>
        {null && (
          <Container top={8} right={24}>
            <Body7>These settings are for advanced use, its not recommended to change any of them.</Body7>
          </Container>
        )}
        <Wrapper>
          <FormWrapper>
            <Container>
              {showField(AdvancedSettingsFields.model, fields) && (
                <Container top={16}>
                  <Label>Model (recommended: {recommendedModel || 'gpt-4o-2024-05-13'})</Label>
                  <select onChange={onGPTModelChange} value={model}>
                    {gptModelItems.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </Container>
              )}
              {showField(AdvancedSettingsFields.overrideQuestion, fields) && enableBetaFeatures && (
                <Container top={24}>
                  <Label>Summary Question</Label>
                  <input id="override-Q" value={overrideQuestion} onChange={onOverrideQuestionChange} />
                </Container>
              )}
              {showField(AdvancedSettingsFields.maxAnswerLength, fields) && (
                <Container top={24}>
                  <Label>Max Answer Length</Label>
                  <input id="maxAnswerLength" value={maxAnswerLength} onChange={onMaxAnswerLengthChangeEffect} />
                </Container>
              )}
              {showField(AdvancedSettingsFields.maxContextLength, fields) && (
                <Container top={24}>
                  <Label>Max Context Length</Label>
                  <input id="maxContextLength" value={maxContextLength} onChange={onMaxContextLengthChange} />
                </Container>
              )}
              {showField(AdvancedSettingsFields.maxContextChunks, fields) && (
                <Container top={24}>
                  <Label>Max Context Chunks</Label>
                  <input id="maxContextChunks" value={maxContextChunks} onChange={onMaxContextChunksChange} />
                </Container>
              )}
              {showField(AdvancedSettingsFields.numOfPages, fields) && (
                <Container top={24}>
                  <Label>Number of Pages</Label>
                  <input id="numOfPages" value={numOfPages} onChange={onNumOfPagesChange} />
                </Container>
              )}

              {showField(AdvancedSettingsFields.seed, fields) && (
                <Container top={24}>
                  <Label>Seed</Label>
                  <input id="seed" value={seed} onChange={onSeedChange} />
                </Container>
              )}

              {showField(AdvancedSettingsFields.temperature, fields) && (
                <Container top={24}>
                  <Label>Temperature</Label>
                  <input id="temperature" value={temperature} onChange={onTemperatureChange} />
                </Container>
              )}

              {showField(AdvancedSettingsFields.top_p, fields) && (
                <Container top={24}>
                  <Label>Top P</Label>
                  <input id="top_p" value={topP} onChange={onTopPChange} />
                </Container>
              )}

              {showField(AdvancedSettingsFields.enableTextSearch, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="enable-text-search"
                    checked={enableTextSearch}
                    onClick={setToggleEnableTextSearch}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="enable-text-search"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Enable Text Search</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.includeUserDocsToggle, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="checkbox-include-user-docs"
                    checked={includeUserDocs}
                    onClick={setToggleIncludeUserDocs}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="checkbox-include-user-docs"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Include My Uploaded Documents</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.includeInferredMetadata, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="checkbox-include-inferred-metadata"
                    checked={includeInferredMetadata}
                    onClick={setToggleIncludeInferredMetadata}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="checkbox-include-inferred-metadata"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Include Inferred Metadata</Container>
                  </label>
                </Container>
              )}

              {showField(AdvancedSettingsFields.headlinesToggle, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="checkbox-headlines"
                    checked={enableHeadlines}
                    onClick={onEnableHeadlinesToggle}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="checkbox-headlines"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Enable Headlines</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.dailySummariesToggle, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="checkbox-daily-summary"
                    checked={enableDailySummaries}
                    onClick={onEnableDailySummariesToggle}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="checkbox-daily-summary"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Enable Daily Summary</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.seeContext, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={seeContext}
                    onClick={onSeeContextChange}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="checkbox"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Show Sources</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.followUpQuestionsToggle, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="enableFollowUpQuestions"
                    checked={enableFollowUpQuestions}
                    onClick={onEnableFollowUpQuestionsChange}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="enableFollowUpQuestions"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Enable Follow Up Questions</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.enableJS, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="enableJS"
                    checked={enableJS}
                    onClick={onEnableJSChange}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="enableJS"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Enable JS</Container>
                  </label>
                </Container>
              )}
              {showField(AdvancedSettingsFields.smoothingToggle, fields) && (
                <Container top={36} row verticallyCenter>
                  <input
                    type="checkbox"
                    id="smoothingcheckbox"
                    checked={enableSmoothing}
                    onClick={onEnableSmoothingChange}
                    style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
                  />
                  <label
                    htmlFor="smoothingcheckbox"
                    className="not-selectable"
                    style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
                  >
                    <Container bottom={3}>Enable Smoothing</Container>
                  </label>
                </Container>
              )}
            </Container>
          </FormWrapper>
        </Wrapper>
      </AdvancedSettingsWrapper>
    </Modal>
  );
}
