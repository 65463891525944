import { docsActionTypes } from '../constants/actionTypes';

const defaultState = {
  isLoading: false,
  byDay: null,

  isLoadingFed: false,
  fedRecent: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case docsActionTypes.setGettingRecentDocs: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case docsActionTypes.setRecentDocs: {
      return {
        ...state,
        isLoading: false,
        byDay: action.payload,
      };
    }

    case docsActionTypes.setGettingRecentFedDocs: {
      return {
        ...state,
        isLoadingFed: true,
      };
    }

    case docsActionTypes.setRecentFedDocs: {
      return {
        ...state,
        isLoadingFed: false,
        fedRecent: action.payload,
      };
    }

    default:
      return state;
  }
}
