import React from 'react';
import styled from 'styled-components';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { TransparentFlatButton } from '../../../components/buttons';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { ROUTES } from '../../../constants/routes';

const SelectButtonWrapper = styled.div`
  button {
    padding: 8px 10px;
  }
`;

const DocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  a:hover {
    opacity: 0.8;
  }
`;

export const DocumentSelectorCell = ({ index, docs: selectedDocs, removeDocs, onOpenDocSelect }) => {
  const onOpenDocSelectModal = () => {
    onOpenDocSelect(index);
  };

  const handleDocClick = (doc) => {
    window.open(ROUTES.docViewer + `?id=${doc.id}`, '_blank');
  };

  const handleRemoveDocuments = () => {
    removeDocs(index, null);
  };

  if (selectedDocs === null || selectedDocs[0] === null) {
    return (
      <td>
        <SelectButtonWrapper>
          <TransparentFlatButton onClick={onOpenDocSelectModal}>+ Select</TransparentFlatButton>
        </SelectButtonWrapper>
      </td>
    );
  }
  return (
    <td>
      <Container row centerAll spaceBetween>
        <DocsWrapper>
          {selectedDocs.map((doc) => (
            <Container key={doc.filename}>
              <a onClick={() => handleDocClick(doc)} style={{ cursor: 'pointer' }}>
                <Body5 bold>{doc.filename}</Body5>
              </a>
            </Container>
          ))}
        </DocsWrapper>
        <Container>
          <CloseButton onClick={handleRemoveDocuments} size={14} />
        </Container>
      </Container>
    </td>
  );
};
