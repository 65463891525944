import React from 'react';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';

export const fetchDoc = async (id) => {
  const URL = `v1/genai_docs/${id}`;
  const { status, data } = await sendApiRequest('get', URL);
  logNetRequest(status, data, URL);
  if (status === 200) {
    return { doc: data };
  } else {
    return {
      error: true,
    };
  }
};

export const useDoc = (id) => {
  const [doc, setDoc] = React.useState(null);

  React.useEffect(() => {
    const run = async (id) => {
      if (id) {
        const { doc, error } = await fetchDoc(id);
        if (error) {
          //
        } else {
          setDoc(doc);
        }
      }
    };
    run(id);
  }, [id]);
  return doc;
};
