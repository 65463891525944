const intentKey = 'intent';

export const getLastIntent = () => {
  return sessionStorage.getItem(intentKey) || null;
};

export const setLastIntent = (v) => {
  return sessionStorage.setItem(intentKey, v);
};

export const clearLastIntent = () => {
  sessionStorage.removeItem(intentKey);
};
