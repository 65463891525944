import React from 'react';
import { scrollToTop } from '../utils/usefulFuncs';
import { Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { SummarizeDocument } from '../containers/DigitalAnalyst/DocumentSummary';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { AdvancedSettingsFields } from '../hooks/application/useAdvancedSettings';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../constants/actionTypes';
import { gptModels } from '../constants';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { useColorTheme } from '../hooks';
import { useDoc } from '../hooks/features/useDoc';
import { TrackingEvent } from '../libs/nvstr-utils.es';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const advancedSettingsFields = [AdvancedSettingsFields.model, AdvancedSettingsFields.maxContextChunks];

export function DocumentSummary() {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const id = parseInt(searchParams.get('id'), 10);

  const onGoBack = () => navigate(ROUTES.home);

  const doc = useDoc(id);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: {
        model: gptModels.GPT_4O_TURBO_2024_05_13,
      },
    });

    scrollToTop();
  }, []);

  React.useEffect(() => {
    if (doc) {
      TrackingEvent.create('View Document Summary', {
        Id: id,
        Filename: doc.filename,
        'Doc Type': doc.doc_type,
        Ticker: doc.security_symbol,
      });
    }
  }, [doc]);

  if (!doc) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <BackArrowButton onClick={onGoBack} />

            <Container top={80} bottom={80}>
              <Container row verticallyCenter>
                <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
                <Container left={16}>
                  <Body5 bold>Working...</Body5>
                </Container>
              </Container>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <BackArrowButton onClick={onGoBack} />

          <AdvancedSettingsButton fields={advancedSettingsFields} />

          <Container top={36}>
            <SummarizeDocument doc={doc} />
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}
