import React from 'react';
import styled from 'styled-components';
import { Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { FormState } from '../../components/UI/FormState';
import InPageAdvancedSettings from './components/InPageAdvancedSettings';
import { FlatButton, TransparentButtonWrap, TransparentFlatButton } from '../../components/buttons';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { createTimeInstance, formatLocalizedDateTime, sleep, TrackingEvent } from '../../libs/nvstr-utils.es';
import { AI_TOOLS, docTypes, FOLLOW_UP_QUESTION_IDENTIFIER, gptModels } from '../../constants';
import { QuestionResult } from './components/QuestionResult';
import { logError, logFormSubmit, scrollToTop } from '../../utils/usefulFuncs';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { Modal, ModalWrapper } from '../../components/layout/Modal';
import { DocumentTypeMultiselect } from '../form/DocTypeMultiSelect';
import { DateRangeSelection } from '../form/DateRangeSelection';
import { DefaultQuestionsSelection } from '../UI/QuestionCards';
import { addOptionalField } from '../../utils/application';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }

  textarea {
    font-size: 13px;
    line-height: 16px;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const Label = styled.div`
  padding-bottom: 8px;
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 440px) {
    justify-content: space-between;
    max-width: 324px;

    button {
      padding: 12px 12px !important;
    }
  }
`;

const ActionsComponent = ({ handleReviseQuestion, handleAskNewQuestion }) => {
  return (
    <ActionsWrapper>
      <FlatButton onClick={(e) => handleReviseQuestion()}>Revise Question</FlatButton>
      <Container>
        <FlatButton onClick={(e) => handleAskNewQuestion()}>Ask New Question</FlatButton>
      </Container>
    </ActionsWrapper>
  );
};

const LoadingQuestionAnswer = () => {
  return (
    <Container top={80} bottom={80} centerAll>
      <Container>
        <Container centerAll>
          <Spinner />
        </Container>
        <Container top={24} left={12}>
          <Body5>Working...</Body5>
        </Container>
      </Container>
    </Container>
  );
};

const AdvancedFiltersButtonsWrapper = styled.div`
  //padding: 0 16px;

  ${TransparentButtonWrap} {
    button {
      padding: 0px 8px !important;
    }
  }
`;

const AdvancedFiltersWrapper = styled.div`
  ${ModalWrapper} {
    position: relative;

    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    height: 28px;
    width: 28px;

    fill: ${({ theme }) => theme.themeColors.text};
  }

  cursor: pointer;
`;

const ActionWrapper = styled.div`
  text-align: center;
  padding: 16px 0 0 0;
`;

const CloseButton = ({ onClick }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
      </svg>
    </ButtonWrapper>
  );
};

function AdvancedFilters({
  selections,
  onSelectionChange,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  setShowAdvancedFilters,
}) {
  return (
    <AdvancedFiltersWrapper>
      <Modal>
        <CloseButton onClick={() => setShowAdvancedFilters(false)} />
        <DocumentTypeMultiselect onSelectionChange={onSelectionChange} selections={selections} />
        <Container top={16}>
          <DateRangeSelection
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
          />
        </Container>
        <ActionWrapper>
          <SkeletonButton fullWidth onClick={() => setShowAdvancedFilters(false)}>
            Dismiss
          </SkeletonButton>
        </ActionWrapper>
      </Modal>
    </AdvancedFiltersWrapper>
  );
}

const defaultIncludeDocTypes = [
  docTypes['10K'],
  docTypes['10Q'],
  docTypes['20F'],
  docTypes['8K'],
  docTypes['6K'],
  docTypes.EARNINGS,
];

export const ScreenerTool = ({ onGoBack, isResearchMode }) => {
  const [question, setQuestion] = React.useState('');

  const [selectedQuestionCard, setSelectedQuestionCard] = React.useState(false);
  const [enableFollowUp, setEnableFollowUp] = React.useState(false);

  const [model, setModel] = React.useState(gptModels.GPT_4O_TURBO_2024_05_13);
  const [prevUsedModel, setPrevUsedModel] = React.useState(gptModels.GPT_4O_TURBO_2024_05_13);

  const [seeContext, setSeeContext] = React.useState(true);
  const [maxAnswerLength, setMaxAnswerLength] = React.useState('');
  const [maxContextLength, setMaxContextLength] = React.useState('');
  const [enableSmoothing, setEnableSmoothing] = React.useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(false);

  const [includeDocTypes, setIncludeDocTypes] = React.useState(defaultIncludeDocTypes);
  const [startDate, setStartDate] = React.useState(createTimeInstance().subtract(18, 'months').toDate());
  const [endDate, setEndDate] = React.useState(createTimeInstance().toDate());

  const [resultId, setResultId] = React.useState(null);

  const [questionAsked, setQuestionAsked] = React.useState(null);

  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const handleDefaultQuestionClick = (q) => {
    setSelectedQuestionCard(true);
    setQuestion(q);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleDocumentTypeFilterSelectionChange = (selectedTypes) => {
    setIncludeDocTypes(selectedTypes);
  };

  const onMaxContextLengthChange = (e) => {
    const {
      target: { value },
    } = e;
    setMaxContextLength(value);
  };

  const onChangeSeeContext = () => {
    setSeeContext(!seeContext);
  };

  const onGPTModelChange = (e) => {
    const {
      target: { value },
    } = e;
    setModel(value);
  };

  const onMaxAnswerLengthChange = (v) => {
    setMaxAnswerLength(v);
  };

  const onQuestionChange = (e) => {
    const {
      target: { value },
    } = e;
    setQuestion(value);
  };

  const onEnableFollowUpChange = (v) => {
    setEnableFollowUp(!enableFollowUp);
  };

  const onEnableSmoothingChange = () => {
    setEnableSmoothing(!enableSmoothing);
  };

  const submit = async ({ question: _params_question, model: forceModel }) => {
    const enhancedQuestionPrefix = 'Write an analyst report answering the question on, ';

    const _question = _params_question || question;
    const enhancedFollowUpQuestion = `${_question}. At the end of your response include a section separated by the text "${FOLLOW_UP_QUESTION_IDENTIFIER}" with a numbered list of 3 additional suggested follow up questions on the topic of this question that a stock analyst would be interested in.`;

    const _model = forceModel || model;
    setPrevUsedModel(_model);
    const form = {
      model: _model,
      question: selectedQuestionCard
        ? enhancedQuestionPrefix + _question
        : enableFollowUp
        ? enhancedFollowUpQuestion
        : _question,
      screen_mode: !isResearchMode,
      research_screen_mode: isResearchMode,
      ticker: null,
      user_mode: AI_TOOLS.SCREENING,
      doc_list: null,
      need_context: seeContext,
    };

    if (includeDocTypes.length > 0) {
      form.doc_types = includeDocTypes;
    }
    // TODO:: when updating to new adv settings
    // addOptionalField(maxContextChunks, form);
    addOptionalField('max_answer_tokens', maxAnswerLength, form);
    addOptionalField('max_context_tokens', maxContextLength, form);
    if (startDate !== null && startDate !== '') {
      form.start_date = formatLocalizedDateTime('api', createTimeInstance(startDate));
    }
    if (endDate !== null && endDate !== '') {
      form.end_date = formatLocalizedDateTime('api', createTimeInstance(endDate));
    }

    TrackingEvent.create('Asked Digital Analyst For Screener', {
      Question: question,
      Model: _model,
    });

    logFormSubmit(form);

    setResultId(null);
    setQuestionAsked(question);
    setIsSubmittingToApi(true);
    scrollToTop();

    const URL = `v1/genai_qa/stream`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    setIsSubmittingToApi(false);
    handle400Statuses(status);
    if (status === 200) {
      const { id } = data;
      setResultId(id);
      setFormError(false);
    } else {
      setResultId(null);
      setFormError(data?.error || error || 'Something went wrong, try again.');
    }
  };

  const onClearQuestion = () => {
    setResultId(null);
    setQuestionAsked(null);
    setFormError(null);
  };

  const handleReviseQuestion = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      try {
        const textarea = document.querySelector('textarea[name="question"]');
        textarea?.focus();
      } catch (e) {
        logError(e);
      }
    }, 100);
  };

  const handleShowAdvancedFilterPress = () => {
    setShowAdvancedFilters(true);
  };

  const handleAskNewQuestion = async (question) => {
    if (question) {
      await sleep(100);
      setQuestion(question);
      submit({ question });
    } else {
      handleReviseQuestion();
      setQuestion('');
    }
  };

  return (
    <PageWrapper>
      <Page width={'800px'}>
        <BackArrowButton onClick={resultId ? onClearQuestion : onGoBack} />
        {isSubmittingToApi ? (
          <LoadingQuestionAnswer />
        ) : (
          <Container top={40}>
            <FormWrapper>
              <InPageAdvancedSettings
                model={model}
                seeContext={seeContext}
                maxContextLength={maxContextLength}
                onMaxContextLengthChange={onMaxContextLengthChange}
                maxAnswerLength={maxAnswerLength}
                onMaxAnswerLengthChange={onMaxAnswerLengthChange}
                onGPTModelChange={onGPTModelChange}
                onSeeContextChange={onChangeSeeContext}
                onEnableSmoothingChange={onEnableSmoothingChange}
                enableSmoothing={enableSmoothing}
                enableFollowUpQuestions={enableFollowUp}
                onEnableFollowUpQuestionsChange={onEnableFollowUpChange}
              />
              <Container>
                {isResearchMode ? <H5>Research Screener Tool</H5> : <H5>Screener Tool</H5>}
                <Container row top={8} maxWidth={500}>
                  <Body5>{"Describe any company you're looking for, or choose/modify these questions"}</Body5>
                </Container>
                <Container>
                  <DefaultQuestionsSelection handleQuestionClick={handleDefaultQuestionClick} />
                </Container>
              </Container>
              <Container top={24}>
                <Container row verticallyCentered spaceBetween>
                  <Label>Question</Label>
                  {!showAdvancedFilters && !isResearchMode ? (
                    <AdvancedFiltersButtonsWrapper>
                      <TransparentFlatButton onClick={handleShowAdvancedFilterPress}>
                        Filter Input Documents
                      </TransparentFlatButton>
                    </AdvancedFiltersButtonsWrapper>
                  ) : null}
                </Container>
                <textarea name={'question'} onChange={onQuestionChange} value={question} />
              </Container>
              {isResearchMode ? null : (
                <Container top={16}>
                  {showAdvancedFilters ? (
                    <AdvancedFilters
                      selections={includeDocTypes}
                      onSelectionChange={handleDocumentTypeFilterSelectionChange}
                      startDate={startDate}
                      endDate={endDate}
                      handleStartDateChange={handleStartDateChange}
                      handleEndDateChange={handleEndDateChange}
                      setShowAdvancedFilters={setShowAdvancedFilters}
                    />
                  ) : null}
                </Container>
              )}
              <Container top={24}>
                <Container centerAll>
                  <FormState error={formError} />
                </Container>
                <SubmitButtonWrapper>
                  <FlatButton disabled={question.length === 0} onClick={submit} fullWidth>
                    Submit
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </FormWrapper>
          </Container>
        )}
      </Page>
      {resultId ? (
        <>
          <div id={'question-answer'}></div>
          <Page width={'800px'}>
            <Container bottom={24}>
              <QuestionResult
                resultId={resultId}
                question={questionAsked}
                ActionsComponent={
                  <Container top={16}>
                    <ActionsComponent
                      handleReviseQuestion={handleReviseQuestion}
                      handleAskNewQuestion={handleAskNewQuestion}
                    />
                  </Container>
                }
                model={prevUsedModel}
                enableFollowUpQuestions={enableFollowUp}
                handleAskQuestion={handleAskNewQuestion}
                enableMoreDetail
              />
            </Container>
          </Page>
        </>
      ) : null}
    </PageWrapper>
  );
};
