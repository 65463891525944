import { BASEURL, extendedFetch } from './network';
import { getEmailTokenParamsString } from '../utils';
import { AuthEffectHandler } from '../classes/AuthEffectHandler';
import { PermissionEffectHandler } from '../classes/PermissionHandler';

export async function getCSRFToken() {
  let token = window.localStorage.getItem('X-CSRF-Token');
  if (token) {
    return token;
  }

  const response = await fetch(`${BASEURL}/api/v1/util/csrf`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  const data = await response.json();
  token = data.csrf_token;
  window.localStorage.setItem('X-CSRF-Token', token);
  return token;
}

const appendSearchParams = (url, params) => {
  if (!params || params === '') {
    return url;
  }
  if (url.includes('?')) {
    return url + '&' + params.slice(1, params.length);
  }
  return url + params;
};

export async function sendMultipartApiRequest(method, endpointUrl, body, params_headers) {
  try {
    const headers = new Headers();
    const tokenParams = getEmailTokenParamsString();
    const csrfToken = await getCSRFToken();
    headers.set('X-CSRF-Token', csrfToken);
    headers.set('Accept', 'application/json');

    params_headers?.forEach((e) => {
      headers.set(e[0], e[1]);
    });

    const url = appendSearchParams(`${BASEURL}/api/${endpointUrl}`, tokenParams);
    const requestData = { method, body, headers, credentials: 'include' };
    const response = await fetch(url, requestData);
    const data = await response.json();
    return { status: response.status, data };
  } catch (e) {
    console.error(e);
    return {
      error: 'Something went wrong. Please try again.',
    };
  }
}

export function handle400Statuses(status) {
  handle401(status);
  handle403(status);
}

function handle401(status) {
  if (status === 401) {
    AuthEffectHandler.onLossOfAuth();
  }
}

function handle403(status) {
  if (status === 403) {
    PermissionEffectHandler.onLossOfPermission();
  }
}

export async function sendApiRequest(method, endpointUrl, body, config) {
  let response;
  try {
    const tokenParams = getEmailTokenParamsString();
    const headers = [];

    if (['post', 'patch', 'put', 'delete'].includes(method.toLowerCase())) {
      const csrfToken = await getCSRFToken();
      headers.push(['X-CSRF-Token', csrfToken]);
    }

    let bodyNormalized = null;
    if (body) {
      bodyNormalized = JSON.stringify(body);
    }

    const url = appendSearchParams(`${BASEURL}/api/${endpointUrl}`, tokenParams);
    response = await extendedFetch(url, method, bodyNormalized, headers);
    const data = await response.json();
    return { status: response.status, data };
  } catch (e) {
    console.error(e);
    return {
      status: response?.status,
      data: null,
      error: 'Something went wrong. Please try again.',
    };
  }
}

export async function sendApiDownloadRequest(endpointUrl, config) {
  try {
    const tokenParams = getEmailTokenParamsString();
    const headers = [];

    const url = appendSearchParams(`${BASEURL}/api/${endpointUrl}`, tokenParams);
    return extendedFetch(url, 'GET', headers);
  } catch (e) {
    console.error(e);
    return {
      error: 'Something went wrong. Please try again.',
    };
  }
}

export async function sendApiRequestHtml(method, endpointUrl, body, config) {
  try {
    const tokenParams = getEmailTokenParamsString();
    const headers = [];

    if (['post', 'patch', 'put', 'delete'].includes(method.toLowerCase())) {
      const csrfToken = await getCSRFToken();
      headers.push(['X-CSRF-Token', csrfToken]);
    }

    let bodyNormalized = null;
    if (body) {
      bodyNormalized = JSON.stringify(body);
    }

    const url = appendSearchParams(`${BASEURL}/api/${endpointUrl}`, tokenParams);
    const response = await extendedFetch(url, method, bodyNormalized, headers);
    const data = await response.text();
    return { status: response.status, data };
  } catch (e) {
    console.error(e);
    return {
      error: 'Something went wrong. Please try again.',
    };
  }
}
