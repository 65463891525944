import { BASEURL, extendedFetch } from './network';
import { INTERNAL_STORAGE_KEYS, InternalStorage } from '../utils/storage';
import { TrackingEvent } from '../libs/nvstr-utils.es';

const saveCSRFToken = async () => {
  try {
    const response = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET', null);
    const data2 = await response.json();
    const { csrf_token: token } = data2;
    window.localStorage.setItem('X-CSRF-Token', token);
    return {
      token,
    };
  } catch (e) {
    console.error(e);
    return {
      token: null,
    };
  }
};

export async function signIn({ email, password, rememberMe }) {
  try {
    //-- initial CSRF token - throwaway - figure out why this is needed? - EH
    const getCSRF1 = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const getCSRFBeforeLogin = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRFBeforeLogin?.json();
    const { csrf_token } = data;

    const signIn = await extendedFetch(
      `${BASEURL}/users/sign_in`,
      'POST',
      JSON.stringify({
        user: {
          email,
          password,
          remember_me: rememberMe,
          is_digital_analyst: true,
        },
      }),
      [['X-CSRF-Token', csrf_token]]
    );
    const responseData = await signIn.json();
    const { error: errorMessage } = responseData;
    if (errorMessage) {
      const displayErrorMessage =
        errorMessage === 'Invalid CSRF Token'
          ? 'An error occurred, please refresh and try to Sign In again.'
          : errorMessage;
      return { errorMessage: displayErrorMessage };
    }

    rememberMe === '1' && InternalStorage.setItemInStorage(INTERNAL_STORAGE_KEYS.EMAIL, email);
    const { token } = await saveCSRFToken();

    return {
      isAuthed: true,
      csrfToken: token,
    };
  } catch (e) {
    console.error(e);
    const errorMessage = 'An error occurred, please refresh and try to Sign In again.';
    return {
      errorMessage,
    };
  }
}

export async function isSignedIn() {
  try {
    const getCSRFBeforeLogin = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRFBeforeLogin?.json();
    const { csrf_token, is_signed_in } = data;

    if (is_signed_in) {
      window.localStorage.setItem('X-CSRF-Token', csrf_token);
      return {
        isAuthed: true,
        csrfToken: csrf_token,
      };
    } else {
      return {
        isAuthed: false,
      };
    }
  } catch (e) {
    console.error(e);
    if (window.location.pathname !== '/login') window.location = '/login';
    return {
      isAuthed: false,
    };
  }
}

export async function signUp({
  firstName,
  lastName,
  email,
  password,
  rememberMe,
  userSignUpComplete,
  userSignUpError,
  recaptchaValue,
}) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await fetch(`${BASEURL}/api/v1/util/csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { csrf_token } = data;
    const extraProperties = {};

    extraProperties.is_digital_analyst_signup = 1;

    const signUp = await fetch(`${BASEURL}/users`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        user: {
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          terms_of_service: '1',
          'g-recaptcha-response': recaptchaValue,
          ...extraProperties,
        },
      }),
    });

    const signUpData = await signUp.json();
    if (signUpData.error) {
      userSignUpError(signUpData.error);
    } else {
      userSignUpComplete();
    }
  } catch (e) {
    console.error(e);
  }
}

export async function signOut() {
  try {
    const CSRF_TOKEN = window.localStorage.getItem('X-CSRF-Token');
    const response = await extendedFetch(`${BASEURL}/users/sign_out`, 'DELETE', null, [['X-CSRF-Token', CSRF_TOKEN]]);
    if (response.status === 204) {
      window.location.reload();
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function forgotPasswordSubmit({ email, recaptchaValue, userPasswordComplete, userPasswordError }) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRF.json();
    const { csrf_token } = data;

    // Forgot Password Post call
    const signIn = await extendedFetch(
      `${BASEURL}/users/password`,
      'POST',
      JSON.stringify({
        user: {
          email,
          'g-recaptcha-response': recaptchaValue,
        },
      }),
      [['X-CSRF-Token', csrf_token]]
    );

    const signInData = await signIn.json();

    if (signInData.error) {
      userPasswordError(signInData.error);
    } else {
      userPasswordComplete();
    }
  } catch (e) {
    userPasswordError('An Error occured please. Please try again.');
    console.error(e);
  }
}

export async function resetPasswordSubmit({
  password1,
  password2,
  resetPasswordToken,
  userPasswordComplete,
  userPasswordError,
}) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRF.json();
    const { csrf_token } = data;

    // Reset Password PUT call
    const signIn = await extendedFetch(
      `${BASEURL}/users/password`,
      'PUT',
      JSON.stringify({
        user: {
          reset_password_token: resetPasswordToken,
          password: password1,
          password_confirmation: password2,
        },
      }),
      [['X-CSRF-Token', csrf_token]]
    );

    const signInData = await signIn.json();

    if (signInData.error) {
      TrackingEvent.create('Reset Password Error', { 'Error Message': signInData.error });
      userPasswordError('An error occurred, please try again. If problem persists, please contact support.');
    } else {
      userPasswordComplete();
    }
  } catch (e) {
    userPasswordError('An error occurred, please try again.');
    console.error(e);
  }
}

export async function signUpEmail({ email, userSignUpComplete, userSignUpError }) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await fetch(`${BASEURL}/api/v1/util/csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { csrf_token } = data;
    const extraProperties = {};
    extraProperties.is_digital_analyst_signup = 1;

    const signUpEmail = await fetch(`${BASEURL}/api/v1/lead`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        email,
        ...extraProperties,
      }),
    });

    const signUpData = await signUpEmail.json();

    if (signUpData.error) {
      userSignUpError(signUpData.error);
    } else {
      userSignUpComplete();
    }
  } catch (e) {
    console.error(e);
  }
}
