import { logError } from '../utils/usefulFuncs';

class _PermissionEffectHandler {
  constructor() {
    this.handler = () => null;
  }

  bindLossOfPermissionHandler = (cb) => {
    this.handler = cb;
  };

  onLossOfPermission = () => {
    try {
      this.handler();
    } catch (e) {
      logError(e);
    }
  };
}

export const PermissionEffectHandler = new _PermissionEffectHandler();
