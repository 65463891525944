import styled from 'styled-components';
import { Body5, Checkbox, Container } from '../../libs/nvstr-common-ui.es';
import React from 'react';
import { TransparentFlatButton } from '../../components/buttons';
import { useSelector } from 'react-redux';

const MultiSelectColumn = styled.div`
  display: grid;
  grid-template-columns: max-content;
  width: max-content;

  padding: 16px 8px 0 8px;
`;
const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  cursor: pointer;

  transition: all 0.14s;
  flex: 1;
  min-width: 268px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    // background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

function MultiSelect({ name, value, selections, onSelectionChange }) {
  const isSelected = selections.includes(value);

  const handleSelect = () => {
    onSelectionChange([...selections, value]);
  };
  const handleUnselect = () => {
    onSelectionChange(selections.filter((v) => v !== value));
  };

  return (
    <MultiSelectWrapper onClick={isSelected ? handleUnselect : handleSelect}>
      <Checkbox
        name={'multi-select-' + name}
        value={isSelected}
        onChange={isSelected ? () => handleUnselect : () => handleSelect}
      />
      <label htmlFor={'multi-select-' + name}>
        <Body5>{name}</Body5>
      </label>
    </MultiSelectWrapper>
  );
}

export function DocumentTypeMultiselect({ onSelectionChange, selections }) {
  const docTypes = useSelector((state) => state.constants.docTypes);

  if (!docTypes) {
    return null;
  }
  return (
    <Container>
      <Body5 bold>Only use these document types:</Body5>
      <MultiSelectColumn>
        {docTypes.map((dt) => (
          <MultiSelect key={dt} name={dt} value={dt} selections={selections} onSelectionChange={onSelectionChange} />
        ))}
      </MultiSelectColumn>
      <Container top={8} row>
        <TransparentFlatButton onClick={() => onSelectionChange([])}>Unselect All</TransparentFlatButton>
        <Container>
          <TransparentFlatButton onClick={() => onSelectionChange(docTypes.map((d) => d))}>
            Select All
          </TransparentFlatButton>
        </Container>
      </Container>
    </Container>
  );
}
