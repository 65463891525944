import React from 'react';
import { Body1, Container, H5 } from '../../libs/nvstr-common-ui.es';
import { TrackingEvent, truncate } from '../../libs/nvstr-utils.es';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { QuestionResult } from './components/QuestionResult';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { ROUTES } from '../../constants/routes';
import { docTypes } from '../../constants';
import { useAdvancedSettings } from '../../hooks/application/useAdvancedSettings';
import { isEarningsFilename, logFormSubmit, logNetRequest } from '../../utils/usefulFuncs';
import { addOptionalField } from '../../utils/application';

const a = 'Write an analyst report detailing revenue and earning metrics, major updates, and outlook in bullets.';
const b = 'Write an analyst report detailing major updates in a few bullets.';
const c =
  'Summarize this event in bullets. Highlight detailed drivers of segment performance, outlook (include detailed timing of inflection points), specific products, and inventory changes. Include insights from answers to analyst questions.';
const docTypeToPrompt = {
  '6-K': b,
  '8-K': b,
  DEF14A: b,
  DEFA14A: b,
  'S-1': a,
  '10-K': a,
  '10-Q': a,
  '20-F': a,
  '40-F': a,
};
const defaultPrompt = c;

const alternateDocTypePrompts = {
  [docTypes.EARNINGS]: 'Summarize the questions and responses on the earnings call, in bullets.',
  [docTypes.USER_EARNINGS]: 'Summarize the questions and responses on the earnings call, in bullets.',
};

const keyMetricsPrompt = 'Answer with only a table with important figures. Include a column with the key drivers';

export function SummarizeDocument({ doc }) {
  const {
    id,
    doc_type: _internal_docType,
    filename,

    security_symbol: ticker,

    is_transcript_available: hasTranscript,
    document_url,
  } = doc;

  const docType = isEarningsFilename(filename) ? docTypes.USER_EARNINGS : _internal_docType;

  const { model, maxContextChunks } = useAdvancedSettings();

  const [resultId, setResultId] = React.useState(null);
  const [alternateResultId, setAlternateResultId] = React.useState(null);
  const [keyMetricsResultId, setKeyMetricsResultId] = React.useState(null);

  const hasOriginalDoc = document_url && document_url.length > 0 ? document_url : false;
  const selectedDocs = [id];
  const tickers = [ticker];

  const askQuestion = async ({ prompt: question }) => {
    TrackingEvent.create('View Recent Document Summary', {
      Question: question,
      Tickers: tickers.length === 0 ? null : tickers,
      Model: model,
      'Selected Docs': selectedDocs,
    });

    const form = {
      question,
      ticker: tickers.length === 0 ? null : tickers,
      model,
      user_mode: 'document_summary',
      doc_list: selectedDocs,
      need_context: true,
    };
    addOptionalField('max_context_chunks', maxContextChunks, form);
    logFormSubmit(form);

    const URL = `v1/genai_qa/stream`;

    const response = await sendApiRequest('post', URL, form);
    handle400Statuses(response?.status);
    logNetRequest(response?.status, response?.data);
    return response;
  };

  const onViewOriginalDoc = () => {
    if (hasOriginalDoc) {
      window.open(document_url, '_blank');
    } else {
      window.open(ROUTES.docViewer + `?id=${id}`, '_blank');
    }
  };

  React.useEffect(() => {
    const getPromptResponse = async () => {
      const prompt = docTypeToPrompt[docType] || defaultPrompt;
      const { status, data, error } = await askQuestion({ prompt });
      logNetRequest(status, data);

      if (status === 200) {
        const { id } = data;
        setResultId(id);
      } else {
        setResultId(null);
      }
    };
    const getAlternatePromptResponse = async () => {
      const prompt = alternateDocTypePrompts[docType];
      if (!prompt) return;

      const { status: keyMetricsStatus, data: keyMetricsData } = await askQuestion({ prompt: keyMetricsPrompt });
      logNetRequest(keyMetricsStatus, keyMetricsData);

      if (keyMetricsStatus === 200) {
        const { id } = keyMetricsData;
        setKeyMetricsResultId(id);
      } else {
        setKeyMetricsResultId(null);
      }

      const { status, data } = await askQuestion({ prompt });
      logNetRequest(status, data);

      if (status === 200) {
        const { id } = data;
        setAlternateResultId(id);
      } else {
        setAlternateResultId(null);
      }
    };

    if (model) {
      getPromptResponse();
      getAlternatePromptResponse();
    }
  }, [model]);

  if (docType === docTypes.EARNINGS || docType === docTypes.USER_EARNINGS) {
    return (
      <Container>
        <Container>
          {docType === docTypes.USER_EARNINGS ? <H5>{`${filename}`}</H5> : <H5>{`${ticker} - ${docType}`}</H5>}
          <Container top={16}>
            {hasTranscript && (
              <Container>
                <TransparentFlatButton onClick={onViewOriginalDoc}>View Original Transcript</TransparentFlatButton>
              </Container>
            )}
            {hasOriginalDoc && (
              <Container>
                <TransparentFlatButton onClick={onViewOriginalDoc}>View Original Document</TransparentFlatButton>
              </Container>
            )}
          </Container>
        </Container>
        <Container top={24}>
          <Body1 bold>Summary</Body1>
          <QuestionResult
            resultId={resultId}
            question={null}
            selectedDocs={selectedDocs}
            tickers={tickers}
            model={model}
            enableMoreDetail
            collapseHeight
          />
        </Container>

        <Container top={24}>
          <Container bottom={10}>
            <Body1 bold>Key Metrics and Drivers</Body1>
          </Container>
          <QuestionResult
            resultId={keyMetricsResultId}
            question={null}
            selectedDocs={selectedDocs}
            tickers={tickers}
            model={model}
            enableMoreDetail
            collapseHeight
          />
        </Container>

        <Container top={24}>
          <Body1 bold>Analyst Q&A</Body1>
          <QuestionResult
            resultId={alternateResultId}
            question={null}
            selectedDocs={selectedDocs}
            tickers={tickers}
            model={model}
            enableMoreDetail
            collapseHeight
          />
        </Container>
      </Container>
    );
  }

  const docText = [
    docTypes.MELIUS_COMPANY_REPORT,
    docTypes.MELIUS_INDUSTRY_REPORT,
    docTypes.MELIUS_WEEKLY_VIDEO_SLIDES,
    docTypes.MELIUS_INDUSTRIAL_STRATEGY,
  ].includes(docType)
    ? `${docType} - ${filename}`
    : `${truncate(ticker, 30)} - ${docType}`;
  return (
    <Container>
      <Container>
        <H5>{docText}</H5>
      </Container>
      <Container>
        <QuestionResult
          resultId={resultId}
          question={null}
          selectedDocs={selectedDocs}
          tickers={tickers}
          model={model}
          enableMoreDetail
          ActionsComponent={
            <Container top={16}>
              {hasTranscript && (
                <Container>
                  <FlatButton onClick={onViewOriginalDoc}>View Original Transcript</FlatButton>
                </Container>
              )}
              {hasOriginalDoc && (
                <Container>
                  <TransparentFlatButton onClick={onViewOriginalDoc}>View Original Document</TransparentFlatButton>
                </Container>
              )}
            </Container>
          }
        />
      </Container>
    </Container>
  );
}
