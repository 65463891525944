import React from 'react';
import { useSelector } from 'react-redux';
import { isUndefinedOrNull } from '../../libs/nvstr-utils.es';

export const useSecurityLookup = () => {
  const store = useSelector((state) => state.securities.lookup);
  return store;
};

const getSecurityFromState = (state, id) => state.securities.lookup[id] || null;

export const useSecurity = (symbol) => {
  const storeSecurity = useSelector((state) => getSecurityFromState(state, symbol));

  const [security, setSecurity] = React.useState(storeSecurity || {});
  const isLoading = !security;

  React.useEffect(() => {
    if (security !== storeSecurity && !isUndefinedOrNull(storeSecurity)) {
      setSecurity(storeSecurity);
    }
  }, [storeSecurity, security]);

  return [isLoading, security];
};
