import styled from 'styled-components';
import React from 'react';
import { Body3, Body5, Container, H5 } from '../../../libs/nvstr-common-ui.es';
import { CollapseButton } from '../../../components/buttons/CollapseButton';
import { Answer } from './AnswerLine';
import { formattedQuestionsLookup } from '../../../hooks/features/usePromptSeries';
import { MoreDetailCompanySnapshot, MoreDetailCompanySnapshotButton } from './MoreDetailCompanySnapshot';
import { useNewCompanySnapshotStreamingResult } from '../../../hooks/features/useNewCompanySnapshotStreamingResult';
import { Context } from './SourceContext';
import { Working } from '../../../components/UI/Working';

const CompanyQuestionPromptsSummaryRowWrapper = styled.div`
  padding: 15px 0 0 0;
`;

function truncate(inputStr, maxLength) {
  if (inputStr.length <= maxLength) {
    return inputStr;
  } else {
    return inputStr.slice(0, maxLength);
  }
}

const _findQuestionDataInLookup = (q, l) => {
  let question = null;
  l.forEach((qs) => {
    if (qs[0] === q) {
      question = qs;
    }
  });
  return question;
};
const formatQuestionForDisplay = (q) => {
  const questionData = _findQuestionDataInLookup(q, formattedQuestionsLookup);
  if (!questionData) return q;
  return questionData[1];
};

const AnswerWrapper = styled.div`
  position: relative;
  padding: 16px 0 16px 8px;
`;

const CompanyQuestionPromptsSummaryRow = ({ question, answersLookup, contextLookup, answerId, ticker }) => {
  const [answer, setAnswer] = React.useState(answersLookup[answerId]);
  const [context, setContext] = React.useState(null);
  const [isHovering, setIsHovering] = React.useState(false);
  const [moreDetailRequested, setMoreDetailRequested] = React.useState(false);
  const isAnswerValid = !!answer;

  React.useEffect(() => {
    const nextAnswer = answersLookup[answerId];
    if (answer !== nextAnswer) {
      setAnswer(nextAnswer);
    }
  }, [answersLookup, answerId, answer]);

  React.useEffect(() => {
    if (contextLookup) {
      const nextAnswer = contextLookup[answerId];
      if (context !== nextAnswer) {
        setContext(nextAnswer);
      }
    }
  }, [contextLookup, answerId, context]);

  const handleMoreDetailClick = () => {
    setMoreDetailRequested(true);
  };
  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverEnd = () => {
    setIsHovering(false);
  };

  return (
    <CompanyQuestionPromptsSummaryRowWrapper>
      <Container>
        <Body3 bold>{formatQuestionForDisplay(question)}</Body3>
      </Container>
      <AnswerWrapper onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
        {answer ? <Answer result={answer} /> : <Working noVerticalPadding />}
        {context ? <Context result={context} /> : null}
        {isHovering && !moreDetailRequested && isAnswerValid && (
          <MoreDetailCompanySnapshotButton onClick={handleMoreDetailClick} />
        )}
      </AnswerWrapper>
      {moreDetailRequested && <MoreDetailCompanySnapshot ticker={ticker} question={question} />}
    </CompanyQuestionPromptsSummaryRowWrapper>
  );
};

const Answers = ({ questionList, resultId, collapseAnswer, ticker }) => {
  const {
    answersLookup,
    contextLookup,
    questionListAndAnswerIds,
    error: streamingError,
  } = useNewCompanySnapshotStreamingResult(resultId, questionList);
  if (collapseAnswer) {
    return null;
  }

  if (!answersLookup || !questionListAndAnswerIds) {
    return (
      <Container>
        <Working noVerticalPadding />
      </Container>
    );
  }

  return (
    <Container>
      {streamingError && (
        <Container bottom={24}>
          <Body5>An unexpected error occurred. Please try again.</Body5>
        </Container>
      )}

      {questionListAndAnswerIds.map((r) => {
        const [question, answerId] = r;
        return (
          <CompanyQuestionPromptsSummaryRow
            key={question}
            ticker={ticker}
            question={question}
            answersLookup={answersLookup}
            contextLookup={contextLookup}
            answerId={answerId}
          />
        );
      })}
    </Container>
  );
};

export const CompanyQuestionPromptsSummary = ({
  questionList,
  companyName,
  isRedFlags,
  isBullBear,
  ticker,
  resultId,
  noHeading,
}) => {
  const [collapseAnswer, setCollapseAnswer] = React.useState(false);

  const truncatedCompanyName = truncate(companyName || '', 33);
  const companyDisplayName = `${truncatedCompanyName} (${ticker})`;

  const Heading = isBullBear ? (
    <H5>{companyDisplayName} Bull&nbsp;Bear&nbsp;Cases</H5>
  ) : isRedFlags ? (
    <H5>{companyDisplayName} Company&nbsp;Red&nbsp;Flags</H5>
  ) : (
    <H5>{companyDisplayName} Company&nbsp;Overview</H5>
  );
  return (
    <Container>
      {noHeading ? null : (
        <Container row spaceBetween>
          {Heading}
          <CollapseButton isCollapsed={collapseAnswer} setCollapsed={setCollapseAnswer} />
        </Container>
      )}

      <Container top={noHeading ? 0 : 24}>
        <Answers questionList={questionList} resultId={resultId} ticker={ticker} collapseAnswer={collapseAnswer} />
      </Container>
    </Container>
  );
};
