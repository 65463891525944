import React from 'react';
import { Container } from '../../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { PublicFilingsToolAdvancedSettings } from './PublicFilingsToolAdvancedSettings';
import styled from 'styled-components';
import { Gear } from '../../../assets/icons/svgs';

const AdvancedSettingsWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;

  z-index: 1116;
  * {
    z-index: 1116;
  }

  svg {
    height: 12px;
    width: 12px;
    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const GearIconWrapper = styled.div`
  cursor: pointer;
  svg {
    height: 18px;
    width: 18px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export default function InPageAdvancedSettings(props) {
  const [isShowing, setIsShowing] = React.useState(false);

  const onCloseClick = () => setIsShowing(false);
  return (
    <AdvancedSettingsWrapper>
      <GearIconWrapper onClick={() => setIsShowing(true)}>
        <Gear />
      </GearIconWrapper>
      {isShowing && (
        <Container>
          <CloseButtonWrapper>
            <CloseButton onClick={onCloseClick} />
          </CloseButtonWrapper>
          <PublicFilingsToolAdvancedSettings {...props} />
        </Container>
      )}
    </AdvancedSettingsWrapper>
  );
}
