import React from 'react';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import styled from 'styled-components';
import { stripFileExtension } from '../../utils/usefulFuncs';
import ErrorText from '../../components/UI/ErrorText';

const FileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
    </svg>
  );
};

const NameWrapper = styled.div`
  min-width: 180px;

  * {
    font-size: 14px;
  }
`;
const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 24px;
    width: 24px;
  }

  button {
    padding: 4px 16px !important;
  }
`;

export const UploadFile = ({ file, onDeleteFile, onEditFile }) => {
  const inputRef = React.useRef(null);

  const [editName, setEditName] = React.useState(file.name);
  const [isEditing, setIsEditing] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleFileNameTextChange = (e) => {
    const value = e.target.value;
    setEditName(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      inputRef?.current?.blur();
    }
  };

  const handleEditFile = () => {
    const error = onEditFile(file.name, editName);
    if (error) {
      setError(error);
    }
  };

  const handleStartEditFileName = () => {
    setError(null);
    setIsEditing(true);
  };
  const handleDeleteFile = () => {
    onDeleteFile(file.name);
  };

  const onBlur = () => {
    handleEditFile();
    setTimeout(() => {
      setIsEditing(false);
    }, 300);
  };

  return (
    <Container>
      <FileWrapper>
        <Container>
          <FileIcon />
        </Container>
        <NameWrapper>
          {isEditing ? (
            <Container left={8}>
              <input
                ref={inputRef}
                value={editName}
                onKeyDown={handleKeyPress}
                onChange={handleFileNameTextChange}
                onBlur={onBlur}
                autoFocus
              />
            </Container>
          ) : (
            <Container left={8}>{file.name}</Container>
          )}
        </NameWrapper>
        <Container left={15}>
          <FlatButton onClick={handleStartEditFileName}>Edit</FlatButton>
        </Container>
        <Container left={4}>
          <TransparentFlatButton onClick={handleDeleteFile}>Delete</TransparentFlatButton>
        </Container>
      </FileWrapper>
      {error && (
        <Container left={30} top={8}>
          <ErrorText>{error}</ErrorText>
        </Container>
      )}
    </Container>
  );
};
