import React from 'react';
import { breakpoints, TrackingEvent } from '../../../libs/nvstr-utils.es';
import { logError, logFormSubmit } from '../../../utils/usefulFuncs';
import { handle400Statuses, sendApiRequest } from '../../../services/api';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { TransparentFlatButton } from '../../../components/buttons';
import { QuestionResult } from './QuestionResult';
import styled from 'styled-components';

const AnswerLineWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;

  &.more-detail {
    position: relative;
    border-radius: 5px;

    cursor: text;

    transition: transform 0.3s ease;

    background: ${({ theme }) => theme.themeColors.componentNoOpacity};
    z-index: 2;

    p,
    strong {
      margin: 0;
    }

    * {
      font-size: 13px !important;
      line-height: 16px !important;
      letter-spacing: -0.03em !important;
    }
  }

  @media (max-width: ${() => breakpoints.mobile}) {
    margin-right: 36px;
  }
`;
const MoreDetailWrapper = styled.div`
  padding: 16px 16px 16px 16px;

  p {
    padding-bottom: 16px;
  }
`;
const ShowMoreLessDetailButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 16px 0 16px;

  button {
    padding: 4px 8px 0 8px;
  }
`;
const MoreDetailEffect = styled.div`
  position: absolute;
  top: 0;
  right: -40px;
  bottom: 0;
  width: 30px;

  @media (max-width: ${() => breakpoints.mobile}) {
    right: -44px;
  }
`;
const MoreDetailIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  padding: 4px;
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};
  cursor: pointer;
  margin-right: 16px;

  border-radius: 2px;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    z-index: 5;
    height: 15px;
    width: 15px;
    margin-bottom: 0px;

    * {
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;

const Cloner = ({ el }) => {
  if (Array.isArray(el)) {
    return el.map((el, i) => (typeof el === 'object' ? React.cloneElement(el, { enableMoreDetail: false }) : el));
  }

  return el;
};

const DisableMoreDetail = ({ childrenEl }) => {
  return childrenEl.map((el, i) => (i > 1 && typeof el === 'object' ? <Cloner key={1} el={el} /> : el));
};

const ChildrenRenderer = ({ childrenEl }) => {
  if (Array.isArray(childrenEl)) {
    return <DisableMoreDetail childrenEl={childrenEl} />;
  }

  return childrenEl;
};

const extractTextNodes = (component) => {
  try {
    let queue = [];
    let text = '';
    if (Array.isArray(component)) {
      queue = [...component];
    } else {
      queue.push(component);
    }

    while (queue.length > 0) {
      const currentNode = queue.shift();

      if (!currentNode) {
        continue;
      }
      if (typeof currentNode === 'string') {
        text += currentNode;
        continue;
      }

      const children = currentNode.props.children;
      if (Array.isArray(children)) {
        children.forEach((child) => queue.push(child));
        continue;
      }

      if (typeof children === 'string') {
        text += children;
      } else {
        logError('unknown', children);
      }
    }

    return text;
  } catch (e) {
    return '';
  }
};

const Li = ({ children }) => <li>{children}</li>;
const P = ({ children }) => <p>{children}</p>;
const Icon = () => (
  <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M7.66602 4.83342H9.33268V6.50008H7.66602V4.83342ZM7.66602 8.16675H9.33268V13.1667H7.66602V8.16675ZM8.49935 0.666748C3.89935 0.666748 0.166016 4.40008 0.166016 9.00008C0.166016 13.6001 3.89935 17.3334 8.49935 17.3334C13.0993 17.3334 16.8327 13.6001 16.8327 9.00008C16.8327 4.40008 13.0993 0.666748 8.49935 0.666748ZM8.49935 15.6667C4.82435 15.6667 1.83268 12.6751 1.83268 9.00008C1.83268 5.32508 4.82435 2.33341 8.49935 2.33341C12.1743 2.33341 15.166 5.32508 15.166 9.00008C15.166 12.6751 12.1743 15.6667 8.49935 15.6667Z" />
    </g>
  </svg>
);

export const MoreDetailEnhanced = ({ isBullet, children, selectedDocs, model }) => {
  const isTextNodeExtractionNeeded = typeof children !== 'string';

  const extractText = () => {
    const text = isTextNodeExtractionNeeded ? extractTextNodes(children) : children;
    return text;
  };

  const [isHovering, setIsHovering] = React.useState(false);
  const [moreDetail, setMoreDetail] = React.useState(false);

  const [showingMoreDetail, setShowMoreDetail] = React.useState(true);
  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [resultId, setResultId] = React.useState(false);

  const handleMoreDetailClick = () => {
    setMoreDetail(true);
  };

  const onSubmit = async () => {
    const text = extractText();
    const question = `Write a short and concise stock analyst report, without a formal heading, explaining this statement further "${text}" as it applies to supplied text.`;
    setIsSubmittingToApi(true);

    TrackingEvent.create('Asked Digital Analyst For More Detail', {
      'Statement For More Detail': text,
      Question: question,
      Model: model,
      'Selected Docs': selectedDocs,
    });

    const form = {
      question: question,
      model,
      doc_list: selectedDocs,
      need_context: true,
      screen_mode: !selectedDocs || selectedDocs.length === 0,
    };
    logFormSubmit(form);

    const URL = `v1/genai_qa/stream`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    setIsSubmittingToApi(false);
    handle400Statuses(status);
    if (status === 200) {
      const { id } = data;
      setResultId(id);
    }
  };

  React.useEffect(() => {
    if (moreDetail) {
      onSubmit();
    }
  }, [moreDetail]);

  if (moreDetail) {
    const Wrapper = isBullet ? Li : P;
    return (
      <Wrapper>
        <AnswerLineWrapper>
          <Body5>
            <ChildrenRenderer childrenEl={children} />
          </Body5>

          <>
            {showingMoreDetail ? (
              <MoreDetailWrapper>
                <ShowMoreLessDetailButtonWrapper>
                  <TransparentFlatButton onClick={() => setShowMoreDetail(false)}>
                    Hide More Detail
                  </TransparentFlatButton>
                </ShowMoreLessDetailButtonWrapper>

                {isSubmittingToApi ? (
                  <Container bottom={16} top={16}></Container>
                ) : resultId ? (
                  <Container bottom={16} top={16}>
                    <QuestionResult
                      resultId={resultId}
                      question={null}
                      selectedDocs={selectedDocs}
                      model={model}
                      hideRating
                      collapseHeight
                      isMoreDetail
                    />
                  </Container>
                ) : null}
              </MoreDetailWrapper>
            ) : (
              <Container top={0} bottom={0} horizontal={16}>
                <ShowMoreLessDetailButtonWrapper>
                  <TransparentFlatButton onClick={() => setShowMoreDetail(true)}>
                    Show More Detail
                  </TransparentFlatButton>
                </ShowMoreLessDetailButtonWrapper>
              </Container>
            )}
          </>
        </AnswerLineWrapper>
      </Wrapper>
    );
  }

  const Wrapper = isBullet ? Li : P;

  const onHover = () => {
    setIsHovering(true);
  };

  const onHoverLeave = () => {
    const DELAY = 300;
    setTimeout(() => {
      setIsHovering(false);
    }, DELAY);
  };

  return (
    <Wrapper>
      <AnswerLineWrapper onMouseEnter={onHover} onMouseLeave={onHoverLeave}>
        <Body5>
          <ChildrenRenderer childrenEl={children} />
        </Body5>

        {isHovering && (
          <MoreDetailEffect>
            <MoreDetailIconWrapper onClick={handleMoreDetailClick}>
              <Icon />
            </MoreDetailIconWrapper>
          </MoreDetailEffect>
        )}
      </AnswerLineWrapper>
    </Wrapper>
  );
};
