import React from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import { FOLLOW_UP_QUESTION_IDENTIFIER } from '../../../constants';
import { MoreDetailEnhanced } from './MoreDetail';
import { extractIntegerAfterHash, isUndefinedOrNull } from '../../../utils/usefulFuncs';
import { Working } from '../../../components/UI/Working';
import { useColorTheme } from '../../../hooks';

const MarkdownStyling = styled.span`
  pre {
    background-color: ${({ theme }) => theme.themeColors.lowContrastBorder};
    border-left: 3px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.text};
    border-radius: 2px;

    margin-bottom: 1.6em;
    padding: 1em 1.5em;

    overflow: scroll;
    overflow-x: scroll;
    page-break-inside: avoid;

    * {
      line-height: 1.6;
    }
  }

  code {
    padding: 0 0.2em;

    .comment {
      /* Gray */
      color: #708090;
    }

    .string {
      /* Green */
      color: #0f9d58;
    }

    .keyword {
      /* Blue */
      color: #0086b3;
    }

    .function {
      /* Orange */
      color: #f08c00;
    }
  }

  td,
  th {
    padding: 8px;
  }

  th {
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }

  td {
    border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  }

  ol,
  ul {
    padding-top: 8px;
    padding-bottom: 4px;
  }

  li {
    min-height: 28px;
    padding: 0 0 0 2px;
    margin-bottom: 12px;

    > span:first-child {
      display: block;
    }

    p {
      margin: 0;
    }
  }
`;

const CitationWrapper = styled.span`
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  &:hover {
    svg {
      opacity: 0.6;
    }
  }
`;
const CitationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M9 22C8.4 22 8 21.6 8 21V18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H13.9L10.2 21.7C10 21.9 9.8 22 9.5 22H9M10 16V19.1L13.1 16H20V4H4V16H10M16.3 6L14.9 9H17V13H13V8.8L14.3 6H16.3M10.3 6L8.9 9H11V13H7V8.8L8.3 6H10.3Z" />
    </svg>
  );
};

const blankArray = [];

function detectPlugins(result) {
  if (!result) return blankArray;

  const plugins = [];

  const hasTable = result.indexOf('|') > -1;
  const hasStrike = result.indexOf('~~') > -1;
  if (hasTable || hasStrike) {
    plugins.push(PLUGINS.gfm);
  }

  const hasHTMLMarkdown = result.indexOf('<b') > -1;
  if (hasHTMLMarkdown) {
    plugins.push(PLUGINS.html);
  }
  const hasCodeSnip = result.indexOf('<code') > -1;
  if (hasCodeSnip) {
    plugins.push(PLUGINS.codeSyntax);
  }
  return plugins;
}

const PLUGINS = {
  codeSyntax: 'rehypeHighlight',
  gfm: 'remarkGfm',
  html: 'rehypeRaw',
};
const pluginLookup = {
  [PLUGINS.codeSyntax]: rehypeHighlight,
  [PLUGINS.gfm]: remarkGfm,
  [PLUGINS.html]: rehypeRaw,
};

function samePlugins(p1, p2) {
  if (p1.length !== p2.length) return false;
  return true;
}

function convertPluginNamesToFunc(p) {
  return p.map((n) => pluginLookup[n]);
}

function removeFollowUpQuestions(result) {
  if (!result) return result;
  return result.split(FOLLOW_UP_QUESTION_IDENTIFIER)[0];
}

function formatResult(result) {
  const v = removeFollowUpQuestions(result);
  return v;
}

function matchFilename(string, sourceDocs) {
  return sourceDocs.find((doc) => string.includes(doc.filename));
}

function levenshteinDistance(a, b) {
  const matrix = [];

  // Increment along the first column of each row
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // Increment each column in the first row
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1 // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function findBestMatch(inputString, docs) {
  let bestMatch = null;
  let lowestDistance = Infinity;

  for (let doc of docs) {
    const str = doc.filename;
    const distance = levenshteinDistance(inputString, str);
    if (distance < lowestDistance) {
      lowestDistance = distance;
      bestMatch = doc;
    }
  }

  return bestMatch;
}

function altFindHeader(string) {
  const split = string.split('-');
  const possibleHeaderNumber = parseFloat(split[split.length - 1]);
  if (!isNaN(possibleHeaderNumber)) {
    return possibleHeaderNumber;
  } else {
    return null;
  }
}

function parseChunkData(string, sourceDocs) {
  let matchedFile = matchFilename(string, sourceDocs);
  let matchedHeader = extractIntegerAfterHash(string);

  if (!matchedFile) matchedFile = findBestMatch(string, sourceDocs);
  if (!matchedHeader) matchedHeader = altFindHeader(string);

  if (isNaN(matchedHeader)) {
    return {
      doc: null,
      chunkId: null,
    };
  }

  if (sourceDocs.length === 1 && !isNaN(matchedHeader)) {
    return {
      doc: sourceDocs[0],
      chunkId: matchedHeader,
    };
  }

  return {
    doc: matchedFile,
    chunkId: matchedHeader,
  };
}

const Citation = ({ isShowingCitations, data, onClick, sourceDocs }) => {
  const debug = false;
  const { doc, chunkId } = parseChunkData(data, sourceDocs);

  const hc = (e) => {
    e.preventDefault();
    onClick(chunkId, doc);
  };

  if (isUndefinedOrNull(doc)) return null;
  if (!isShowingCitations) return null;
  return (
    <CitationWrapper onClick={hc}>
      {debug ? <Body5>{data}</Body5> : null}
      <CitationIcon />
    </CitationWrapper>
  );
};

export const ResultOnly = ({
  result,
  isShowingCitations,
  onCitationClick,
  enableMoreDetail,
  sourceDocs,
  selectedDocs,
  model,
  isComplete,
}) => {
  const [plugins, setPlugins] = React.useState(blankArray);

  React.useEffect(() => {
    if (result) {
      const detectedPlugins = detectPlugins(result);

      if (!samePlugins(detectedPlugins, plugins)) {
        setPlugins(detectedPlugins);
      }
    }
  }, [plugins, result]);

  if (!result) return null;

  const formattedResult = formatResult(result);

  return (
    <MarkdownStyling>
      <Body5>
        <Markdown
          rehypePlugins={convertPluginNamesToFunc(plugins)}
          components={{
            li(props) {
              const { node, ...rest } = props;

              if (enableMoreDetail) {
                return <MoreDetailEnhanced {...rest} selectedDocs={selectedDocs} model={model} isBullet />;
              }
              return <li {...rest} />;
            },
            a(props) {
              const { node, ...rest } = props;
              if (rest.href === 'https://example.com/citation') {
                return (
                  <Citation
                    isShowingCitations={isShowingCitations}
                    data={rest.children}
                    onClick={onCitationClick}
                    sourceDocs={sourceDocs}
                  />
                );
              }
              return null;
            },
          }}
        >
          {formattedResult}
        </Markdown>
      </Body5>
    </MarkdownStyling>
  );
};

function AddingCitations() {
  const colorTheme = useColorTheme();

  return (
    <Container row verticallyCenter>
      <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
      <Container left={8} bottom={8}>
        <Body5>Adding Citations...</Body5>
      </Container>
    </Container>
  );
}

export const Answer = ({
  result,
  isAddingCitations,
  isShowingCitations,
  onCitationClick,
  enableMoreDetail,
  selectedDocs,
  sourceDocs,
  model,
  isComplete,
}) => {
  if (result === 'Working...') {
    return <Working />;
  }
  return (
    <>
      {isAddingCitations && <AddingCitations />}

      <ResultOnly
        result={result}
        isShowingCitations={isShowingCitations}
        onCitationClick={onCitationClick}
        enableMoreDetail={enableMoreDetail}
        selectedDocs={selectedDocs}
        sourceDocs={sourceDocs}
        model={model}
        isComplete={isComplete}
      />
    </>
  );
};
