import { docsActionTypes } from '../constants/actionTypes';

const defaultState = {
  tickerFiles: {},
  miscDocs: [],
  userFilesByTicker: {},
  docLookupById: {},
  userGenericFiles: [],
  macroDocs: [],
};

export const filterDocDuplicates = (docs) => {
  const docIds = {};
  return docs.filter((d) => {
    const docId = d.id;
    if (docIds[docId]) {
      return false;
    } else {
      docIds[d.id] = true;
      return true;
    }
  });
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case docsActionTypes.setMacroDocs: {
      return {
        ...state,
        macroDocs: action.payload,
      };
    }

    case docsActionTypes.addDocs: {
      const { tickerFiles, miscDocs, docLookupById, userFilesByTicker, userGenericFiles, researchModeDocs } =
        action.payload;

      const conditionals = {};

      return {
        ...state,
        tickerFiles: {
          ...state.tickerFiles,
          ...(tickerFiles || {}),
        },
        miscDocs: filterDocDuplicates([...state.miscDocs, ...(miscDocs || [])]),
        userFilesByTicker,
        docLookupById,
        researchModeDocs,
        userGenericFiles,
        ...conditionals,
      };
    }

    default:
      return state;
  }
}
