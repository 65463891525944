import React from 'react';
import styled from 'styled-components';
import { FlatButton, TransparentFlatButton } from '../buttons';
import { Container, useScreenSize } from '../../libs/nvstr-common-ui.es';
import { SCREEN_SIZES, TrackingEvent } from '../../libs/nvstr-utils.es';
import { FullLogo } from '../../assets/logos/FullLogo';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthed } from '../../utils/selectors';
import { signOut } from '../../services/authentication';
import { CloseButton } from '../buttons/CloseButton';
import HorizontalDots from '../../assets/icons/svgs/HorizontalDots';
import { Link } from 'react-router-dom';
import { useCurrentUser, usePartnerTheme } from '../../hooks/user/useCurrentUser';
import { ROUTES } from '../../constants/routes';
import { Search } from '../UI/StockSearch';
import useWindowSize from '../../hooks/application/useWindowSize';
import { useGlobalTheme } from '../../hooks/application/useColorTheme';
import { COLOR_THEME_NAMES } from '../../constants/app';
import { colorThemeActions } from '../../constants/actionTypes';
import { setItemToStorage } from '../../utils/storage';
import { useLocationUpdate } from '../../hooks/application/useLocationSafe';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: ${({ isMobile }) => (isMobile ? '60px' : '64px')};
  padding: ${({ isMobile }) => (isMobile ? '0 12px' : '0 24px')};

  background: ${({ theme }) => theme.themeColors.header};
  // border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: ${({ largeWidth }) => (largeWidth ? '1880px' : '1440px')};
  margin: 0 auto;
`;
const GetStartedButtonWrapper = styled.div`
  button {
    padding: 18px 40px;

    background: ${({ theme }) => theme.themeColors.text} !important;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text} !important;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText} !important;
    }
  }
`;
const LoginButtonWrapper = styled.div`
  button {
    padding: 16px 24px;
  }
`;
const GetStartedButtonMobileWrapper = styled.div`
  button {
    padding: 12px 12px;

    background: ${({ theme }) => theme.themeColors.text} !important;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text} !important;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText} !important;
    }
  }
`;
const LoginButtonMobileWrapper = styled.div`
  button {
    padding: 12px 12px;
  }
`;
const DesktopLogoWrapper = styled.div`
  svg {
    width: 160px;
  }
`;
const MobileLogoWrapper = styled.div`
  svg {
    width: 120px;
  }
`;
const AdvancedSettingsModalWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  min-width: 240px;

  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 6px;
  background: ${({ theme }) => theme.themeColors.header};
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 0 16px;
  z-index: 10000;
`;
const HeaderIconButtonWrapper = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 3px;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};
  }

  transition: all 50ms;
  border: 1px solid ${({ theme }) => theme.themeColors.header};
  background: ${({ theme }) => theme.themeColors.header};

  &:hover {
    background: ${({ theme }) => theme.themeColors.appBackground};
    border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
    box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.05);
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;

  * {
    z-index: 6;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  g {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const SearchCloseButtonWrapper = styled.div`
  cursor: pointer;

  * {
    z-index: 6;
  }

  svg {
    height: 15px;
    width: 15px;
    opacity: 0.5;
  }

  g {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const FullWidthSearchWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  height: 60px;
  padding: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const FullWidthActionsWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  z-index: 1000;
  background: ${({ theme }) => theme.themeColors.overlay};
`;
const WatchlistIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding: 5px;
  margin-right: 8px;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function SignOutButton() {
  const [isSigningOut, setIsSigningOut] = React.useState(false);
  const hc = async () => {
    setIsSigningOut(true);
    const result = await signOut();
    if (!result) {
      setIsSigningOut(true);
    }
  };

  if (isSigningOut) {
    return (
      <TransparentFlatButton disabled fullWidth onClick={hc}>
        Signing Out...
      </TransparentFlatButton>
    );
  }
  return (
    <TransparentFlatButton fullWidth onClick={hc}>
      Sign Out
    </TransparentFlatButton>
  );
}

const ThemeToggleButton = () => {
  const dispatch = useDispatch();
  const theme = useGlobalTheme();

  const isLightTheme = theme.colorThemeName === COLOR_THEME_NAMES.lightProfessional;

  const onToggleThemeClick = () => {
    const nextTheme = isLightTheme ? COLOR_THEME_NAMES.darkProfessional : COLOR_THEME_NAMES.lightProfessional;
    dispatch({
      type: colorThemeActions.set,
      payload: nextTheme,
    });
    setItemToStorage('ct', nextTheme);

    TrackingEvent.create('Update Theme', {
      Theme: nextTheme,
    });
  };

  return (
    <TransparentFlatButton fullWidth onClick={onToggleThemeClick}>
      {isLightTheme ? 'Dark Mode' : 'Light Mode'}
    </TransparentFlatButton>
  );
};

function UserSettings() {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [isShowing, setIsShowing] = React.useState(false);

  const onCloseClick = () => setIsShowing(false);

  const onFAQsClick = () => {
    window.open('https://ai.tornado.com/faq', '_blank');
  };
  const onAccountSettingsClick = () => {
    navigate(ROUTES.accountSettings);
    // window.open('https://tornado.com/app/users/edit?tab=account', '_blank');
  };

  const onThemeClick = () => {
    navigate(ROUTES.themeBuilder);
  };

  if (isShowing) {
    return (
      <AdvancedSettingsModalWrapper>
        <Container bottom={24}>
          <CloseButtonWrapper>
            <CloseButton onClick={onCloseClick} />
          </CloseButtonWrapper>
          <Container top={36}></Container>
          {currentUser.is_nvstr_employee && (
            <Container top={8}>
              <TransparentFlatButton fullWidth onClick={onThemeClick}>
                Theme
              </TransparentFlatButton>
            </Container>
          )}
          <Container top={8}>
            <TransparentFlatButton fullWidth onClick={onAccountSettingsClick}>
              Account Settings
            </TransparentFlatButton>
          </Container>
          <Container top={8}>
            <ThemeToggleButton />
          </Container>

          <Container top={8}>
            <TransparentFlatButton fullWidth onClick={onFAQsClick}>
              FAQs
            </TransparentFlatButton>
          </Container>
          <Container top={8}>
            <SignOutButton />
          </Container>
        </Container>
      </AdvancedSettingsModalWrapper>
    );
  }
  return (
    <HeaderIconButtonWrapper onClick={() => setIsShowing(true)}>
      <HorizontalDots />
    </HeaderIconButtonWrapper>
  );
}

function HeaderActions({ isLoadingState, isAuthed, isMobile }) {
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const [searchActive, setSearchActive] = React.useState(false);
  const partnerTheme = usePartnerTheme();

  const handleLoginClick = () => {
    navigate(ROUTES.login);
  };
  const handleGetStartedClick = () => {
    navigate(ROUTES.signup);
  };
  const handleWatchlistClick = () => {
    navigate(ROUTES.watchlist);
  };
  const handleSearchDeactivate = () => {
    setSearchActive(false);
  };

  const onSearchResultClick = (result) => {
    const { symbol, name } = result;
    const text = `${symbol} - ${name}`;
    const param = encodeURIComponent(text);
    navigate(ROUTES.stock + '?stock=' + param);
  };

  if (isLoadingState) {
    return <div />;
  }

  if (isAuthed) {
    if (searchActive && windowWidth <= 475 && partnerTheme !== 1) {
      return (
        <FullWidthActionsWrapper onClick={handleSearchDeactivate}>
          <FullWidthSearchWrapper>
            <Search
              onResultClick={onSearchResultClick}
              onSearchDeactivate={() => setSearchActive(false)}
              searchActive
            />
            <SearchCloseButtonWrapper>
              <CloseButton onClick={handleSearchDeactivate} />
            </SearchCloseButtonWrapper>
          </FullWidthSearchWrapper>
        </FullWidthActionsWrapper>
      );
    }
    return (
      <Container row verticallyCenter left={24}>
        {partnerTheme === 1 ? null : (
          <Container right={16}>
            <Search
              onResultClick={onSearchResultClick}
              onSearchActivate={() => setSearchActive(true)}
              onSearchDeactivate={handleSearchDeactivate}
            />
          </Container>
        )}
        <UserSettings />
      </Container>
    );
  }

  if (isMobile) {
    return (
      <Container row verticallyCenter>
        <LoginButtonMobileWrapper>
          <TransparentFlatButton onClick={handleLoginClick}>Login</TransparentFlatButton>
        </LoginButtonMobileWrapper>
        <GetStartedButtonMobileWrapper>
          <FlatButton onClick={handleGetStartedClick}>Get Started</FlatButton>
        </GetStartedButtonMobileWrapper>
      </Container>
    );
  }

  return (
    <Container row verticallyCenter>
      <LoginButtonWrapper>
        <TransparentFlatButton onClick={handleLoginClick}>Login</TransparentFlatButton>
      </LoginButtonWrapper>
      <GetStartedButtonWrapper>
        <FlatButton onClick={handleGetStartedClick}>Get Started</FlatButton>
      </GetStartedButtonWrapper>
    </Container>
  );
}

export function Header({ isLoadingState, largeWidth, forceTornado }) {
  const isAuthed = useSelector(getIsAuthed);
  const screenSize = useScreenSize();
  const isMobile = screenSize === SCREEN_SIZES.mobile;

  useLocationUpdate();

  if (isMobile) {
    return (
      <OuterWrapper isMobile>
        <Wrapper largeWidth={largeWidth}>
          {isLoadingState ? null : (
            <Link to={'/'}>
              <MobileLogoWrapper>
                <FullLogo forceTornado={forceTornado} />
              </MobileLogoWrapper>
            </Link>
          )}
          <HeaderActions isLoadingState={isLoadingState} isAuthed={isAuthed} isMobile />
        </Wrapper>
      </OuterWrapper>
    );
  }
  return (
    <OuterWrapper>
      <Wrapper largeWidth={largeWidth}>
        {isLoadingState ? null : (
          <Link to={'/'}>
            <DesktopLogoWrapper>
              <FullLogo forceTornado={forceTornado} />
            </DesktopLogoWrapper>
          </Link>
        )}
        <HeaderActions isLoadingState={isLoadingState} isAuthed={isAuthed} />
      </Wrapper>
    </OuterWrapper>
  );
}
