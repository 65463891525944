import React from 'react';
import Papa from 'papaparse';

export const isEven = (v) => v % 2 === 0;

const parseCSV = async (file) => {
  return new Promise((resolve, reject) => {
    const config = {
      complete: (result) => {
        resolve(result.data);
      },
    };
    Papa.parse(file.file, config);
  });
};

const parseHeader = (row) => {
  const omittedFirstCell = row.slice(1, row.length);
  const mergedCells = omittedFirstCell.filter((element, index) => {
    return isEven(index);
  });
  return [...mergedCells];
};

function extractTextInsideParentheses(inputString) {
  // Regular expression to match text inside parentheses
  const regex = /\(([^)]+)\)/g;
  let matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  if (matches.length > 1) {
    return matches[-1];
  }

  return matches[0];
}

function removeTextInsideParentheses(inputString) {
  // Regular expression to match text inside parentheses, including the parentheses
  const regex = /\([^)]+\)/g;
  return inputString.replace(regex, '');
}

const parseStockNameAndSymbol = (s) => {
  const symbol = extractTextInsideParentheses(s);
  const name = removeTextInsideParentheses(s);
  return [symbol, name];
};

const mergeTimeSeries = (timestamps, scoreAndExplanations) => {
  const rows = [];
  timestamps.forEach((t, i) => {
    rows.push({
      timestamp: t,
      score: scoreAndExplanations[i][0],
      explanation: scoreAndExplanations[i][1],
    });
  });
  return rows;
};

const groupTimeSeriesAndStocks = (stocks, timeSeries) => {
  const group = stocks.map((s, i) => {
    const stockGroupSeriesLength = 2;
    const positionInTimeSeriesStart = 1 + i * stockGroupSeriesLength;
    const data = { ...s };
    const timestamps = [];
    const scoreAndExplanations = [];
    timeSeries.forEach((t) => timestamps.push(t[0]));
    timeSeries.forEach((series) => {
      const seriesData = series.slice(positionInTimeSeriesStart, positionInTimeSeriesStart + stockGroupSeriesLength);
      scoreAndExplanations.push(seriesData);
    });
    data.timestamps = timestamps;
    data.scoreAndExplanations = scoreAndExplanations;
    data.rows = mergeTimeSeries(timestamps, scoreAndExplanations);
    return data;
  });
  return group;
};

const parseCSVToData = (csv) => {
  const prompt = csv[0][0];
  const header = parseHeader(csv[2]);
  const dataRows = csv.slice(3, csv.length - 1);
  const timeSeries = csv.slice(5, csv.length - 1);

  const stocks = header.map((s) => {
    const [symbol, name] = parseStockNameAndSymbol(s);
    const stock = {
      symbol,
      name,
    };
    return stock;
  });

  const mergedData = groupTimeSeriesAndStocks(stocks, timeSeries);

  return {
    dataRows,
    header,
    prompt,
    mergedData,
  };
};

const parse = async (fileOrFiles) => {
  if (Array.isArray(fileOrFiles)) {
    const parsedCsvFiles = fileOrFiles.map((f) => parseCSV(f));
    const results = await Promise.all(parsedCsvFiles);
    return parseCSVToData(results[0]);
  } else {
    const result = await parseCSV(fileOrFiles);
    return parseCSVToData(result);
  }
};

export const useBuildVisualizationData = (fileOrFiles) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const handleFilesChange = async () => {
      const data = await parse(fileOrFiles);
      setData(data);
    };
    handleFilesChange();
  }, [fileOrFiles]);

  return data;
};

const parseCSVToChartData = (csv) => {
  const series = [];
  csv.forEach((s, i) => {
    const [symbol, name, score, returnPerc, startDate, endDate] = s;
    const point = {
      symbol,
      name,
      x: isNaN(parseFloat(score)) ? 0 : parseFloat(score),
      y: isNaN(parseFloat(returnPerc)) ? 0 : parseFloat(returnPerc),
    };
    series.push(point);
  });
  return series;
};

const parseForChart = async (fileOrFiles) => {
  if (Array.isArray(fileOrFiles)) {
    const parsedCsvFiles = fileOrFiles.map((f) => parseCSV(f));
    const results = await Promise.all(parsedCsvFiles);
    return parseCSVToChartData(results[0]);
  } else {
    const result = await parseCSV(fileOrFiles);
    return parseCSVToChartData(result);
  }
};

export const useBuildChartVisualizationData = (fileOrFiles) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const handleFilesChange = async () => {
      const data = await parseForChart(fileOrFiles);
      setData(data);
    };
    handleFilesChange();
  }, [fileOrFiles]);

  return data;
};
