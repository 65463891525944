import React from 'react';
import styled from 'styled-components';
import { Header } from '../../components/navigation';
import { ExtraWideBody } from '../../components/layout/Body';
import { applicationActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { gptModels } from '../../constants';
import TableAnalysisTool from '../../containers/TableAnalysis/TableAnalysisTool';
import { GRID_MODES } from '../../containers/gridMode';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const defaultAdvancedSettings = {
  model: gptModels.GPT_4O_TURBO_2024_05_13,
  maxContextLength: '32000',
};

function FedTableAnalysis() {
  const dispatch = useDispatch();
  const [docMode] = React.useState(GRID_MODES.fed);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  return (
    <PageWrapper>
      <Header />
      <ExtraWideBody withFooter>
        <TableAnalysisTool docMode={docMode} />
      </ExtraWideBody>
    </PageWrapper>
  );
}

export default FedTableAnalysis;
