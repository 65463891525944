import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../hooks';
import { useRecentFedDocsData } from '../../hooks/features/useRecentFedDocs';
import React from 'react';
import styled from 'styled-components';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';
import { AI_TOOLS } from '../../constants';
import { ROUTES } from '../../constants/routes';

const Wrapper = styled.div`
  //
`;
const SideBySidePages = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  margin: 0 16px;
`;
const DocumentIconWrapper = styled.div`
  svg {
    margin-top: 2px;
    height: 16px;
    width: 16px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const DocWrapper = styled.a`
  text-decoration: none;

  display: grid;
  grid-template-columns: 40px 1fr 200px; /* Adjust sizes as needed */

  padding: 6px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    border-bottom: 1px solid transparent;
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }
`;
const DateWrapper = styled.div`
  width: 200px;
`;

const RecentDocItem = ({ data }) => {
  const { id, filename, published_at, report_date } = data;
  const dateFormat = 'asOf';
  const date = formatLocalizedDateTime(dateFormat, createTimeInstance(published_at));

  const permissions = useCurrentUserToolsAvailable();
  const isFedGPTPermissioned = permissions.includes(AI_TOOLS.FED_GPT);
  const summaryUrl = isFedGPTPermissioned ? `fed-gpt?doc-ids=${id}` : `${ROUTES.fedDigitalAnalyst}?doc-ids=${id}`;

  return (
    <DocWrapper href={summaryUrl} target={'_blank'}>
      <DocumentIconWrapper>
        <DocumentIcon />
      </DocumentIconWrapper>
      <Container>
        <Body5>{filename}</Body5>
      </Container>
      <DateWrapper>
        <Container row verticallyCenter alignRight left={16}>
          <Body5>{date}</Body5>
        </Container>
      </DateWrapper>
    </DocWrapper>
  );
};

export const RecentFedDocsList = () => {
  const colorTheme = useColorTheme();
  const { docs, isLoading } = useRecentFedDocsData();

  if (isLoading || docs === null) {
    return (
      <Wrapper>
        <Container top={40} bottom={80}>
          <Container row verticallyCenter>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            <Container left={16}>
              <Body5 bold>Loading Recent Fed Documents</Body5>
            </Container>
          </Container>
        </Container>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {docs.map((d) => (
        <RecentDocItem key={d.id} data={d} />
      ))}
    </Wrapper>
  );
};
