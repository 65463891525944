import React from 'react';
import styled from 'styled-components';
import {
  Body1,
  Body5,
  Body6,
  Container,
  H3,
  Page,
  SVG_LOADING_ICON_TYPES,
  SvgLoadingIcon,
} from '../libs/nvstr-common-ui.es';
import { Body } from '../components/layout/Body';
import { Header } from '../components/navigation';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useColorTheme } from '../hooks';
import { useSecurity } from '../hooks/features/useSecurity';
import { fetchSecuritiesData } from '../services/securities';
import { useDispatch } from 'react-redux';
import { useLatestDocs } from '../hooks/features/useLatestDocs';
import { LatestDocsList } from '../containers/DigitalAnalyst/DocumentList';
import { scrollToTop } from '../utils/usefulFuncs';
import { createRows } from '../utils';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;
const SectionRadioButtonWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  border-radius: 5px;
  padding: 12px 24px;
  cursor: pointer;

  * {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  transition: all 0.2s;

  &.active {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
const SectionRadioButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const SECTIONS = {
  companySnapshot: 'company_snap',
  recentDocs: 'recent_docs',
};

const SectionRadioButton = ({ text, onClick, type, activeSection }) => {
  const handleClick = () => onClick(type);
  return (
    <SectionRadioButtonWrapper onClick={handleClick} className={activeSection === type ? 'active' : 'inactive'}>
      {text}
    </SectionRadioButtonWrapper>
  );
};

export default function StockDetail() {
  const colorTheme = useColorTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);

  const [searchParams] = useSearchParams();
  const securitySymbolAndName = decodeURIComponent(searchParams.get('stock'));
  const [securitySymbol, setSecuritySymbol] = React.useState(securitySymbolAndName.split(' - ')[0]);

  const [snapshotResultId, setSnapshotResultId] = React.useState(null);

  const [isLoading, security] = useSecurity(securitySymbol);
  const [activeSection, setActiveSection] = React.useState(SECTIONS.recentDocs);

  const securityName = securitySymbolAndName.split(' - ')[1];

  const {
    latestDocDays,
    latestDocsByDayLookup,

    isLoading: isLoadingRecentDocs,
    getTickerDocData,
  } = useLatestDocs();

  React.useEffect(() => {
    const nextSecuritySymbolAndName = securitySymbolAndName.split(' - ')[0];

    if (securitySymbol === 'null') {
      navigate(-1);
    } else {
      if (securitySymbol !== nextSecuritySymbolAndName) {
        setSecuritySymbol(nextSecuritySymbolAndName);
      }
    }
  }, [searchParams]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  React.useEffect(() => {
    if (securitySymbol !== 'null') {
      fetchSecuritiesData([securitySymbol])(dispatch);
      getTickerDocData({ symbols: [securitySymbol] });
    }
  }, [securitySymbol]);

  React.useEffect(() => {
    if (Array.isArray(latestDocDays)) {
      setRows(createRows(latestDocDays, latestDocsByDayLookup, []));
    }
  }, [latestDocDays, latestDocsByDayLookup]);

  const handleActiveSectionChange = (v) => {
    setActiveSection(v);
  };

  if (isLoading) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <BodyWrapper>
            <Page>
              <Container vertical={16}>
                <Body6 bold>{securitySymbol}</Body6>
                <Container>
                  <Body1>{securityName}</Body1>
                </Container>
              </Container>
              <Container vertical={24}>
                <Container centerAll>
                  <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} color={colorTheme.border} />
                  <Container left={12}>
                    <Body1 isLowContrast>Loading...</Body1>
                  </Container>
                </Container>
              </Container>
            </Page>
          </BodyWrapper>
        </Body>
      </PageWrapper>
    );
  }

  if (!security) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <BodyWrapper>
            <Page>
              <Container vertical={16}>
                <Body6 bold>Something went wrong, please try again later...</Body6>
              </Container>
            </Page>
          </BodyWrapper>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <Container vertical={16}>
              <H3 bold lineHeight={'28px'}>
                {security?.symbol}
              </H3>
              <Container top={4}>
                <Body5>{security?.name}</Body5>
              </Container>
              {security?.sector?.id && (
                <Container top={2}>
                  <Body5 isLowContrast>{security?.sector?.description}</Body5>
                </Container>
              )}
            </Container>
            {null && (
              <Container vertical={16}>
                <SectionRadioButtons>
                  <SectionRadioButton
                    text={'Recent Documents / Transcripts'}
                    activeSection={activeSection}
                    type={SECTIONS.recentDocs}
                    onClick={handleActiveSectionChange}
                  />
                </SectionRadioButtons>
              </Container>
            )}

            <Container>
              <Container vertical={24}>
                {isLoadingRecentDocs ? (
                  <Container centerAll>
                    <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} color={colorTheme.border} />
                    <Container left={12}>
                      <Body1 isLowContrast>Loading Company Documents and Transcripts...</Body1>
                    </Container>
                  </Container>
                ) : (
                  <LatestDocsList rows={rows} enableSummaryMode />
                )}
              </Container>
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}
