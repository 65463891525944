import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { scrollToTop } from '../utils/usefulFuncs';
import { Body1, Body5, Container, Page } from '../libs/nvstr-common-ui.es';
import { SupportEmail } from '../components/UI/SupportEmail';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

function AccountInReviewPage() {
  const navigate = useNavigate();

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <Container>
            <Body1 bold>Welcome to the Digital Analyst</Body1>
          </Container>
          <Container top={16}>
            <Body5>
              Thanks for your interest in ai.tornado.com. Your account is being reviewed. Please contact{' '}
              <SupportEmail /> if we can assist further.
            </Body5>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}

export default AccountInReviewPage;
