import styled from 'styled-components';
import { FOLLOW_UP_QUESTION_IDENTIFIER } from '../../../constants';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import React from 'react';
import { ResultOnly } from './AnswerLine';

const FollowUpQuestionWrapper = styled.div`
  padding: 0 16px 16px 16px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const AskQuestionButtonWrapper = styled.div`
  padding: 0 0 0 12px;

  button {
    padding: 8px 24px 10px 24px !important;
  }
`;

function filterStringsStartingWithNumberPeriod(arr) {
  return arr.filter((item) => {
    const periodIndex = item.indexOf('.');

    if (periodIndex <= 0) {
      return false;
    }

    const substringBeforePeriod = item.substring(0, periodIndex);
    const isNumber = !isNaN(substringBeforePeriod) && !isNaN(parseFloat(substringBeforePeriod));
    const isAtStart = periodIndex < 3;
    return isNumber && isAtStart;
  });
}

function removeNumericOrderFromString(str) {
  let i = 0;
  while (i < str.length) {
    if (str[i] === '.' && i !== 0) {
      i++;
      while (i < str.length && str[i] === ' ') {
        i++;
      }
      return str.substring(i);
    } else if (!isDigit(str[i])) {
      return str;
    }
    i++;
  }

  return str;
}

const removeBoldMarkdown = (str) => str.split('*').join('');

function isDigit(char) {
  return char >= '0' && char <= '9';
}

function parseFollowUpQuestionsFrom(result) {
  const followUpSection = result.split(FOLLOW_UP_QUESTION_IDENTIFIER)[1];
  if (followUpSection?.length > 0) {
    const lines = followUpSection.split('\n');
    const bullets = filterStringsStartingWithNumberPeriod(lines);
    return bullets;
  }
  return [];
}

function FollowUpQuestion({ question, handleAskQuestion, model }) {
  const hc = () => {
    handleAskQuestion(removeBoldMarkdown(removeNumericOrderFromString(question)), model);
  };

  if (handleAskQuestion) {
    return (
      <li>
        <FollowUpQuestionWrapper>
          <Body5>{removeBoldMarkdown(removeNumericOrderFromString(question))}</Body5>
          <AskQuestionButtonWrapper>
            <SkeletonButton onClick={hc}>Ask</SkeletonButton>
          </AskQuestionButtonWrapper>
        </FollowUpQuestionWrapper>
      </li>
    );
  }

  return (
    <li>
      <FollowUpQuestionWrapper>
        <ResultOnly
          result={removeBoldMarkdown(removeNumericOrderFromString(question))}
          selectedDocs={[]}
          sourceDocs={[]}
        />
      </FollowUpQuestionWrapper>
    </li>
  );
}

const useParseFollowUpQuestions = (result) => {
  const [followUpQuestions, setFollowUpQuestions] = React.useState([]);

  React.useEffect(() => {
    const parsedFollowUpQuestions = parseFollowUpQuestionsFrom(result);
    if (parsedFollowUpQuestions.length > 0) {
      setFollowUpQuestions(parsedFollowUpQuestions);
    } else {
      setFollowUpQuestions([]);
    }
  }, [result]);

  return followUpQuestions;
};

export function FollowUpQuestions({ result, handleAskQuestion, model }) {
  const followUpQuestions = useParseFollowUpQuestions(result);

  if (followUpQuestions.length === 0) {
    return null;
  }

  return (
    <Container top={16}>
      <Container bottom={16}>
        <Body5 bold>Follow Up Questions</Body5>
      </Container>
      <Body5>
        <ol>
          {followUpQuestions.map((q) => (
            <FollowUpQuestion key={q} question={q} handleAskQuestion={handleAskQuestion} model={model} />
          ))}
        </ol>
      </Body5>
    </Container>
  );
}
