import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { watchlistActions } from '../../constants/actionTypes';

export const fetchWatchlist = async (dispatch) => {
  const URL = `v1/genai_watchlist`;
  const { status, data, error } = await sendApiRequest('get', URL);
  logNetRequest(URL, status, data);
  if (status === 200) {
    dispatch({
      type: watchlistActions.update,
      payload: data.watchlist,
    });
    return true;
  } else {
    // setError('Something went wrong, please try again.');
    return false;
  }
};

export const useWatchlist = (update) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (update) {
      fetchWatchlist(dispatch);
    }
  }, []);

  const store = useSelector((state) => state.watchlist);
  const { isLoading, watchlist } = store;
  return React.useMemo(() => {
    return {
      isLoading,
      watchlist,
    };
  }, [isLoading, watchlist]);
};
