import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { infoIconActions } from '../../constants/actionTypes';

const InfoIconWrapper = styled.span`
  cursor: pointer;

  g {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const ToolTip = ({ width }) => {
  const ratio = 17 / 18;
  const height = width / ratio;
  return (
    <svg width={width} height={height || width} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M7.66602 4.83342H9.33268V6.50008H7.66602V4.83342ZM7.66602 8.16675H9.33268V13.1667H7.66602V8.16675ZM8.49935 0.666748C3.89935 0.666748 0.166016 4.40008 0.166016 9.00008C0.166016 13.6001 3.89935 17.3334 8.49935 17.3334C13.0993 17.3334 16.8327 13.6001 16.8327 9.00008C16.8327 4.40008 13.0993 0.666748 8.49935 0.666748ZM8.49935 15.6667C4.82435 15.6667 1.83268 12.6751 1.83268 9.00008C1.83268 5.32508 4.82435 2.33341 8.49935 2.33341C12.1743 2.33341 15.166 5.32508 15.166 9.00008C15.166 12.6751 12.1743 15.6667 8.49935 15.6667Z" />
      </g>
    </svg>
  );
};

const InfoIconTooltip = (props) => {
  const {
    type,
    symbol,

    customDefinition,

    position: positionKey,
    className,
    conditionalProps,
    style,
    size,
  } = props;

  const containerRef = React.useRef();
  const dispatch = useDispatch();

  const handleIconClick = () => {
    const data = {
      show: true,
      elementRef: containerRef,

      type,
      symbol,

      customDefinition,

      positionKey,
      className,
      conditionalProps,
    };
    dispatch({
      type: infoIconActions.show,
      payload: data,
    });
  };

  return (
    <InfoIconWrapper
      tabIndex="-1"
      className={`react-info-icon ${className}`}
      onFocus={handleIconClick}
      ref={containerRef}
      style={{
        position: 'static',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',
        ...(style || {}),
      }}
      {...conditionalProps}
    >
      <ToolTip width={size} />
    </InfoIconWrapper>
  );
};

InfoIconTooltip.defaultProps = {
  size: '14px',
  position: 'default',
  className: '',
  conditionalProps: {},
};

export default InfoIconTooltip;
