import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageCard, PageCardWrapper } from '../components/layout/PageCardWrapper';
import { Body5, Body6, Column, Container, Place } from '../libs/nvstr-common-ui.es';
import { PRODUCT_NAME } from '../constants/app';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import InputField from '../containers/form/InputField';
import ReCAPTCHA from 'react-google-recaptcha';
import { getRecaptchaKey } from '../constants';
import { FlatButton } from '../components/buttons';
import { SplitPage } from '../components/layout/SplitPage';
import { PublicPageMessaging } from '../components/UI/PublicPageMessaging';
import { forgotPasswordSubmit } from '../services/authentication';
import { useLocationUpdate } from '../hooks/application/useLocationSafe';

export const LogoMobileWrap = styled('div')`
  position: relative;
  top: 20px;
  padding: 0;
  display: none;
`;

export const ValidationWrap = styled('div')`
  color: ${({ theme }) => theme.themeColors.error} !important;
  font-size: 12px;
`;

export const FormWrap = styled('div')`
  position: relative;

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
  }
`;

export const LoadingCover = styled.div`
  width: 360px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
`;

export const FormTitle = styled('div')`
  font-size: 24px;
  font-weight: 600;
`;

export const SignSwitchLink = styled('div')`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
`;

const SignInFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

function ForgotPasswordForm() {
  const [email, setEmail] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);
  const [forgotPasswordComplete, setForgotPasswordComplete] = React.useState(false);
  const [isFailedValidation, setIsFailedValidation] = React.useState(true);
  const [validationMessage, setValidationMessage] = React.useState('');

  const recaptchaRef = React.createRef();

  useLocationUpdate();

  const userPasswordComplete = () => {
    setIsLoading(false);
    setForgotPasswordComplete(true);
  };

  const userPasswordError = (errorMessage) => {
    setIsLoading(false);
    setIsFailedValidation(true);
    setValidationMessage(errorMessage);
  };

  const userPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    const isEmailValid = email.trim() !== '';

    if (isEmailValid) {
      setIsFailedValidation(false);
      const recaptchaValue = recaptchaRef.current.getValue();
      forgotPasswordSubmit({
        email,
        recaptchaValue,
        userPasswordComplete,
        userPasswordError,
      });
    } else {
      setIsFailedValidation(true);
      setIsLoading(false);
      setValidationMessage('Please enter your email address.');
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userPassword(e);
    }
  };
  var webUrl = 'https://' + window.location.hostname;

  if (forgotPasswordComplete)
    return (
      <Column>
        <div>
          <Container bottom={16}>
            <FormTitle>Check Your Email</FormTitle>
          </Container>
          <Body5>
            If an active {PRODUCT_NAME} account is associated with this email address, we'll send you an email with
            instructions on how to reset your password.
          </Body5>
          <Container top={16}>
            <SignSwitchLink>
              Back to <Link to="/login">Sign In</Link>
            </SignSwitchLink>
          </Container>
        </div>
      </Column>
    );

  if (isLoading) {
    return (
      <LoadingCover>
        <LoadingSpinner text={'Submitting...'} />
      </LoadingCover>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      <FormWrap>
        <form>
          <Body6 bold>Forgot your password?</Body6>

          <Container top={16}>
            <InputField
              id="email-field"
              placeholder={'Email'}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          </Container>

          <Place padding="0px 0 10px 0">
            <ReCAPTCHA ref={recaptchaRef} sitekey={getRecaptchaKey()} />
          </Place>

          {isFailedValidation ? (
            <Container top={8} bottom={16}>
              <ValidationWrap>{validationMessage}</ValidationWrap>
            </Container>
          ) : null}

          <FlatButton id="forgot-password-submit" fullWidth onClick={userPassword}>
            Submit
          </FlatButton>

          <Container top={8} left={2}>
            <SignSwitchLink>
              Back to <Link to="/login">Sign In</Link>
            </SignSwitchLink>
          </Container>
        </form>
      </FormWrap>
    </div>
  );
}

export default function ForgotPassword() {
  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging shortened />}
      RightComponent={
        <SignInFormWrapper>
          <PageCard>
            <ForgotPasswordForm />
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
}
