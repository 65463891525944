import React from 'react';
import styled from 'styled-components';
import { NewsArticle } from './NewsArticle';
import { useRecentDocsAndNewsData } from '../../hooks/features/useRecentDocsAndNews';
import { Body5, Container, H5, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NewsArticleWrapper = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

export function NewsArticles() {
  const colorTheme = useColorTheme();
  const { news: articles } = useRecentDocsAndNewsData();

  if (!articles) {
    return (
      <Wrapper>
        <Container top={0}>
          <H5>Headlines</H5>
        </Container>
        <Container top={40} bottom={80} right={40}>
          <Container row verticallyCenter>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            <Container left={16}>
              <Body5 bold>Loading Recent News</Body5>
            </Container>
          </Container>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container bottom={16}>
        <H5>Headlines</H5>
      </Container>
      {articles.map((n) => (
        <NewsArticleWrapper key={n.id}>
          <NewsArticle data={n} />
        </NewsArticleWrapper>
      ))}
    </Wrapper>
  );
}
