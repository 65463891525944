import { liveDocsActions } from '../constants/actionTypes';
import { parseDocGroup } from '../utils/doc';

const defaultState = {
  groups: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case liveDocsActions.addGroups: {
      return {
        ...state,
        groups: action.payload,
      };
    }

    case liveDocsActions.editDoc: {
      const { id, groupName, updatedDoc } = action.payload;
      const updatedDocGroupName = parseDocGroup(updatedDoc);

      if (groupName === updatedDocGroupName) {
        const groupDocs = state.groups[groupName];
        const copyDocs = [...groupDocs];
        const index = copyDocs.findIndex((d) => d.id === id);
        copyDocs[index] = updatedDoc;

        return {
          ...state,
          groups: {
            ...state.groups,
            [groupName]: copyDocs,
          },
        };
      } else {
        const oldGroupName = groupName;
        const oldGroupDocs = state.groups[oldGroupName];
        const copyOldGroupDocs = [...oldGroupDocs];
        const updatedOldGroupDocs = copyOldGroupDocs.filter((d) => d.id !== id);

        const newGroupName = updatedDocGroupName;
        let newGroupDocs = state.groups[newGroupName];
        if (!newGroupDocs) {
          newGroupDocs = [];
        }
        const copyNewGroupDocs = [...newGroupDocs];
        copyNewGroupDocs.push(updatedDoc);

        return {
          ...state,
          groups: {
            ...state.groups,
            [oldGroupName]: updatedOldGroupDocs,
            [newGroupName]: copyNewGroupDocs,
          },
        };
      }
    }

    case liveDocsActions.deleteDoc: {
      const { id, groupName } = action.payload;
      const groupDocs = state.groups[groupName];
      const copyDocs = [...groupDocs];
      const updatedDocs = copyDocs.filter((d) => d.id !== id);

      return {
        ...state,
        groups: {
          ...state.groups,
          [groupName]: updatedDocs,
        },
      };
    }

    default:
      return state;
  }
}
