import React from 'react';
import styled from 'styled-components';
import { Container } from '../../libs/nvstr-common-ui.es';
import { colorPalette } from '../../libs/nvstr-utils.es';

const NewsText = styled.div`
  flex: 1;
  padding-right: 24px;

  .truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines you want to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Image = styled.img`
  height: 84px;
  width: 120px;
  object-fit: cover;
`;
const NewsImageWrapper = styled.div`
  width: 140px;
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;
const Description = styled.div`
  font-size: 13px;
  line-height: 18px;
`;

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 600px;

  text-decoration: none;
  cursor: pointer;

  .news-image-container {
    position: relative;
    height: 84px;
    width: 120px;
    overflow: hidden;
    border-radius: 8px;
    //border: 1px solid black;
  }

  :hover {
    ${Title} {
      color: ${({ theme }) => colorPalette.secondary.lightBlue};

      * {
        color: ${({ theme }) => colorPalette.secondary.lightBlue} !important;
      }
    }
  }
`;

const SmallImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 84px;
  width: 120px;
`;

const NewsImage = ({ src, title, domain }) => {
  if (domain === 'ft.com') {
    return (
      <SmallImageWrapper>
        <img src={src} alt={title} />
      </SmallImageWrapper>
    );
  }
  return <Image src={src} alt={title} />;
};

const formatTitle = (title, domain) => {
  if (domain === 'ft.com') return title.split('Financial Times')[0];
  return title;
};
const correctSourceText = (source) => {
  if (!source) return '';
  if (source.toLowerCase() === 'ft.com') return 'Financial Times';
  return source;
};

function Dot() {
  return <div style={{ fontSize: '30px', lineHeight: '18px' }}>&nbsp;&#183;&nbsp;</div>;
}

export function NewsArticle({ data }) {
  const { id, source, timestamp, metadata, open_graph_object: ogo, security } = data;
  const { description, domain, original_link_url, title, url, image_link: src } = ogo;
  return (
    <Wrapper href={url} target={'_blank'}>
      <NewsText>
        <Container>
          <Title>{formatTitle(title, domain)}</Title>
        </Container>
        <Container top={6}>
          <Description className={'truncate'}>{description}</Description>
        </Container>
        <Container top={6} row verticallyCenter>
          <Description>{correctSourceText(source)}</Description>
          <Dot />
          <Description>{domain}</Description>
        </Container>
      </NewsText>
      {null && (
        <NewsImageWrapper className={'news-image-container'}>
          <NewsImage src={src} title={title} domain={domain} />
        </NewsImageWrapper>
      )}
    </Wrapper>
  );
}
