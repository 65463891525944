import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import {
  Body1,
  Body3,
  Body5,
  Container,
  H5,
  Page,
  SVG_LOADING_ICON_TYPES,
  SvgLoadingIcon,
} from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { useColorTheme } from '../hooks';
import { AI_TOOLS, docTypes, gptModels } from '../constants';
import { parseDateString, scrollToTopPageLoad } from '../utils/usefulFuncs';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { AdvancedSettingsFields, useAdvancedSettings } from '../hooks/application/useAdvancedSettings';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { useDocs } from '../hooks/features/useDocs';
import { DocRow } from '../containers/DigitalAnalyst/components/DocRow';
import { useCustomPromptSeries } from '../hooks/features/useCustomPromptSeries';
import { useNewCompanySnapshotStreamingResult } from '../hooks/features/useNewCompanySnapshotStreamingResult';
import { Answer } from '../containers/DigitalAnalyst/components/AnswerLine';
import { createTimeInstance } from '../libs/nvstr-utils.es';
import { Context } from '../containers/DigitalAnalyst/components/SourceContext';
import { Working } from '../components/UI/Working';
import { FlatButton } from '../components/buttons';

const advancedSettingsFields = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxAnswerLength,
  AdvancedSettingsFields.smoothingToggle,
  AdvancedSettingsFields.maxContextChunks,
];

const defaultAdvancedSettings = {
  model: gptModels.GPT_4O_TURBO_2024_05_13,
  enableSmoothing: false,
  maxAnswerLength: 1000,
};

const earningsDocTypes = [
  docTypes.EARNINGS,
  docTypes.USER_EARNINGS,
  docTypes.CONFERENCE_TRANSCRIPTS,
  docTypes.LENDER_CALL,
];

const tool = AI_TOOLS.LENDER_EARNINGS_CALL_ANALYSIS;

const defaultPrompts = [
  {
    displayQuestion: 'Summary',
    prompt:
      'Please list the salient takeaways from this earnings call, paying attention to past results, trends, tone of the speakers, and expectations for the future, with regard to the financial performance of the company, and what might affect that performance positively or negatively going forward. Highlight important quotes, and place important figures and their trends in table format.',
  },
  {
    displayQuestion: 'Important Figures and Drivers',
    prompt: 'Answer with only a table with important figures and drivers',
  },
  {
    displayQuestion: 'Takeaways',
    prompt: 'Please list the salient takeaways from this earnings call.',
  },
  {
    displayQuestion: 'Factors Which May Affect Performance',
    prompt: 'Based on this document, which factors may positively or negatively affect performance going forward?',
  },
  {
    displayQuestion: 'Important Quotes',
    prompt: 'Based on this document, what are some important quotes from management?',
  },
];

function replacePromptWithDisplayQuestion(prompt, prompts) {
  if (!prompts) return null;

  const foundPrompt = prompts.filter((p) => prompt === p.prompt)[0];
  return foundPrompt?.displayQuestion || null;
}

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StretchPage = styled.div`
  min-height: 80vh;
`;

const DocsListWrapper = styled.div`
  //
`;

function extractSortDate(doc) {
  const { filename, created_at } = doc;
  const parsedFileNameDate = parseDateString(filename);
  let date = createTimeInstance(parsedFileNameDate);
  if (date?.isValid()) {
    return date;
  } else {
    return createTimeInstance(created_at);
  }
}

const DocsList = ({ docs, onSelect, isLoading }) => {
  const navigate = useNavigate();
  const [filteredDocs, setFilteredDocs] = React.useState(null);
  const [noDocs, setNoDocs] = React.useState(false);

  React.useEffect(() => {
    if ((!docs || docs.length === 0) && !isLoading) {
      setNoDocs(true);
      return;
    }
    if (docs) {
      const filtered = docs.filter((d) => earningsDocTypes.includes(d.doc_type));
      const sorted = filtered.sort((a, b) => {
        const aDate = extractSortDate(a);
        const bDate = extractSortDate(b);
        return bDate.valueOf() - aDate.valueOf();
      });
      if (sorted.length === 0) {
        setNoDocs(true);
      } else {
        setNoDocs(false);
      }
      setFilteredDocs(sorted);
    }
  }, [docs, isLoading]);

  const handleUploadDocsClick = () => {
    navigate(ROUTES.docManagement);
  };

  if (noDocs && !isLoading) {
    return (
      <Container style={{ maxWidth: '500px' }}>
        <p>
          <Body5>
            You must upload earnings transcript, conference transcripts, or lender calls to access this mode.
          </Body5>
        </p>
        <p>
          <Body5>
            Please use Upload Source Document in the main menu to upload earnings call transcripts which can be analyzed
            here, or use Upload Documents Now to navigate there.
          </Body5>
        </p>
        <Container top={16}>
          <FlatButton onClick={handleUploadDocsClick}>Upload Documents Now</FlatButton>
        </Container>
      </Container>
    );
  }

  if (filteredDocs === null) {
    return null;
  }

  return (
    <DocsListWrapper>
      {filteredDocs.map((d) => (
        <DocRow key={d.id} doc={d} onClick={onSelect} />
      ))}
    </DocsListWrapper>
  );
};

const AnalysisWrapper = styled.div``;

const AnswerRowWrapper = styled.div`
  padding-bottom: 24px;

  table {
    margin: 16px 16px;
  }
`;

const Answers = ({ prompts, questionList, resultId, model, selectedDocs }) => {
  const state = useNewCompanySnapshotStreamingResult(resultId, questionList);
  const { answersLookup, contextLookup, questionListAndAnswerIds, error: streamingError } = state;

  if (!answersLookup || !questionListAndAnswerIds) {
    return (
      <Container>
        <Working noVerticalPadding />
      </Container>
    );
  }

  return (
    <Container>
      {questionListAndAnswerIds.map((r) => {
        const [question, answerId] = r;
        const answer = answersLookup[answerId];
        const context = contextLookup ? contextLookup[answerId] : null;
        const displayQuestion = replacePromptWithDisplayQuestion(question, prompts);
        return (
          <AnswerRowWrapper>
            <Container>
              <Body3 bold>{displayQuestion}</Body3>
            </Container>
            <Container top={16} left={16}>
              {answer ? (
                <Answer result={answer} model={model} selectedDocs={selectedDocs} enableMoreDetail />
              ) : (
                <Working noVerticalPadding />
              )}
              {context ? <Context result={context} /> : null}
            </Container>
          </AnswerRowWrapper>
        );
      })}
      {streamingError && (
        <Container bottom={24}>
          <Body5>An unexpected error occurred. Please try again.</Body5>
        </Container>
      )}
    </Container>
  );
};

const Analysis = ({ doc }) => {
  const { model, enableSmoothing, maxAnswerLength, maxContextChunks } = useAdvancedSettings();

  const [docList] = React.useState([doc]);
  const [settings, setSettings] = React.useState({
    model,
    maxContextChunks: maxContextChunks || null,
    enableSmoothing: enableSmoothing || false,
    maxAnswerLength: maxAnswerLength || null,
    user_mode: tool,
  });
  const [prompts] = React.useState(defaultPrompts);
  const questionList = prompts.map((p) => p.prompt);

  const { resultId, isSubmittingToApi, formError } = useCustomPromptSeries(prompts, docList, settings);

  React.useEffect(() => {
    if (
      settings.model !== model ||
      settings.enableSmoothing !== enableSmoothing ||
      settings.maxAnswerLength !== maxAnswerLength
    ) {
      setSettings({
        model,
        enableSmoothing,
        maxAnswerLength,
      });
    }
  }, [settings, model, enableSmoothing, maxAnswerLength]);
  if (formError) {
    return (
      <Container>
        <Body5>{formError}</Body5>
      </Container>
    );
  }

  return (
    <AnalysisWrapper>
      <Container>
        {resultId ? (
          <Answers
            prompts={prompts}
            questionList={questionList}
            resultId={resultId}
            model={model}
            selectedDocs={docList}
          />
        ) : null}
      </Container>
    </AnalysisWrapper>
  );
};

const LenderEarningCallAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colorTheme = useColorTheme();

  const [wasError, setWasError] = React.useState(false);
  const [filters] = React.useState({ isUserOnlyMode: true });

  const [selectedDoc, setSelectedDoc] = React.useState(null);

  const { docs, isLoading, error } = useDocs(filters);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });

    scrollToTopPageLoad();
  }, []);

  const onDocSelect = (d) => {
    setSelectedDoc(d);
  };

  const onRemoveSelectedDoc = () => {
    setSelectedDoc(null);
  };

  const onClear = () => {
    onRemoveSelectedDoc();
  };

  const onGoBack = () => (selectedDoc === null ? navigate(ROUTES.home) : onClear());

  if (wasError || error) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={40} bottom={144}>
              <Body5>Something went wrong, please try again later.</Body5>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  if (selectedDoc === null) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <StretchPage>
              <BackArrowButton onClick={onGoBack} />
              <AdvancedSettingsButton fields={advancedSettingsFields} />
              <Container top={40}>
                <H5>Select Earnings Transcript</H5>
                <Container row top={8}>
                  {isLoading && (
                    <Container left={16} top={40} bottom={96}>
                      <Container row verticallyCenter>
                        <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
                        <Container left={16}>
                          <Body5>Loading Earning Transcripts...</Body5>
                        </Container>
                      </Container>
                    </Container>
                  )}
                </Container>
              </Container>
              <Container top={24}>
                <DocsList docs={docs} onSelect={onDocSelect} isLoading={isLoading} />
              </Container>
            </StretchPage>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <StretchPage>
            <BackArrowButton onClick={onGoBack} />
            <AdvancedSettingsButton fields={advancedSettingsFields} />
            <Container top={40}>
              <Body1 bold>{selectedDoc.filename}</Body1>
              <Container top={24}>
                <Analysis doc={selectedDoc} />
              </Container>
            </Container>
          </StretchPage>
        </Page>
      </Body>
    </PageWrapper>
  );
};

export default LenderEarningCallAnalysis;
