import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import { AdvancedSettingsFields } from '../hooks/application/useAdvancedSettings';
import { AI_TOOLS, gptModels } from '../constants';
import NewQueryTool from '../containers/DigitalAnalyst/NewQueryTool';
import { useHasBetaFeaturesEnabled } from '../hooks/user/useCurrentUser';
import { Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { useColorTheme } from '../hooks';
import { usePreselectedDocs } from '../hooks/features/usePreselectedDocs';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const experimentalAdvancedSettingsFields = [
  AdvancedSettingsFields.seed,
  AdvancedSettingsFields.temperature,
  AdvancedSettingsFields.top_p,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.maxAnswerLength,
  AdvancedSettingsFields.maxContextChunks,
  AdvancedSettingsFields.enableTextSearch,
];
const advancedSettingsFields = [AdvancedSettingsFields.model, AdvancedSettingsFields.enableTextSearch];
const advancedSettings = {
  seeContext: true,
  enableFollowUpQuestions: false,
  model: gptModels.GPT_4O_TURBO_2024_05_13,
  maxContextLength: '',
  maxAnswerLength: '',
  enableTextSearch: true,
  recommendedModel: gptModels.GPT_4O_TURBO_2024_05_13,
  seed: null,
  temperature: null,
  topP: null,
};

const tool = AI_TOOLS.FED_GPT;

function Fedgpt() {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();
  const showExperiementalSettings = useHasBetaFeaturesEnabled();

  const { complete: completedPreselectDocs, preSelectedDocs } = usePreselectedDocs();
  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  if (!completedPreselectDocs) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page width={'600px'}>
            <BackArrowButton onClick={onGoBack} />
            <Container top={99} centerAll bottom={99}>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
              <Container left={8}>
                <Body5>Loading Documents...</Body5>
              </Container>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <NewQueryTool
          onGoBack={onGoBack}
          isFedMode
          isFedGpt
          advancedSettingsFields={
            showExperiementalSettings ? experimentalAdvancedSettingsFields : advancedSettingsFields
          }
          advancedSettings={advancedSettings}
          preSelectedDocs={preSelectedDocs}
          tool={tool}
        />
      </Body>
    </PageWrapper>
  );
}

export default Fedgpt;
