import React from 'react';
import { TagInput } from '../../form/TagInput';
import { TrackingEvent } from '../../../libs/nvstr-utils.es';
import { Container } from '../../../libs/nvstr-common-ui.es';

export const TickerSelect = ({
  tickers,
  onAddTicker,
  onRemoveTicker,
  multipleTickersEnabled,
  isResearchMode,
  hideLabel,
}) => {
  const onAddTag = (t, n) => {
    TrackingEvent.create('Added Tickers For Digital Analyst', {
      Tickers: [t],
    });

    onAddTicker(t, n);
  };

  return (
    <Container>
      <TagInput
        label={multipleTickersEnabled ? 'Tickers' : 'Ticker'}
        tags={tickers}
        onAddTag={onAddTag}
        onRemoveTag={onRemoveTicker}
        isResearchMode={isResearchMode}
        hideLabel={hideLabel}
      />
    </Container>
  );
};
