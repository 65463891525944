import { useDispatch } from 'react-redux';
import React from 'react';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { watchlistActions } from '../../constants/actionTypes';
import { FlatButton } from '../../components/buttons';
import { Modal } from '../../components/layout/Modal';
import { Body1, Container } from '../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../components/buttons/CloseButton';
import { Search } from '../../components/UI/StockSearch';

export function AddToWatchlist() {
  const dispatch = useDispatch();
  const [modalProps] = React.useState({ maximizeWidth: false, forceHeight: true });
  const [isAdding, setIsAdding] = React.useState(false);

  const postNewWatchlistStocks = async (stocks) => {
    const newWatchlistIds = stocks.map((s) => {
      const { company_id: id } = s;
      return id;
    });
    const URL = `v1/genai_watchlist`;
    const form = {
      watchlist: newWatchlistIds,
    };
    const { status, data, error } = await sendApiRequest('post', URL, form);
    logNetRequest(URL, status, data);
    if (status === 200) {
      dispatch({
        type: watchlistActions.add,
        payload: stocks,
      });
    } else {
      // setError('Something went wrong, please try again.');
    }
  };

  const handleResultClick = (result) => {
    postNewWatchlistStocks([result]);
  };

  return (
    <>
      <FlatButton onClick={() => setIsAdding(true)}>+ Add to watchlist</FlatButton>
      {isAdding && (
        <Modal modalProps={modalProps} onDismiss={() => setIsAdding(false)}>
          <Container>
            <Container row spaceBetween verticallyCenter>
              <Body1 bold>Add to Watchlist</Body1>
              <CloseButton onClick={() => setIsAdding(false)} />
            </Container>
            <Container top={24}>
              <Search onResultClick={handleResultClick} searchActive />
            </Container>
          </Container>
        </Modal>
      )}
    </>
  );
}
