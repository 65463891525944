import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
`;

export const IconButton = ({ children, onClick }) => {
  return <Wrapper onClick={onClick}>{children}</Wrapper>;
};
