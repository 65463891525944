import { colorThemeActions } from '../constants/actionTypes';
import {
  defaultCustomColorTheme,
  generateComputedColors,
  getStartingColorTheme,
  getStartingColorThemeName,
} from '../utils/themes';

const defaultState = {
  value: getStartingColorThemeName(),
  customTheme: getStartingColorTheme(),
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case colorThemeActions.set: {
      return {
        ...state,
        value: action.payload,
      };
    }

    case colorThemeActions.setCustomColorThemeColorDefinition: {
      const colors = action.payload;
      const computedColors = generateComputedColors(colors);
      return {
        ...state,
        customTheme: {
          ...state.customTheme,
          ...colors,
          ...computedColors,
        },
      };
    }

    case colorThemeActions.resetCustomColorTheme: {
      return {
        ...state,
        customTheme: {
          ...defaultCustomColorTheme,
        },
      };
    }

    default:
      return state;
  }
}
