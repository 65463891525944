import { Body1, Body5 } from '../../libs/nvstr-common-ui.es';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;
const HeadingWrapper = styled.div``;
const DescriptionWrapper = styled.div`
  padding-top: 8px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 16px;
  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  min-height: 97px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-radius: 2px;
  }
`;
const ButtonTextWrapper = styled.div`
  padding-left: 16px;
`;

const BetaFlagWrapper = styled.span`
  color: ${({ theme }) => theme.themeColors.error};
  font-size: 12px;
  margin: 0 0 0 8px;
  vertical-align: super;
`;
const BetaFlag = () => {
  return <BetaFlagWrapper>Beta</BetaFlagWrapper>;
};

export const PageNavigationButton = ({ Icon, heading, description, onClick, isBeta }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <IconWrapper>{Icon}</IconWrapper>
      <ButtonTextWrapper>
        <HeadingWrapper>
          <Body1 bold>{heading}</Body1>
          {isBeta && <BetaFlag />}
        </HeadingWrapper>
        <DescriptionWrapper>
          <Body5>{description}</Body5>
        </DescriptionWrapper>
      </ButtonTextWrapper>
    </ButtonWrapper>
  );
};
