import React, { useState } from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body, FooterSeparator } from '../components/layout/Body';
import { Body1, Body5, CheckCircle, Container, Page } from '../libs/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { TrackingEvent } from '../libs/nvstr-utils.es';
import { useColorTheme } from '../hooks';
import InputField from '../containers/form/InputField';
import { FlatButton } from '../components/buttons';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { handle400Statuses, sendApiRequest } from '../services/api';
import { logError, logNetRequest } from '../utils/usefulFuncs';
import { Working } from '../components/UI/Working';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;

export const FormWrap = styled('div')`
  margin-top: -16px;
  position: relative;
  padding: 16px 0;

  max-width: 500px;

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
  }
`;
export const ValidationWrap = styled('div')`
  color: ${({ theme }) => theme.themeColors.error} !important;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
`;
const CheckWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

const ResetPassword = () => {
  const colorTheme = useColorTheme();

  const [currentPassword, setCurrentPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [forgotPasswordComplete, setResetPasswordComplete] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  React.useEffect(() => {
    if (forgotPasswordComplete) {
      setTimeout(() => {
        try {
          window.location.reload();
        } catch (e) {
          logError(e);
        }
      }, 2000);
    }
  }, [forgotPasswordComplete]);
  const userPasswordComplete = () => {
    setIsSubmitting(false);
    setResetPasswordComplete(true);
  };

  const userPasswordError = (errorMessage) => {
    setIsSubmitting(false);
    setValidationMessage(errorMessage);
  };

  const validateAndSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const passwordLength = password1 && password1.length > 7;
    const passWordsMatch = password1 === password2;

    const passValidation = passwordLength && passWordsMatch;
    if (!passValidation) {
      if (!passWordsMatch) {
        setValidationMessage('Please enter the same password twice.');
      } else if (!passwordLength) {
        setValidationMessage('Password must have at least 8 characters.');
      }
      return null;
    }

    setIsSubmitting(true);
    setValidationMessage(null);
    const form = {
      // user: {
      current_password: currentPassword,
      password: password1,
      password_confirmation: password2,
      // },
    };

    TrackingEvent.create('Request Change Password', {});
    const URL = 'v1/current_user/password';
    const { status, data, error } = await sendApiRequest('PATCH', URL, form);
    logNetRequest(status, data, error);
    handle400Statuses(status);
    if (status === 200) {
      TrackingEvent.create('Request Change Password Success', {});
      userPasswordComplete();
    } else {
      let message = data?.error || error || 'Something went wrong, please try again.';
      message = message.replace('invalid', 'incorrect');
      TrackingEvent.create('Request Change Password Failed', {
        Message: message,
      });
      userPasswordError(message);
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      validateAndSubmit(e);
    }
  };

  if (isSubmitting) {
    return (
      <Container>
        <Working />
      </Container>
    );
  }

  if (forgotPasswordComplete) {
    return (
      <Container top={48} bottom={64}>
        <Container row verticallyCenter>
          <CheckWrapper>
            <CheckCircle />
          </CheckWrapper>
          <Container left={15}>
            <Body1 bold>Changed Password Successfully</Body1>
          </Container>
        </Container>
        <Container top={8}>
          <Body5>Please login again with the new password.</Body5>
        </Container>
      </Container>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      <FormWrap>
        <form>
          <Body1 bold>Change Password</Body1>
          <Container left={4}>
            <Container top={24}>
              <Body5>Current Password</Body5>
            </Container>
            <Container top={8}>
              <InputField
                id="current-password-field-1"
                placeholder={'Current Password'}
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="password"
              />
            </Container>
            <Container top={8}>
              <Container bottom={8}>
                <Body5>New Password</Body5>
              </Container>
              <InputField
                id="password-field-1"
                placeholder={'New Password'}
                type="password"
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
                autoComplete="password1"
              />
            </Container>
            <Container bottom={8}>
              <Body5>Confirm New Password</Body5>
            </Container>
            <InputField
              id="password-field-2"
              placeholder={'Confirm New Password'}
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              autoComplete="password2"
            />

            <ValidationWrap>{validationMessage || null}</ValidationWrap>
            <Container top={16} style={{ textAlign: 'center' }}>
              <FlatButton id="forgot-password-submit" fullWidth onClick={validateAndSubmit}>
                Submit
              </FlatButton>
            </Container>
          </Container>
        </form>
      </FormWrap>
    </div>
  );
};

export const AccountSettings = () => {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    TrackingEvent.create('View Account Settings', {});
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page width={'525px'}>
            <BackArrowButton onClick={onGoBack} />
            <Container top={40}>
              <Body1 bold>Account Settings</Body1>
            </Container>
            <FooterSeparator />
            <Container>
              <ResetPassword />
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
};
