import React from 'react';
import { useSelector } from 'react-redux';

export const useCustomClient = () => {
  const {
    isLoadingCustomLogo: isLoading,
    customLogo: logo,
    customFavi: favi,
    customTitle: title,
  } = useSelector((state) => state.application);

  return {
    isLoading,
    logo,
    favi,
    title,
  };
};
