import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './containers/utility/ErrorBoundary';
import DynamicScripts from './containers/utility/DynamicScripts';
import { PageTracking } from './containers/utility/PageTracking';
import { useGlobalTheme } from './hooks/application/useColorTheme';
import GlobalStyler from './containers/utility/GlobalStyling';
import AppInitialization from './AppInitialization';
import { Managers } from './containers/managers';
import { TrackingEvent } from './libs/nvstr-utils.es';
import { initAmplitudeInstance, logAmplitudeEvent } from './utils/amplitude';
import { Provider } from 'react-redux';
import store from './utils/store';
import { AdvancedSettingsModal } from './containers/DigitalAnalyst/AdvancedSettings';
import { ApplicationErrorMessageBanner } from './containers/application/ApplicationErrorMessageBanner';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

function AppBody() {
  return (
    <>
      <GlobalStyler />
      <BrowserRouter>
        <ErrorBoundary>
          <Managers />
          <DynamicScripts />
          <PageTracking />
          <AppInitialization />
          <AdvancedSettingsModal />
          <ApplicationErrorMessageBanner />
        </ErrorBoundary>
      </BrowserRouter>
    </>
  );
}

function AppTheme() {
  const theme = useGlobalTheme();
  return (
    <ThemeProvider theme={theme}>
      <AppBody />
    </ThemeProvider>
  );
}

function Init() {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    initAmplitudeInstance();
    TrackingEvent.addDefaultProperties({ 'Is Digital Analyst': true });
    TrackingEvent.addEventSender((name, properties) => logAmplitudeEvent(name, properties));
    setIsReady(true);
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <Provider store={store}>
      <AppTheme />
    </Provider>
  );
}

export default Init;
