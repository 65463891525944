import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: ${({ $Size: size }) => (size || 16) + 'px'};
  width: ${({ $Size: size }) => (size || 16) + 'px'};

  path {
    fill: ${({ theme, $Color: color }) => color || theme.themeColors.text};
  }
`;

export const Expand = ({ size, color }) => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" $Size={size} $Color={color}>
      <path d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z" />
    </SVG>
  );
};
