import React from 'react';
import { Body5, Container, H5, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { CloseButton } from '../../components/buttons/CloseButton';
import { useColorTheme } from '../../hooks';
import { SentimentChart } from './SentimentChart';
import { SentimentTranscript } from './SentimentTranscript';
import { SentimentAggregations } from './SentimentAggregations';

const Wrapper = styled.div`
  overflow: hidden;
`;
const Heading = styled.div`
  h5 {
    padding-right: 8px;
  }
`;

const SelectedDocumentWrapper = styled.div``;
const IconWrapper = styled.div`
  margin-right: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

function SelectedDocument({ doc }) {
  if (!doc) return null;

  const { filename } = doc;
  return (
    <SelectedDocumentWrapper>
      <Container row verticallyCenter>
        <IconWrapper>
          <DocumentIcon />
        </IconWrapper>
        <Container>
          <Body5>{filename}</Body5>
        </Container>
      </Container>
    </SelectedDocumentWrapper>
  );
}

export const SentimentAnalysis = ({ isLoading, data, chartData, metadata, doc, handleReset }) => {
  const colorTheme = useColorTheme();

  const [highlightStatementIndex, setHighlightStatementIndex] = React.useState(null);

  const handleHighlightStatement = (i) => {
    setHighlightStatementIndex(i);
  };
  const onClearHighlight = () => {
    setHighlightStatementIndex(null);
  };

  const scrollToAnalysis = () => {
    const id = 'question-answer';
    const yOffset = -30;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    setTimeout(() => {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }, 600);
  };

  React.useEffect(() => {
    scrollToAnalysis();
  }, []);

  if (isLoading) {
    return (
      <Page width={'1400px'}>
        <Wrapper>
          <div id={'question-answer'}></div>

          <Container>
            <Heading>
              <Container row verticallyCenter spaceBetween>
                <H5>Fed Sentiment</H5>
              </Container>
            </Heading>
            <Container top={12}>
              <SelectedDocument doc={doc} />
            </Container>
            <Container top={80} bottom={80} centerAll>
              <Container verticallyCenter top={64} bottom={64}>
                <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
                <Container left={12}>
                  <Body5>Working...</Body5>
                </Container>
              </Container>
            </Container>
          </Container>
        </Wrapper>
      </Page>
    );
  }

  return (
    <>
      <Page width={'1400px'}>
        <Wrapper>
          <div id={'question-answer'}></div>
          <Heading>
            <Container row verticallyCenter spaceBetween>
              <H5>Fed Sentiment</H5>
              <CloseButton onClick={handleReset} />
            </Container>
          </Heading>
          <Container top={12}>
            <SelectedDocument doc={doc} />
          </Container>
          <Container top={24}>
            <SentimentAggregations metadata={metadata} />
          </Container>
        </Wrapper>
      </Page>
      <Page width={'1400px'}>
        <Wrapper>
          <Container>
            <H5>Document Analysis</H5>
          </Container>
          <Container top={24}>
            <SentimentChart data={chartData} metadata={metadata} onHighlightStatement={handleHighlightStatement} />
          </Container>
          <Container top={24}>
            <SentimentTranscript
              data={data}
              metadata={metadata}
              highlightStatementIndex={highlightStatementIndex}
              onClearHighlight={onClearHighlight}
            />
          </Container>
        </Wrapper>
      </Page>
    </>
  );
};
