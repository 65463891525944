import { useLocation } from 'react-router-dom';
import React from 'react';
import { TrackingEvent } from '../../libs/nvstr-utils.es';

export const PageTracking = () => {
  const { pathname, search } = useLocation();

  React.useEffect(() => {
    const properties = {
      location: pathname,
      url: pathname,
      url_query: search,
    };
    TrackingEvent.create('View Screen', properties);
  }, [pathname, search]);

  return null;
};
