import React from 'react';
import { convertInfoIconWordKeyToEventName, getDefinitionComponent } from '../../constants/infoIcons';
import { Overlay } from '../layout/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../hooks/application/useWindowSize';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { infoIconActions } from '../../constants/actionTypes';
import styled from 'styled-components';

const MOBILE_LEFT_PADDING = 10;
const MOBILE_BOTTOM_PADDING = 10;

const generateTooltipContentComponent = (storeData) => {
  const { customDefinition, type, symbol } = storeData;

  return customDefinition ? customDefinition : getDefinitionComponent(type, symbol);
};

const TooltipContentWrapper = styled.div`
  padding: 16px;
  border-radius: 4px;
  min-height: 100px;
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const Tooltip = (props) => {
  const { style, windowHeight, windowWidth, height, width, CustomComponent } = props;

  let Content = null;
  if (CustomComponent) {
    Content = (
      <div>
        <CustomComponent {...props} />
      </div>
    );
  } else {
    Content = <div style={{ fontSize: '12px' }}>{generateTooltipContentComponent(props)}</div>;
  }

  const showMobileStyle = windowWidth < 600;
  if (showMobileStyle) {
    return (
      <TooltipContentWrapper
        style={{
          position: 'absolute',
          maxHeight: windowHeight - 100,
          maxWidth: 400,
          margin: '0 auto',
          bottom: MOBILE_BOTTOM_PADDING,
          left: MOBILE_LEFT_PADDING,
          right: MOBILE_LEFT_PADDING,
        }}
      >
        {Content}
      </TooltipContentWrapper>
    );
  }

  return (
    <TooltipContentWrapper
      style={{
        height,
        width,
        ...style,
      }}
    >
      {Content}
    </TooltipContentWrapper>
  );
};

const _getElementHeightAndWidth = (el) => {
  let rect = el.getBoundingClientRect();
  let width = rect.width;
  let height = rect.height;

  return { height, width };
};

const INFO_ICON_SIZE_PIXELS = 14;
const INFO_ICON_TOP_OFFSET = 3;

const POSITION_TYPES = {
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
};

const InfoIconModal = ({ customRender }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.infoIcon);

  const [infoIconSize, setInfoIconSize] = React.useState(null);
  const [canFullRender, setCanFullRender] = React.useState(false);

  const { type, symbol, show } = storeData;

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const onShow = () => {
    onPrerenderForSize();

    const properties = {
      Name: convertInfoIconWordKeyToEventName(type) || null,
    };
    TrackingEvent.create('Clicked Info Icon', properties);
  };

  const onHide = () => {
    setCanFullRender(false);
    setInfoIconSize(null);

    dispatch({
      type: infoIconActions.hide,
    });
  };

  React.useEffect(() => {
    if (show) {
      onShow();
    }
    if (!show) {
      onHide();
    }
  }, [show]);

  const onPrerenderForSize = () => {
    if (!show) return null;

    const size = _getInfoIconPrerenderedSize();
    setInfoIconSize(size);
    setCanFullRender(true);
  };

  const _getInfoIconPrerenderedSize = () => {
    const $el = document.getElementById('info_icon_prerender_container');
    if (!$el) {
      return {
        height: 200,
        width: 200,
      };
    }

    const { height, width } = _getElementHeightAndWidth($el);

    if (height === 0 || width === 0) {
      return {
        height: 200,
        width: 200,
      };
    }

    const maxWidth = Math.min(windowWidth - 32, 600);
    return {
      height,
      width: Math.min(width, maxWidth),
    };
  };

  const _getClickedIconPosition = () => {
    if (storeData === null) return null;

    const { elementRef } = storeData;

    const el = elementRef.current ? elementRef.current : elementRef;
    if (!el) return null;

    const htmlPageScrollOffset = {
      top: window.scrollY, // htmlPage.scrollTop(),
      left: window.scrollX, // .scrollLeft(),
    };
    const { top, left } = el.getBoundingClientRect();
    return {
      top: top - htmlPageScrollOffset.top,
      left: left - htmlPageScrollOffset.left,
    };
  };

  const _getBottomRightAnchorPosition = () => {
    if (storeData === null) return null;

    const { elementRef } = storeData;

    const el = elementRef.current ? elementRef.current : elementRef;
    if (!el) return null;

    const htmlPageScrollOffset = {
      top: window.scrollY,
      left: window.scrollX,
    };
    const { top, left } = el.getBoundingClientRect();

    const height = window.innerHeight;
    const width = window.innerWidth;
    return {
      top: top - htmlPageScrollOffset.top + height,
      left: left - htmlPageScrollOffset.left + width,
    };
  };

  const _buildTooltipPositionStyle = () => {
    const { useBottomRightAsAnchor } = storeData;

    const iconPosition = useBottomRightAsAnchor ? _getBottomRightAnchorPosition() : _getClickedIconPosition();
    if (!iconPosition) return {};

    const commonPositionStyles = {
      position: 'absolute',
    };

    if (useBottomRightAsAnchor) {
      const bottomRightAnchorFit = _calcBottomLeftPositionedValues(iconPosition);
      if (bottomRightAnchorFit === null) return {};

      const { top, left } = bottomRightAnchorFit;
      return {
        ...commonPositionStyles,
        top,
        left,
      };
    }

    const bestPositionFit = _getBestPositionFit();
    if (bestPositionFit === null) {
      return {};
    }

    const { top, left } = bestPositionFit;
    return {
      ...commonPositionStyles,
      top,
      left,
    };
  };

  const _getBestPositionFit = () => {
    const iconPosition = _getClickedIconPosition();

    let bestFit = null;
    const availablePositionTypes = Object.keys(POSITION_TYPES);
    availablePositionTypes.forEach((type) => {
      if (bestFit !== null) return;

      const position = POSITION_TYPE_CALC_LOOKUP[POSITION_TYPES[type]](iconPosition);
      if (position !== null) bestFit = position;
    });
    return bestFit;
  };

  const _calcBottomRightPositionedValues = (iconPosition) => {
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;

    const posTop = top + INFO_ICON_SIZE_PIXELS + INFO_ICON_TOP_OFFSET;
    const posLeft = left;

    const edgeY = posTop + height;
    const edgeX = posLeft + width;

    const willFitX = windowWidth >= edgeX;
    const willFitY = windowHeight >= edgeY;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  const _calcTopRightPositionedValues = (iconPosition) => {
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;

    const posTop = top - height - INFO_ICON_TOP_OFFSET;
    const posLeft = left;

    const edgeY = posTop;
    const edgeX = posLeft + width;

    const willFitX = windowWidth >= edgeX;
    const willFitY = edgeY >= 0;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  const _calcTopLeftPositionedValues = (iconPosition) => {
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;

    const posTop = top - height - INFO_ICON_TOP_OFFSET;
    const posLeft = left - width + INFO_ICON_SIZE_PIXELS;

    const edgeY = posTop;
    const edgeX = posLeft;

    const willFitX = edgeX >= 0;
    const willFitY = edgeY >= 0;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  const _calcBottomLeftPositionedValues = (iconPosition) => {
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;

    const posTop = top + INFO_ICON_SIZE_PIXELS + INFO_ICON_TOP_OFFSET;
    const posLeft = left - width + INFO_ICON_SIZE_PIXELS;

    const edgeY = posTop + height;
    const edgeX = posLeft;

    const willFitX = edgeX >= 0;
    const willFitY = windowHeight >= edgeY;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  const POSITION_TYPE_CALC_LOOKUP = {
    [POSITION_TYPES.BOTTOM_RIGHT]: _calcBottomRightPositionedValues,
    [POSITION_TYPES.BOTTOM_LEFT]: _calcBottomLeftPositionedValues,
    [POSITION_TYPES.TOP_LEFT]: _calcTopLeftPositionedValues,
    [POSITION_TYPES.TOP_RIGHT]: _calcTopRightPositionedValues,
  };

  const _handleOverlayClick = () => {
    onHide();
  };

  if (!show) return null;

  if (canFullRender)
    return (
      <Overlay onDismiss={_handleOverlayClick} customClass={'priority-z-index'}>
        {customRender || (
          <Tooltip
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            {...storeData}
            {...infoIconSize}
            CustomComponent={storeData.CustomComponent}
            style={_buildTooltipPositionStyle()}
          />
        )}
      </Overlay>
    );

  return (
    <div
      id={'info_icon_prerender_container'}
      style={{
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        maxWidth: 400 - MOBILE_LEFT_PADDING * 2,
      }}
    >
      {customRender || <Tooltip {...storeData} {...infoIconSize} CustomComponent={storeData.CustomComponent} />}
    </div>
  );
};

export default InfoIconModal;
