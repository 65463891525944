import React from 'react';
import { linearRegression, linearRegressionLine } from 'simple-statistics';
import { Filler, Legend, LinearScale, LineElement, PointElement, ScatterController, Title, Tooltip } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // auto registers controllers, js will fail w/o it
Chart.register(LinearScale, ScatterController, PointElement, LineElement, Title, Tooltip, Filler, Legend);

// Helper function to convert HSL to RGB
// Note: This function assumes saturation and lightness are given as percentages
// and hue is given as a degree on the color wheel
function hslToRgb(h, s, l) {
  s /= 100;
  l /= 100;
  const k = (n) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return {
    r: Math.round(255 * f(0)),
    g: Math.round(255 * f(8)),
    b: Math.round(255 * f(4)),
  };
}

function buildDatasets(scatterData) {
  const defaultData = {};

  const colors = assignFullSpectrumColors(scatterData);

  return scatterData.map((d, i) => ({
    ...defaultData,
    label: d.symbol + ' - ' + d.name,
    backgroundColor: colors[i],
    data: [
      {
        x: d.x,
        y: d.y,
      },
    ],
  }));
}

function assignFullSpectrumColors(elements) {
  const elementsWithColors = elements.map((element, index) => {
    // Calculate the hue value to span the full spectrum (0 to 360 degrees)
    const hue = Math.round((index / (elements.length - 1)) * 360);
    // Keep saturation and lightness constant to ensure colors are bright and vivid
    const saturation = 100;
    const lightness = 50;
    // Convert HSL to RGB (optional, depending on your use case)
    const rgbColor = hslToRgb(hue, saturation, lightness);
    return `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`;
  });

  return elementsWithColors;
}

const ScatterPlotWithRegression = ({ data: scatterData, regressionPoints }) => {
  const dataPairs = scatterData.map((point) => [point.x, point.y]);
  const regression = linearRegression(dataPairs);
  const getRegressionY = linearRegressionLine(regression);
  const regressionData = scatterData.map((point) => ({
    x: point.x,
    y: getRegressionY(point.x),
  }));

  const datasets = buildDatasets(scatterData);

  const data = {
    datasets: [
      ...datasets,
      {
        label: 'Regression Line',
        data: regressionData,
        type: 'line',
        borderColor: 'rgba(53, 162, 235, 1)',
        borderWidth: 2,
        fill: false,
        showLine: true,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
      },
      y: {
        type: 'linear',
      },
    },
  };
  return <Scatter data={data} options={options} />;
};

export const FactorVisualizationScatterplot = React.memo(({ data, onShowModal, onDismissModal }) => {
  if (!data) {
    return null;
  }

  const chartData = data;
  const dataPairs = chartData.map((item) => [item.x, item.y]);
  const regression = linearRegression(dataPairs);
  const regressionLine = linearRegressionLine(regression);
  const regressionPoints = chartData.map((item) => ({
    x: item.x,
    y: regressionLine(item.x),
  }));

  return <ScatterPlotWithRegression data={chartData} regressionLinePoints={regressionPoints} />;
});
