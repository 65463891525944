import React from 'react';

const actionTypes = {
  CHANGE_ACTIVE_SECTION_NAME: 'casn',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_ACTIVE_SECTION_NAME: {
      const activeSectionName = action.payload;
      if (state.activeSectionName === activeSectionName) return state;
      return {
        ...state,
        activeSectionName,
      };
    }

    default:
      return state;
  }
};

const initialState = {
  activeSectionName: null,
};

function findPos(el) {
  let p = 0;
  if (!el) return p;

  if (el.offsetParent) {
    do {
      p += el.offsetTop;
    } while ((el = el.offsetParent));
    return p;
  }
}

const useScrollSpy = (sectionIds) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { activeSectionName } = state;

  const scrollToSection = (sectionId) => {
    const elId = `faq-section-${sectionId}`;
    const element = document.getElementById(elId);

    const position = findPos(element);
    window.scroll(0, position);
  };

  React.useEffect(() => {
    const navHeight = 100; // TODO: get a ref to this
    const offsetPx = navHeight + 100;

    const onScroll = () => {
      if (!sectionIds) return null;

      let newActiveSectionId = null;
      for (let i = 0; i < sectionIds.length; i++) {
        const sectionId = sectionIds[i];
        const elId = `faq-section-${sectionId}`;
        const section = document.getElementById(elId);

        if (!section || !(section instanceof Element)) continue;

        const position = section.getBoundingClientRect().top;
        if (position - offsetPx < 0) {
          newActiveSectionId = sectionId;
          continue;
        }

        break;
      }

      if (newActiveSectionId === null && sectionIds && sectionIds.length > 0) newActiveSectionId = sectionIds[0];

      dispatch({
        type: actionTypes.CHANGE_ACTIVE_SECTION_NAME,
        payload: newActiveSectionId,
      });
    };

    window.addEventListener('scroll', onScroll);

    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [sectionIds]);

  return {
    activeSectionName,
    scrollToSection,
  };
};

export default useScrollSpy;
