import styled from 'styled-components';
import { Body1, Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import React from 'react';
import { AI_TOOLS, gptModels } from '../../constants';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { applicationActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { PROMPT_SERIES_MODES, usePromptSeries } from '../../hooks/features/usePromptSeries';
import { Modal } from '../../components/layout/Modal';
import { CloseButton } from '../../components/buttons/CloseButton';
import { DocumentSelection } from './DocumentSelection';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { EvaluationTemplateResponseSummary } from './components/EvaluationTemplateResponseSummary';
import { useDocs } from '../../hooks/features/useDocs';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const advancedSettingsFields = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.maxContextChunks,
  AdvancedSettingsFields.smoothingToggle,
];

const defaultAdvancedSettings = {
  model: gptModels.GPT_4o_2024_08_06,
  enableSmoothing: false,
  maxContextLength: '100000',
  maxContextChunks: '600',
};

const Heading = styled.div`
  padding: 36px 0 0 0;

  h5 {
    padding-right: 8px;
  }
`;

const SelectedDocumentWrapper = styled.div``;
const IconWrapper = styled.div`
  margin-right: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CloseButtonWrapper = styled.div`
  margin-left: 8px;

  svg {
    padding-top: 1px;
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const ActionWrapper = styled.div`
  text-align: center;
  padding: 36px 0 0 0;
`;

function SelectedDocument({ doc, handleRemove }) {
  if (!doc) return null;

  const { filename, doc_type, report_date } = doc;
  return (
    <SelectedDocumentWrapper>
      <Container row verticallyCenter>
        <IconWrapper>
          <DocumentIcon />
        </IconWrapper>
        <Container>
          <Body5>{filename}</Body5>
        </Container>
        <CloseButtonWrapper>
          <CloseButton onClick={handleRemove} />
        </CloseButtonWrapper>
      </Container>
    </SelectedDocumentWrapper>
  );
}

const tool = AI_TOOLS.EVAL_TEMPLATE;

export default function EvalTemplateTool({ onGoBack }) {
  const dispatch = useDispatch();

  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: true });
  const [filters] = React.useState({ isUserOnlyMode: true, isPPMMode: true });
  const { docs } = useDocs(filters);

  const [filename, setFilename] = React.useState('');
  const [isSelectingDocs, setIsSelectingDocs] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState([]);

  const { resultId, questionList, formError: error, onSubmit, onClear } = usePromptSeries(tool);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  const handleAddDocument = (v) => {
    setSelectedDocs([v]);
    setIsSelectingDocs(false);
  };

  const handleRemoveDocument = () => {
    setSelectedDocs([]);
  };

  const onSelectDocumentsClick = () => setIsSelectingDocs(true);

  const onGenerateEvaluation = () => {
    if (selectedDocs.length === 0) return null;
    const docIds = selectedDocs.map((d) => d.id);
    setFilename(selectedDocs[0].filename);
    onSubmit(null, PROMPT_SERIES_MODES.evaluationTool, docIds);
  };

  const handleClear = () => {
    onClear();
    setFilename('');
  };

  return (
    <PageWrapper>
      {isSelectingDocs && (
        <Modal onDismiss={() => setIsSelectingDocs(false)} modalProps={modalProps}>
          <Container row verticallyCenter spaceBetween>
            <Container bottom={4}>
              <Body1 bold>Select Document Sources</Body1>
            </Container>
            <CloseButton onClick={() => setIsSelectingDocs(false)} />
          </Container>
          <Container top={24}>
            <DocumentSelection
              docs={docs}
              filters={filters}
              onSelect={handleAddDocument}
              onRemoveSelect={handleRemoveDocument}
              selectedDocs={selectedDocs}
              onClose={() => setIsSelectingDocs(false)}
              noDocsMessage={
                'Uploaded documents with the document type "PPM" will show here. Only "PPM" documents are supported in this mode.'
              }
              singleDocMode
            />
          </Container>
        </Modal>
      )}
      <Page width={'1400px'}>
        <BackArrowButton onClick={onGoBack} />
        <Heading>
          <H5>Hedge Fund PPM/OM Evaluation Template</H5>
          <AdvancedSettingsButton fields={advancedSettingsFields} />
        </Heading>
        <Container top={40}>
          <FormWrapper>
            <AdvancedSettingsButton fields={advancedSettingsFields} />
            <Container top={24} bottom={24}>
              <SelectedDocument doc={selectedDocs[0]} handleRemove={handleRemoveDocument} />
              <Container>
                {selectedDocs.length === 0 ? (
                  <Container top={24}>
                    <FlatButton onClick={onSelectDocumentsClick}>+ Select Document</FlatButton>
                  </Container>
                ) : (
                  <Container row top={16}>
                    <TransparentFlatButton onClick={onSelectDocumentsClick}>Change Document</TransparentFlatButton>
                  </Container>
                )}
              </Container>
              {selectedDocs.length === 0 ? null : (
                <ActionWrapper>
                  <FlatButton fullWidth onClick={onGenerateEvaluation}>
                    Create Report
                  </FlatButton>
                </ActionWrapper>
              )}
            </Container>
          </FormWrapper>
        </Container>
      </Page>
      {error && (
        <Page width={'1400px'}>
          <Container vertical={16}>
            <Container>
              <Body1 bold>An error occurred analyzing this document.</Body1>
            </Container>
            <Container top={16} bottom={48}>
              <Body5>{error}</Body5>
            </Container>
          </Container>
        </Page>
      )}
      {resultId && (
        <Page width={'1400px'}>
          <div id={'snapshot-answers-section'} />
          <EvaluationTemplateResponseSummary questionList={questionList} resultId={resultId} filename={filename} />
          {/*<ResultRatingForm resultId={resultId} />*/}
          <Container top={24} centerAll>
            <SkeletonButton onClick={handleClear}>Close Section</SkeletonButton>
          </Container>
        </Page>
      )}
    </PageWrapper>
  );
}
