import { securitiesActions } from '../constants/actionTypes';

const defaultState = {
  lookup: {},
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case securitiesActions.add: {
      const security = action.payload;
      return {
        lookup: {
          ...state.lookup,
          [security.symbol]: { security },
        },
      };
    }
    case securitiesActions.addMultiple: {
      const securities = action.payload;
      const mergeObj = {};
      securities.forEach((s) => {
        mergeObj[s.symbol] = s;
      });
      return {
        lookup: {
          ...state.lookup,
          ...mergeObj,
        },
      };
    }

    default:
      return state;
  }
}
