import React from 'react';
import Router from './Router';
import { isSignedIn } from './services/authentication';
import { Header } from './components/navigation';
import { useDispatch } from 'react-redux';
import { getDataForCache, getRequiredData, handleAppTrackingInit } from './utils/initialization';
import * as Sentry from '@sentry/react';

const Loading = () => {
  return <Header isLoadingState />;
};

function AppInitialization() {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    let cancelled = false;
    const init = async () => {
      const { isAuthed } = await isSignedIn();
      if (cancelled) return;
      const transaction = Sentry.startTransaction({ name: 'get-session-start-required-data' });
      if (isAuthed) {
        // const timeStart = createTimeInstance().valueOf();
        const responses = await getRequiredData(dispatch);
        await handleAppTrackingInit(responses[0]);
        // const timeEnd = createTimeInstance().valueOf();
        setIsReady(true);

        const cacheResponses = await getDataForCache(dispatch);
      } else {
        setIsReady(true);
      }

      transaction?.finish(); // Finishing the transaction will send it to Sentry
    };

    init();

    return () => {
      cancelled = true;
    };
  }, []);

  if (!isReady) {
    return <Loading />;
  }

  return <Router />;
}

export default AppInitialization;
