import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import PublicSentimentDocSelectTool from '../containers/DigitalAnalyst/PublicSentimentDocSelectTool';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

function PublicFedSentiment() {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <PublicSentimentDocSelectTool onGoBack={onGoBack} />
      </Body>
    </PageWrapper>
  );
}

export default PublicFedSentiment;
