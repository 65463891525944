import React from 'react';
import styled from 'styled-components';
import { FullLogo, TornadoLogoWrapper } from '../../assets/logos/FullLogo';
import { Body1 } from '../../libs/nvstr-common-ui.es';
import { Link } from 'react-router-dom';

const TextGroup = styled.div`
  padding: 8px 0 0 0;
`;

export const SubTitle = styled('div')`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4em;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const MessagingWrapper = styled.div`
  max-width: 580px;
  margin: 0 auto;

  ${TornadoLogoWrapper} {
    span {
      font-size: 16px;
    }
    svg {
      height: 40px;
      width: 216px;
    }
  }

  @media (max-width: 875px) {
    padding: 24px 0 0 0;
    max-width: unset;
    margin: 0;
    text-align: center;

    ${TextGroup} {
      display: ${({ hideOnMobile, shortened }) => (hideOnMobile || shortened ? 'none' : 'block')};
    }

    ${TornadoLogoWrapper} {
      width: 159px;
      margin: 0 auto;
      span {
        font-size: 16px;
      }

      svg {
        height: 40px;
        width: 184px;
      }
    }

    ${SubTitle} {
      padding: 0;
      text-align: center;
      max-width: 440px;
      margin: 0 auto;
    }
  }
`;

export const PublicPageMessaging = ({ hideOnMobile, shortened }) => {
  return (
    <MessagingWrapper hideOnMobile={hideOnMobile} shortened={shortened}>
      <Link to={'/'}>
        <FullLogo forceTornado />
      </Link>
      <TextGroup>
        <SubTitle>Digital Analyst Platform</SubTitle>
      </TextGroup>
    </MessagingWrapper>
  );
};
