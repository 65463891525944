import React from 'react';
import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { addUrlParam } from '../../utils';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { docsActionTypes } from '../../constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { logNetRequest } from '../../utils/usefulFuncs';

function formatToSelectedDate(day) {
  let date;
  if (day === 'Recent') {
    date = createTimeInstance();
  } else {
    date = createTimeInstance(day);
  }
  return date;
}

function groupDocsByDayLookup(docs) {
  const days = {};
  docs.forEach((d) => {
    const { published_at: publishedAt } = d;
    if (publishedAt) {
      const date = formatLocalizedDateTime('api', createTimeInstance(publishedAt));
      if (days[date]) {
        days[date].push(d);
      } else {
        days[date] = [d];
      }
    }
  });

  return days;
}

function sortDates(dates) {
  return dates.sort((a, b) => new Date(b) - new Date(a));
}

function groupResponseToDocsList(lookupBySymbol) {
  const docs = [];
  const values = Object.values(lookupBySymbol);
  values.forEach((docsForStock) => {
    docsForStock.forEach((d) => docs.push(d));
  });
  return docs;
}

function buildDocsSortedByDayList(docsByDay) {
  const availableDatesSortedByMostRecent = sortDates(Object.keys(docsByDay));
  return availableDatesSortedByMostRecent.map((d) => ({
    day: d,
    docs: docsByDay[d],
  }));
}

function buildRecentDocs(docs) {
  const list = [];
  docs.forEach((d) => {
    const { published_at } = d;
    const cutoff = createTimeInstance().subtract(1, 'day').set('hour', '16').set('minute', '0').set('second', '0');
    if (createTimeInstance(published_at).isAfter(cutoff)) {
      list.push(d);
    }
  });
  return list;
}

export function useRecentFedDocsData() {
  const isLoadingDocs = useSelector((state) => state.recentDocs.isLoadingFed);
  const docs = useSelector((state) => state.recentDocs.fedRecent);

  return {
    isLoading: isLoadingDocs,
    docs,
  };
}

export function useGetRecentFedDocs(startDate, filterDocTypes, speaker) {
  const dispatch = useDispatch();

  const getTickerDocData = async (params, isCancelled) => {
    const { startDate: _params_startDate, filterDocTypes: _params_filterDocTypes } = params;

    dispatch({
      type: docsActionTypes.setGettingRecentFedDocs,
    });

    let startDate = null;
    if (_params_startDate) {
      startDate = createTimeInstance(_params_startDate);
    }
    const dateFormat = '-L';
    const startDateApi = formatLocalizedDateTime(dateFormat, startDate);
    let URL = `v1/genai_tickers_filenames`;
    let URLParams = '';
    if (_params_filterDocTypes && _params_filterDocTypes.length > 0) {
      URLParams = addUrlParam(URLParams, `doc_types=${_params_filterDocTypes}`);
    }
    if (startDate) {
      URLParams = addUrlParam(URLParams, `start_date=${startDateApi}`);
    }
    URLParams = addUrlParam(URLParams, `user_mode=0`);
    URL += URLParams;
    const { status, data } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    logNetRequest(status, data);

    if (isCancelled) {
      return null;
    }

    if (status === 200) {
      const fedDocs = data['0_FED'];
      const payload = fedDocs.sort((a, b) => {
        return createTimeInstance(a.published_at).isAfter(createTimeInstance(b.published_at)) ? -1 : 1;
      });
      dispatch({
        type: docsActionTypes.setRecentFedDocs,
        payload,
      });
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    let isCancelled = false;
    getTickerDocData({ startDate, filterDocTypes, speaker }, isCancelled);

    return () => {
      isCancelled = true;
    };
  }, [startDate, filterDocTypes, speaker]);

  return null;
}
