import React from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { DOMAIN } from '../../constants/app';
import { parseQueryString } from '../../libs/nvstr-utils.es';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { Container } from '../../libs/nvstr-common-ui.es';
import InputField from '../form/InputField';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { getRecaptchaKey } from '../../constants';
import { signUp } from '../../services/authentication';

export const ValidationWrap = styled('div')`
  color: ${({ theme }) => theme.themeColors.error} !important;
  font-size: 12px;
`;

export const SignSwitchLink = styled('div')`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
`;

export const FormWrap = styled.div.attrs((props) => ({
  issigningup: props.issigningup || 'false',
}))`
  position: relative;

  ${({ issigningup }) =>
    issigningup === 'true'
      ? 'opacity: 0; position: absolute; top: 0; bottom: 0; overflow: scroll; pointer-events: none;'
      : ''}
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
  }
`;

export const LoadingCover = styled.div`
  width: 360px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
`;

export const SubTitle = styled('div')`
  font-size: 18px;
  font-weight: 300;
  margin: 5px 0 20px;
  line-height: 1.4em;
`;

export const FormTitle = styled('div')`
  font-size: 24px;
  font-weight: 600;
`;

export const TermsAndConditions = styled('div')`
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;

  a {
    color: ${({ theme }) => theme.themeColors.text};
    font-weight: 700;
  }
`;

export const Agreement = styled('div')`
  padding: 0;
`;
export const BonusDisclaimer = styled('div')`
  padding: 8px 0 0 0;
`;

export const RegisteredEmail = styled('div')`
  font-size: 16px;
  font-weight: 400;

  & > b {
    font-weight: 800;
  }
`;

const LoginWrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.text};

    :hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
`;

const InputErrorWrapper = styled.div`
  position: relative;
  padding-bottom: 16px;
`;
const InputErrorPosition = styled.div`
  position: absolute;
  top: -10px;
  left: 12px;
`;

export const VALIDATION_MESSAGES = {
  requiredField: 'Required',
  validName: 'Cannot use restricted characters.',
  validEmail: 'Please enter a valid email.',
  passwordLength: 'Please enter a password with at least 8 characters.',
};

const isEmptyValidation = (v) => (v.trim() === '' ? VALIDATION_MESSAGES.requiredField : null);

const validName = (v) => (['.', '@'].some((c) => v.includes(c)) ? VALIDATION_MESSAGES.validName : null);

const passwordLengthValidation = (v) => (v.length < 8 ? VALIDATION_MESSAGES.passwordLength : null);

const validEmailValidation = (v) => null; //(false ? VALIDATION_MESSAGES.validEmail : null);

const fieldValidation = {
  firstName: [(v) => isEmptyValidation(v), (v) => validName(v)],
  lastName: [(v) => isEmptyValidation(v), (v) => validName(v)],
  email: [(v) => isEmptyValidation(v), (v) => validEmailValidation(v)],
  password: [(v) => isEmptyValidation(v), (v) => passwordLengthValidation(v)],
};

export const signUpValidation = (form) => {
  const formErrors = {};
  const fieldNames = Object.keys(form);
  fieldNames.forEach((name) => {
    let errors = [];
    const validations = fieldValidation[name];
    if (Array.isArray(validations)) {
      validations.forEach((validation) => {
        const error = validation(form[name]);
        if (error) {
          errors.push(error);
        }
      });
    }
    if (errors.length > 0) {
      formErrors[name] = errors;
    }
  });
  return { errors: formErrors, isValid: Object.keys(formErrors).length === 0 };
};

const SignUpTermsAndConditions = () => {
  return (
    <TermsAndConditions>
      <Agreement>
        By signing up you agree to our{' '}
        <a href={`${DOMAIN}/terms_of_service`} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        ,{' '}
        <a href={`${DOMAIN}/privacy_policy`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a href={`${DOMAIN}/referral_rules`} target="_blank" rel="noopener noreferrer">
          Referral Program Rules
        </a>
      </Agreement>
    </TermsAndConditions>
  );
};

const InputError = ({ name, formErrors }) => {
  const errors = formErrors[name];
  if (!errors) return null;

  return (
    <InputErrorWrapper>
      <InputErrorPosition>
        <ValidationWrap>{errors[0]}</ValidationWrap>
      </InputErrorPosition>
    </InputErrorWrapper>
  );
};

export const SignUpForm = () => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const [signUpComplete, setSignUpComplete] = React.useState(false);

  const [formErrors, setFormErrors] = React.useState({});
  const [submissionError, setSubmissionError] = React.useState(null);

  const [isEmailSignup, setIsEmailSignup] = React.useState(false);

  const recaptchaRef = React.createRef();

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);
    if (query.email) {
      setIsEmailSignup(true);
      const emailParam = decodeURIComponent(query.email);
      setEmail(emailParam);
    }
  }, []);

  const userSignUpComplete = () => {
    setIsSigningUp(false);
    setSignUpComplete(true);
  };
  const onClear = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setIsEmailSignup(false);
  };

  const userSignUpError = (errorMessage) => {
    setIsSigningUp(false);
    setSubmissionError(errorMessage);
  };

  const userSignUp = (e) => {
    e?.preventDefault();

    const validationResult = signUpValidation({
      firstName,
      lastName,
      email,
      password,
    });

    if (validationResult.isValid) {
      setIsSigningUp(true);
      setFormErrors({});
      setSubmissionError(null);
      const recaptchaValue = recaptchaRef.current.getValue();
      signUp({
        firstName,
        lastName,
        email,
        password,
        rememberMe: 1,
        recaptchaValue,
        userSignUpComplete,
        userSignUpError,
      });
    } else {
      setFormErrors(validationResult.errors);
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userSignUp(e);
    }
  };

  if (signUpComplete) {
    return (
      <FormWrap>
        <Container top={8}>
          <SubTitle>We've sent a confirmation link to your email address. </SubTitle>
          <SubTitle>Please check your email, and click the link to activate your account.</SubTitle>
          <Container>
            <LoginWrapper>
              <b>
                <Link to="/login">Log In</Link>
              </b>
            </LoginWrapper>
          </Container>
        </Container>
      </FormWrap>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      {isSigningUp && (
        <LoadingCover>
          <LoadingCover>
            <LoadingSpinner text={'Signing Up...'} />
          </LoadingCover>
        </LoadingCover>
      )}
      <FormWrap issigningup={isSigningUp.toString()}>
        <form>
          <FormTitle>Sign Up</FormTitle>
          <Container top={4}>
            <SignSwitchLink>
              Already have an account? <Link to="/login">Log In</Link>
            </SignSwitchLink>
          </Container>
          {isEmailSignup && (
            <Container top={16}>
              <RegisteredEmail>
                <>
                  Continue sign up for <b>{email}</b>, please enter your details below to complete registration
                </>
              </RegisteredEmail>
            </Container>
          )}
          <Container top={16}>
            <InputField
              id="first-name-field"
              name={'First name'}
              placeholder={'First Name'}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <InputError name="firstName" formErrors={formErrors} />
          </Container>

          <InputField
            id="last-name-field"
            name={'Last name'}
            placeholder={'Last Name'}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <InputError name="lastName" formErrors={formErrors} />

          {!isEmailSignup && (
            <InputField
              id="email-field"
              name={'email'}
              placeholder={'Work Email'}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          )}
          <InputError name="email" formErrors={formErrors} />

          <InputField
            id="password-field"
            placeholder={'Password'}
            name={'password'}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <InputError name="password" formErrors={formErrors} />

          <ReCAPTCHA ref={recaptchaRef} sitekey={getRecaptchaKey()} />

          {isEmailSignup && (
            <Container top={4}>
              <TransparentFlatButton fullWidth onClick={onClear}>
                Clear
              </TransparentFlatButton>
            </Container>
          )}
          <Container top={isEmailSignup ? 4 : 16}>
            <FlatButton id="sign-up-submit" fullWidth onClick={userSignUp}>
              Sign Up
            </FlatButton>
          </Container>

          {submissionError && (
            <Container top={4}>
              <ValidationWrap>{submissionError}</ValidationWrap>
            </Container>
          )}
          <Container top={16}>
            <SignUpTermsAndConditions />
          </Container>
        </form>
      </FormWrap>
    </div>
  );
};
