import styled from 'styled-components';
import { Body5, Container, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import React from 'react';
import { AI_TOOLS, gptModels } from '../../constants';
import { CompanyQuestionPromptsSummary } from './components/CompanyOverview';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { TickerSelect } from './components/TickerSelect';
import { FormState } from '../../components/UI/FormState';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { ResultRatingForm } from '../form/ResultRatingForm';
import { usePromptSeries } from '../../hooks/features/usePromptSeries';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../../constants/actionTypes';
import QuestionList, { QUESTION_LIST_ACTIONS, useQuestionList } from './components/QuestionList';

const multipleTickersEnabled = false;

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const Label = styled.div`
  padding-bottom: 8px;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;
const ActionButtonWrapper = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const advancedSettingsFields = [
  // AdvancedSettingsFields.seeContext,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.smoothingToggle,
  AdvancedSettingsFields.includeUserDocsToggle,
  AdvancedSettingsFields.maxContextChunks,
];

const defaultAdvancedSettings = {
  model: gptModels.GPT_4O_TURBO_2024_05_13,
  enableSmoothing: false,
  includeUserDocs: true,
  maxContextLength: '',
  maxAnswerLength: '',
};

const tool = AI_TOOLS.CUSTOM_SNAPSHOT;

export default function CustomSnapshotTool({ onGoBack }) {
  const dispatch = useDispatch();
  const [tickers, setTickers] = React.useState([]);
  const [companyName, setCompanyName] = React.useState('');

  const { list: customQuestionList, dispatch: questionDispatch } = useQuestionList();

  const { resultId, resultIdTicker, isSubmittingToApi, formError, onSubmit, onClear, questionList } =
    usePromptSeries(tool);

  const [isAdding, setIsAdding] = React.useState(false);
  const [selectedQuestions, setSelectedQuestions] = React.useState([]);

  const handleSelectAll = () => setSelectedQuestions(customQuestionList.map((q) => q.text));

  const handleUnselectAll = () => setSelectedQuestions([]);

  React.useEffect(() => {
    setSelectedQuestions(customQuestionList.map((t) => t.text));
  }, [customQuestionList]);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  React.useEffect(() => {
    if (resultId) {
      const id = 'snapshot-answers-section';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [resultId]);

  const onAddQuestion = (q) => {
    questionDispatch({
      type: QUESTION_LIST_ACTIONS.addQuestion,
      payload: q,
    });

    setIsAdding(false);
  };

  const onGetCompanySummary = () => {
    if (selectedQuestions.length === 0) {
      return;
    }
    onSubmit(tickers[0], selectedQuestions);
  };

  const onAddTicker = (t, name) => {
    setTickers([t]);
    setCompanyName(name);
  };

  const onRemoveTicker = () => {
    setTickers([]);
  };

  const handleClearSnapshot = () => {
    onClear();
  };

  const onEditQuestion = (original, newQ) => {
    questionDispatch({
      type: QUESTION_LIST_ACTIONS.editQuestion,
      payload: [original, newQ],
    });
  };

  const handleAddQuestionClick = () => {
    setIsAdding(true);
  };

  const handleAddQuestionCancel = () => {
    setIsAdding(false);
  };

  const onSelectedQuestionToggle = (q) => {
    const wasSelected = selectedQuestions.includes(q);
    if (wasSelected) {
      setSelectedQuestions(selectedQuestions.filter((t) => t !== q));
    } else {
      setSelectedQuestions([...selectedQuestions, q]);
    }
  };

  return (
    <PageWrapper>
      <Page width={resultId ? '1000px' : '800px'}>
        <BackArrowButton onClick={onGoBack} />
        {isSubmittingToApi ? (
          <Container top={80} bottom={80} centerAll>
            <Container>
              <Container centerAll>
                <Spinner />
              </Container>
              <Container top={24} left={12}>
                <Body5>Working...</Body5>
              </Container>
            </Container>
          </Container>
        ) : (
          <Container top={40}>
            <FormWrapper>
              <AdvancedSettingsButton fields={advancedSettingsFields} />
              <Container top={24}>
                <TickerSelect
                  onAddTicker={onAddTicker}
                  onRemoveTicker={onRemoveTicker}
                  tickers={tickers}
                  multipleTickersEnabled={multipleTickersEnabled}
                />
              </Container>

              <Container top={24}>
                <Label>
                  <Body5>Question List</Body5>
                </Label>
                <QuestionList
                  list={customQuestionList}
                  selectedQuestions={selectedQuestions}
                  onAddQuestion={onAddQuestion}
                  onEditQuestion={onEditQuestion}
                  onSelectedQuestionToggle={onSelectedQuestionToggle}
                  handleSelectAll={handleSelectAll}
                  handleUnselectAll={handleUnselectAll}
                  handleAddQuestionCancel={handleAddQuestionCancel}
                />
              </Container>

              <ActionButtonWrapper>
                <SkeletonButton onClick={handleAddQuestionClick}>+ Add Question</SkeletonButton>
                <TransparentFlatButton onClick={handleSelectAll}>Select All</TransparentFlatButton>
                <TransparentFlatButton onClick={handleUnselectAll}>Unselect All</TransparentFlatButton>
              </ActionButtonWrapper>
              <Container top={48}>
                <Container centerAll>
                  <FormState error={formError} />
                </Container>
                <SubmitButtonWrapper>
                  <FlatButton disabled={tickers.length === 0} onClick={onGetCompanySummary} fullWidth>
                    Get Snapshot
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </FormWrapper>
          </Container>
        )}
      </Page>
      {resultId && (
        <Page width={'1000px'}>
          <div id={'snapshot-answers-section'} />
          <CompanyQuestionPromptsSummary
            questionList={questionList}
            companyName={companyName}
            ticker={resultIdTicker}
            resultId={resultId}
          />
          <ResultRatingForm resultId={resultId} />

          <Container top={24} centerAll>
            <SkeletonButton onClick={handleClearSnapshot}>Close Snapshot</SkeletonButton>
          </Container>
        </Page>
      )}
    </PageWrapper>
  );
}
