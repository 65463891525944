import React from 'react';
import { Header } from '../components/navigation';
import styled from 'styled-components';
import { Body } from '../components/layout/Body';
import { Body1, Body5, Container, Page } from '../libs/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { FlatButton } from '../components/buttons';
import { PDFUploadForm } from '../containers/form/PDFUploadForm';
import { TextUploadForm } from '../containers/form/TextUploadForm';
import { AI_TOOLS } from '../constants';
import { useCurrentUserToolsAvailable, usePartnerTheme } from '../hooks/user/useCurrentUser';
import { XLSUploadForm } from '../containers/form/XLSUploadForm';
import { MSDocUploadForm } from '../containers/form/MSDocUploadForm';
import { PPTUploadForm } from '../containers/form/PPTUploadForm';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  h3 {
    text-transform: none;
  }
`;

const ButtonStyler = styled.div`
  button {
    width: 200px;
  }
`;

const UPLOAD_TYPES = {
  ADOBE_OCR_DOC: 'adobe_ocr_doc',
  OCR_DOC: 'ocr_doc',
  FED_DOC: 'fed_doc',
  PDF: 'pdf',
  text: 'text',
  XLS: 'xls',
  DOC: 'docx',
  PPT: 'pptx',
  MS_DOC: 'ms_doc',
};

function PDFDocUploader({ fedDoc, ocrDoc, adobeOcrDoc }) {
  return <PDFUploadForm fedDoc={fedDoc} ocrDoc={ocrDoc} adobeOcrDoc={adobeOcrDoc} />;
}

function MSDocUploader({}) {
  return <MSDocUploadForm />;
}

function XLSDocUploader({}) {
  return <XLSUploadForm />;
}

function PPTUploader() {
  return <PPTUploadForm />;
}

function TextUploader({}) {
  return (
    <Container>
      <h3>Text Document Uploader</h3>
      <TextUploadForm />
    </Container>
  );
}

export const DocUploader = () => {
  const navigate = useNavigate();

  const [uploadType, setUploadType] = React.useState(null);

  const toolsAvailable = useCurrentUserToolsAvailable();
  const isWebsiteUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.WEBPAGE_UPLOAD);
  const isExcelUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.EXCEL_UPLOAD);
  const isMsDocUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.MS_DOC_UPLOAD);
  const isPPTUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.PPT_UPLOAD);
  const isFedUploadToolAvailable = toolsAvailable.includes(AI_TOOLS.FED_UPLOAD);

  const partnerTheme = usePartnerTheme();
  const isCustomClient = partnerTheme === 1;

  if (uploadType === UPLOAD_TYPES.text) {
    return <TextUploader onBackClick={() => setUploadType(null)} />;
  }
  if (uploadType === UPLOAD_TYPES.PDF) {
    return <PDFDocUploader onBackClick={() => setUploadType(null)} />;
  }
  if (uploadType === UPLOAD_TYPES.FED_DOC) {
    return <PDFDocUploader onBackClick={() => setUploadType(null)} fedDoc />;
  }
  if (uploadType === UPLOAD_TYPES.OCR_DOC) {
    return <PDFDocUploader onBackClick={() => setUploadType(null)} ocrDoc />;
  }
  if (uploadType === UPLOAD_TYPES.ADOBE_OCR_DOC) {
    return <PDFDocUploader onBackClick={() => setUploadType(null)} adobeOcrDoc />;
  }
  if (uploadType === UPLOAD_TYPES.MS_DOC) {
    return <MSDocUploader onBackClick={() => setUploadType(null)} adobeOcrDoc />;
  }
  if (uploadType === UPLOAD_TYPES.PPT) {
    return <PPTUploader onBackClick={() => setUploadType(null)} adobeOcrDoc />;
  }
  if (uploadType === UPLOAD_TYPES.XLS) {
    return <XLSDocUploader onBackClick={() => setUploadType(null)} />;
  }

  const handleUploadOcrClick = () => {
    setUploadType(UPLOAD_TYPES.OCR_DOC);
  };
  const handleUploadAdobeOcrClick = () => {
    setUploadType(UPLOAD_TYPES.ADOBE_OCR_DOC);
  };
  const handleUploadFedClick = () => {
    setUploadType(UPLOAD_TYPES.FED_DOC);
  };
  const handleUploadPDFClick = () => {
    setUploadType(UPLOAD_TYPES.PDF);
  };
  const handleUploadXLSClick = () => {
    setUploadType(UPLOAD_TYPES.XLS);
  };
  const handleUploadMSDocClick = () => {
    setUploadType(UPLOAD_TYPES.MS_DOC);
  };
  const handleUploadPPTClick = () => {
    setUploadType(UPLOAD_TYPES.PPT);
  };
  const handleUploadTextClick = () => {
    setUploadType(UPLOAD_TYPES.text);
  };
  const handleUploadWebsiteClick = () => {
    navigate(ROUTES.webpageIngestion);
  };

  return (
    <Container>
      <Body1 bold>Upload Document Type</Body1>
      {isCustomClient ? (
        <Container top={8}>
          <Body5>Warning: Please upload only public information to FedGPT</Body5>
        </Container>
      ) : null}
      <Container top={16} bottom={24}>
        <ButtonStyler>
          <Container top={24}>
            <FlatButton onClick={handleUploadPDFClick}>PDF Document</FlatButton>
          </Container>
          {isMsDocUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadMSDocClick}>DOCX Document</FlatButton>
            </Container>
          )}
          {isPPTUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadPPTClick}>PPTX Document</FlatButton>
            </Container>
          )}
          {isExcelUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadOcrClick}>OCR Document</FlatButton>
            </Container>
          )}
          {isExcelUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadAdobeOcrClick}>Adobe OCR Document</FlatButton>
            </Container>
          )}
          {isFedUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadFedClick}>Fed Document</FlatButton>
            </Container>
          )}
          {isExcelUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadXLSClick}>Excel Document</FlatButton>
            </Container>
          )}
          {isWebsiteUploadToolAvailable && (
            <Container top={24}>
              <FlatButton onClick={handleUploadWebsiteClick}>Upload Website</FlatButton>
            </Container>
          )}
          <Container top={24}>
            <FlatButton onClick={handleUploadTextClick}>Upload Text</FlatButton>
          </Container>
        </ButtonStyler>
      </Container>
    </Container>
  );
};

export const DocUploaderPage = () => {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);
  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <BackArrowButton onClick={onGoBack} />
          <DocUploader />
        </Page>
      </Body>
    </PageWrapper>
  );
};
