import React from 'react';
import { PageCard, PageCardWrapper } from '../components/layout/PageCardWrapper';
import { SplitPage } from '../components/layout/SplitPage';
import { PublicPageMessaging } from '../components/UI/PublicPageMessaging';
import styled from 'styled-components';
import { SignUpForm } from '../containers/authentication/SignUpForm';
import { useLocationUpdate } from '../hooks/application/useLocationSafe';

const SignUpFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

export default function SignUp() {
  useLocationUpdate();

  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging />}
      RightComponent={
        <SignUpFormWrapper>
          <PageCard>
            <SignUpForm />
          </PageCard>
        </SignUpFormWrapper>
      }
    />
  );
}
