import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Body2, Body5, Column, H1, Row, Spacing } from '../libs/nvstr-common-ui.es';
import Faqs from '../containers/application/FAQ';
import { HeaderLine } from '../components/UI/HeaderLine';
import { Header } from '../components/navigation';
import { TrackingEvent } from '../libs/nvstr-utils.es';

const SupportContactChannelsWrapper = styled.div`
  padding: 36px 0;
`;
const SupportContactChannelItemWrapper = styled.div`
  padding: 0 72px 0 0;
`;
const SupportContactChannelRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 36px 32px;
`;

const supportChannels = [
  {
    label: 'Pricing & Demo Scheduling',
    value: 'ai@tornado.com',
  },
  {
    label: 'Technical Support and General Inquiry',
    value: 'support@tornado.com',
  },
];

const SupportContactChannelItem = ({ isDarkMode, label, value }) => {
  return (
    <SupportContactChannelItemWrapper>
      <Body5>{label}</Body5>
      <Spacing top={8}>
        <Body2>{value}</Body2>
      </Spacing>
    </SupportContactChannelItemWrapper>
  );
};

const SupportContactChannels = ({ isDarkMode, isMobile }) => {
  return (
    <SupportContactChannelRowWrapper>
      {supportChannels.map((item) => (
        <SupportContactChannelItem key={item.label} label={item.label} value={item.value} isDarkMode={isDarkMode} />
      ))}
    </SupportContactChannelRowWrapper>
  );
};

const MobilePage = () => {
  return (
    <Column padding="40px 20px 0px 20px" width="100%">
      <H1>
        <b>CONTACT</b> & FAQ
      </H1>
      <Row padding="36px 40px 0 0" maxWidth="634px">
        <Body2>Have questions? We&apos;re here to help!</Body2>
      </Row>
      <Row padding="36px 0 0 0">
        <HeaderLine isLowContrast />
      </Row>
      <SupportContactChannelsWrapper>
        <SupportContactChannels isMobile />
      </SupportContactChannelsWrapper>
      <HeaderLine isLowContrast />
      <Faqs isMobile />
    </Column>
  );
};

const DesktopPage = () => {
  return (
    <Column padding="72px 60px 0px 60px" width="100%">
      <H1>
        <b>CONTACT</b> & FAQ
      </H1>
      <Row padding="52px 0 0 0" maxWidth="634px">
        <Body2>Have questions? We&apos;re here to help!</Body2>
      </Row>
      <Row padding="72px 0 0 0">
        <HeaderLine isLowContrast />
      </Row>
      <SupportContactChannelsWrapper>
        <SupportContactChannels />
      </SupportContactChannelsWrapper>
      <Row>
        <HeaderLine isLowContrast />
      </Row>
      <Row padding={'116px 0 0 0'}>
        <Faqs />
      </Row>
    </Column>
  );
};

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.header};
  height: 100vh;

  ul {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const Body = styled.div`
  background: ${({ theme }) => theme.themeColors.header};

  max-width: 1880px;
  margin: 0 auto;
`;

const Layout = ({ children }) => {
  return (
    <PageWrapper>
      <Body>
        <Header largeWidth forceTornado />
        {children}
      </Body>
    </PageWrapper>
  );
};

const FAQ = ({ theme }) => {
  const { isMobile } = theme;

  React.useEffect(() => {
    TrackingEvent.create('View DA FAQs');

    const body = document.getElementsByTagName('body')[0];
    body?.classList.add('dark-mode');

    return () => {
      body?.classList.remove('dark-mode');
    };
  }, []);

  return (
    <Layout pageTitle={'Contact & FAQ'} section="Contact & FAQ" disableOverflow={true}>
      {isMobile ? <MobilePage /> : <DesktopPage />}
    </Layout>
  );
};

export default withTheme(FAQ);
