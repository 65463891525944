import React from 'react';
import styled from 'styled-components';
import { sendApiRequest } from '../../../services/api';
import { logNetRequest } from '../../../utils/usefulFuncs';
import { Modal } from '../../../components/layout/Modal';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { Body5, Body7, Checkbox, Container } from '../../../libs/nvstr-common-ui.es';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import { useTableAnalysis } from '../../../hooks/features/useTableAnalysis';
import { useDispatch } from 'react-redux';
import { gridTableAnalysisActions } from '../../../constants/actionTypes';

const AddPromptWrapper = styled.div`
  position: relative;
  padding: 16px;

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 40px;
    width: 100%;
    padding: 16px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 16px;
    -webkit-appearance: auto !important;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 8;
`;
const SelectFavQuestionButtonWrapper = styled.div`
  padding: 0 0 0 16px;

  button {
    padding: 8px 16px;
  }
`;

function _isAlreadySelected(p, prompts) {
  return prompts.filter((sp) => sp.prompt === p.prompt).length > 0;
}

function _filterFavoriteQuestionList(favoriteQuestions, prompts, toggles) {
  const { showSharedFavorites } = toggles;
  const filteredFavs = favoriteQuestions.filter((p) => !_isAlreadySelected(p, prompts));
  return showSharedFavorites ? filteredFavs : filteredFavs.filter((p) => p.is_my_prompt);
}

export const AddPromptModal = ({ favId, onDismiss, defaultValues, isEditMode }) => {
  const [modalProps] = React.useState({ maximizeWidth: true });

  const dispatch = useDispatch();

  const [showSharedFavorites, setShowSharedFavorites] = React.useState(true);

  const [isSelectingFav, setIsSelectingFav] = React.useState(false);

  const [text, setText] = React.useState(defaultValues?.text || '');
  const [selection, setSelection] = React.useState('');
  const [isDiffMode, setIsDiffMode] = React.useState(
    'is_diff_mode' in (defaultValues || {}) ? defaultValues?.is_diff_mode : false
  );

  const { favoriteQuestions, prompts } = useTableAnalysis();

  const toggles = { showSharedFavorites };
  const availableSelectionFavoriteQuestions = _filterFavoriteQuestionList(favoriteQuestions, prompts, toggles);

  const canDelete = selection !== '' ? selection.is_my_prompt : false;

  const onAddPrompt = (p) => {
    dispatch({
      type: gridTableAnalysisActions.addPrompts,
      payload: [p],
    });
  };

  const onClear = () => {
    onDismiss(false);
    setIsSelectingFav(false);
    setText('');
    setSelection('');
    setIsDiffMode(false);
  };

  const toggleDiffMode = () => {
    return () => setIsDiffMode(!isDiffMode);
  };

  const toggleShowSharedMode = () => {
    return () => setShowSharedFavorites(!showSharedFavorites);
  };

  const onAddPromptComplete = () => {
    if (isSelectingFav && selection !== '') {
      onAddPrompt(selection);
    }
    if (!isSelectingFav && text.length > 0) {
      onAddPrompt({ display_label: text, prompt: text, is_diff_mode: isDiffMode });
    }
    onClear();
  };

  const onEditPromptComplete = async () => {
    if (text.length === 0) {
      onClear();
      return;
    }
    const edits = {
      prompt: text,
      display_label: text,
      is_diff_mode: isDiffMode,
    };
    const prompt = { prompt: defaultValues.text };

    if (favId) {
      const form = {
        ...edits,
      };
      const URL = `v1/genai_favorite_prompts/${favId}`;
      const { status, data, error } = await sendApiRequest('patch', URL, form);
      logNetRequest(status, data);
      dispatch({
        type: gridTableAnalysisActions.updateFavoriteQuestion,
        payload: {
          id: favId,
          ...edits,
        },
      });
    } else {
      dispatch({
        type: gridTableAnalysisActions.updatePrompt,
        payload: [prompt, edits],
      });
    }

    onClear();
  };

  const onEditCancel = () => {
    onClear();
    onDismiss();
  };

  const onRemoveSelectedFavorite = async () => {
    const id = selection.id;
    const URL = `v1/genai_favorite_prompts/${id}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.removeFavoriteQuestion,
        payload: id,
      });
      setSelection('');
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSelectFavoriteQuestion = (e) => {
    if (e.target.value === '') {
      setSelection('');
      return;
    }

    const favId = parseInt(e.target.value, 10);
    const favQ = favoriteQuestions.filter((d) => d.id === favId)[0];
    if (favQ) {
      setSelection(favQ);
    }
  };

  const handleSelectFavoriteClick = () => {
    setIsSelectingFav(true);
  };

  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <AddPromptWrapper>
        <CloseButtonWrapper>
          <CloseButton onClick={onClear} />
        </CloseButtonWrapper>
        <Container>
          <Body5 bold>
            {isEditMode ? 'Make changes to this question' : 'Enter or select a question to add to analysis table'}
          </Body5>
        </Container>
        <Container top={16}>
          {isSelectingFav ? (
            availableSelectionFavoriteQuestions.length === 0 ? (
              <Container vertical={16}>
                <Body5>There are no favorite questions available</Body5>
              </Container>
            ) : (
              <select value={selection?.id || ''} onChange={handleSelectFavoriteQuestion}>
                <option value={''}>Please select...</option>
                {availableSelectionFavoriteQuestions.map((d, i) => (
                  <option key={i} value={d.id}>
                    {d.prompt}
                  </option>
                ))}
              </select>
            )
          ) : (
            <textarea value={text} onChange={handleTextChange} />
          )}
        </Container>
        <Container top={12} row centerAll spaceBetween>
          <Container>
            <Container row>
              <Checkbox name={'is-diff-mode'} value={isDiffMode} onChange={toggleDiffMode} />
              <div onClick={toggleDiffMode()}>
                <Container top={3} style={{ cursor: 'pointer' }}>
                  <Body7 bold>Include adjacent document, e.g. for comparisons and diffs</Body7>
                </Container>
              </div>
            </Container>
            {isSelectingFav && !isEditMode && (
              <Container row top={16}>
                <Checkbox name={'show-shared-favs'} value={showSharedFavorites} onChange={toggleShowSharedMode} />
                <div onClick={toggleShowSharedMode()}>
                  <Container top={3} style={{ cursor: 'pointer' }}>
                    <Body7 bold>Show Shared Favorites</Body7>
                  </Container>
                </div>
              </Container>
            )}
          </Container>

          <Container>
            {isSelectingFav ? (
              <Container row>
                {canDelete && !isEditMode && (
                  <SelectFavQuestionButtonWrapper>
                    <TransparentFlatButton onClick={onRemoveSelectedFavorite}>Delete</TransparentFlatButton>
                  </SelectFavQuestionButtonWrapper>
                )}

                {isEditMode ? null : (
                  <SelectFavQuestionButtonWrapper>
                    <SkeletonButton onClick={() => setIsSelectingFav(false)}>Enter Question Instead</SkeletonButton>
                  </SelectFavQuestionButtonWrapper>
                )}
              </Container>
            ) : isEditMode ? null : (
              <SelectFavQuestionButtonWrapper>
                <SkeletonButton onClick={handleSelectFavoriteClick}>Select Question From Favorites</SkeletonButton>
              </SelectFavQuestionButtonWrapper>
            )}
          </Container>
        </Container>

        <Container top={30} style={{ textAlign: 'center' }}>
          <FlatButton fullWidth onClick={isEditMode ? onEditPromptComplete : onAddPromptComplete}>
            {isEditMode ? 'Save Edits' : 'Add Question'}
          </FlatButton>
        </Container>
        {isEditMode && (
          <Container top={8} style={{ textAlign: 'center' }}>
            <TransparentFlatButton fullWidth onClick={onEditCancel}>
              Cancel
            </TransparentFlatButton>
          </Container>
        )}
      </AddPromptWrapper>
    </Modal>
  );
};

export const AddPromptRow = () => {
  const [isAddingPrompt, setIsAddingPrompt] = React.useState(false);
  const onDismiss = () => setIsAddingPrompt(false);

  const handleAddQuestionClick = () => {
    setIsAddingPrompt(true);
  };

  return (
    <>
      {isAddingPrompt && <AddPromptModal onDismiss={onDismiss} />}
      <TransparentFlatButton onClick={handleAddQuestionClick}>+ Add Question</TransparentFlatButton>
    </>
  );
};
