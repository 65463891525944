import React from 'react';
import styled from 'styled-components';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { formatContext, parseContextValue } from '../../../utils/result';

const ContextWrapper = styled.div``;

const ContextRow = ({ value, isUsingFullContext }) => {
  const { filename, chunkIndex, text } = parseContextValue(value);

  if (isUsingFullContext) {
    return (
      <p>
        <Container>
          <Body5 bold>{filename}</Body5>
          <Body5 isLowContrast> {chunkIndex}</Body5>
        </Container>

        <Container top={8} left={8}>
          <Body5>{text}</Body5>
        </Container>
      </p>
    );
  }

  return (
    <p>
      <Body5>{value}</Body5>
    </p>
  );
};

export const ContextResult = ({ collapse, isShowingCitations, value }) => {
  const [context, setContext] = React.useState(null);

  React.useEffect(() => {
    const formatted = formatContext(value);
    setContext(formatted || null);
  }, [value]);

  if (collapse || !context) return null;

  return (
    <ContextWrapper>
      {context.map((line, i) => (
        <ContextRow key={i} value={line} isUsingFullContext={isShowingCitations} />
      ))}
    </ContextWrapper>
  );
};
