import React from 'react';
import styled from 'styled-components';
import Close from '../../assets/icons/svgs/Close';

const ButtonWrapper = styled.div`
  position: relative;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 5;

  svg {
    height: ${({ $Size }) => ($Size || 18) + 'px'};
    width: ${({ $Size }) => ($Size || 18) + 'px'};

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

export const CloseButton = ({ onClick, size }) => {
  return (
    <ButtonWrapper onClick={onClick} $Size={size}>
      <Close />
    </ButtonWrapper>
  );
};
