import { gridTableAnalysisActions } from '../constants/actionTypes';

const defaultState = {
  favoriteQuestions: [],
  prompts: [],
  selectedDocuments: [null],
  tableRows: null,
};

function _findIndex(list, key, value) {
  let matchedPromptId = null;
  list.forEach((p, i) => {
    if (p[key] === value) {
      matchedPromptId = i;
    }
  });
  return matchedPromptId;
}

function updatePrompts(prompts, newPrompt, id) {
  let matchedPromptIndex = _findIndex(prompts, id ? 'id' : 'prompt', id ? id : newPrompt.prompt);
  if (matchedPromptIndex !== null) {
    const copiedPromptState = [...prompts];
    const promptToUpdate = copiedPromptState[matchedPromptIndex];
    copiedPromptState[matchedPromptIndex] = {
      ...promptToUpdate,
      ...newPrompt,
    };
    return copiedPromptState;
  }
  return prompts;
}

function updateTableRow(tableRows, originalPrompt, nextFavoriteQ) {
  if (!tableRows) return tableRows;
  const nextTableRows = [...tableRows];
  let foundIndex = null;
  nextTableRows.forEach((r, i) => {
    if (originalPrompt === r.prompt.prompt) {
      foundIndex = i;
    }
  });
  if (foundIndex !== null) {
    nextTableRows[foundIndex] = {
      ...nextTableRows[foundIndex],
      prompt: nextFavoriteQ,
    };
  }
  return nextTableRows;
}

function isInPrompts(favQ, prompts) {
  let found = false;
  prompts.forEach((p) => {
    if (favQ.prompt === p.prompt) {
      found = true;
    }
  });
  return found;
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case gridTableAnalysisActions.loadAllFavs: {
      const favs = state.favoriteQuestions;
      if (!favs) {
        return state;
      }
      const prompts = state.prompts;
      const nonDuplicates = favs.filter((f) => !isInPrompts(f, prompts));
      return { ...state, prompts: [...state.prompts, ...nonDuplicates] };
    }

    case gridTableAnalysisActions.replaceFavoriteQuestions: {
      return { ...state, favoriteQuestions: action.payload };
    }

    case gridTableAnalysisActions.addFavoriteQuestion: {
      const newFavoriteQuestion = action.payload;
      const prompts = state.prompts;
      const nextPrompts = updatePrompts(prompts, newFavoriteQuestion);
      return {
        ...state,
        prompts: nextPrompts,
        favoriteQuestions: [...state.favoriteQuestions, newFavoriteQuestion],
      };
    }

    case gridTableAnalysisActions.updateFavoriteQuestion: {
      const updatedFavoriteQuestion = action.payload;

      const favoriteQuestions = state.favoriteQuestions;
      const matchedQId = _findIndex(favoriteQuestions, 'id', updatedFavoriteQuestion.id);
      const copiedFavQState = [...favoriteQuestions];
      const q = copiedFavQState[matchedQId];
      const originalPrompt = q.prompt;
      const nextFavoriteQ = {
        ...q,
        ...updatedFavoriteQuestion,
      };
      copiedFavQState[matchedQId] = nextFavoriteQ;
      const prompts = state.prompts;
      const nextPrompts = updatePrompts(prompts, nextFavoriteQ, updatedFavoriteQuestion.id);
      return {
        ...state,
        prompts: nextPrompts,
        favoriteQuestions: copiedFavQState,
        tableRows: updateTableRow(state.tableRows, originalPrompt, nextFavoriteQ),
      };
    }

    case gridTableAnalysisActions.removeFavoriteQuestion: {
      const prompts = state.prompts;

      let matchedPromptIndex = _findIndex(prompts, 'id', action.payload);
      if (matchedPromptIndex !== null) {
        const copiedPromptState = [...prompts];
        const promptToUpdate = copiedPromptState[matchedPromptIndex];
        copiedPromptState[matchedPromptIndex] = {
          display_label: promptToUpdate.display_label,
          prompt: promptToUpdate.prompt,
          is_diff_mode: promptToUpdate.is_diff_mode,
        };
        return {
          ...state,
          prompts: copiedPromptState,
          favoriteQuestions: state.favoriteQuestions.filter((d) => d.id !== action.payload),
        };
      }
      return { ...state, favoriteQuestions: state.favoriteQuestions.filter((d) => d.id !== action.payload) };
    }

    case gridTableAnalysisActions.addPrompts: {
      const newPrompts = action.payload;
      let nextTableRows = state.tableRows;
      if (nextTableRows !== null) {
        const newTableRows = [];
        newPrompts.forEach((p) => {
          const row = {
            prompt: p,
            documents: state.selectedDocuments,
            cellCount: state.selectedDocuments.filter((d) => d !== null).length,
          };
          newTableRows.push(row);
        });

        nextTableRows = [...state.tableRows, ...newTableRows];
      }
      return { ...state, prompts: [...state.prompts, ...newPrompts], tableRows: nextTableRows };
    }

    case gridTableAnalysisActions.updatePrompt: {
      const [original, edits] = action.payload;
      const prompts = state.prompts;
      let matchedPromptIndex = _findIndex(prompts, 'prompt', original.prompt);
      if (matchedPromptIndex !== null) {
        const copiedPromptState = [...prompts];
        // const promptToUpdate = copiedPromptState[matchedPromptIndex];
        const updatedPromptData = {
          ...edits,
        };
        copiedPromptState[matchedPromptIndex] = updatedPromptData;
        return {
          ...state,
          prompts: copiedPromptState,
          tableRows: updateTableRow(state.tableRows, original.prompt, updatedPromptData),
        };
      }
      return state;
    }

    case gridTableAnalysisActions.removePrompt: {
      return { ...state, prompts: state.prompts.filter((p) => p.prompt !== action.payload.prompt) };
    }

    case gridTableAnalysisActions.replacePrompts: {
      return { ...state, prompts: action.payload };
    }

    case gridTableAnalysisActions.initTableRows: {
      const tableRows = [];
      state.prompts.forEach((p) => {
        const row = {
          prompt: p,
          documents: state.selectedDocuments,
          cellCount: state.selectedDocuments.filter((d) => d !== null).length,
        };
        tableRows.push(row);
      });
      return { ...state, tableRows };
    }

    case gridTableAnalysisActions.addTableRows: {
      return { ...state, tableRows: [...state.tableRows, ...action.payload] };
    }

    case gridTableAnalysisActions.updateTableRow: {
      const { index, data } = action.payload;
      const nextTableRows = [...state.tableRows];
      nextTableRows[index] = data;
      return { ...state, tableRows: nextTableRows };
    }

    case gridTableAnalysisActions.deleteTableRow: {
      const { index } = action.payload;
      const nextTableRows = [...state.tableRows.filter((r, i) => i !== index)];
      return { ...state, tableRows: nextTableRows };
    }

    case gridTableAnalysisActions.clearTable: {
      return { ...defaultState, favoriteQuestions: state.favoriteQuestions };
    }

    case gridTableAnalysisActions.importTable: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case gridTableAnalysisActions.addSelectedDocument: {
      const { index, document } = action.payload;
      const nextSelectedDocuments = [...state.selectedDocuments];
      nextSelectedDocuments[index] = !nextSelectedDocuments[index]
        ? [document]
        : [...nextSelectedDocuments[index], document];

      const lastDoc = nextSelectedDocuments[nextSelectedDocuments.length - 1];
      if (lastDoc !== null) {
        nextSelectedDocuments.push(null);
      }
      return { ...state, selectedDocuments: nextSelectedDocuments };
    }

    case gridTableAnalysisActions.removeSelectedDocument: {
      const { index, document } = action.payload;
      const nextSelectedDocuments = [...state.selectedDocuments];
      nextSelectedDocuments[index] = null;

      // const lastDoc = nextSelectedDocuments[nextSelectedDocuments.length - 1];
      // if (lastDoc !== null) {
      //   nextSelectedDocuments.push(null);
      // }
      return { ...state, selectedDocuments: nextSelectedDocuments };
    }

    default:
      return state;
  }
}
