import React from 'react';
import styled, { css } from 'styled-components';
import { Overlay } from './Overlay';

export const ModalWrapper = styled.div`
  position: relative;
  margin: 16px;
  border-radius: 5px;
  text-align: left;
  cursor: default;
  overflow: hidden;

  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  color: ${({ theme }) => theme.themeColors.text};
`;

export const ModalContent = styled.div`
  padding: 24px 16px;
  overflow: auto;

  max-height: 98vh;

  ${({ forceHeight, theme }) =>
    forceHeight
      ? css`
          height: 98vh;
        `
      : ``};

  ${({ maximizeWidth, theme }) =>
    maximizeWidth
      ? css`
          width: 80vw;
          max-width: 1600px;
        `
      : ``};

  @media (max-width: 1499px) {
    ${({ maximizeWidth, theme }) =>
      maximizeWidth
        ? css`
            width: 92vw;
          `
        : ``};
  }

  @media (max-width: 390px) {
    padding: 16px;
  }
  @media (max-width: 340px) {
    padding: 8px;
  }
`;

export const Modal = (props) => {
  const { children, restrictDismiss, onDismiss, modalProps } = props;

  const handleModalClick = (e) => {
    e.stopPropagation(); // prevent overlay click from registering
  };

  return (
    <Overlay {...props}>
      <ModalWrapper {...(modalProps || {})} onClick={handleModalClick}>
        <ModalContent {...(modalProps || {})}>{children}</ModalContent>
      </ModalWrapper>
    </Overlay>
  );
};
