import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZES } from '../../libs/nvstr-utils.es';

const Wrapper = styled.div`
  flex: 1;
  max-width: ${({ maxWidth }) => maxWidth || '800px'};

  position: relative;
  margin: 12px 0 0 0;
  padding: ${({ noPadding }) => (noPadding ? '0px' : ' 16px 24px')};
  border-radius: 5px;
  text-align: left;
  cursor: default;

  background: ${({ theme, transparentBackground }) =>
    transparentBackground
      ? 'transparent'
      : theme.screenSize === SCREEN_SIZES.mobile
      ? theme.themeColors.componentNoOpacity
      : theme.themeColors.component};
  color: ${({ theme }) => theme.themeColors.text};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;

    max-width: ${({ width }) => width || '100%'};
    width: 100%;
    height: ${({ fullHeight, expandHeight, height }) =>
      height || (fullHeight || expandHeight ? 'calc(100vh - 64px)' : 'unset')}; // 64px === nav height
    max-height: ${({ expandHeight }) => (expandHeight ? '800px' : 'unset')}; // 64px === nav height
    ${({ theme, mobileTransparentBackground }) =>
      mobileTransparentBackground && theme.screenSize === SCREEN_SIZES.mobile
        ? 'background: ' + theme.themeColors.componentNoOpacity + ';'
        : ''};
  }

  @media (max-width: 430px) {
    padding: ${({ noPadding }) => (noPadding ? '0px' : ' 16px')};
  }
  @media (max-width: 340px) {
    padding: ${({ noPadding }) => (noPadding ? '0px' : ' 8px')};
  }
`;

export function MultiColumnPage({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
