import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageCard, PageCardWrapper } from '../components/layout/PageCardWrapper';
import { resetPasswordSubmit } from '../services/authentication';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Container } from '../libs/nvstr-common-ui.es';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import InputField from '../containers/form/InputField';
import { FlatButton } from '../components/buttons';
import { SplitPage } from '../components/layout/SplitPage';
import { PublicPageMessaging } from '../components/UI/PublicPageMessaging';

const SignInFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

export const ValidationWrap = styled('div')`
  color: ${({ theme }) => theme.themeColors.error} !important;
  font-size: 12px;
`;

export const FormWrap = styled('div')`
  position: relative;

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
  }
`;

export const LoadingCover = styled.div`
  width: 360px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
`;

export const SubTitle = styled('div')`
  font-size: 18px;
  font-weight: 300;
  margin: 5px 0 20px;
  line-height: 1.4em;
`;

export const FormTitle = styled('div')`
  font-size: 24px;
  font-weight: 600;
`;

export const SignSwitchLink = styled('div')`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
`;

function ResetPasswordForm() {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [forgotPasswordComplete, setResetPasswordComplete] = useState(false);
  const [isFailedValidation, setIsFailedValidation] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  const navigate = useNavigate();

  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const resetToken = searchParams.get('reset_password_token');
    setResetPasswordToken(resetToken);
  }, []);

  const userPasswordComplete = () => {
    setIsLoading(false);
    setResetPasswordComplete(true);
  };

  const userPasswordError = (errorMessage) => {
    setIsLoading(false);
    setIsFailedValidation(true);
    setValidationMessage(errorMessage);
  };

  const userPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    const passwordLength = password1 && password1.length > 7;
    const passWordsMatch = password1 === password2;
    if (passwordLength && passWordsMatch) {
      setIsFailedValidation(false);
      resetPasswordSubmit({
        password1,
        password2,
        userPasswordComplete,
        userPasswordError,
        resetPasswordToken,
      });
    } else {
      setIsFailedValidation(true);
      setIsLoading(false);
      if (!passWordsMatch) {
        setValidationMessage('Please enter the same password twice.');
      } else if (!passwordLength) {
        setValidationMessage('Password must have at least 8 characters.');
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userPassword(e);
    }
  };

  if (forgotPasswordComplete) {
    return (
      <div>
        <FormTitle>Your Password has been reset. </FormTitle>
        <Container top={24}>
          <SubTitle>
            Continue to <Link to="/login">Sign In</Link>
          </SubTitle>
        </Container>
      </div>
    );
  }
  if (isLoading) {
    return (
      <LoadingCover>
        <LoadingSpinner text={'Submitting...'} />
      </LoadingCover>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      <FormWrap>
        <form>
          <Container bottom={24}>
            <FormTitle>Reset Password</FormTitle>
          </Container>

          <InputField
            id="password-field-1"
            placeholder={'New Password'}
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            autoComplete="current-password"
          />

          <InputField
            id="password-field-2"
            placeholder={'Confirm New Password'}
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            autoComplete="current-password"
          />

          {isFailedValidation ? <ValidationWrap>{validationMessage}</ValidationWrap> : null}

          <Container top={16}>
            <FlatButton id="forgot-password-submit" fullWidth onClick={userPassword}>
              Submit
            </FlatButton>
          </Container>
          <Container top={8}>
            <SignSwitchLink>
              Back to <Link to="/login">Sign In</Link>
            </SignSwitchLink>
          </Container>
        </form>
      </FormWrap>
    </div>
  );
}

export default function ResetPassword() {
  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging />}
      RightComponent={
        <SignInFormWrapper>
          <PageCard>
            <ResetPasswordForm />
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
}
