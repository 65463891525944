import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { Body5, Spacing } from '../../libs/nvstr-common-ui.es';
import { useCurrentUser } from '../../hooks/user/useCurrentUser';
import { FlatButton } from '../../components/buttons';
import { ROUTES } from '../../constants/routes';

const Wrapper = styled.div`
  padding: 24px;
  text-align: center;
`;
const MessageContainer = styled.div`
  padding: 24px;
  width: 90%;
  max-width: 360px;
  margin: 0 auto;

  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.themeColors.border}`};
`;

const PageCrashedDisplay = () => {
  const handleClick = () => {
    if (window?.location?.host === 'localhost:3000') {
      window.location.reload();
    } else {
      window.location = ROUTES.home;
    }
  };

  return (
    <div>
      <Wrapper>
        <MessageContainer>
          <div>
            <Body5>Something went wrong. Please reload the page.</Body5>
            <Spacing top={16} center>
              <FlatButton onClick={handleClick}>Reload</FlatButton>
            </Spacing>
          </div>
        </MessageContainer>
      </Wrapper>
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  const currentUser = useCurrentUser();
  const onBeforeCapture = (scope) => {
    scope.setUser({ id: currentUser?.id });
  };
  const onError = (e) => {
    TrackingEvent.create('Webapp page crash occurred', {
      message: e?.message,
    });
  };
  return (
    <Sentry.ErrorBoundary fallback={PageCrashedDisplay} beforeCapture={onBeforeCapture} onError={onError}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
