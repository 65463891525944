import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { Body5, Container, H5 } from '../../libs/nvstr-common-ui.es';
import React from 'react';
import styled from 'styled-components';

const FutureEventItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px; /* Adjust sizes as needed */

  padding: 6px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const FUTURE_EVENTS = [
  {
    name: 'FOMC Press Conference',
    date: createTimeInstance('9/18/2024, 2:00 pm'),
  },
];

const FutureEventItem = ({ data }) => {
  const dateFormat = data.dateOnly ? 'll2' : 'asOf';
  const readableDate = formatLocalizedDateTime(dateFormat, createTimeInstance(data.date));

  return (
    <FutureEventItemWrapper>
      <Body5>{data.name}</Body5>
      <Container row alignRight>
        <Body5>{readableDate}</Body5>
      </Container>
    </FutureEventItemWrapper>
  );
};

export const FutureLiveFedEvents = () => {
  const [futureLiveEvents] = React.useState(FUTURE_EVENTS.filter((e) => createTimeInstance().isBefore(e.date)));

  return (
    <Container>
      <Container>
        <H5>Future Live Events</H5>
      </Container>
      <Container top={24}>
        {futureLiveEvents.length > 0 ? (
          futureLiveEvents.map((e) => <FutureEventItem key={e.name} data={e} />)
        ) : (
          <Container bottom={48}>
            <Body5>No future live events are currently scheduled</Body5>
          </Container>
        )}
      </Container>
    </Container>
  );
};
