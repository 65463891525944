import { COLOR_THEME_KEYS, colorPalette, convertHexToRGBA, getContrastTheme } from '../libs/nvstr-utils.es';
import { getItemFromStorage, setItemToStorage, STORAGE_KEYS } from './storage';
import { COLOR_THEME_NAMES, DEFAULT_COLOR_THEME } from '../constants/app';

const { charcoal, oak, whiteBlueTint } = colorPalette.primary;
const {
  white,
  darkCloud,
  lightCloud,
  cloud,
  streetBlue,
  streetGreen,
  streetRed,
  darkBlue,
  orange,
  purple,
  lightCharcoal,
  green,
  red,
  darkRed,
  darkGreen,
  brightGreen,
  yellow,
  lightBlue,
} = colorPalette.secondary;

export const defaultCustomColorTheme = {
  header: '#2F2E2B',
  appBackground: '#211e18',
  component: '#2F2E2B',
  componentNoOpacity: '#2F2E2B',
  disabled: 'rgba(255,255,255,0.05)',
  overlay: 'rgba(247,244,242,0.6)',
  border: 'rgba(255,255,255,0.2)',
  lowContrastBorder: 'rgba(255,255,255,0.05)',
  text: '#ffffff',
  secondaryText: 'rgba(255,255,255,0.6)',
  buttonText: white,
  proColor: darkGreen,
  conColor: darkRed,
  neutral: lightCharcoal,
  negative: darkRed,
  positive: darkGreen,
  inputBg: 'transparent',
  avatarBackgroundColor: '#ab9419',
  buttonColor: '#ab9419',
  primaryCtaButton: '#ab9419',
  primaryEyeCatchingCtaButton: '#ab9419',
  badge: '#ab9419',
  error: red,
  warning: orange,
  hoverComponentBackground: 'rgba(171,148,25,0.1)',
};

export const colorThemeNameDisplayName = {
  [COLOR_THEME_KEYS.appBackground]: 'App Background',
  [COLOR_THEME_KEYS.componentNoOpacity]: 'Page Background',
  [COLOR_THEME_KEYS.text]: 'Text Color',
  [COLOR_THEME_KEYS.primaryCtaButton]: 'Button Color',
  [COLOR_THEME_KEYS.buttonText]: 'Button Text Color',
  [COLOR_THEME_KEYS.positive]: 'Positive Number',
  [COLOR_THEME_KEYS.neutral]: 'Neutral/ No Change',
  [COLOR_THEME_KEYS.negative]: 'Negative',
  [COLOR_THEME_KEYS.proColor]: 'Pro Color',
  [COLOR_THEME_KEYS.conColor]: 'Con Color',
};

export const getStartingColorThemeName = () => {
  const color = window.localStorage.getItem('ct');
  const isValid = Object.values(COLOR_THEME_NAMES).includes(color);
  let defaultColorThemeName = isValid ? color : DEFAULT_COLOR_THEME;

  try {
    const usingCustomTheme = getItemFromStorage(STORAGE_KEYS.USING_CUSTOM_THEME) === 'true';
    if (usingCustomTheme) {
      defaultColorThemeName = COLOR_THEME_NAMES.custom;
    }
  } catch (e) {
    setItemToStorage(STORAGE_KEYS.USING_CUSTOM_THEME, 'false');
  }

  return defaultColorThemeName;
};

export const getStartingColorTheme = () => {
  let startingColorTheme = defaultCustomColorTheme;

  try {
    const usingCustomTheme = getItemFromStorage(STORAGE_KEYS.USING_CUSTOM_THEME) === 'true';
    if (usingCustomTheme) {
      const prevCustomTheme = getItemFromStorage(STORAGE_KEYS.PREVIOUS_CUSTOM_THEME);
      if (prevCustomTheme) {
        const userThemes = _getThemesFromStorage();
        const userThemeNames = Object.keys(userThemes);
        if (userThemeNames.includes(prevCustomTheme)) {
          startingColorTheme = userThemes[prevCustomTheme];
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  return startingColorTheme;
};

export const generateComputedColors = (colors) => {
  const computedColors = {};

  if (colors[COLOR_THEME_KEYS.componentNoOpacity]) {
    // computedColors[COLOR_THEME_KEYS.component] = convertHexToRGBA(colors[COLOR_THEME_KEYS.componentNoOpacity], 0.9);
    computedColors[COLOR_THEME_KEYS.component] = colors[COLOR_THEME_KEYS.componentNoOpacity];
    computedColors[COLOR_THEME_KEYS.header] = colors[COLOR_THEME_KEYS.componentNoOpacity];
    computedColors[COLOR_THEME_KEYS.overlay] = convertHexToRGBA(
      getContrastTheme(colors[COLOR_THEME_KEYS.componentNoOpacity], {
        dark: oak,
        light: charcoal,
      }),
      0.6
    );
  }

  if (colors[COLOR_THEME_KEYS.primaryCtaButton]) {
    computedColors[COLOR_THEME_KEYS.hoverComponentBackground] = convertHexToRGBA(
      colors[COLOR_THEME_KEYS.primaryCtaButton],
      0.1
    );
    computedColors[COLOR_THEME_KEYS.avatarBackgroundColor] = colors[COLOR_THEME_KEYS.primaryCtaButton];
    computedColors[COLOR_THEME_KEYS.buttonColor] = colors[COLOR_THEME_KEYS.primaryCtaButton];
    computedColors[COLOR_THEME_KEYS.primaryEyeCatchingCtaButton] = colors[COLOR_THEME_KEYS.primaryCtaButton];
    computedColors[COLOR_THEME_KEYS.badge] = colors[COLOR_THEME_KEYS.primaryCtaButton];
  }

  if (colors[COLOR_THEME_KEYS.text]) {
    computedColors[COLOR_THEME_KEYS.border] = convertHexToRGBA(colors[COLOR_THEME_KEYS.text], 0.2);
    computedColors[COLOR_THEME_KEYS.lowContrastBorder] = convertHexToRGBA(colors[COLOR_THEME_KEYS.text], 0.05);
    computedColors[COLOR_THEME_KEYS.disabled] = convertHexToRGBA(colors[COLOR_THEME_KEYS.text], 0.05);
    computedColors[COLOR_THEME_KEYS.secondaryText] = convertHexToRGBA(colors[COLOR_THEME_KEYS.text], 0.6);
  }

  return computedColors;
};

export const saveColorTheme = (colorThemeName, customTheme) => {
  let storedColorTheme = window.localStorage.getItem('colorThemes');
  if (!storedColorTheme) {
    storedColorTheme = {};
  } else {
    storedColorTheme = JSON.parse(storedColorTheme);
  }
  storedColorTheme[colorThemeName] = customTheme;
  window.localStorage.setItem('colorThemes', JSON.stringify(storedColorTheme));
};

export const _getThemesFromStorage = () => {
  let storedColorThemes = window.localStorage.getItem('colorThemes');
  if (storedColorThemes) {
    return JSON.parse(storedColorThemes);
  } else {
    return {};
  }
};
