import { currentUserActionTypes } from '../constants/actionTypes';

const defaultState = null;

const OVERRIDE_CURRENT_USER_DATA = {};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case currentUserActionTypes.setCurrentUser: {
      return {
        ...(state || {}),
        ...action.payload,
        ...OVERRIDE_CURRENT_USER_DATA,
      };
    }

    case currentUserActionTypes.updateCurrentUser: {
      return {
        ...(state || {}),
        ...action.payload,
        ...OVERRIDE_CURRENT_USER_DATA,
      };
    }

    default:
      return state;
  }
}
