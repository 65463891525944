import React from 'react';
import { BackArrow } from '../../assets/icons/svgs/BackArrow';
import styled from 'styled-components';
import { breakpoints } from '../../libs/nvstr-utils.es';

const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: ${({ Left }) => Left || '16px'};

  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 5;

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  @media (max-width: ${() => breakpoints.mobile}) {
    top: 4px;
    left: 4px;
  }
`;

export default function BackArrowButton({ onClick, left }) {
  return (
    <IconWrapper onClick={onClick} Left={left}>
      <BackArrow />
    </IconWrapper>
  );
}
