import React from 'react';
import styled from 'styled-components';
import jQuery from 'jquery';
import {
  Body1,
  Body5,
  Column,
  H3,
  H5,
  Place,
  Row,
  SVG_LOADING_ICON_TYPES,
  SvgLoadingIcon,
} from '../../libs/nvstr-common-ui.es';
import { HeaderLine } from '../../components/UI/HeaderLine';
import { colorPalette, parseQueryString } from '../../libs/nvstr-utils.es';
import useScrollSpy from '../../hooks/application/useScrollSpy';
import useFAQS from '../../hooks/features/useFaqs';
import TextButton from '../../components/buttons/TextButton';
import InvisibleButton from '../../components/buttons/InvisibleButton';
import useSectionExpandedState from '../../hooks/features/useSectionExpandedState';

const Wrapper = styled.div`
  g {
    fill: ${(props) => props.theme.primary.charcoal};
  }
`;
const CaretLess = ({ height, width }) => {
  return (
    <Wrapper>
      <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M14.59 13.41L10 8.83003L5.41 13.41L4 12L10 6.00003L16 12L14.59 13.41Z" />
        </g>
      </svg>
    </Wrapper>
  );
};

const CaretMore = ({ height, width }) => {
  return (
    <Wrapper>
      <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M14.59 6L10 10.58L5.41 6L4 7.41L10 13.41L16 7.41L14.59 6Z" />
        </g>
      </svg>
    </Wrapper>
  );
};

const StyleWrapper = styled.div`
  ul {
    list-style: none; /* Remove default bullets */
  }

  li {
    margin-bottom: 8px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li::marker {
    // color: ${(props) => colorPalette.primary.oak};
    content: '• ';
    display: inline-block; /* Needed to add space between the bullet and the text */
  }
`;

const StickyWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  padding-top: 16px;
`;

const FAQTable = ({ rows }) => {
  return (
    <div style={{ paddingLeft: '8px' }}>
      <table>
        <tbody>
          {rows.map((d, i) => (
            <tr key={i}>
              <td>
                <Body1>{d[0]}</Body1>
              </td>
              <td style={{ paddingLeft: '16px', textAlign: 'right' }}>
                <Body1>{d[1]}</Body1>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FAQList = ({ rows }) => {
  return (
    <div style={{ paddingLeft: '8px' }}>
      <ul>
        {rows.map((d, i) => (
          <li key={i}>
            <Body1>{d}</Body1>
          </li>
        ))}
      </ul>
    </div>
  );
};

const TextButtonWrapper = styled.div`
  button {
    text-align: left;
    max-width: 210px;
  }
`;

const FAQGroupSectionName = ({ section, isActive, isDarkMode, onSectionNameClick }) => {
  const { heading: text, name } = section;

  const handleGroupSectionNavigationClick = () => {
    onSectionNameClick(name);

    // TrackingEvents.faq.expandSection.send({
    //   Section: text,
    //   'Section Name': name,
    // });
  };

  return (
    <TextButtonWrapper>
      <TextButton onClick={handleGroupSectionNavigationClick} isLowContrast={!isActive} isDarkMode={isDarkMode}>
        {isActive ? <Body5 bold>{text}</Body5> : <Body5>{text}</Body5>}
      </TextButton>
    </TextButtonWrapper>
  );
};

const FAQMessage = ({ message, isDarkMode }) => {
  if (typeof message !== 'string') {
    return (
      <div>
        {message.map((d, i) => (
          <div key={i} style={{ paddingTop: '8px' }}>
            {typeof d === 'string' ? (
              <Body1 isDarkMode={isDarkMode}>
                <span dangerouslySetInnerHTML={{ __html: `<p>${d}<p>` }} />
              </Body1>
            ) : d.type === 'list' ? (
              <FAQList rows={d.data.rows} isDarkMode={isDarkMode} />
            ) : d.type === 'table' ? (
              <FAQTable rows={d.data.rows} isDarkMode={isDarkMode} />
            ) : (
              <Body1 isDarkMode={isDarkMode} />
            )}
          </div>
        ))}
      </div>
    );
  }
  return (
    <Body1 isDarkMode={isDarkMode}>
      <span dangerouslySetInnerHTML={{ __html: `<p>${message}<p>` }} />
    </Body1>
  );
};

const FAQQuestionAndAnswer = ({ id: faqId, question, message, isExpanded, isDarkMode, isMobile, onToggleQuestion }) => {
  const handleQuestionExpansionToggleClick = () => {
    onToggleQuestion.current(faqId);

    // TrackingEvents.faq.expandQuestion.send({ Question: question, ID: faqId });
  };

  const iconSize = '20';
  return (
    <>
      <InvisibleButton onClick={handleQuestionExpansionToggleClick}>
        <Row style={{ justifyContent: 'space-between' }} padding={'24px 0 28px 0'} width={'100%'}>
          <Column maxWidth={'722px'}>
            <H5 isDarkMode={isDarkMode}>{question}</H5>
          </Column>
          <Column vAlign={'center'} width={isMobile ? '45px' : '124px'}>
            <Row padding={isMobile ? '0 0 0 25px' : '0 0 0 100px'}>
              {isExpanded ? (
                <CaretLess height={iconSize} width={iconSize} useTextColor isDarkMode />
              ) : (
                <CaretMore height={iconSize} width={iconSize} useTextColor isDarkMode />
              )}
            </Row>
          </Column>
        </Row>
      </InvisibleButton>

      {isExpanded ? (
        <Row maxWidth={'722px'} padding={'0 0 36px 0'}>
          <FAQMessage message={message} isDarkMode={isDarkMode} />
        </Row>
      ) : null}
    </>
  );
};

const FAQGroupSection = ({ section, isDarkMode, isMobile, onToggleQuestion, expandedQuestions }) => {
  const { questions, heading, name } = section;
  return (
    <section id={`faq-section-${name}`} style={{ width: '100%' }}>
      <Column>
        <H3 isDarkMode={isDarkMode}>
          <b>{heading}</b>
        </H3>
        <Place margin={'24px 0 0 0'}>
          <HeaderLine isDarkMode={isDarkMode} />
        </Place>
        {questions.map((faq, i) => (
          <div key={i} id={faq.id}>
            <FAQQuestionAndAnswer
              {...faq}
              isDarkMode={isDarkMode}
              isMobile={isMobile}
              isExpanded={expandedQuestions.includes(faq.id)}
              onToggleQuestion={onToggleQuestion}
            />
            <HeaderLine isDarkMode={isDarkMode} isLowContrast />
          </div>
        ))}
      </Column>
    </section>
  );
};

const Faqs = ({ isMobile, isDarkMode }) => {
  const { isLoading, orderedSections } = useFAQS();

  const {
    expandedSectionIds: expandedQuestions,
    toggleSectionState: toggleQuestionState,
    setSectionState,
  } = useSectionExpandedState();

  const orderedSectionNames = (orderedSections || []).map((s) => s.name);
  const { activeSectionName, scrollToSection } = useScrollSpy(orderedSectionNames);

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);
    const autoFocusSectionName = query?.section;
    setTimeout(() => {
      scrollToSection(autoFocusSectionName);
    }, 600);
  }, []);

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);

    if (query?.id) {
      const autoFocusId = parseInt(query?.id, 10);
      setTimeout(() => {
        setSectionState.current(autoFocusId, true);
        jQuery('html, body').animate(
          {
            scrollTop: jQuery(`#${autoFocusId}`).offset().top,
          },
          600
        );
      }, 600);
    }
  }, []);

  if (isLoading) {
    return (
      <Row width="100%" hAlign={'center'} vAlign={'center'} padding="100px 0 250px 0">
        <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} width={64} isDarkMode />
      </Row>
    );
  }

  if (isMobile) {
    return (
      <>
        <Row padding={'36px 0 0 0'}>
          <Column width={'240px'} padding={'4px 0 0 0'}>
            <H5>
              <b>Topics</b>
            </H5>
            <Place padding="36px 0 0 16px">
              {orderedSections.map((s) => (
                <Column key={s.heading} padding={'0 0 16px 0'}>
                  <FAQGroupSectionName
                    section={s}
                    isActive={activeSectionName === s.name}
                    onSectionNameClick={scrollToSection}
                  />
                </Column>
              ))}
            </Place>
          </Column>
        </Row>
        <StyleWrapper>
          <Column padding="20px 0 0 0">
            {orderedSections.map((s) => (
              <Row key={s.heading} padding={isMobile ? '0 0 36px 0' : '0 0 164px 0'}>
                <FAQGroupSection
                  section={s}
                  expandedQuestions={expandedQuestions}
                  onToggleQuestion={toggleQuestionState}
                  isMobile={isMobile}
                />
              </Row>
            ))}
          </Column>
        </StyleWrapper>
      </>
    );
  }

  return (
    <>
      <div style={{ overflow: 'visible' }}>
        <StickyWrapper>
          <Column id={'faq-group-container'} width={'240px'} padding={'4px 36px 0 0'}>
            {orderedSections.map((s) => (
              <Row key={s.name} padding={'0 0 16px 0'}>
                <FAQGroupSectionName
                  section={s}
                  isActive={activeSectionName === s.name}
                  onSectionNameClick={scrollToSection}
                />
              </Row>
            ))}
          </Column>
        </StickyWrapper>
      </div>
      <StyleWrapper>
        <Column width={'100%'} maxWidth={'852px'}>
          {orderedSections.map((s) => (
            <Row key={s.name} padding={'0 0 164px 0'}>
              <FAQGroupSection
                section={s}
                expandedQuestions={expandedQuestions}
                onToggleQuestion={toggleQuestionState}
              />
            </Row>
          ))}
        </Column>
      </StyleWrapper>
    </>
  );
};

export default Faqs;
