import React from 'react';
import styled, { css } from 'styled-components';
import { Body1, Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { convertHexToRGBA } from '../../../libs/nvstr-utils.es';
import { DOVISH_COLOR, HAWKISH_COLOR } from '../../../constants';

function convertScoreToOpacityThreshold(_score) {
  const score = Math.abs(_score);
  if (score > 0.6) {
    return 1;
  }
  if (score >= 0.3) {
    return 0.5;
  }
  if (score > 0) {
    return 0.1;
  }
  return 0;
}

const SentenceWrapper = styled.div`
  position: relative;

  ${({ Score, theme }) =>
    css`
      display: inline;
      border: 1px solid ${({ theme }) => theme.themeColors.component};

      font-size: 14px;
      line-height: 20px;

      &:hover {
        border: 1px solid ${({ theme }) => theme.themeColors.text};
        border-radius: 1px;
      }

      background: ${Score === 0
        ? 'transparent'
        : Score > 0
        ? convertHexToRGBA(HAWKISH_COLOR, convertScoreToOpacityThreshold(Score))
        : convertHexToRGBA(DOVISH_COLOR, convertScoreToOpacityThreshold(Score))};
    `}

  ${({ $IsHighlighted }) =>
    $IsHighlighted
      ? css`
          border: 3px solid ${({ theme }) => theme.themeColors.text};
          border-radius: 1px;
        `
      : ''}
`;
const HoverStatsWrapper = styled.div`
  position: absolute;
  top: -42px;
  right: 0px;
  min-width: 140px;
  text-align: center;

  padding: 11px 12px 8px 12px;
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};

  color: ${({ theme }) => theme.themeColors.buttonText} !important;
  z-index: ${({ $ForceTop }) => ($ForceTop ? 10 : 5)} !important;

  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;

function buildPrefix(_score) {
  const score = Math.abs(_score);
  if (score > 0.6) {
    return 'Very';
  }
  if (score >= 0.3) {
    return '';
  }
  if (score > 0) {
    return 'Slightly';
  }

  return '';
}

function convertScoreToDisplay(_score) {
  const prefix = buildPrefix(_score);
  if (_score > 0) return `${prefix} Hawkish`;
  if (_score < 0) return `${prefix} Dovish`;
  return 'Neutral';
}

const HoverStats = ({ enabled, score }) => {
  if (!enabled) {
    return null;
  }
  if (score > 0 || score < 0) {
    return (
      <HoverStatsWrapper>
        <Body5 bold>{convertScoreToDisplay(score)}</Body5>
      </HoverStatsWrapper>
    );
  }

  return null;
};

export const PublicSentimentSentence = ({ data, index, isHighlighted, enableHoverStats }) => {
  const ref = React.useRef(null);

  const [lockHighlight, setLockHighlight] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const { statement, speaker, score, statement_index } = data;

  const handleHoverStart = () => {
    setIsHovering(true);
  };
  const handleSentenceClick = () => {
    if (lockHighlight) {
      setIsHovering(false);
      setLockHighlight(false);
    } else {
      setIsHovering(false);
      setLockHighlight(true);
    }
  };

  return (
    <>
      <SentenceWrapper
        id={'statement-' + index}
        $IsHighlighted={isHighlighted || lockHighlight}
        ref={ref}
        Score={parseFloat(score)}
        onClick={handleSentenceClick}
        onMouseEnter={handleHoverStart}
        onMouseLeave={() => setIsHovering(false)}
      >
        <HoverStats
          index={index}
          enabled={(isHovering || lockHighlight) && enableHoverStats}
          dismissHighlight={() => setLockHighlight(false)}
          score={score}
          showSetScoreCTA={lockHighlight}
        />
        <Body5> </Body5>
        <Body5>{statement}</Body5>
      </SentenceWrapper>
    </>
  );
};
