import React from 'react';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useDocs } from '../../hooks/features/useDocs';
import { useColorTheme } from '../../hooks';
import { DocumentTree } from '../UI/DocumentTree';
import { logger, logNetRequest, removeElementFromArray } from '../../utils/usefulFuncs';
import { sendApiRequest } from '../../services/api';
import { useConfirmationModal } from '../../hooks/application/useConfirmationModal';
import { useDispatch } from 'react-redux';
import { liveDocsActions } from '../../constants/actionTypes';
import { useLiveDocs } from '../../hooks/features/useLiveDocs';
import { parseDocGroup, unassignedDocGroupName } from '../../utils/doc';

function _groupDocsIntoTickerAndTags(docs) {
  const untagged = [];
  const lookup = {};

  docs.forEach((d) => {
    const docGroup = parseDocGroup(d);
    if (lookup[docGroup]) {
      lookup[docGroup].push(d);
    } else {
      lookup[docGroup] = [d];
    }
  });

  const folderNames = Object.keys(lookup).sort();
  const tagged = {};
  folderNames.forEach((name) => {
    tagged[name] = lookup[name];
  });

  return {
    untagged,
    tagged,
  };
}

function createDocumentTreeOrder(liveDocs) {
  if (!liveDocs) return liveDocs;

  const names = Object.keys(liveDocs);

  const priorityOrder = [];

  if (names.includes(unassignedDocGroupName)) {
    priorityOrder.push(unassignedDocGroupName);
    removeElementFromArray(unassignedDocGroupName, names);
  }

  const sortedRest = names.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return [...priorityOrder, ...sortedRest];
}

export function DocumentOrganizer() {
  const dispatch = useDispatch();
  const colorTheme = useColorTheme();
  const [filters] = React.useState({ isUserOnlyMode: true, userUploaded: true });
  const { docs, isLoading } = useDocs(filters);
  const liveDocs = useLiveDocs();

  const documentTreeOrder = React.useMemo(() => {
    return createDocumentTreeOrder(liveDocs);
  }, [liveDocs]);

  React.useEffect(() => {
    if (docs) {
      const { untagged, tagged } = _groupDocsIntoTickerAndTags(docs);
      dispatch({
        type: liveDocsActions.addGroups,
        payload: {
          [unassignedDocGroupName]: untagged,
          ...tagged,
        },
      });
    }
  }, [docs]);

  const handleConfirmDelete = async (doc) => {
    const { id } = doc;
    const groupName = parseDocGroup(doc);
    const URL = `v1/genai_user_doc/${id}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: liveDocsActions.deleteDoc,
        payload: { id, groupName },
      });
    } else {
      // TODO: application error message
    }
  };

  const { ConfirmationModal, onShowModal } = useConfirmationModal(handleConfirmDelete);

  const onDeleteClick = (body, params) => {
    onShowModal(body, params);
  };

  if (isLoading) {
    return (
      <Container>
        <Container top={46} bottom={49} row verticallyCenter>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={8}>
            <Body5>Loading Documents...</Body5>
          </Container>
        </Container>
      </Container>
    );
  }

  if (!docs || !liveDocs) {
    return (
      <Container top={46} bottom={49}>
        No files to manage.
      </Container>
    );
  }

  return (
    <Container bottom={24}>
      {ConfirmationModal}
      <Container top={24}>
        {documentTreeOrder.map((name) => {
          const docs = liveDocs[name];
          return <DocumentTree key={name} folderName={name} docs={docs} onDeleteDoc={onDeleteClick} />;
        })}
      </Container>
    </Container>
  );
}
