import React from 'react';
import { Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { gptModels } from '../../../constants';
import { useHasBetaFeaturesEnabled } from '../../../hooks/user/useCurrentUser';

const Label = styled.div`
  padding-bottom: 8px;
`;

const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 280px;
  background: ${({ theme }) => theme.themeColors.appBackground};
  border-radius: 5px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
`;

export const PublicFilingsToolAdvancedSettings = ({
  enableFollowUpQuestions,
  seeContext,
  model,
  maxContextLength,
  maxAnswerLength,
  enableSmoothing,
  enableDailySummaries,
  overrideQuestion,
  includeUserDocs,

  setEnableDailySummaries,
  onEnableFollowUpQuestionsChange,
  onMaxContextLengthChange,
  onMaxAnswerLengthChange,
  onSeeContextChange,
  onGPTModelChange,
  onEnableSmoothingChange,
  onOverrideQuestionChange,
  setToggleIncludeUserDocs,

  enableInferredMetadata,
  onEnableInferredMetadataChange,
}) => {
  const enableBetaFeatures = useHasBetaFeaturesEnabled();
  const gptModelItems = Object.values(gptModels);

  const onMaxAnswerLengthChangeEffect = (e) => {
    const maxValue = 4096;
    const value = e.target.value;
    const intValue = parseInt(value, 10);
    if (intValue > maxValue) {
      onMaxAnswerLengthChange(maxValue.toString());
    } else {
      onMaxAnswerLengthChange(value);
    }
  };

  return (
    <Wrapper>
      <FormWrapper>
        <Container>
          {onGPTModelChange && (
            <Container top={16}>
              <Label>Model</Label>
              <select onChange={onGPTModelChange} value={model}>
                {gptModelItems.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </Container>
          )}
          {onOverrideQuestionChange && enableBetaFeatures && (
            <Container top={24}>
              <Label>Summary Question</Label>
              <input id="override-Q" value={overrideQuestion} onChange={onOverrideQuestionChange} />
            </Container>
          )}
          {onMaxAnswerLengthChange && (
            <Container top={24}>
              <Label>Max Answer Length</Label>
              <input id="maxAnswerLength" value={maxAnswerLength} onChange={onMaxAnswerLengthChangeEffect} />
            </Container>
          )}
          {onMaxContextLengthChange && (
            <Container top={24}>
              <Label>Max Context Length</Label>
              <input id="maxContextLength" value={maxContextLength} onChange={onMaxContextLengthChange} />
            </Container>
          )}

          {setToggleIncludeUserDocs && (
            <Container top={36} row verticallyCenter>
              <input
                type="checkbox"
                id="checkbox-include-user-docs"
                checked={includeUserDocs}
                onClick={setToggleIncludeUserDocs}
                style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
              />
              <label
                htmlFor="checkbox-include-user-docs"
                className="not-selectable"
                style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
              >
                <Container bottom={3}>Include My Uploaded Documents</Container>
              </label>
            </Container>
          )}
          {setEnableDailySummaries && (
            <Container top={36} row verticallyCenter>
              <input
                type="checkbox"
                id="checkbox"
                checked={enableDailySummaries}
                onClick={setEnableDailySummaries}
                style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
              />
              <label
                htmlFor="checkbox"
                className="not-selectable"
                style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
              >
                <Container bottom={3}>Enable Daily Summary</Container>
              </label>
            </Container>
          )}
          {onSeeContextChange && (
            <Container top={36} row verticallyCenter>
              <input
                type="checkbox"
                id="checkbox"
                checked={seeContext}
                onClick={onSeeContextChange}
                style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
              />
              <label
                htmlFor="checkbox"
                className="not-selectable"
                style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
              >
                <Container bottom={3}>Show Sources</Container>
              </label>
            </Container>
          )}

          {onEnableInferredMetadataChange && (
            <Container top={36} row verticallyCenter>
              <input
                type="checkbox"
                id="checkbox-inferred-metadata"
                checked={enableInferredMetadata}
                onClick={onEnableInferredMetadataChange}
                style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
              />
              <label
                htmlFor="checkbox-inferred-metadata"
                className="not-selectable"
                style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
              >
                <Container bottom={3}>Use Inferred Metadata</Container>
              </label>
            </Container>
          )}
          {onEnableFollowUpQuestionsChange && (
            <Container top={36} row verticallyCenter>
              <input
                type="checkbox"
                id="enableFollowUpQuestions"
                checked={enableFollowUpQuestions}
                onClick={onEnableFollowUpQuestionsChange}
                style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
              />
              <label
                htmlFor="enableFollowUpQuestions"
                className="not-selectable"
                style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
              >
                <Container bottom={3}>Enable Follow Up Questions</Container>
              </label>
            </Container>
          )}
          {onEnableSmoothingChange && (
            <Container top={36} row verticallyCenter>
              <input
                type="checkbox"
                id="smoothingcheckbox"
                checked={enableSmoothing}
                onClick={onEnableSmoothingChange}
                style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
              />
              <label
                htmlFor="smoothingcheckbox"
                className="not-selectable"
                style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
              >
                <Container bottom={3}>Enable Smoothing</Container>
              </label>
            </Container>
          )}
        </Container>
      </FormWrapper>
    </Wrapper>
  );
};
