import styled from 'styled-components';
import { Body5 } from '../../libs/nvstr-common-ui.es';

const ErrorWrapper = styled.div`
  * {
    color: ${({ theme }) => `${theme.themeColors.error} !important`};
  }
`;
export default function ({ children }) {
  if (!children) return null;

  return (
    <ErrorWrapper>
      <Body5>{children}</Body5>
    </ErrorWrapper>
  );
}
