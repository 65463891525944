export const unassignedDocGroupName = 'Unassigned Documents';

export function parseDocGroup(doc) {
  const { security_symbol, security_name, doc_tag } = doc;

  if (security_symbol) {
    const symbolAndName = security_name ? security_symbol + ' - ' + security_name : security_symbol;
    return symbolAndName;
  }

  if (doc_tag) {
    const tagName = doc_tag.name;
    return tagName;
  }

  return unassignedDocGroupName;
}
