import styled from 'styled-components';
import React from 'react';
import { Modal } from '../../../components/layout/Modal';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { Body1, Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../../components/buttons';
import { useTableAnalysis } from '../../../hooks/features/useTableAnalysis';
import { IconButton } from '../../../components/buttons/IconButton';
import { useDispatch } from 'react-redux';
import { gridTableAnalysisActions } from '../../../constants/actionTypes';
import { sendApiRequest } from '../../../services/api';
import { logNetRequest } from '../../../utils/usefulFuncs';

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 6;
`;

const ImportTableModeWrapper = styled.div``;
const ShareIconWrapper = styled.div`
  svg {
    height: 20px;
    width: 20px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const FavoriteQuestionRowWrapper = styled.div`
  padding: 12px 0;

  display: grid;
  grid-template-columns: 1fr 30px 30px; /* Adjust sizes as needed */

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;
const TextWrapper = styled.div`
  grid-column: 1;
`;

const FavoriteQuestionRow = ({ data }) => {
  const dispatch = useDispatch();

  const { id, is_shared: isShared, prompt } = data;
  const onSetShare = async () => {
    const form = {
      is_shared: true,
    };
    const URL = `v1/genai_favorite_prompts/${id}`;
    const { status, data, error } = await sendApiRequest('patch', URL, form);
    logNetRequest(status, data);
    dispatch({
      type: gridTableAnalysisActions.updateFavoriteQuestion,
      payload: {
        id,
        ...form,
      },
    });
  };
  const onUnsetShare = async () => {
    const form = {
      is_shared: false,
    };
    const URL = `v1/genai_favorite_prompts/${id}`;
    const { status, data, error } = await sendApiRequest('patch', URL, form);
    logNetRequest(status, data);
    dispatch({
      type: gridTableAnalysisActions.updateFavoriteQuestion,
      payload: {
        id,
        ...form,
      },
    });
  };

  const onDelete = async () => {
    const URL = `v1/genai_favorite_prompts/${id}`;
    const { status, data, error } = await sendApiRequest('delete', URL);
    logNetRequest(status, data);
    if (status === 200) {
      dispatch({
        type: gridTableAnalysisActions.removeFavoriteQuestion,
        payload: id,
      });
    }
  };

  return (
    <FavoriteQuestionRowWrapper>
      <TextWrapper>
        <Body5>{prompt}</Body5>
      </TextWrapper>
      <ShareIconWrapper>
        {isShared ? (
          <IconButton onClick={onUnsetShare}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
            </svg>
          </IconButton>
        ) : (
          <IconButton onClick={onSetShare}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
            </svg>
          </IconButton>
        )}
      </ShareIconWrapper>
      <ShareIconWrapper>
        <IconButton onClick={onDelete}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" />
          </svg>
        </IconButton>
      </ShareIconWrapper>
    </FavoriteQuestionRowWrapper>
  );
};

export const ManageFavoriteQuestionSharing = ({ onDismiss }) => {
  const [modalProps] = React.useState({ maximizeWidth: true });

  const { favoriteQuestions } = useTableAnalysis();
  const myFavoriteQuestions = favoriteQuestions.filter((q) => q.is_my_prompt);

  const onContinue = () => {
    onDismiss();
  };

  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <CloseButtonWrapper>
        <CloseButton onClick={onDismiss} size={14} />
      </CloseButtonWrapper>

      <ImportTableModeWrapper>
        <Body1 bold>Update your favorite question sharing</Body1>
        <Container top={6}>
          <Body5>Questions are only shared within your organization.</Body5>
        </Container>
        <Container top={16}>
          {myFavoriteQuestions.length === 0 && (
            <Container>
              <Body5>You have no favorite questions.</Body5>
            </Container>
          )}
          {myFavoriteQuestions.map((q) => (
            <FavoriteQuestionRow key={q.id} data={q} />
          ))}
        </Container>
        <Container top={24} style={{ textAlign: 'center' }}>
          <FlatButton fullWidth onClick={onContinue}>
            Dismiss
          </FlatButton>
        </Container>
      </ImportTableModeWrapper>
    </Modal>
  );
};
