import { Route, Routes } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Splash from './pages/Splash';
import React from 'react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { getIsAuthed } from './utils/selectors';
import ToolSelection from './pages/ToolSelection';
import DigitalAnalyst from './pages/DigitalAnalyst';
import { useNavigate } from 'react-router';
import ClosedBeta from './pages/ClosedBeta';
import CompanySnapshot from './pages/CompanySnapshot';
import { ROUTES } from './constants/routes';
import Screener from './pages/Screener';
import { ThemeBuilder } from './pages/ThemeBuilder';
import DocumentManagement from './pages/DocumentManagement';
import StockDetail from './pages/StockDetail';
import { DocumentViewer } from './pages/DocumentViewer';
import EarningsCallSummaries from './pages/EarningsCallSummaries';
import ResearchScreener from './pages/PremiumScreener';
import ResearchDigitalAnalyst from './pages/ResearchDigitalAnalyst';
import FactorVisualization from './pages/FactorVisualization';
import FactorVisualizationChart from './pages/FactorVisualizationChart';
import FedDigitalAnalyst from './pages/FedDigitalAnalyst';
import FedGpt from './pages/Fedgpt';
import { usePermissionedModes } from './hooks/user/useCurrentUser';
import { toCapitalCase } from './libs/nvstr-utils.es';
import { DocumentSummary } from './pages/DocumentSummary';
import { LatestUpdatesAndNews } from './pages/LatestUpdatesAndNews';
import WebpageUploader from './pages/WebpageUploader';
import WarningIndicators from './pages/WarningIndicators';
import BullBearCases from './pages/BullBearCases';
import FedSentiment from './pages/FedSentiment';
import WebDigitalAnalyst from './pages/WebDigitalAnalyst';
import EvalTemplate from './pages/EvalTemplate';
import CustomSnapshot from './pages/CustomSnapshot';
import PublicFedSentiment from './pages/PublicFedSentiment';
import MacroEconomicSignals from './pages/MacroEconomicSignals';
import EconomicImpact from './pages/EconomicImpact';
import FedTableAnalysis from './pages/TableAnalysis/FedTableAnalysis';
import { TextSearch } from './pages/TextSearch';
import FAQ from './pages/FAQ';
import LenderEarningCallAnalysis from './pages/LenderEarningCallAnalysis';
import { AwaitDocProcessingToDigitalAnalystMode } from './pages/AwaitDocProcessingToDigitalAnalystMode';
import { AccountSettings } from './pages/AccountSettings';
import { ComplianceMode } from './pages/ComplianceMode';
import FedRecentDocs from './pages/FedRecentDocs';
import CreditAgreementAnalysis from './pages/TableAnalysis/CreditAgreementAnalysis';
import Watchlist from './pages/Watchlist';
import { clearLastIntent, getLastIntent, setLastIntent } from './utils/intents';
import GenericTableAnalysis from './pages/TableAnalysis/GenericTableAnalysis';
import PPMTableAnalysis from './pages/TableAnalysis/PPMTableAnalysis';
import AccountInReviewPage from './pages/AccountInReviewPage';
import { QueryHistory } from './pages/QueryHistory';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const eRoutes = {
  FedGpt: <FedGpt />,
  FedSentiment: <FedSentiment />,
  FedReleaseAnalysis: <FedTableAnalysis />,
};

const Redirect = ({ nonAuth }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const intent = window.location.pathname + window.location.search;
    console.log('intent', intent);
    setLastIntent(intent);
    navigate(nonAuth ? ROUTES.login : ROUTES.home);
  }, []);
  return null;
};

const ResetPasswordActiveSessionRedirect = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/app/users/edit?tab=preferences');
  }, []);
  return null;
};

function routeName(s) {
  return s
    .split('-')
    .map((s) => toCapitalCase(s))
    .join('');
}

const LoggedInRedirect = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const prevIntent = getLastIntent();
    const route = prevIntent || ROUTES.home;
    console.log('navigating after signin', { prevIntent, route });
    navigate(route);
    clearLastIntent();
  }, []);

  return null;
};

function AuthedRoutes() {
  const permissionedModes = usePermissionedModes();
  return (
    <SentryRoutes>
      <Route path={ROUTES.faq} element={<FAQ />} />
      <Route path={ROUTES.customSnapshot} element={<CustomSnapshot />} />
      <Route path={ROUTES.evaluationTemplate} element={<EvalTemplate />} />
      <Route path={ROUTES.themeBuilder} element={<ThemeBuilder />} />
      <Route path={ROUTES.digitalAnalyst} element={<DigitalAnalyst />} />
      <Route path={ROUTES.fedLatestDocs} element={<FedRecentDocs />} />
      <Route path={ROUTES.fedDigitalAnalyst} element={<FedDigitalAnalyst />} />
      <Route path={ROUTES.fedPublicSentimentAnalyst} element={<PublicFedSentiment />} />
      <Route path={ROUTES.website} element={<WebDigitalAnalyst />} />
      <Route path={ROUTES.latestEarningsUpdates} element={<EarningsCallSummaries />} />
      <Route path={ROUTES.lenderEarningsUpdates} element={<LenderEarningCallAnalysis />} />
      <Route path={ROUTES.researchDigitalAnalyst} element={<ResearchDigitalAnalyst />} />
      <Route path={ROUTES.companySnapshot} element={<CompanySnapshot />} />
      <Route path={ROUTES.bullBearCases} element={<BullBearCases />} />
      <Route path={ROUTES.docManagement} element={<DocumentManagement />} />
      <Route path={ROUTES.factor} element={<FactorVisualization />} />
      <Route path={ROUTES.factorVisual} element={<FactorVisualizationChart />} />
      <Route path={ROUTES.screener} element={<Screener />} />
      <Route path={ROUTES.researchScreener} element={<ResearchScreener />} />
      <Route path={ROUTES.latestUpdates} element={<LatestUpdatesAndNews />} />
      <Route path={ROUTES.resetPassword} element={<ResetPasswordActiveSessionRedirect />} />
      <Route path={ROUTES.stock} element={<StockDetail />} />
      <Route path={ROUTES.docSummary} element={<DocumentSummary />} />

      <Route path={ROUTES.compliance} element={<ComplianceMode />} />
      <Route path={ROUTES.queryHistory} element={<QueryHistory />} />

      <Route path={ROUTES.docProcessingToDA} element={<AwaitDocProcessingToDigitalAnalystMode />} />
      <Route path={ROUTES.docViewer} element={<DocumentViewer />} />
      <Route path={ROUTES.textSearch} element={<TextSearch />} />
      <Route path={ROUTES.webpageIngestion} element={<WebpageUploader />} />
      <Route path={ROUTES.warningIndicators} element={<WarningIndicators />} />

      <Route path={ROUTES.creditAgreementAnalysis} element={<CreditAgreementAnalysis />} />
      <Route path={ROUTES.ppmTableAnalysis} element={<PPMTableAnalysis />} />
      <Route path={ROUTES.tableAnalysis} element={<GenericTableAnalysis />} />

      <Route path={ROUTES.macroEconomicSignals} element={<MacroEconomicSignals />} />
      <Route path={ROUTES.economicImpact} element={<EconomicImpact />} />

      <Route path={ROUTES.accountSettings} element={<AccountSettings />} />
      <Route path={ROUTES.watchlist} element={<Watchlist />} />

      <Route path={ROUTES.home} element={<ToolSelection />} />
      <Route path={ROUTES.login} element={<LoggedInRedirect />} />

      <Route path={ROUTES.inReview} element={<AccountInReviewPage />} />

      {permissionedModes.map((m) => {
        const { route } = m;
        const RouteName = routeName(route);
        return <Route path={route} element={eRoutes[RouteName]} key={route} />;
      })}

      {/* <Route path={'/sandbox'} element={<Sandbox />} /> */}
      <Route path="/closed-beta" element={<ClosedBeta />} />
      <Route path="/*" element={<Redirect />} />
    </SentryRoutes>
  );
}

function NonAuthedRoutes() {
  return (
    <SentryRoutes>
      <Route path={ROUTES.faq} element={<FAQ />} />

      <Route path={ROUTES.login} exact element={<SignIn />} />
      <Route path={ROUTES.signup} exact element={<SignUp />} />
      <Route path={ROUTES.forgotPassword} exact element={<ForgotPassword />} />
      <Route path={ROUTES.resetPassword} exact element={<ResetPassword />} />
      <Route path={ROUTES.splash} element={<Splash />} />
      <Route path="/*" element={<Redirect nonAuth />} />
    </SentryRoutes>
  );
}

export default function Router() {
  const isAuthed = useSelector(getIsAuthed);

  if (isAuthed) {
    return <AuthedRoutes />;
  }
  return <NonAuthedRoutes />;
}
