import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { Container, Page } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { DocumentOrganizer } from '../containers/application/DocumentOrganizer';
import { scrollToTop } from '../utils/usefulFuncs';
import { DocumentUploadStatus } from '../containers/application/DocumentUploadStatus';
import { DocUploader } from './DocUploader';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

function DocumentManagement() {
  const navigate = useNavigate();
  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <BackArrowButton onClick={onGoBack} />
          <Container top={36}>
            <DocUploader />
          </Container>
        </Page>
        <DocumentUploadStatus />
        <Page>
          <DocumentOrganizer />
        </Page>
      </Body>
    </PageWrapper>
  );
}

export default DocumentManagement;
