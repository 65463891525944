import React from 'react';
import styled from 'styled-components';
import { Body5, CheckCircle, Container } from '../../../libs/nvstr-common-ui.es';
import { useStreamingResult } from '../../../hooks/features/useStreamingResult';
import { ResultRatingForm } from '../../form/ResultRatingForm';
import { ShareQuestionAndAnswer } from './ShareQuestionAndAnswer';
import { CollapseButton } from '../../../components/buttons/CollapseButton';
import { Answer } from './AnswerLine';
import { FollowUpQuestions } from './FollowUpQuestions';
import { ContextResult } from './ResultContext';
import { HighlightContextItemModal } from '../../../components/modals/HighlightContextItemModal';

const Wrapper = styled.div`
  min-height: ${({ collapseHeight }) => (collapseHeight ? '10vh' : '70vh')};
`;
const CheckWrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;
const Label = styled.div`
  padding-bottom: 8px;
`;

export const QuestionResult = ({
  resultId,
  citationsResultId,

  question,
  selectedDocs,
  sourceDocs,
  tickers,
  model,

  enableFollowUpQuestions,
  enableMoreDetail,

  handleAskQuestion,

  isMoreDetail,
  hideRating,
  collapseHeight,
  ActionsComponent,
}) => {
  const [collapseAnswer, setCollapseAnswer] = React.useState(false);
  const [collapseContext, setCollapseContext] = React.useState(true);
  const [highlightContextItem, setHighlightContextItem] = React.useState(null);

  const {
    result: streamingResult,
    context: streamingContext,
    error: streamingError,
    streamEnd,
  } = useStreamingResult(resultId);

  const {
    result: citationsStreamingResult,
    context: streamingCitationsContext,
    error: citationsStreamingError,
    streamEnd: citationsStreamEnded,
  } = useStreamingResult(citationsResultId, true);

  // SCROLL INTO VIEW EFFECT
  React.useEffect(() => {
    const id = 'question-answer';
    const yOffset = -10;
    const element = document.getElementById(id);

    if ((resultId || citationsResultId) && element && !isMoreDetail) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 600);
    }
  }, [resultId, citationsResultId]);

  const toggleCollapse = () => {
    setCollapseContext(!collapseContext);
  };
  const onCitationClick = (chunkId, doc) => {
    setHighlightContextItem({ chunkId, doc });
  };
  const onCitationDismiss = () => {
    setHighlightContextItem(null);
  };

  if (streamingError || citationsStreamingError) {
    return (
      <Container top={24}>
        <Body5 bold>Something went wrong. Try submitting again.</Body5>
      </Container>
    );
  }

  const result = streamingResult;
  const isShowingCitations = !!citationsResultId && citationsStreamEnded;
  const context = isShowingCitations ? streamingCitationsContext : streamingContext;
  return (
    <Wrapper collapseHeight={collapseHeight}>
      {question && (
        <Container top={8}>
          <Label>
            <Body5 bold>Question Asked:</Body5>
          </Label>
          <Body5>{question}</Body5>
        </Container>
      )}

      <Container top={question ? 24 : 8}>
        {question ? (
          <Container row spaceBetween>
            <Label>
              <Body5 bold>Answer:</Body5>
            </Label>
            <CollapseButton isCollapsed={collapseAnswer} setCollapsed={setCollapseAnswer} />
          </Container>
        ) : null}

        {citationsResultId ? (
          !citationsStreamEnded ? null : (
            <Container verticallyCenter top={0} bottom={8} height={68}>
              <CheckWrapper>
                <CheckCircle />
              </CheckWrapper>
              <Container left={8} bottom={3}>
                <Body5 bold>Citations Inserted</Body5>
              </Container>
            </Container>
          )
        ) : null}

        {!collapseAnswer && (
          <Answer
            result={citationsResultId ? citationsStreamingResult : result}
            isShowingCitations={isShowingCitations}
            onCitationClick={onCitationClick}
            enableMoreDetail={enableMoreDetail && streamEnd}
            model={model}
            selectedDocs={selectedDocs}
            sourceDocs={sourceDocs}
            tickers={tickers}
            isAddingCitations={citationsResultId && !citationsStreamEnded && citationsStreamingResult !== 'Working...'}
            isComplete={streamEnd && (citationsResultId ? citationsStreamEnded : true)}
          />
        )}
      </Container>

      {enableFollowUpQuestions && (
        <FollowUpQuestions result={result} handleAskQuestion={handleAskQuestion} model={model} />
      )}

      {highlightContextItem !== null && (
        <HighlightContextItemModal item={highlightContextItem} context={context} onDismiss={onCitationDismiss} />
      )}
      {context ? (
        <Container top={8} bottom={8}>
          <Container row alignRight>
            <ButtonWrapper onClick={toggleCollapse}>
              <Container right={4} bottom={4}>
                <Label>
                  <Body5 bold>Sources</Body5>
                </Label>
              </Container>
              <CollapseButton isCollapsed={collapseContext} setCollapsed={setCollapseContext} />
            </ButtonWrapper>
          </Container>
          <ContextResult
            value={context}
            isShowingCitations={isShowingCitations}
            highlightContextItem={highlightContextItem}
            collapse={collapseContext}
          />
        </Container>
      ) : null}

      {ActionsComponent || null}

      {question && (
        <Container top={16}>
          <ShareQuestionAndAnswer question={question} answer={result} />
        </Container>
      )}

      {resultId && !hideRating && streamEnd && (
        <Container top={16}>
          <ResultRatingForm resultId={resultId} />
        </Container>
      )}
    </Wrapper>
  );
};
