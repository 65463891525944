import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Container, Place, Row } from '../../libs/nvstr-common-ui.es';
import { INTERNAL_STORAGE_KEYS, InternalStorage } from '../../utils/storage';
import { FullLogo } from '../../assets/logos/FullLogo';
import { FlatButton } from '../../components/buttons';
import { signIn } from '../../services/authentication';
import { BASEURL, extendedFetch } from '../../services/network';
import InputField from '../form/InputField';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { getDataForCache, getRequiredData, handleAppTrackingInit } from '../../utils/initialization';
import { useDispatch } from 'react-redux';
import { EventNames, fillForX, TrackingEvent } from '../../libs/nvstr-utils.es';

export const LogoMobileWrap = styled('div')`
  position: relative;
  top: 20px;
  padding: 0;
  display: none;
`;

export const ValidationWrap = styled('div')`
  color: ${({ theme }) => theme.themeColors.error} !important;
  font-size: 12px;
`;

export const FormWrap = styled('div')`
  position: relative;

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
  }
`;

export const LoadingCover = styled.div`
  width: 360px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
`;

export const SignSwitchLink = styled('div')`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
`;

export const ExtraOptions = styled('div')`
  position: relative;
  margin: -8px 0 0 0;
  padding: 0 0 10px 0;
  width: 100%;
  max-width: 460px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignInFormWrapper = styled.div`
  max-width: 360px;
`;

export const VALIDATION_MESSAGES = {
  multiple: 'Please enter your email and password to sign in.',
  email: 'Please enter your email',
  password: 'Please enter your password.',
};

export const signInValidation = ({ email, password }) => {
  const validateStates = {
    email: email.trim() !== '',
    password: password.trim() !== '',
  };

  const failedFieldsLength = Object.values(validateStates).filter((value) => {
    return value === false;
  }).length;

  const failedValidation = failedFieldsLength > 0;

  if (!failedValidation) {
    return {
      isValid: true,
    };
  }

  if (failedValidation && failedFieldsLength > 1) {
    return {
      isValid: false,
      validationField: 'multiple',
    };
  }

  if (failedValidation && failedFieldsLength === 1) {
    const validationField = Object.entries(validateStates).filter(([key, value]) => {
      return value === false;
    })[0];

    const fieldName = validationField[0];

    return {
      isValid: false,
      validationField: fieldName,
    };
  }
};

const logSignInEvents = (sessionData) => {
  const analyticsId = sessionData.analytics_id;
  const signInCount = sessionData.sign_in_count;
  // const signupDate = sessionData.signed_up_at;
  // const isLiveTrading = sessionData.is_live_trading;
  // const daysSinceSignup = getDaysSinceDate(signupDate);
  // if (signInCount === 5 && daysSinceSignup <= 7 && isLiveTrading) sendFacebookLTVEvent(5);

  TrackingEvent.create(EventNames.signIn, {
    User: analyticsId,
    'Onboarding End Test Group': sessionData.onboarding_end_test_group,
  });

  const SIGN_IN_MILESTONES = [10, 20, 50];
  SIGN_IN_MILESTONES.forEach((times) => {
    if (signInCount === times) {
      TrackingEvent.create(fillForX(times, EventNames.signInXTimes), {
        'Is Fast App': true,
        times,
        User: analyticsId,
        'Onboarding End Test Group': sessionData.onboarding_end_test_group,
      });
    }
  });

  const SIGN_IN_MILESTONE_THRESHOLDS = [20, 50];
  SIGN_IN_MILESTONE_THRESHOLDS.forEach((times) => {
    if (signInCount >= times) {
      TrackingEvent.create(fillForX(times, EventNames.signInAtLeastXTimes), {
        'Is Fast App': true,

        times,
        User: analyticsId,
        'Onboarding End Test Group': sessionData.onboarding_end_test_group,
      });
    }
  });
};

export const sendSessionStartEvents = (currentUserSessionData) => {
  logSignInEvents(currentUserSessionData);
};

export const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState('1');

  const [validationMessage, setValidationMessage] = React.useState('');
  const [isSigningIn, setIsSigningIn] = React.useState(false);

  const userSignIn = async (e) => {
    e?.preventDefault();

    const validationState = signInValidation({
      email,
      password,
    });

    if (!validationState.isValid) {
      setIsSigningIn(false);
      if (VALIDATION_MESSAGES[validationState.validationField]) {
        setValidationMessage(VALIDATION_MESSAGES[validationState.validationField]);
      }
    }

    setIsSigningIn(true);

    const { isAuthed, errorMessage } = await signIn({
      email,
      password,
      rememberMe,
    });

    if (isAuthed) {
      const userSessionResponse = await extendedFetch(`${BASEURL}/api/v1/current_user/session`, 'GET', null);
      const userSessionData = await userSessionResponse.json();
      const { status } = userSessionResponse;
      if (status === 200) {
        sendSessionStartEvents(userSessionData);
        // const timeStart = createTimeInstance().valueOf();
        const responses = await getRequiredData(dispatch);
        await handleAppTrackingInit(responses[0]);
        getDataForCache(dispatch);
        // const timeEnd = createTimeInstance().valueOf();

        // after this runs, router updates to authed routes and handles redirect
      }
    } else {
      setIsSigningIn(false);
      setValidationMessage(errorMessage);
    }
  };

  const clickRemember = (e) => {
    const newValue = rememberMe === '1' ? '' : '1';
    if (newValue === '') {
      InternalStorage.setItemInStorage(INTERNAL_STORAGE_KEYS.EMAIL, '');
    }
    setRememberMe(newValue);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userSignIn(e);
    }
  };

  if (isSigningIn) {
    return (
      <LoadingCover>
        <LoadingSpinner text={'Signing In...'} />
      </LoadingCover>
    );
  }

  return (
    <SignInFormWrapper onKeyDown={(e) => handleKeyUp(e)}>
      <FormWrap>
        <LogoMobileWrap>
          <FullLogo forceTornado />
        </LogoMobileWrap>
        <form>
          <InputField
            id="email-field"
            placeholder={'Email'}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
          <InputField
            id="password-field"
            placeholder={'Password'}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <ExtraOptions>
            <Row position="relative" vAlign="center">
              <input
                type="checkbox"
                id="checkbox"
                checked={rememberMe === '1'}
                onClick={clickRemember}
                style={{ cursor: 'pointer', marginTop: 0 }}
              />
              <label htmlFor="checkbox" className="not-selectable" style={{ cursor: 'pointer', margin: 0 }}>
                <Place padding="0 0 0 8px" position="relative" top="0px" display="inline-block">
                  Stay signed in
                </Place>
              </label>
            </Row>
            <Place>
              <Link to="/forgot_password">Forgot Password</Link>
            </Place>
          </ExtraOptions>

          <Container bottom={8}>
            {validationMessage ? <ValidationWrap>{validationMessage}</ValidationWrap> : null}
          </Container>

          <FlatButton id="sign-in-submit" fullWidth onClick={userSignIn}>
            Sign In
          </FlatButton>

          <Container top={8}>
            <SignSwitchLink>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </SignSwitchLink>
          </Container>
        </form>
      </FormWrap>
    </SignInFormWrapper>
  );
};
