import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { DragDrop } from '../../components/UI/DragAndDropUploader';
import React from 'react';
import styled from 'styled-components';

const DataUploaderWrapper = styled.div`
  //
`;

export function CSVUploader({ files, onUpload }) {
  return (
    <DataUploaderWrapper>
      <Container top={8}>
        <Body5>Upload your CSV files for visualization</Body5>
      </Container>
      <Container top={36}>
        <DragDrop onAddFiles={onUpload} files={[]} csv />
      </Container>
    </DataUploaderWrapper>
  );
}
