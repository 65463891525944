import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: ${({ $Size: size }) => (size || 16) + 'px'};
  width: ${({ $Size: size }) => (size || 16) + 'px'};

  path {
    fill: ${({ theme, $Color: color }) => color || theme.themeColors.text};
  }
`;

export const Collapse = ({ size, color }) => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" $Size={size} $Color={color}>
      <path d="M19.5,3.09L15,7.59V4H13V11H20V9H16.41L20.91,4.5L19.5,3.09M4,13V15H7.59L3.09,19.5L4.5,20.91L9,16.41V20H11V13H4Z" />
    </SVG>
  );
};
