import { combineReducers } from 'redux';

import application from './application';
import currentUser from './currentUser';
import colorTheme from './colorTheme';
import constants from './constants';
import docs from './docs';
import gridTableAnalysis from './gridTableAnalysis';
import infoIcon from './infoIcon';
import liveDocs from './liveDocs';
import news from './news';
import recentDocs from './recentDocs';
import securities from './securities';
import watchlist from './watchlist';

const rootReducer = combineReducers({
  application,
  colorTheme,
  constants,
  currentUser,
  docs,
  gridTableAnalysis,
  infoIcon,
  liveDocs,
  news,
  recentDocs,
  securities,
  watchlist,
});

export default rootReducer;
