import { logError } from '../utils/usefulFuncs';

class _AuthEffectHandler {
  constructor() {
    this.lossOfAuthHandler = () => null;
  }

  bindLossOfAuthHandler = (cb) => {
    this.lossOfAuthHandler = cb;
  };

  onLossOfAuth = () => {
    try {
      this.lossOfAuthHandler();
    } catch (e) {
      logError(e);
    }
  };
}

export const AuthEffectHandler = new _AuthEffectHandler();
