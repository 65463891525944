import React from 'react';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import { logError, logFormSubmit, logger } from '../../../utils/usefulFuncs';

const shareElementId = 'shareElement';
export const ShareQuestionAndAnswer = ({ question, answer }) => {
  const [showCopiedMessage, setShowCopiedMessage] = React.useState(false);
  const [showCopyNotSupportedMessage, setShowCopyNotSupportedMessage] = React.useState(false);

  const richTextToCopy = (
    <div id={shareElementId} style={{ height: 0, width: 0, overflow: 'hidden' }}>
      <p>Question Asked: {question}</p>
      <p>Answer: {answer}</p>
    </div>
  );

  React.useEffect(() => {
    let timeout;
    if (showCopiedMessage) {
      timeout = setTimeout(() => {
        setShowCopiedMessage(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showCopiedMessage]);

  React.useEffect(() => {
    let timeout;
    if (showCopyNotSupportedMessage) {
      timeout = setTimeout(() => {
        setShowCopyNotSupportedMessage(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showCopyNotSupportedMessage]);

  function copyToClipboardFirefoxFallback() {
    const range = document.createRange();
    const element = document.getElementById(shareElementId);
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }

  const hc = () => {
    let didFail;

    try {
      // const richTextBlob = new Blob([richTextToCopy], { type: 'text/html' });
      const textBlob = new Blob([`Question Asked: ${question}\n\nAnswer: ${answer}`], { type: 'text/plain' });
      navigator.clipboard.write([
        new ClipboardItem({
          // 'text/html': richTextBlob, // remove for now, it produces [object Object] in some applications
          'text/plain': textBlob,
        }),
      ]);
    } catch (e) {
      logError('failed clipboard', e);
    }
    try {
      copyToClipboardFirefoxFallback();
      didFail = false;
    } catch (e) {
      logError('failed fallback', e);
      didFail = true;
    }

    if (didFail) {
      setShowCopyNotSupportedMessage(true);
    } else {
      setShowCopiedMessage(true);
    }
    logger({
      question,
      answer,
    });
  };

  if (showCopiedMessage) {
    return (
      <Container vertical={10} left={4}>
        <Body5 bold>Question and Answer Copied</Body5>
      </Container>
    );
  }

  if (showCopyNotSupportedMessage) {
    return (
      <Container vertical={10} left={4}>
        <Body5 bold>Copy feature not supported in this browser</Body5>
      </Container>
    );
  }
  return (
    <Container>
      <SkeletonButton onClick={hc}>Copy Question & Answer</SkeletonButton>
      {richTextToCopy}
    </Container>
  );
};
