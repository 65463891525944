import { sendApiRequest } from './api';
import { securitiesActions } from '../constants/actionTypes';

export const fetchSecuritiesData = (symbols) => async (dispatch) => {
  const URL = 'v1/securities';
  const query = `?symbols=${symbols.join(',')}`;
  const { status, data, error } = await sendApiRequest('get', URL + query);
  if (status === 200) {
    dispatch({
      type: securitiesActions.addMultiple,
      payload: data.securities,
    });
    return {
      ok: true,
      data,
    };
  } else {
    return {
      status,
      error,
      data,
    };
  }
};
