import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { Body1, Body5, Container, H1, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { updateCurrentUser } from '../services/user';
import { ROUTES } from '../constants/routes';
import { TrackingEvent } from '../libs/nvstr-utils.es';
import { useColorTheme } from '../hooks';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;

function ClosedBeta() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colorTheme = useColorTheme();

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const init = async () => {
      const { ok } = await updateCurrentUser({ user: { has_digital_analyst_access: true } })(dispatch);
      if (ok) {
        TrackingEvent.create('Set User With AI Access');
        setTimeout(() => {
          navigate(ROUTES.home);
        }, 2000);
      } else {
        TrackingEvent.create('Set User With AI Access Failed');
        setError(true);
      }
    };
    init();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <Container>
              <h3>Digital Analyst</h3>
            </Container>
            <Container top={48} centerAll bottom={99}>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
              <Container left={8}>
                <Body5>Loading...</Body5>
              </Container>
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}

export default ClosedBeta;
