import styled from 'styled-components';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import React from 'react';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../hooks';
import { useStockSearch } from '../../hooks/features/useStockSearch';

const SearchIconWrapper = styled.svg`
  height: 16px;
  width: 16px;

  path {
    fill: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.6)};
  }
`;
const SearchIcon = () => {
  return (
    <SearchIconWrapper viewBox="2 0 20 20">
      <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
    </SearchIconWrapper>
  );
};
const SearchBarWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.appBackground};
  border-radius: 5px;
  height: 40px;
  padding: 4px 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50vw;
  max-width: 400px;

  input {
    outline: none;
    border: none;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.themeColors.text};
    margin: 0;
    padding: 0 0 2px 0;
    width: 100%;

    &::placeholder {
      color: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.6)};
    }

    &:focus {
      outline: none;
    }
  }

  @media (min-width: 476px) {
    position: relative;
  }
  @media (max-width: 475px) {
    width: ${({ SearchActive }) => (SearchActive ? '100%' : '40px')};
    background: none;

    svg {
      path {
        fill: ${({ theme }) => theme.themeColors.text};
      }
    }
  }
`;
const ResultsWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border-radius: 2px;
  max-height: 80vh;
  overflow: auto;
  z-index: 1000;

  @media (min-width: 476px) {
    top: 53px;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;
const ResultsRowWrapper = styled.div`
  display: block;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText} !important;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText} !important;
    }
  }
`;

const ResultsRow = ({ result, onResultClick }) => {
  const { symbol, name } = result;

  const hc = (e) => {
    e.stopPropagation();
    onResultClick(result);
  };

  const text = `${symbol} - ${name}`;

  return (
    <ResultsRowWrapper onClick={hc}>
      <Body5>{text}</Body5>
    </ResultsRowWrapper>
  );
};

function Results({ results, inputValue, isLoading, onResultClick }) {
  const colorTheme = useColorTheme();

  if (!results || inputValue === '') {
    return null;
  }

  if (isLoading) {
    return (
      <ResultsWrapper>
        <Container row centerAll fullWidth all={24}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={16}>
            <Body5>Finding stocks...</Body5>
          </Container>
        </Container>
      </ResultsWrapper>
    );
  }

  if (results.length === 0) {
    return (
      <ResultsWrapper>
        <Container row centerAll fullWidth all={24}>
          <Body5>No stocks match {`"${inputValue}"`}</Body5>
        </Container>
      </ResultsWrapper>
    );
  }

  return (
    <ResultsWrapper>
      {results.map((r) => (
        <ResultsRow key={r} result={r} onResultClick={onResultClick} />
      ))}
    </ResultsWrapper>
  );
}

export const Search = ({ searchActive, onSearchActivate, onResultClick }) => {
  const inputRef = React.useRef(null);

  const [value, setValue] = React.useState('');
  const { isLoading, results, error } = useStockSearch(value);

  React.useEffect(() => {
    if (searchActive) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 300);
    }
  }, []);

  const handleFocus = () => {
    if (!searchActive) {
      onSearchActivate && onSearchActivate();
    }
  };

  const handleInputChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
  };

  const handleResultClick = (result) => {
    onResultClick(result);
    setValue('');
  };

  const handleBarClick = (e) => {
    e.stopPropagation();
    // stop propagation to overlay layer
  };

  const onSearchIconClick = (e) => {
    e.stopPropagation();
    // stop propagation to overlay layer

    inputRef.current.focus();
  };

  return (
    <SearchBarWrapper onClick={handleBarClick} SearchActive={searchActive}>
      <div onClick={onSearchIconClick} style={{ cursor: 'pointer' }}>
        <Container right={12}>
          <SearchIcon />
        </Container>
      </div>
      <input
        ref={inputRef}
        onFocus={handleFocus}
        placeholder={'Search Stocks...'}
        value={value}
        onChange={handleInputChange}
      />
      <Results
        isLoading={isLoading}
        inputValue={value}
        results={results}
        error={error}
        onResultClick={handleResultClick}
      />
    </SearchBarWrapper>
  );
};
