import React from 'react';
import { useSelector } from 'react-redux';

const forcedPermissions = [];

const addForcedPermission_dev = (permission, heading, route, icon, desc) => {
  forcedPermissions.push({
    description: desc || 'Description here',
    heading: heading || 'New mode',
    icon_key: icon || 'custom',
    route: route,
  });
};

// addForcedPermission_dev(
//   AI_TOOLS.GENERALIZED_GRID_MODE,
//   'Query History',
//   ROUTES.queryHistory,
//   'watchlist',
//   'See your history of questions and answers you have asked Digital Analyst'
// );

export const useHasBetaFeaturesEnabled = () => useCurrentUser()?.is_nvstr_employee;

export const useCurrentUser = () => useSelector((state) => state.currentUser);

export const usePartnerTheme = () => {
  return useCurrentUser()?.custom_client_id;
};

export const useCurrentUserPermissions = () => {
  const [noPermissions] = React.useState([]);
  return useCurrentUser()?.ai_allowed_doc_types || noPermissions;
};

export const usePermissionedModes = () => {
  const [noPermissionModes] = React.useState([]);
  const user = useCurrentUser();
  const permissionedModes = user?.permissioned_modes_metadata;
  const userProp = permissionedModes ? Object.values(permissionedModes) : noPermissionModes;
  return [...userProp, ...forcedPermissions];
};

export const usePermissionedModesV2 = () => {
  const user = useCurrentUser();
  const rawGroups = user?.grouped_permissioned_modes_metadata;
  const groups = [];
  const ungrouped = [];
  rawGroups.forEach((g) => {
    if (g.group_name) {
      groups.push(g);
    } else {
      const modes = g.modes;
      modes.forEach((m) => {
        console.log('ungrouped permission', g);
      });
    }
  });
  if (forcedPermissions.length > 0) {
    const group = {
      group_name: 'Misc',
      modes: [],
    };
    forcedPermissions.forEach((m) => group.modes.push(m));
    ungrouped.push(group);
  }

  return {
    groups,
    ungrouped,
  };
};

export const useCurrentUserToolsAvailable = () => {
  const [noTools] = React.useState([]);
  const currentUser = useCurrentUser();
  return currentUser?.ai_allowed_mode_types || noTools;
};

export const useIsOnboarding = () => {
  const user = useCurrentUser();
  if (!user) {
    return null;
  }
  return !user.has_completed_onboarding;
};

export const useIsLiveTrading = () => {
  const user = useCurrentUser();
  return user?.is_live_trading;
};

export const useIsExtendedPricingEnabled = () => useIsLiveTrading();

export const useStreamingPrices = () => {
  const isEventSourceSupported = 'EventSource' in window;
  const isLiveTrading = useIsLiveTrading();
  return isEventSourceSupported && isLiveTrading;
};

export const useIsLivePricingEnabled = () => {
  return useStreamingPrices();
};

export const useIsDelayedPricesEnabled = () => {
  return !useStreamingPrices();
};
