import React from 'react';
import { DocumentSelection } from '../DocumentSelection';
import { useDocs } from '../../../hooks/features/useDocs';
import { Loading } from '../../../components/UI/Loading';

export const PresetDocumentSelection = ({
  docMode,
  presetParams,
  selectedDocs,
  handleAddDocument,
  handleRemoveDocument,
  onDismiss,
}) => {
  const { tickers, isQueryMode, isResearchMode, isWebMode } = presetParams;
  const [filters] = React.useState({ tickers, isQueryMode, isResearchMode, isWebMode, docMode });

  const { docs, isLoading: isLoadingDocs } = useDocs(filters, null);

  if (isLoadingDocs) {
    return <Loading />;
  }

  return (
    <DocumentSelection
      tickers={presetParams.tickers}
      docs={docs}
      onSelect={handleAddDocument}
      onRemoveSelect={handleRemoveDocument}
      selectedDocs={selectedDocs}
      onClose={onDismiss}
    />
  );
};
