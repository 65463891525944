import React from 'react';
import { sendApiRequest } from '../../services/api';

const getSearchResultsFromAPI = async (v, isResearchMode) => {
  let URL = `v1/genai_tickers_search?query=${v}`;
  if (isResearchMode) {
    URL += '&research_only=true';
  }
  const { status, data, error } = await sendApiRequest('get', URL);
  return {
    status,
    data,
    error,
  };
};

export const useTagSearchResults = (inputValue, isResearchMode) => {
  const [selectableTags, setSelectableTags] = React.useState([]);
  const [isQuerying, setIsQuerying] = React.useState(false);

  React.useEffect(() => {
    const queryRequestStatus = { isCanceled: false };
    const handleInputChange = async (inputValue, queryRequestStatus) => {
      if (queryRequestStatus.isCanceled) return null;

      if (inputValue !== '') {
        setIsQuerying(true);
        const { status, data } = await getSearchResultsFromAPI(inputValue, isResearchMode);
        setIsQuerying(false);
        if (status === 200) {
          if (!queryRequestStatus.isCanceled) {
            setSelectableTags(data);
          }
        } else {
          setTimeout(() => {
            handleInputChange(inputValue, queryRequestStatus);
          }, 500);
        }
      }
    };
    if (inputValue === '') {
      setSelectableTags([]);
    } else {
      handleInputChange(inputValue, queryRequestStatus);
    }

    return () => {
      queryRequestStatus.isCanceled = true;
    };
  }, [inputValue]);

  return [isQuerying, selectableTags];
};

export const useStockSearch = (inputValue) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [results, setResults] = React.useState(null);

  React.useEffect(() => {
    const queryRequestStatus = { isCanceled: false };
    const handleInputChange = async (inputValue, queryRequestStatus) => {
      if (queryRequestStatus.isCanceled) return null;

      if (inputValue !== '') {
        setIsLoading(true);
        const { status, data } = await getSearchResultsFromAPI(inputValue);
        setIsLoading(false);
        if (status === 200) {
          if (!queryRequestStatus.isCanceled) {
            setResults(data);
          }
        } else {
          setTimeout(() => {
            handleInputChange(inputValue, queryRequestStatus);
          }, 500);
        }
      }
    };
    if (inputValue === '') {
      setResults([]);
    } else {
      handleInputChange(inputValue, queryRequestStatus);
    }

    return () => {
      queryRequestStatus.isCanceled = true;
    };
  }, [inputValue]);

  return {
    isLoading,
    error,
    results,
  };
};
