import React from 'react';
import styled from 'styled-components';
import { useRecentDocsAndNewsData } from '../../hooks/features/useRecentDocsAndNews';
import { Body5, Container, H5, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { DateDisplay } from '../../components/UI/DateDisplay';
import { useColorTheme } from '../../hooks';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { ROUTES } from '../../constants/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const DailyDocGroupWrapper = styled.div``;
const DocTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  * {
    font-size: 14px;
    line-height: 18px;
  }
`;
const Name = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Ticker = styled.div`
  white-space: nowrap;
`;
const DocType = styled.div`
  width: 163px;
  white-space: nowrap;
`;
const DocumentIconWrapper = styled.div`
  svg {
    margin-top: 2px;
    height: 16px;
    width: 16px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const DocWrapper = styled.a`
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  padding: 6px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    border-bottom: 1px solid transparent;
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }
`;

function filterDuplicateIds(docs) {
  let ids = {};
  return docs.filter((d) => {
    if (ids[d.id]) {
      return false;
    } else {
      ids[d.id] = true;
      return true;
    }
  });
}

function Dot() {
  return <div style={{ fontSize: '30px', lineHeight: '18px' }}>&nbsp;&#183;&nbsp;</div>;
}

function Doc({ data }) {
  const { id, doc_type: docType, security_name: name, security_symbol: ticker } = data;
  const summaryUrl = `${ROUTES.docSummary}?id=${id}`;
  return (
    <DocWrapper href={summaryUrl} target={'_blank'}>
      <DocumentIconWrapper>
        <DocumentIcon />
      </DocumentIconWrapper>
      <DocTextWrapper>
        <Ticker>{ticker}&nbsp;-&nbsp;</Ticker>
        <Name>{name}</Name>
        <Dot />
        <DocType>{docType}</DocType>
      </DocTextWrapper>
    </DocWrapper>
  );
}

function DailyDocGroup({ data }) {
  const { day, docs } = data;
  if (day === 'Recent') {
    return null;
  }
  return (
    <DailyDocGroupWrapper>
      <Container>
        <DateDisplay date={day} />
      </Container>
      <Container top={16}>
        {filterDuplicateIds(docs).map((d) => (
          <Doc key={d.id} data={d} />
        ))}
      </Container>
    </DailyDocGroupWrapper>
  );
}

export function DailyDocsList() {
  const colorTheme = useColorTheme();
  const { docs, isLoading } = useRecentDocsAndNewsData();

  if (isLoading || docs === null) {
    return (
      <Wrapper>
        <Container top={40} bottom={80}>
          <Container row verticallyCenter>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            <Container left={16}>
              <Body5 bold>Loading Recent Company Documents</Body5>
            </Container>
          </Container>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {docs.map((g) => (
        <DailyDocGroup key={g.id} data={g} />
      ))}
    </Wrapper>
  );
}
