import React from 'react';
import { Body1, Body5, Container, Page } from '../libs/nvstr-common-ui.es';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import styled from 'styled-components';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { AdvancedSettingsFields } from '../hooks/application/useAdvancedSettings';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { useColorTheme } from '../hooks';
import { FlatButton, TransparentFlatButton } from '../components/buttons';
import { sendApiRequest } from '../services/api';
import { logNetRequest } from '../utils/usefulFuncs';
import InfoIconTooltip from '../components/UI/InfoIconTooltip';
import { InfoIconTypes } from '../constants/infoIcons';
import { addUrlParam } from '../utils';
import { createTimeInstance, formatLocalizedDateTime } from '../libs/nvstr-utils.es';
import DatePicker from 'react-date-picker';
import { ResultOnly } from '../containers/DigitalAnalyst/components/AnswerLine';
import { FollowUpQuestions } from '../containers/DigitalAnalyst/components/FollowUpQuestions';
import { Working } from '../components/UI/Working';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
const FormWrapper = styled.div`
  padding: 0 4px;

  input,
  textarea {
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }
`;
const DatePickerWrapper = styled.div`
  height: 48px;
  padding: 8px 0 0 0;

  .react-date-picker__inputGroup__input {
    border: none;
    padding: 0 8px;
  }

  .react-date-picker__inputGroup__input {
    &.react-date-picker__inputGroup__year {
      min-width: 56px;
    }

    min-width: 40px;
    text-align: center;
  }

  .react-date-picker__wrapper {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 4px;
    padding: 8px;
  }

  .react-calendar {
    background: ${({ theme }) => theme.themeColors.appBackground};

    color: ${({ theme }) => theme.themeColors.text};

    .react-calendar__tile--now {
      border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
      background: ${({ theme }) => theme.themeColors.appBackground};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    button {
      background: ${({ theme }) => theme.themeColors.appBackground} !important;
      color: ${({ theme }) => theme.themeColors.text} !important;

      &:disabled {
        cursor: not-allowed;
      }

      &:enabled:hover {
        border-radius: 2px;
        background: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
        color: ${({ theme }) => theme.themeColors.buttonText} !important;

        * {
          color: ${({ theme }) => theme.themeColors.buttonText} !important;
        }
      }
    }
  }

  .react-calendar__navigation button:disabled {
    background: ${({ theme }) => theme.themeColors.appBackground};
    color: ${({ theme }) => theme.themeColors.text};
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }

  .react-calendar__tile:disabled {
    background: ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
  }
`;
const CalendarIconWrapper = styled.div`
  svg {
    height: 22px;
    width: 22px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const ViewAnswerButtonWrapper = styled.div`
  button {
    padding: 0px 12px;
  }
`;
const ResultItemWrapper = styled.div`
  padding: 16px 0;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

function OpenCalenderIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

function ClearIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12L19,15.59M22,3A2,2 0 0,1 24,5V19A2,2 0 0,1 22,21H7C6.31,21 5.77,20.64 5.41,20.11L0,12L5.41,3.88C5.77,3.35 6.31,3 7,3H22M22,5H7L2.28,12L7,19H22V5Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

const advancedSettingsFields = [AdvancedSettingsFields.model];

const defaultStartDate = createTimeInstance().subtract(7, 'days').toDate();
const defaultEndDate = createTimeInstance().subtract(0, 'days').toDate();
const maxDate = createTimeInstance().subtract(0, 'days').toDate();

function ResultItem({ data }) {
  const { user_email, prompt, result, timestamp } = data;

  const [viewAnswer, setViewAnswer] = React.useState(false);

  const displayText = formatLocalizedDateTime('lll', createTimeInstance(timestamp));

  const handleHideAnswer = () => {
    setViewAnswer(false);
  };
  const handleViewAnswer = () => {
    setViewAnswer(true);
  };
  return (
    <ResultItemWrapper>
      <Container row>
        <Container>
          <Body5>Timestamp:</Body5>
        </Container>
        <Container left={16}>
          <Body5>{displayText} (ET)</Body5>
        </Container>
      </Container>
      <Container row>
        <Container>
          <Body5>Email:</Body5>
        </Container>
        <Container left={49}>
          <Body5>{user_email}</Body5>
        </Container>
      </Container>
      <Container row>
        <Container>
          <Body5>Prompt:</Body5>
        </Container>
        <Container left={39}>
          <Body5>{prompt}</Body5>
        </Container>
      </Container>
      <Container row top={8}>
        <Container>
          <Body5>Result:</Body5>
        </Container>
        <Container left={32}>
          <ViewAnswerButtonWrapper>
            {viewAnswer ? (
              <TransparentFlatButton onClick={handleHideAnswer}>Hide</TransparentFlatButton>
            ) : (
              <TransparentFlatButton onClick={handleViewAnswer}>View</TransparentFlatButton>
            )}
          </ViewAnswerButtonWrapper>
        </Container>
      </Container>
      {viewAnswer ? (
        <>
          <Container top={8} left={84}>
            <ResultOnly result={result} selectedDocs={[]} sourceDocs={[]} />
            <Container top={0}>
              <FollowUpQuestions result={result} />
            </Container>
          </Container>
        </>
      ) : null}
    </ResultItemWrapper>
  );
}

function Results({ data }) {
  const results = data?.records;
  return (
    <Container>
      {!results || results.length === 0 ? (
        <Container>
          <Body5>No results match selected criteria.</Body5>
        </Container>
      ) : null}
      {results?.map((d, i) => (
        <ResultItem key={i} data={d} />
      ))}
    </Container>
  );
}

export function ComplianceMode() {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  const [email, setEmail] = React.useState('');
  const [keywords, setKeywords] = React.useState('');
  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);

  const [results, setResults] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isGettingResults, setIsGettingResults] = React.useState(false);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onKeywordsChange = (e) => {
    setKeywords(e.target.value);
  };

  const handleStartDateChange = (value) => {
    if (value === null) {
      setStartDate(defaultStartDate);
    } else {
      setStartDate(value);
    }
  };
  const handleEndDateChange = (value) => {
    if (value === null) {
      setEndDate(defaultEndDate);
    } else {
      setEndDate(value);
    }
  };
  const removeWhitespace = (str) => str.split(' ').join('');

  const onSubmit = async () => {
    const dateFormat = '-L';
    const startDateApi = formatLocalizedDateTime(dateFormat, createTimeInstance(startDate));
    const endDateApi = formatLocalizedDateTime(dateFormat, createTimeInstance(endDate));
    let URLParams = `start_date=${startDateApi}&end_date=${endDateApi}`;
    if (email.length > 0) {
      URLParams = addUrlParam(URLParams, `user_emails=${encodeURIComponent(removeWhitespace(email))}`);
    }
    if (keywords.length > 0) {
      URLParams = addUrlParam(URLParams, `keywords=${encodeURIComponent(keywords)}`);
    }
    const URL = `v1/genai_compliance_review?${URLParams}`;

    setIsGettingResults(true);
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data);
    if (status === 200) {
      setResults(data);
    } else {
      setError(error || 'Something went wrong, please try again.');
    }
    setIsGettingResults(false);
  };

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <BackArrowButton onClick={onGoBack} />

          <AdvancedSettingsButton fields={advancedSettingsFields} />

          <Container top={40}>
            <Body1 bold>Search</Body1>
            &nbsp;&nbsp;
            <InfoIconTooltip type={InfoIconTypes.COMPLIANCE_SEARCH} />
            <FormWrapper>
              <Container top={24}>
                <Container bottom={8}>
                  <Body5>Email Addresses</Body5>
                </Container>
                <textarea value={email} onChange={onEmailChange} />
              </Container>
              <Container top={16}>
                <Container bottom={8}>
                  <Body5>Keywords</Body5>
                </Container>
                <input value={keywords} onChange={onKeywordsChange} placeholder={'Keywords...'} />
              </Container>
              <Container top={16}>
                <Container bottom={0}>
                  <Body5>Start Date</Body5>
                </Container>
                <DatePickerWrapper>
                  <DatePicker
                    name={'start-date'}
                    calendarIcon={OpenCalenderIcon}
                    clearIcon={ClearIcon}
                    onChange={handleStartDateChange}
                    value={startDate}
                    // maxDate={maxDate}
                  />
                </DatePickerWrapper>
              </Container>
              <Container top={24}>
                <Container bottom={0}>
                  <Body5>End Date</Body5>
                </Container>
                <DatePickerWrapper>
                  <DatePicker
                    name={'end-date'}
                    calendarIcon={OpenCalenderIcon}
                    clearIcon={ClearIcon}
                    onChange={handleEndDateChange}
                    value={endDate}
                    // maxDate={maxDate}
                  />
                </DatePickerWrapper>
              </Container>
              <Container top={36} style={{ textAlign: 'center' }}>
                <FlatButton fullWidth onClick={onSubmit}>
                  Submit
                </FlatButton>
              </Container>
            </FormWrapper>
          </Container>
        </Page>
        {(results || isGettingResults) && (
          <Page>
            {isGettingResults ? (
              <Container top={80} bottom={80}>
                <Working noVerticalPadding />
              </Container>
            ) : (
              <Results data={results} />
            )}
          </Page>
        )}
      </Body>
    </PageWrapper>
  );
}
