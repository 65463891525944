import styled from 'styled-components';
import React from 'react';
import { createTimeInstance } from '../../libs/nvstr-utils.es';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import DatePicker from 'react-date-picker';

const DatePickerWrapper = styled.div`
  height: 40px;
  padding: 8px;

  .react-date-picker__inputGroup__input {
  }

  .react-date-picker__inputGroup__input {
    &.react-date-picker__inputGroup__year {
      min-width: 56px;
    }

    min-width: 40px;
    text-align: center;
  }

  .react-date-picker__wrapper {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 4px;
    padding: 8px;
  }

  .react-calendar {
    background: ${({ theme }) => theme.themeColors.appBackground};

    color: ${({ theme }) => theme.themeColors.text};

    .react-calendar__tile--now {
      border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
      background: ${({ theme }) => theme.themeColors.appBackground};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    button {
      background: ${({ theme }) => theme.themeColors.appBackground} !important;
      color: ${({ theme }) => theme.themeColors.text} !important;

      &:disabled {
        cursor: not-allowed;
      }

      &:enabled:hover {
        background: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
        color: ${({ theme }) => theme.themeColors.buttonText} !important;

        * {
          color: ${({ theme }) => theme.themeColors.buttonText} !important;
        }
      }
    }
  }

  .react-calendar__navigation button:disabled {
    background: ${({ theme }) => theme.themeColors.appBackground};
    color: ${({ theme }) => theme.themeColors.text};
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }

  .react-calendar__tile:disabled {
    background: ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

function OpenCalenderIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

function ClearIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12L19,15.59M22,3A2,2 0 0,1 24,5V19A2,2 0 0,1 22,21H7C6.31,21 5.77,20.64 5.41,20.11L0,12L5.41,3.88C5.77,3.35 6.31,3 7,3H22M22,5H7L2.28,12L7,19H22V5Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

const DateRangeInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: 16px 0 0 0;

  @media (max-width: 660px) {
    display: block;

    .hyphen {
      display: none;
    }

    ${DatePickerWrapper} {
      height: 72px;
    }
  }
`;
const DateRangeSelectionWrapper = styled.div`
  input {
    border: none !important;
    padding: 0px !important;
  }
`;

const CalendarIconWrapper = styled.div`
  svg {
    height: 22px;
    width: 22px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export function DateRangeSelection({ startDate, endDate, handleStartDateChange, handleEndDateChange }) {
  const minDate = null;
  const maxDate = createTimeInstance().toDate();
  return (
    <DateRangeSelectionWrapper>
      <Body5 bold>Only use documents in this date range:</Body5>
      <DateRangeInputsWrapper>
        <DatePickerWrapper>
          <DatePicker
            name={'start-date'}
            calendarIcon={OpenCalenderIcon}
            clearIcon={ClearIcon}
            onChange={handleStartDateChange}
            value={startDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DatePickerWrapper>
        <div className={'hyphen'}>
          <Container height={60} centerAll width={40}>
            <Body5 bold>-</Body5>
          </Container>
        </div>
        <DatePickerWrapper>
          <DatePicker
            name={'end-date'}
            calendarIcon={OpenCalenderIcon}
            clearIcon={ClearIcon}
            onChange={handleEndDateChange}
            value={endDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DatePickerWrapper>
      </DateRangeInputsWrapper>
    </DateRangeSelectionWrapper>
  );
}
