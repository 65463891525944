import React from 'react';
import { TrackingEvent } from '../../../libs/nvstr-utils.es';
import { ROUTES } from '../../../constants/routes';
import { Body5, Close } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { isValidUrl, logError, truncateStringWithDate } from '../../../utils/usefulFuncs';

const SelectedDocWrapper = styled.div`
  border-radius: 2px;
  // background: ${({ theme, isSelectedToRemove }) =>
    isSelectedToRemove ? 'none' : theme.themeColors.primaryCtaButton};
  border: 1px solid
    ${({ theme, isSelectedToRemove }) =>
      isSelectedToRemove ? theme.themeColors.primaryCtaButton : theme.themeColors.primaryCtaButton};
  cursor: pointer;
  z-index: 1;

  padding: 3px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: ${({ isSelectedToRemove }) => (isSelectedToRemove ? 0.5 : 1)};
  max-width: 480px;
  max-height: 24px;

  * {
    color: ${({ theme, isSelectedToRemove }) =>
      isSelectedToRemove ? theme.themeColors.text : theme.themeColors.text} !important;
    fill: ${({ theme, isSelectedToRemove }) =>
      isSelectedToRemove ? theme.themeColors.secondary : theme.themeColors.text} !important;
  }

  svg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    height: 8px !important;
    width: 12px !important;

    opacity: 0.8;
  }

  :hover {
    opacity: 0.9;
  }
`;
const CloseIconWrapper = styled.div``;
const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HoverEffectWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 28px;

  background: ${({ theme }) => theme.themeColors.primaryCtaButton};
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  padding: 8px;

  z-index: 2;

  color: ${({ theme }) => theme.themeColors.buttonText};

  * {
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

const HoverEffect = ({ isHoveringTarget, children }) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    let timeoutId = null;
    if (isHoveringTarget) {
      const DELAY = 600;
      timeoutId = setTimeout(() => {
        setShow(true);
      }, DELAY);
    }

    if (!isHoveringTarget) {
      setShow(false);
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [isHoveringTarget]);

  if (!show || !isHoveringTarget) return false;

  return <HoverEffectWrapper>{children}</HoverEffectWrapper>;
};

function isDocumentAWebsite(doc) {
  try {
    const { filename } = doc;
    const firstPart = filename.split(' ')[0];
    return isValidUrl(firstPart);
  } catch (e) {
    logError(e);
    return false;
  }
}

function getDocumentWebsite(doc) {
  const { filename } = doc;
  return filename.split(' ')[0];
}

const TRUNCATE_TEXT_LENGTH = 60;

export default function SelectedDocument({ doc, handleRemoveClick, selectedDocs }) {
  const [isHovering, setIsHovering] = React.useState(false);

  React.useEffect(() => {
    if (!doc || !doc.filename) {
      TrackingEvent.create('Selected Doc was invalid', {
        'Selected Docs': selectedDocs,
        Doc: doc,
      });
    }
  }, [doc]);

  if (!doc || !doc.filename) {
    return null;
  }

  const { id, filename } = doc;

  const onRemoveClick = (e) => {
    e.stopPropagation();
    handleRemoveClick(filename);
  };

  const handleDocClick = () => {
    if (isDocumentAWebsite(doc)) {
      window.open(getDocumentWebsite(doc), '_blank');
    } else {
      window.open(ROUTES.docViewer + `?id=${id}`, '_blank');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <SelectedDocWrapper
        onClick={handleDocClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Truncate>
          <Body5>{truncateStringWithDate(filename, TRUNCATE_TEXT_LENGTH)}</Body5>
        </Truncate>
        <CloseIconWrapper onClick={onRemoveClick}>
          <Close width={12} />
        </CloseIconWrapper>
      </SelectedDocWrapper>
      <HoverEffect isHoveringTarget={isHovering}>
        <Body5>{filename}</Body5>
      </HoverEffect>
    </div>
  );
}
