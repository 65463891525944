import React from 'react';
import styled from 'styled-components';
import { Body5, Checkbox, Container } from '../../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../../components/buttons';
import { Modal } from '../../../components/layout/Modal';
import { Edit } from '../../../assets/icons/svgs/Edit';

const Wrapper = styled.div``;
const QuestionListWrapper = styled.div`
  padding: 8px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const QuestionRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const AddQuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  button {
    padding: 8px 12px;
  }

  width: 80vw;
  max-width: 800px;

  input {
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }
`;

const EditButtonWrapper = styled.div`
  padding: 3px 0 0 12px;

  svg {
    height: 14px;
    width: 14px;

    fill: ${({ theme }) => theme.themeColors.text};
  }

  cursor: pointer;
`;

let nextId = 1;

const initialState = {
  list: [],
};

export const QUESTION_LIST_ACTIONS = {
  addQuestion: 'adq',
  editQuestion: 'edq',
};

const KEY = 'custom-snapshot-question';
const storeList = (list) => {
  window.localStorage.setItem(KEY, JSON.stringify(list));
};
const getList = () => {
  const v = window.localStorage.getItem(KEY);
  if (v === null) return null;
  return JSON.parse(v);
};

function reducer(state, action) {
  switch (action.type) {
    case QUESTION_LIST_ACTIONS.addQuestion: {
      const newQ = {
        id: nextId++,
        text: action.payload,
      };
      const nextList = [...state.list, newQ];
      storeList(nextList);
      return {
        list: nextList,
      };
    }

    case QUESTION_LIST_ACTIONS.editQuestion: {
      const [original, newQuestion] = action.payload;

      const editedList = [...state.list];
      editedList.forEach((q) => {
        if (q.text === original) {
          q.text = newQuestion;
        }
      });
      storeList(editedList);
      return {
        list: editedList,
      };
    }

    default:
      throw new Error();
  }
}

const QuestionRow = ({ data, selectedQuestions, onSelectedQuestionToggle, handleEditQuestionClick }) => {
  const { text } = data;
  const isSelected = selectedQuestions.includes(text);

  const onClick = () => onSelectedQuestionToggle(text);
  return (
    <QuestionRowWrapper>
      <Checkbox name={'checkbox_' + text.split(' ').join('_')} value={isSelected} onChange={() => onClick} />
      <div onClick={onClick}>
        <Container bottom={1} style={{ cursor: 'pointer' }}>
          <Body5>{text}</Body5>
        </Container>
      </div>
      <EditButtonWrapper onClick={() => handleEditQuestionClick(text)}>
        <Edit />
      </EditButtonWrapper>
    </QuestionRowWrapper>
  );
};

export const useQuestionList = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => ({ list: getList() || [] }));

  return {
    list: state.list,
    dispatch,
  };
};

export default function QuestionList({
  isAdding,
  list,
  selectedQuestions,
  onAddQuestion,
  onEditQuestion,
  onSelectedQuestionToggle,
  handleAddQuestionCancel,
}) {
  const [modalProps] = React.useState({ maximizeWidth: false });

  const [editQuestion, setEditQuestion] = React.useState(null);
  const [questionInputText, setQuestionInputText] = React.useState('');

  const handleEditQuestionClick = (q) => {
    setEditQuestion(q);
    setQuestionInputText(q);
  };

  const handleAddQuestionChange = (e) => {
    setQuestionInputText(e.target.value);
  };

  const handleEditQuestionChange = (e) => {
    setQuestionInputText(e.target.value);
  };

  const onAddQuestionSubmit = () => {
    onAddQuestion(questionInputText);
    setQuestionInputText('');
  };

  const onEditQuestionSubmit = () => {
    onEditQuestion(editQuestion, questionInputText);
    setEditQuestion(null);
    setQuestionInputText('');
  };

  return (
    <Wrapper>
      {isAdding && (
        <Modal onDismiss={handleAddQuestionCancel} modalProps={modalProps}>
          <Container horizontal={16}>
            <Container bottom={16}>
              <Body5 bold>Add Question</Body5>
            </Container>
            <AddQuestionWrapper top={16}>
              <input value={questionInputText} onChange={handleAddQuestionChange} autoFocus />
            </AddQuestionWrapper>
            <Container top={24} style={{ textAlign: 'center' }}>
              <FlatButton onClick={onAddQuestionSubmit} fullWidth>
                Enter
              </FlatButton>
            </Container>
          </Container>
        </Modal>
      )}
      {editQuestion !== null && (
        <Modal onDismiss={() => setEditQuestion(null)} modalProps={modalProps}>
          <Container horizontal={16}>
            <Container bottom={16}>
              <Body5 bold>Edit Question</Body5>
            </Container>
            <AddQuestionWrapper top={16}>
              <input value={questionInputText} onChange={handleEditQuestionChange} autoFocus />
            </AddQuestionWrapper>
            <Container top={24} style={{ textAlign: 'center' }}>
              <FlatButton onClick={onEditQuestionSubmit} fullWidth>
                Update
              </FlatButton>
            </Container>
          </Container>
        </Modal>
      )}
      <QuestionListWrapper>
        {list.map((q) => (
          <QuestionRow
            key={q.id}
            data={q}
            selectedQuestions={selectedQuestions}
            onSelectedQuestionToggle={onSelectedQuestionToggle}
            handleEditQuestionClick={handleEditQuestionClick}
          />
        ))}
      </QuestionListWrapper>
    </Wrapper>
  );
}
