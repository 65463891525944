import React from 'react';
import styled from 'styled-components';
import { BaseButton, BaseButtonWrapper } from './BaseButton';

export const BaseFlatButtonWrapper = styled.div`
  button {
    padding: 12px 24px;
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
    max-width: ${({ $fullWidth }) => ($fullWidth ? '420px' : 'auto')};
  }
`;

export const FlatButtonWrap = styled(BaseFlatButtonWrapper)`
  ${BaseButtonWrapper} {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border: ${({ theme }) => `1px solid ${theme.themeColors.primaryCtaButton}`};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }

    :hover {
      opacity: 0.8;
    }
  }
`;

export const TransparentButtonWrap = styled(BaseFlatButtonWrapper)`
  ${BaseButtonWrapper} {
    background: none;
    border: none;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
      color: ${({ theme }) => theme.themeColors.text};
    }

    :hover {
      opacity: 0.8;
    }
  }
`;

export const DisabledTransparentButtonWrap = styled(BaseFlatButtonWrapper)`
  ${BaseButtonWrapper} {
    background: ${({ theme }) => theme.themeColors.text};
    border: ${({ theme }) => '1px solid ' + theme.themeColors.text};

    opacity: 0.25;
    cursor: not-allowed;

    * {
      color: ${({ theme }) => theme.themeColors.componentNoOpacity};
      fill: ${({ theme }) => theme.themeColors.componentNoOpacity};
    }

    &:hover {
      cursor: not-allowed;
      opacity: 0.28;
    }
  }
`;
export const DisabledButtonWrap = styled(BaseFlatButtonWrapper)`
  ${BaseButtonWrapper} {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};

    opacity: 0.25;
    cursor: not-allowed;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }

    &:hover {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
`;

export const TransparentFlatButton = (props) => {
  const { children, buttonProps, disabled, onClick, fullWidth } = props;

  if (disabled) {
    return (
      <DisabledTransparentButtonWrap $fullWidth={fullWidth}>
        <BaseButton {...buttonProps} onClick={onClick}>
          <b>{children}</b>
        </BaseButton>
      </DisabledTransparentButtonWrap>
    );
  }

  return (
    <TransparentButtonWrap disabled={disabled} $fullWidth={fullWidth}>
      <BaseButton {...buttonProps} onClick={onClick}>
        <b>{children}</b>
      </BaseButton>
    </TransparentButtonWrap>
  );
};

export const FlatButton = (props) => {
  const { children, buttonProps, disabled, onClick, fullWidth } = props;

  if (disabled) {
    return (
      <DisabledButtonWrap $fullWidth={fullWidth}>
        <BaseButton {...buttonProps} onClick={onClick}>
          <b>{children}</b>
        </BaseButton>
      </DisabledButtonWrap>
    );
  }

  return (
    <FlatButtonWrap $fullWidth={fullWidth}>
      <BaseButton {...buttonProps} onClick={onClick}>
        <b>{children}</b>
      </BaseButton>
    </FlatButtonWrap>
  );
};

FlatButton.defaultProps = {
  buttonProps: {},
};
