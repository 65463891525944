import { sendApiRequest } from './api';
import { applicationActionTypes } from '../constants/actionTypes';

export const fetchStaticCopy = () => {
  return async (dispatch) => {
    const response = await sendApiRequest('get', 'v1/static', null, null);
    const { status, data } = response;
    if (status === 200) {
      dispatch({
        type: applicationActionTypes.setStaticCopy,
        payload: data,
      });
    }
    return response;
  };
};
