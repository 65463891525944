const sectionNamesOrdered = [
  'intro',
  'comparative-advantages',
  'data-sources-and-queries',
  'transparency-and-source-verification',
  'pricing-information',
  'scheduling-a-demo',
  'security-and-privacy',
];

const productName = 'Digital Analyst';
const emailDomain = 'tornado';

// const nextFAQId = 1;

// <a href="mailto:ai@tornado.com">ai@tornado.com </a>

const FAQData = ({ ltIndicatorComponent, isOptimizerEnabled, isLiveTradingAvailable }) => {
  const sectionLookup = {
    intro: {
      name: 'intro',
      heading: "Introduction to Tornado's Digital Analyst",
      questions: [
        {
          id: 1,
          question: 'What is Tornado’s Digital Analyst?',
          message: `Tornado's Digital Analyst is an advanced AI platform tailored for the financial sector. It accelerates research workflows by synthesizing information from an extensive array of millions of pages of public company filings, including 10-Ks, 10-Qs, and earnings call transcripts which are updated multiple times per day, plus your own proprietary sources. Designed for investment professionals, the tool offers customizable, scalable write-up generation, allowing for efficient and informed decision-making.`,
        },
        {
          id: 2,
          question: 'Where can I find the Digital Analyst?',
          message: `The Digital Analyst is accessible through Tornado's online platform here: <a href="https://ai.tornado.com">ai.tornado.com</a>`,
        },
        {
          id: 3,
          question: 'How does the Digital Analyst work?',
          message: `<p>The Digital Analyst employs generative artificial intelligence algorithms to analyze millions of pages of financial data from over 2,000 companies, updated continuously throughout the day, providing users with a suite of tools for comprehensive analysis:</p>
            <ul><li>Recent Company Documents: Access the latest filings and calls, with filters for document type, company size and more</li>
     <li>Upload Source Document: Incorporate your own documents into the analysis for a customized insights</li>
        <li>Earnings Call Analysis: Summarize earnings calls and delve into analyst Q&A sessions</li>
        <li>Company Snapshot: Obtain essential information about a company using its ticker symbol</li>
        <li>Company Red Flags: Identify possible risks for any company</li>
        <li>Screening: Identify companies that meet specific criteria or belong to targeted sectors</li>
        <li>Query: Utilize pre-filled or custom queries to extract information from a vast database of filings and your own proprietary sources</li></ul>
        <p>Depending on your organization’s needs, you may subscribe to all of these features or a custom selection.</p>`,
        },
      ],
    },
    'comparative-advantages': {
      name: 'comparative-advantages',
      heading: 'Comparative Advantages',
      questions: [
        {
          id: 4,
          question: 'How is the Digital Analyst different from ChatGPT?',
          message: `The Digital Analyst stands out with its specialized focus on financial analysis, offering real-time access to regulatory filings, data uploads, and a private, secure environment for research. Unlike ChatGPT, it provides source attribution for every piece of information, ensuring accuracy and reliability. Your queries and data are never shared with other users or used to train or fine-tune an LLM. You can also customize the tool to your organization’s needs.`,
        },
        {
          id: 5,
          question: "Can the Digital Analyst be customized to fit my organization's specific needs?",
          message: `Yes, Tornado's Digital Analyst is highly customizable. It can be tailored to integrate with and even ingest documents from your existing workflows and data sources to use within its advanced AI platform, providing a seamless research experience. Our team wants to work with you and make sure the tool meets your unique needs.`,
        },
        {
          id: 6,
          question: "Is my data and search activity private when using Tornado's Digital Analyst?",
          message: `Yes, your data and search activity are securely ring-fenced when using our Digital Analyst. We prioritize the confidentiality of your research and ensure that your data and results are not shared or used to train the algorithm. This guarantees that the insights you gain remain exclusive to you and your organization.`,
        },
      ],
    },

    'data-sources-and-queries': {
      name: 'data-sources-and-queries',
      heading: 'Data Sources and Queries',
      questions: [
        {
          id: 7,
          question: 'Where does the Digital Analyst pull information from?',
          message: `The Digital Analyst sources its data from an up-to-date repository of regulatory filings and earnings calls from over 2,000 companies, which may be supplemented by any additional data you or your organization choose to incorporate for a tailored analysis experience.`,
        },
        {
          id: 8,
          question: 'Are there pre-filled questions to use in my query on the Digital Analyst?',
          message: `Yes, the Digital Analyst features a curated list of pre-filled questions designed to streamline your research process. These questions cover a broad spectrum of financial and strategic aspects of a company's operations.`,
        },
      ],
    },
    'transparency-and-source-verification': {
      name: 'transparency-and-source-verification',
      heading: 'Transparency and Source Verification',
      questions: [
        {
          id: 9,
          question: 'Are you able to see the source information for each query?',
          message: `Absolutely. The Digital Analyst provides a 'Source' feature that allows users to view the original source documents for each query, ensuring transparency and verifiability of the information provided.`,
        },
      ],
    },
    'pricing-information': {
      name: 'pricing-information',
      heading: 'Pricing Information',
      questions: [
        {
          id: 10,
          question: 'What is the cost of using Tornado’s Digital Analyst?',
          message: `Tornado's Digital Analyst offers pricing to accommodate the diverse needs of our various clients. To obtain detailed pricing information tailored to your organization's specific requirements, please contact us directly at ai@tornado.com. We provide competitive rates that reflect the value and efficiency our tool brings to your research and analysis processes.`,
        },
      ],
    },
    'scheduling-a-demo': {
      name: 'scheduling-a-demo',
      heading: 'Scheduling a Demo',
      questions: [
        {
          id: 11,
          question: 'How can I set up a demo of the Digital Analyst?',
          message: `To experience the full capabilities of Tornado's Digital Analyst firsthand, we invite you to schedule a personalized demo. Simply reach out to our team at <a href="mailto:ai@tornado.com">ai@tornado.com</a> with your preferred dates and times, and we will coordinate with you to set up a live demonstration, or schedule a call here. During the demo, our experts will guide you through the features and functionalities of the tool, showcasing how you can effectively leverage our AI platform to streamline your workflow and enhance your financial analysis.`,
        },
      ],
    },

    'security-and-privacy': {
      name: 'security-and-privacy',
      heading: 'Security and Privacy',
      questions: [
        {
          id: 12,
          question: 'How does Tornado ensure the security and privacy of my data?',
          message: `Tornado prioritizes the security and privacy of your data. Our platform employs advanced encryption and security protocols to protect your information. All searches and data are stored privately, protecting your proprietary research keeping your data confidential and secure. DDQ available upon request.`,
        },
      ],
    },
  };

  const orderedSections = sectionNamesOrdered.map((n) => {
    return sectionLookup[n];
  });

  return {
    orderedSections,
    sectionNamesOrdered,
    sectionLookup,
  };
};

export default FAQData;
