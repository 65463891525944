import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;

export const CenterButton = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
