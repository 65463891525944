import styled from 'styled-components';
import React from 'react';

const Background = styled.div`
  background-color: ${({ theme }) => theme.themeColors.appBackground};

  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 1000px;
  margin: 0 auto;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 24px;

  @media (max-width: 875px) {
    width: 100%;
    padding: 16px;
  }
`;

const GridWrapper = styled.div`
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 875px) {
    display: block;
  }
`;

export const SplitPage = ({ LeftComponent, RightComponent }) => {
  return (
    <Background>
      <GridWrapper>
        <SectionWrapper>{LeftComponent}</SectionWrapper>
        <SectionWrapper>{RightComponent}</SectionWrapper>
      </GridWrapper>
    </Background>
  );
};
