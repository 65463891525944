import { infoIconActions } from '../constants/actionTypes';

const defaultState = {
  show: false,
  symbol: null,
  type: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case infoIconActions.show: {
      return { ...action.payload };
    }
    case infoIconActions.hide: {
      return defaultState;
    }

    default:
      return state;
  }
}
