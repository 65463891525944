import * as amplitude from '@amplitude/analytics-browser';
import { LOG_AMPLITUDE_EVENTS } from '../constants/app';
import { logError, logger } from './usefulFuncs';

let didInit = false;

const defaultEventProperties = {
  'Is Mobile App': false,
  'Product Branding': 'Digital Analyst',
};
const _defaultUserTrackingProperties = {};

export const setDefaultUserTrackingProperties = (properties) => {
  const keys = Object.keys(properties);
  keys.forEach((key) => {
    _defaultUserTrackingProperties[key] = properties[key];
  });
};

export const UTM_KEYS = ['utm_source', 'utm_content', 'utm_medium', 'utm_term', 'utm_campaign'];

export const UTMTrackingKeys = {
  utm_source: 'UTM Source',
  utm_content: 'UTM Content',
  utm_medium: 'UTM Medium',
  utm_term: 'UTM Term',
  utm_campaign: 'UTM Campaign',
};

export const getAmplitudePublicAPIKey = () => {
  let PUBLIC_AMPLITUDE_KEY = process.env.REACT_APP_PUBLIC_AMPLITUDE_KEY;
  if (!PUBLIC_AMPLITUDE_KEY) {
    console.warn('key was missing: ' + PUBLIC_AMPLITUDE_KEY);
    PUBLIC_AMPLITUDE_KEY = '5d38cf63e73716fbf73bbe6811283360';
  }
  return PUBLIC_AMPLITUDE_KEY;
};

export const logAmplitudeEvent = async (eventName, _params_properties = {}) => {
  const properties = {
    ...defaultEventProperties,
    ..._params_properties,
  };
  const loggerCallback = (responseCode, responseBody) => {
    if (LOG_AMPLITUDE_EVENTS) {
      logger(`${eventName} event sent`, {
        properties,
        responseCode,
        responseBody,
      });
    }
  };

  try {
    await amplitude.track(eventName, properties, loggerCallback);
  } catch (e) {
    console.error('amplitude instance not found');
  }
};

export const setTrackingUserProperties = (userProperties) => {
  try {
    if (!didInit) {
      console.warn('amplitude still initializing');
      return;
    }

    const identifyEvent = new amplitude.Identify();
    const propertiesList = Object.entries(userProperties);
    propertiesList.forEach((entry) => {
      const [key, value] = entry;
      identifyEvent.add(key, value);
    });

    if (LOG_AMPLITUDE_EVENTS) {
      logger('setting user tracking properties');
    }
  } catch (e) {
    logError(e);
  }
};

export const initAmplitudeInstance = () => {
  if (didInit) {
    console.warn('amplitude already initialized');
    return null;
  }

  try {
    didInit = true;
    const apiKey = getAmplitudePublicAPIKey();
    const options = {
      logLevel: LOG_AMPLITUDE_EVENTS ? amplitude.Types.LogLevel.Verbose : amplitude.Types.LogLevel.None,
      defaultTracking: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
    };
    const instance = amplitude.init(apiKey, options);
    window.amplitudeInstance = instance;

    if (LOG_AMPLITUDE_EVENTS) {
      logger('init success', instance);
    }
  } catch (e) {
    logError('could not setup amplitude', e);
  }
};

export const identifyAmplitudeUser = (amplitudeUserId) => {
  try {
    if (LOG_AMPLITUDE_EVENTS) {
      logger('identifying amplitude user', {
        amplitudeUserId,
      });
    }

    amplitude.setUserId(amplitudeUserId);
    const identify = new amplitude.Identify().set('User', amplitudeUserId);
    const result = amplitude.identify(identify);

    if (LOG_AMPLITUDE_EVENTS) {
      logger(result);
    }
  } catch (e) {
    logError(e);
  }
};
