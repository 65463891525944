import styled from 'styled-components';
import React from 'react';
import { Modal } from '../../../components/layout/Modal';
import { Body1, Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';

const PrefillQuestionWrapper = styled.div`
  display: block;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
    color: ${({ theme }) => theme.themeColors.text} !important;
    * {
      color: ${({ theme }) => theme.themeColors.text} !important;
    }
  }
`;

export const PrefillQuestionModal = ({ onDismiss, onSelect }) => {
  const [questions] = React.useState([
    'What does this company do?',
    'How does it generate revenue?',
    'What products / services does it sell?',
    'How large are its target markets?',
    'Who are its key customers?',
    'How is it differentiated from its competitors?',
    'How does it generate earnings?',
    'What are its reported operating segments?',
    'What % of revenue and operating income is each segment?',
    'How else does the company break down revenue and earnings (such as by product and/or service, by geography)?',
    'What’s its strategy to grow sales and earnings?',
    'What are its long term financial targets?',
    'What are recent trends in sales and profitability, overall and by segment?',
    'What’s its capital structure? Is it changing, and if so, how?',
    'What’s its investment strategy? How does it fund growth? Is it changing, and if so, how?',
    'What are the risks to the company’s business?',
  ]);

  const [modalProps] = React.useState({});

  const hc = (q) => () => {
    onSelect(q);
    onDismiss();
  };
  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <Container bottom={24}>
        <Body1>Select Question</Body1>
      </Container>
      <Container>
        {questions.map((q) => (
          <PrefillQuestionWrapper key={q} onClick={hc(q)}>
            <Body5>{q}</Body5>
          </PrefillQuestionWrapper>
        ))}
      </Container>

      <Container style={{ textAlign: 'center' }} top={24}>
        <SkeletonButton fullWidth onClick={onDismiss}>
          Dismiss
        </SkeletonButton>
      </Container>
    </Modal>
  );
};
