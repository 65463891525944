import styled from 'styled-components';
import { colorPalette } from '../../libs/nvstr-utils.es';
import React from 'react';

const Line = styled('div')`
  border-radius: ${(props) => props.borderRadius || '0px'};
  background: ${(props) => props.color || props.background || '#404040'};

  height: ${(props) => props.height || '1px'};
  width: ${(props) => props.width || '100%'};
  opacity: ${(props) => props.opacity || '1'};

  user-select: none;
`;

export const HeaderLine = ({ isLowContrast, height }) => {
  const color = colorPalette.primary.charcoal;
  const opacity = isLowContrast ? 0.2 : 1;
  return <Line color={color} opacity={opacity} height={height} />;
};
