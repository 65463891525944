import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: ${({ $Size: size }) => (size || 16) + 'px'};
  width: ${({ $Size: size }) => (size || 16) + 'px'};

  path {
    stroke: ${({ theme, $Color: color }) => color || theme.themeColors.text};
    fill: ${({ theme, $Color: color }) => color || theme.themeColors.text};
  }
`;

export const Edit = ({ size, color }) => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" $Size={size} $Color={color}>
      <path d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" />
    </SVG>
  );
};
