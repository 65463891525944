export const FED_SPEAKERS = [
  'Powell',
  'Barr',
  'Jefferson',
  'Bowman',
  'Cook',
  'Kugler',
  'Waller',
  'Barkin',
  'Bostic',
  'Collins',
  'Daly',
  'Goolsbee',
  'Harker',
  'Kashkari',
  'Logan',
  'Mester',
  'Musalem',
  'Schmid',
  'Williams',
  'Clarida',
  'Quarles',
  'Brainard',
  'Yellen',
  'Bernanke',
].sort();
