import { useTableAnalysis } from '../../hooks/features/useTableAnalysis';
import { Container, Page } from '../../libs/nvstr-common-ui.es';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { SeriesTable } from '../DigitalAnalyst/components/SeriesTable';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

export const RunGridModeTable = ({ onGoBack, docMode }) => {
  const { tableRows, selectedDocuments } = useTableAnalysis();

  return (
    <Page width={'100%'}>
      <Wrapper>
        <BackArrowButton onClick={onGoBack} />
        <Container top={24}>
          <SeriesTable tableRows={tableRows} docColumns={selectedDocuments} tool={docMode} docMode={docMode} />
        </Container>
      </Wrapper>
    </Page>
  );
};
