import { logError } from './usefulFuncs';
import { applicationActionTypes } from '../constants/actionTypes';

export function removeFavicon() {
  const oldLink = document.getElementById('page-icon');
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
}

export function setFaviconSVG(svgString) {
  removeFavicon();

  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  const svgURL = URL.createObjectURL(blob);

  // Create a new link element
  const link = document.createElement('link');
  link.rel = 'icon';
  link.type = 'image/svg+xml';
  link.href = svgURL;

  // Append the link element to the head of the document
  document.head.appendChild(link);
}

export function smoothScrollToAnchor(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  } else {
    logError('element not found');
  }
}

export function showErrorBanner(message, config, dispatch) {
  dispatch({
    type: applicationActionTypes.showErrorBanner,
    payload: { message, config },
  });
}

export function addOptionalField(name, value, form, isFloat) {
  if (!isNaN(value) && value !== '') {
    form[name] = isFloat ? parseFloat(value) : parseInt(value, 10);
  }
}
