import { extractIntegerAfterHash } from './usefulFuncs';
import { replaceAll } from '../libs/nvstr-utils.es';

export function parseFullContextFilename(v) {
  if (!v) return v;
  return v.split('#')[0];
}

export function parseContextValue(v) {
  const [throwaway, header, body] = v.split('@@@ ');
  const filename = parseFullContextFilename(header);
  const chunkIndex = extractIntegerAfterHash(header);
  const text = body;
  return {
    filename,
    chunkIndex,
    text,
  };
}

export function checkIsContextHighlighted(contextItem, highlightContextItem) {
  if (!highlightContextItem) return false;

  const { chunkIndex, filename } = contextItem;
  const { chunkId, doc } = highlightContextItem;

  if (chunkIndex === chunkId && filename?.includes(doc.filename)) {
    return true;
  }
  if (chunkIndex === chunkId && filename?.includes(hackFilenameForAPI(doc.filename))) {
    return true;
  }
  return false;
}

export function formatContext(value) {
  if (!value) return [];

  return value?.split('###');
}

function removeConsecutiveWhitespaceReverse(str) {
  if (!str) return [];
  let results = [];
  let parts = str.split(/\s+/);

  for (let i = parts.length - 1; i >= 0; i--) {
    let trimmedString = parts.slice(i).join(' ').trim();
    results.push(trimmedString);
  }

  return results;
}

function compareFilenames(str1, str2) {
  const versions = removeConsecutiveWhitespaceReverse(str1);
  return versions.includes(str2);
}

function hackFilenameForAPI(filename) {
  return replaceAll(filename, '_', ' ');
}

function getAllFilenameContext(filename, formatted) {
  const items = [];

  formatted.forEach((line, i) => {
    const { filename: contextFilename, text } = parseContextValue(line);
    if (compareFilenames(contextFilename, hackFilenameForAPI(filename))) {
      items.push(text);
    }
  });
  return items;
}

export function getContextItem(item, context) {
  const formatted = formatContext(context);
  let found = null;
  formatted.forEach((line, i) => {
    const { filename, chunkIndex, text } = parseContextValue(line);
    const contextItem = { chunkIndex, filename };

    if (checkIsContextHighlighted(contextItem, item)) {
      const { filename, chunkIndex, text } = parseContextValue(line);
      found = {
        filename,
        chunkIndex,
        text,
      };
    }
  });

  if (found === null) {
    const { doc } = item;
    const filename = doc?.filename;
    const lines = getAllFilenameContext(filename, formatted);
    return { allContextData: lines };
  } else {
    // console.log('found', found);
  }

  return { contextData: found };
}
