import { ROUTES } from '../../../constants/routes';
import { DocumentIcon } from '../../../assets/icons/svgs/DocumentIcon';
import React from 'react';
import styled from 'styled-components';

const DocumentIconWrapper = styled.div`
  svg {
    margin-top: 2px;
    height: 16px;
    width: 16px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const DocWrapper = styled.a`
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  padding: 6px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    border-bottom: 1px solid transparent;
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }
`;

const DocTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  * {
    font-size: 14px;
    line-height: 18px;
  }
`;
const Name = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Ticker = styled.div`
  white-space: nowrap;
`;
const DocType = styled.div`
  width: 163px;
  white-space: nowrap;
`;
function Dot() {
  return <div style={{ fontSize: '30px', lineHeight: '18px' }}>&nbsp;&#183;&nbsp;</div>;
}
export const DocRow = ({ doc, onClick }) => {
  const {
    // id,
    // doc_type: docType,
    // doc_tag,
    security_name,
    filename,
    security_symbol,
    display_category,
  } = doc;
  const hc = () => {
    onClick(doc);
  };
  return (
    <DocWrapper onClick={hc}>
      <DocumentIconWrapper>
        <DocumentIcon />
      </DocumentIconWrapper>
      <DocTextWrapper>
        {!security_symbol ? (
          <>
            <Ticker>{filename}</Ticker>
          </>
        ) : (
          <>
            <Ticker>{security_symbol}&nbsp;-&nbsp;</Ticker>
            <Name>{security_name}</Name>
            <Dot />
            <DocType>{display_category}</DocType>
          </>
        )}
      </DocTextWrapper>
    </DocWrapper>
  );
};
