import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { logNetRequest, scrollToTop } from '../utils/usefulFuncs';
import { Body1, Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import { useColorTheme } from '../hooks';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { useWatchlist } from '../hooks/features/useWatchlist';
import { sendApiRequest } from '../services/api';
import { CloseButton } from '../components/buttons/CloseButton';
import { watchlistActions } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AddToWatchlist } from '../containers/companies/AddToWatchlist';
import { WatchlistImport } from '../containers/companies/WatchlistImport';
import { TrackingEvent } from '../libs/nvstr-utils.es';
import { showErrorBanner } from '../utils/application';
import { setItemToStorage, STORAGE_KEYS } from '../utils/storage';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const WatchlistWrapper = styled.div``;
const NameWrapper = styled.div`
  cursor: pointer;
`;
const QueryTickerWrapper = styled.div`
  cursor: pointer;
  margin-right: 16px;

  svg {
    height: 18px;
    width: 18px;
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const RemoveItemWrapper = styled.div`
  svg {
    height: 14px;
    width: 14px;
  }
`;
const WatchlistRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;

  border-bottom: ${({ theme }) => `1px solid ${theme.themeColors.lowContrastBorder}`};
`;

function WatchlistRow({ stock }) {
  const dispatch = useDispatch();

  const { symbol, name, company_id: id } = stock;

  const removeWatchlistItem = async () => {
    TrackingEvent.create('Remove Watchlist Row', {
      Ticker: symbol,
    });
    const form = {
      watchlist: [id],
    };
    const URL = `v1/genai_watchlist`;
    const { status, data, error } = await sendApiRequest('delete', URL, form);
    logNetRequest(URL, status, data);
    if (status === 200) {
      dispatch({
        type: watchlistActions.remove,
        payload: id,
      });
    } else {
      showErrorBanner('Something went wrong, please try again.', null, dispatch);
    }
  };

  const askDA = () => {
    TrackingEvent.create('Click Ask DA Watchlist Row', {
      Ticker: stock.symbol,
    });

    // const url = ROUTES.digitalAnalyst + '?cid=' + id;
    const url = ROUTES.digitalAnalyst + '?ticker=' + stock.symbol;
    window.open(url, '_blank');
  };

  const navigateToStockPage = () => {
    TrackingEvent.create('Click View Stock Page Watchlist Row', {
      Ticker: stock.symbol,
    });

    const text = `${symbol} - ${name}`;
    const param = encodeURIComponent(text);
    const url = ROUTES.stock + '?stock=' + param;
    window.open(url, '_blank');
  };

  return (
    <WatchlistRowWrapper>
      <NameWrapper onClick={navigateToStockPage}>
        <Body5>{stock.name}</Body5>
        <Body5> - </Body5>
        <Body5>{stock.symbol}</Body5>
      </NameWrapper>
      <Container row verticallyCenter>
        <QueryTickerWrapper onClick={askDA}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>Ask Digital Analyst</title>
            <path d="M12 3C6.5 3 2 6.6 2 11C2 13.2 3.1 15.2 4.8 16.5C4.8 17.1 4.4 18.7 2 21C4.4 20.9 6.6 20 8.5 18.5C9.6 18.8 10.8 19 12 19C17.5 19 22 15.4 22 11S17.5 3 12 3M12 17C7.6 17 4 14.3 4 11S7.6 5 12 5 20 7.7 20 11 16.4 17 12 17M12.2 6.5C11.3 6.5 10.6 6.7 10.1 7C9.5 7.4 9.2 8 9.3 8.7H11.3C11.3 8.4 11.4 8.2 11.6 8.1C11.8 8 12 7.9 12.3 7.9C12.6 7.9 12.9 8 13.1 8.2C13.3 8.4 13.4 8.6 13.4 8.9C13.4 9.2 13.3 9.4 13.2 9.6C13 9.8 12.8 10 12.6 10.1C12.1 10.4 11.7 10.7 11.5 10.9C11.1 11.2 11 11.5 11 12H13C13 11.7 13.1 11.5 13.1 11.3C13.2 11.1 13.4 11 13.6 10.8C14.1 10.6 14.4 10.3 14.7 9.9C15 9.5 15.1 9.1 15.1 8.7C15.1 8 14.8 7.4 14.3 7C13.9 6.7 13.1 6.5 12.2 6.5M11 13V15H13V13H11Z" />
          </svg>
        </QueryTickerWrapper>
        <RemoveItemWrapper>
          <CloseButton onClick={removeWatchlistItem} />
        </RemoveItemWrapper>
      </Container>
    </WatchlistRowWrapper>
  );
}

function Watchlist({ data: watchlist }) {
  React.useEffect(() => {
    TrackingEvent.create('View Watchlist', {
      'Stock Count': watchlist.length,
    });
  }, []);

  return (
    <WatchlistWrapper>
      {watchlist.length === 0 && <Body5>No companies are saved to your watchlist.</Body5>}
      {watchlist?.map((s) => (
        <WatchlistRow stock={s} />
      ))}
    </WatchlistWrapper>
  );
}

function WatchlistPage() {
  const colorTheme = useColorTheme();
  const navigate = useNavigate();
  const { isLoading, watchlist } = useWatchlist(true);

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    setItemToStorage(STORAGE_KEYS.SEEN_WATCHLIST, 'true');

    scrollToTop();
  }, []);

  if (isLoading) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page width={'600px'}>
            <BackArrowButton onClick={onGoBack} />
            <Container top={99} centerAll bottom={99}>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
              <Container left={8}>
                <Body5>Loading Watchlist...</Body5>
              </Container>
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page>
          <BackArrowButton onClick={onGoBack} />

          <Container top={38}>
            <Container>
              <Body1 bold>Watchlist</Body1>
              <Container top={8}>
                <Body5>
                  Maintain your watchlist to specify which companies you get alerts on. You can also filter the Recent
                  Company Updates page by your watchlist to see relevant documents.
                </Body5>
              </Container>
            </Container>
            <Container top={24}>
              <AddToWatchlist />
            </Container>
            <Container top={16}>
              <Container top={16} bottom={24}>
                <Watchlist data={watchlist} />
              </Container>
            </Container>
            <Container>
              <WatchlistImport />
            </Container>
          </Container>
        </Page>
      </Body>
    </PageWrapper>
  );
}

export default WatchlistPage;
