import React from 'react';
import styled, { withTheme } from 'styled-components';
import { colorPalette, SCREEN_SIZES, TrackingEvent } from '../../libs/nvstr-utils.es';
import { Body1, Body5, Column, LogoShort, Place, Row } from '../../libs/nvstr-common-ui.es';
import VerticalLogoFull from '../../assets/logos/VerticalLogoFull';
import { ROUTES } from '../../constants/routes';

const PATHS = {
  HOME: 'https://tornado.com',
  ABOUT: 'https://tornado.com/about',
  BLOG: 'https://tornado.com/blog',
  LEGAL: 'https://tornado.com/legal',

  CONTACT: 'https://tornado.com/contact',

  LINKEDIN: 'https://www.linkedin.com/company/tornadoinvesting',
  TWITTER: 'https://twitter.com/TornadoInvest',
  INSTAGRAM: 'https://www.instagram.com/TornadoInvest/',
};

const companyLinks = {
  heading: 'Company',
  links: [
    {
      text: 'Home',
      url: PATHS.HOME,
      name: 'Home',
    },
    {
      text: 'About',
      url: PATHS.ABOUT,
      name: 'About Us',
    },
    {
      text: 'Blog',
      url: PATHS.BLOG,
      name: 'Blog',
    },
  ],
};

const socialLinks = {
  heading: 'Social',
  links: [
    {
      text: 'LinkedIn',
      url: PATHS.LINKEDIN,
      name: 'LinkedIn',
    },
    {
      text: 'Twitter',
      url: PATHS.TWITTER,
      name: 'Twitter',
    },
    {
      text: 'Instagram',
      url: PATHS.INSTAGRAM,
      name: 'Instagram',
    },
  ],
};

const resourcesLinks = {
  heading: 'Resources',
  links: [
    {
      text: 'Contact & FAQ',
      url: ROUTES.faq,
      name: 'Contact/FAQ',
    },
    {
      text: 'Legal',
      url: PATHS.LEGAL,
      name: 'Legal',
    },
  ],
};

const Anchor = styled.a`
  text-decoration: ${(props) => props.textDecoration || 'none'};
  cursor: ${(props) => props.cursor || 'pointer'};
  user-select: none;
`;

const FooterWrapper = styled.div`
  background: ${colorPalette.primary.charcoal};
  position: relative;
  overflow: hidden;
  padding: 0 100px 48px 100px;

  h3 {
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    margin: 0;
  }

  * {
    color: ${colorPalette.primary.oak} !important;
  }

  @media (max-width: 1000px) {
    padding: 0 48px 48px 48px;
  }
  @media (max-width: 800px) {
    padding: 0 24px 24px 24px;
  }
  @media (max-width: 440px) {
    padding: 0 0 16px 0;
  }
`;
const Line = styled('div')`
  border-radius: ${(props) => props.borderRadius || '0px'};
  background: ${(props) => props.color || props.background || '#404040'};

  height: ${(props) => props.height || '1px'};
  width: ${(props) => props.width || '100%'};
  opacity: ${(props) => props.opacity || '1'};

  user-select: none;
`;

const DesktopFooterWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const VerticalLogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100px;
  z-index: 3;
`;

const FooterBody = styled.div`
  padding: 100px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 11;
`;
const FooterContentWrapper = styled.div`
  position: relative;
  z-index: 10;

  max-width: 1100px;
  margin: 0 auto;
`;

const HeaderLine = ({ isLowContrast, height }) => {
  const color = colorPalette.primary.oak;
  const opacity = isLowContrast ? 0.2 : 1;
  return <Line color={color} opacity={opacity} height={height} />;
};

const LinkColumn = ({ linkColumnData, isDarkMode }) => {
  const { heading, links } = linkColumnData;
  const logLinkClick = (name) => {
    TrackingEvent.create('Click Footer Link');
  };
  const bindNameToLogLinkClick = (name) => () => logLinkClick(name);
  return (
    <Place width="158px">
      <Row padding={'0 0 24px 0'}>
        <b>
          <Body5 isDarkMode={isDarkMode}>{heading}</Body5>
        </b>
      </Row>
      {links.map((link, i) => {
        return (
          <Row key={`${i}-${link.text}`} padding={i === 0 ? '0' : '14px 0 0 0'}>
            <a href={link.url} className={'no-text-decoration'}>
              <span onClick={bindNameToLogLinkClick(link.name)}>
                <Body1 isLarge isDarkMode={isDarkMode}>
                  {link.text}
                </Body1>
              </span>
            </a>
          </Row>
        );
      })}
    </Place>
  );
};

const FooterDisclosures = ({ isDarkMode, isMobile }) => {
  const desktopTextProps = {
    fontSize: '12px',
    textCase: 'none',
    isDarkMode,
    isLowContrast: true,
    isSmall: true,
  };
  const mobileTextProps = {
    fontSize: '10px',
    textCase: 'none',
    isDarkMode,
    isLowContrast: true,
    isSmall: true,
  };
  const textProps = isMobile ? mobileTextProps : desktopTextProps;
  return (
    <Column>
      <Row>
        <Body5 {...textProps}>
          By using this site you agree to our{' '}
          <Anchor textDecoration="underline" href="/terms_of_service">
            Terms of Service
          </Anchor>{' '}
          and{' '}
          <Anchor textDecoration="underline" href="/privacy_policy">
            Privacy Policy
          </Anchor>
          . Nvstr Financial LLC dba Tornado ("Tornado") is a wholly owned subsidiary of Nvstr Technologies Inc. The
          contents of this site are not investment advice. Brokerage services are offered by Tornado. IMPORTANT: The
          projections or other information generated by investment analysis tools regarding the likelihood of various
          investment outcomes are hypothetical in nature, do not reflect actual investment results and are not
          guarantees of future results. Investment analysis tool output, Community Thought Leader content, and Thought
          Leader ideas are not recommendations and should not be construed as such. Tornado does not endorse Thought
          Leaders, Community Thought Leaders, their content, or their positions in any way. A Thought Leader or
          Community Thought Leader&apos;s investment approach may be very different from yours, and their positions may
          not be appropriate for you. Thought Leader ideas are sourced from public information regarding a firm&apos;s
          recent positions. Please refer to the Terms of Service for more information. Investing in stocks is inherently
          risky; using margin may increase these risks.
        </Body5>
      </Row>
      <Row padding="15px 0 0 0">
        <Body5 {...textProps}>
          © 2023 Nvstr Technologies Inc. All rights reserved. The Tornado platform, including the functionality of its
          associated web page(s) and app(s), is protected under at least US Patent No. 11,138,667 as well as other
          patents pending.
        </Body5>
      </Row>
      <Row padding="15px 0 0 0">
        <Body5 {...textProps}>
          Tornado’s data is sourced from S&P Global Market Intelligence, Nasdaq, IEX, and Xignite. Tornado is a member
          of{' '}
          <Anchor textDecoration="underline" href="https://www.finra.org/">
            FINRA
          </Anchor>{' '}
          and{' '}
          <Anchor textDecoration="underline" href="https://www.sipc.org/">
            SIPC
          </Anchor>
          . Further information is available through{' '}
          <Anchor textDecoration="underline" href="https://brokercheck.finra.org/firm/summary/269966">
            FINRA’s BrokerCheck website
          </Anchor>{' '}
          and our{' '}
          <Anchor textDecoration="underline" href="https://tornado.com/crs">
            Form CRS
          </Anchor>
          .
        </Body5>
      </Row>
    </Column>
  );
};

const DesktopFooter = ({ isDarkMode }) => {
  return (
    <DesktopFooterWrapper>
      <VerticalLogoWrapper>
        <Place position="absolute" top="28px" left="4px" zIndex={1}>
          <VerticalLogoFull />
        </Place>
        <Place position="absolute" top="-730px" left="168px" zIndex={1}>
          <VerticalLogoFull />
        </Place>
        <Place position="absolute" top="680px" left="168px" zIndex={1}>
          <VerticalLogoFull />
        </Place>
      </VerticalLogoWrapper>
      <FooterContentWrapper>
        <FooterBody>
          <Place>
            <Column>
              <Row maxWidth="620px" padding="35px 0 0 0" wrap="wrap" style={{ justifyContent: 'space-between' }}>
                <Place padding="0 0 24px 0">
                  <LinkColumn isDarkMode={isDarkMode} linkColumnData={companyLinks} />
                </Place>
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={socialLinks} />
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={resourcesLinks} isLastItem />
              </Row>
            </Column>
          </Place>
        </FooterBody>
        <Row>
          <HeaderLine isDarkMode />
        </Row>
        <Row padding={'82px 0 0 0'}>
          <FooterDisclosures isDarkMode={isDarkMode} />
        </Row>
      </FooterContentWrapper>
    </DesktopFooterWrapper>
  );
};

export const MobileFooter = ({ isDarkMode }) => {
  const showTextAppLink = false;

  return (
    <Column padding="92px 7.5% 70px 7.5%" position="relative" overflow="hidden">
      <Place position="absolute" top="28px" left="4px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="absolute" top="-730px" left="168px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="absolute" top="680px" left="168px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="relative" zIndex={10}>
        <Column>
          <Row>
            <a href={PATHS.HOME}>
              <LogoShort width="59px" height="51px" isDarkMode={isDarkMode} />
            </a>
          </Row>
          <Row padding="32px 0 0 0"></Row>
          <Row padding="72px 0 0 0" maxWidth="424px" wrap="wrap" style={{ justifyContent: 'space-between' }}></Row>
          {showTextAppLink && (
            <>
              <Row padding={'72px 0 0 0'}>
                <Body5 isDarkMode={isDarkMode}>JOIN NOW</Body5>
              </Row>
              <Row padding={'36px 0 0 0'}>
                <Body5 isDarkMode={isDarkMode}>TEXT ME THE APP</Body5>
              </Row>
            </>
          )}
          <Column>
            <Row padding="68px 0 0 0" width="100%" wrap="wrap">
              <Place padding="0 0 68px 0">
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={companyLinks} />
              </Place>
              <Place padding="0 0 68px 0">
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={socialLinks} />
              </Place>

              <LinkColumn isDarkMode={isDarkMode} linkColumnData={resourcesLinks} />
            </Row>
          </Column>
          <Row padding={'64px 0 0 0'}>
            <HeaderLine isDarkMode={isDarkMode} />
          </Row>
          <Row padding={'64px 0 0 0'}>
            <FooterDisclosures isDarkMode={isDarkMode} isMobile />
          </Row>
        </Column>
      </Place>
    </Column>
  );
};

const Footer = ({ theme }) => {
  const { screenSize } = theme;
  const isMobile = screenSize === SCREEN_SIZES.mobile;
  return (
    <FooterWrapper>
      <HeaderLine isLowContrast height={'2px'} />
      {isMobile ? <MobileFooter /> : <DesktopFooter />}
    </FooterWrapper>
  );
};

export default withTheme(Footer);
