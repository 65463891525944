import React from 'react';
import FAQData from '../../documents/FAQ';

const ltIndicatorComponent = '';

const isOptimizerEnabled = true;
const isLiveTradingAvailable = true;

const actionTypes = {
  toggleQuestionState: 'toggleQuestionState',
  updateFaqSections: 'updateFaqSections',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.updateFaqSections: {
      const { orderedSections } = action.payload;
      return {
        ...state,
        isLoading: false,
        orderedSections,
      };
    }

    case actionTypes.toggleQuestionState: {
      const faqId = action.payload;
      if (state.expandedQuestions.includes(faqId)) {
        return {
          ...state,
          expandedQuestions: state.expandedQuestions.filter((id) => faqId !== id),
        };
      }

      return {
        ...state,
        expandedQuestions: [...state.expandedQuestions, faqId],
      };
    }

    default:
      return state;
  }
};

const { orderedSections } = FAQData({
  ltIndicatorComponent,
  isOptimizerEnabled,
  isLiveTradingAvailable,
});

const initialState = {
  isLoading: false,
  orderedSections,
  expandedQuestions: [],
};

const useFAQS = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isLoading, orderedSections } = state;

  return {
    isLoading,
    orderedSections,
  };
};

export default useFAQS;
