import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logFormSubmit, logNetRequest } from '../../utils/usefulFuncs';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { addOptionalField } from '../../utils/application';

const ACTIONS = {
  init: 'init',
  clear: 'clear',
  update: 'u',
};

const initState = {
  resultId: null,
  isSubmittingToApi: false,
  formError: null,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.init: {
      return { ...state, ...action.payload };
    }
    case ACTIONS.clear: {
      return { ...state, ...initState };
    }

    case ACTIONS.update: {
      return { ...state, ...action.payload };
    }

    default:
      throw new Error('missing case: useCustomPromptSeries');
  }
}

export const useCustomPromptSeries = (prompts, docList, settings) => {
  const [state, dispatch] = React.useReducer(reducer, initState, () => initState);

  React.useEffect(() => {
    let cancelStatus = { isCancelled: false };

    const onSubmit = async () => {
      const { model, enableSmoothing, maxContextLength, maxContextChunks, maxAnswerLength } = settings;

      dispatch({
        type: ACTIONS.update,
        payload: { prompts, docList },
      });

      const form = {
        question_list: prompts.map((p) => p.prompt),
        doc_list: docList.map((d) => d.id),
        model,
        streaming: true,
        user_mode: settings?.user_mode,
        include_context: true,
        enable_smoothing: enableSmoothing,
      };
      addOptionalField('max_context_chunks', maxContextChunks, form);
      addOptionalField('max_context_tokens', maxContextLength, form);
      addOptionalField('max_answer_tokens', maxAnswerLength, form);

      logFormSubmit(form);

      TrackingEvent.create('Asked Digital Analyst Question Series', {
        'Question List': prompts.map((p) => p.prompt),
        Model: model,
      });

      dispatch({
        type: ACTIONS.update,
        payload: {
          isSubmittingToApi: true,
          resultId: null,
          formError: null,
        },
      });

      const URL = `v1/genai_default_questions`;
      const { status, data, error } = await sendApiRequest('post', URL, form);
      handle400Statuses(status);
      logNetRequest(URL, status, data);
      if (cancelStatus.isCancelled) return;

      if (status === 200) {
        const { id } = data;
        dispatch({
          type: ACTIONS.update,
          payload: {
            isSubmittingToApi: false,
            resultId: id,
            formError: null,
          },
        });
        return true;
      } else {
        dispatch({
          type: ACTIONS.update,
          payload: {
            isSubmittingToApi: false,
            resultId: null,
            formError: data?.error || error || 'Something went wrong, try again.',
          },
        });
        return false;
      }
    };

    onSubmit();
    return () => {
      dispatch({
        type: ACTIONS.clear,
      });
      cancelStatus.isCancelled = true;
    };
  }, [prompts, docList, settings]);

  return state;
};
