import { newsActionTypes } from '../constants/actionTypes';

const defaultState = {
  articles: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case newsActionTypes.setNews: {
      return {
        articles: action.payload,
      };
    }

    default:
      return state;
  }
}
