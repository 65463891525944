import React from 'react';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { FlatButton } from '../../../components/buttons';
import { useAskDigitalAnalyst } from '../../../hooks/features/useAskDigitalAnalyst';
import { QuestionResult } from './QuestionResult';
import { useAdvancedSettings } from '../../../hooks/application/useAdvancedSettings';

const Wrapper = styled.div`
  padding: 0 16px 16px 24px;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -4px;
  right: 140px;

  button {
    padding: 5px 16px 6px 16px;
  }
`;

export const MoreDetailCompanySnapshotButton = ({ onClick }) => {
  return (
    <ButtonWrapper>
      <FlatButton onClick={onClick}>Get More Detail</FlatButton>
    </ButtonWrapper>
  );
};
export const MoreDetailCompanySnapshot = ({ question, ticker }) => {
  const {
    // prevUsedModel,
    // questionAsked,
    onSubmit,

    resultId,
    formError,
    isSubmittingToApi,
  } = useAskDigitalAnalyst();

  const questionAsked = '';
  const { model, includeUserDocs } = useAdvancedSettings();

  React.useEffect(() => {
    const moreDetailQuestion = `For the question "${question}", please focus on responding with the most detail possible that would be interesting to a stock analyst and written in the style of the Wall Street Journal.`;
    onSubmit({ ticker, question: moreDetailQuestion, model });
  }, []);

  return (
    <Wrapper>
      <Body5 bold>More Detail</Body5>
      <Container top={0}>
        {resultId || isSubmittingToApi ? (
          <Container>
            <QuestionResult
              isSubmittingToApi={isSubmittingToApi}
              resultId={resultId}
              question={null}
              collapseHeight
              hideRating
            />
          </Container>
        ) : null}
      </Container>
    </Wrapper>
  );
};
