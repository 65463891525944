import React from 'react';
import styled from 'styled-components';
import { LogoFull } from '../../libs/nvstr-common-ui.es';
import { useCustomClient } from '../../hooks/application/useCustomClient';
import { COLOR_THEME_NAMES } from '../../constants/app';

export const TornadoLogoWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
export const CustomLogoWrapper = styled.div`
  svg {
    path {
      ${({ theme }) => (theme.colorThemeName === COLOR_THEME_NAMES.darkProfessional ? 'fill: #fff !important;' : '')};
    }
  }
`;

const forceLogo = null;
/*
`
`;
*/

const CustomLogo = ({ logo }) => {
  return <CustomLogoWrapper dangerouslySetInnerHTML={{ __html: logo }}></CustomLogoWrapper>;
};

export const FullLogo = (props) => {
  const { isLoading, logo } = useCustomClient();

  if (props.forceTornado) {
    return (
      <TornadoLogoWrapper>
        <LogoFull {...props} />
      </TornadoLogoWrapper>
    );
  }

  if (isLoading) return null;

  if (logo || forceLogo) {
    return <CustomLogo logo={logo || forceLogo} />;
  }
  return (
    <TornadoLogoWrapper>
      <LogoFull {...props} />
    </TornadoLogoWrapper>
  );
};
