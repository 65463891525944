import { Body1, Container, LoadingSpinner as _Spinner } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';

const Wrapper = styled.div`
  svg {
    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const SpinnerWrapper = styled.svg`
  box-sizing: border-box;
  width: 45px;
  height: 45px;

  display: block;
  overflow: visible;
  animation-name: spin-cycle;

  @keyframes spin-cycle {
    from {
      fill: ${({ themeColorName, theme }) => theme.themeColors[themeColorName ? themeColorName : 'primaryCtaButton']};
      transform: scale(1);
    }
    to {
      fill: rgba(255, 255, 255, 0);
      transform: scale(1);
    }
  }

  & > * {
    transform: translate3d(0, 0, 0);
    transform-origin: center;
    animation-iteration-count: infinite;
    fill: rgba(255, 255, 255, 0.2);
    animation-name: spin-cycle;
    animation-duration: 0.8s;
  }

  & > *:nth-of-type(8) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  & > *:nth-of-type(7) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  & > *:nth-of-type(6) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  & > *:nth-of-type(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  & > *:nth-of-type(4) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  & > *:nth-of-type(3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;

    & > *:nth-of-type(2) {
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s;
    }

    & > *:nth-of-type(1) {
      animation-delay: 0.8s;
    }
  }
`;

const RawSpinner = () => {
  return (
    <SpinnerWrapper viewBox="156 -189 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M636 99h-64c-17.7 0-32-14.3-32-32s14.3-32 32-32h64c17.7 0 32 14.3 32 32S653.7 99 636 99z" />
      <path d="M547.8-23.5C535.2-11 515-11 502.5-23.5s-12.5-32.8 0-45.2l45.2-45.2c12.5-12.5 32.8-12.5 45.2 0s12.5 32.8 0 45.2L547.8-23.5z" />
      <path d="M412-61c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32s32 14.3 32 32v64C444-75.3 429.7-61 412-61z" />
      <path d="M276.2-23.5L231-68.8c-12.5-12.5-12.5-32.8 0-45.2s32.8-12.5 45.2 0l45.2 45.2c12.5 12.5 12.5 32.8 0 45.2S288.8-11 276.2-23.5z" />
      <path d="M284 67c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32s14.3-32 32-32h64C269.7 35 284 49.3 284 67z" />
      <path d="M276.2 248c-12.5 12.5-32.8 12.5-45.2 0 -12.5-12.5-12.5-32.8 0-45.2l45.2-45.2c12.5-12.5 32.8-12.5 45.2 0s12.5 32.8 0 45.2L276.2 248z" />
      <path d="M412 323c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32s32 14.3 32 32v64C444 308.7 429.7 323 412 323z" />
      <path d="M547.8 157.5l45.2 45.2c12.5 12.5 12.5 32.8 0 45.2 -12.5 12.5-32.8 12.5-45.2 0l-45.2-45.2c-12.5-12.5-12.5-32.8 0-45.2S535.2 145 547.8 157.5z" />
    </SpinnerWrapper>
  );
};

export function Spinner({ text }) {
  return (
    <>
      <RawSpinner />
      {text && (
        <Container top={8}>
          <Body1 bold>{text}</Body1>
        </Container>
      )}
    </>
  );
}

export default function LoadingSpinner({ text }) {
  return (
    <Wrapper>
      <_Spinner />
      {text && (
        <Container top={8}>
          <Body1 bold>{text}</Body1>
        </Container>
      )}
    </Wrapper>
  );
}
