import styled from 'styled-components';
import ChevronUp from '../../assets/icons/svgs/ChevronUp';
import ChevronDown from '../../assets/icons/svgs/ChevronDown';
import React from 'react';

const IconWrapper = styled.div`
  padding-bottom: 8px;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const CollapseButton = ({ isCollapsed, setCollapsed }) => {
  if (isCollapsed) {
    return (
      <IconWrapper onClick={() => setCollapsed(false)}>
        <ChevronDown />
      </IconWrapper>
    );
  } else {
    return (
      <IconWrapper onClick={() => setCollapsed(true)}>
        <ChevronUp />
      </IconWrapper>
    );
  }
};
