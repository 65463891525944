import React from 'react';
import { FlatButton } from '../../components/buttons';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import styled from 'styled-components';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';
import { logger } from '../../utils/usefulFuncs';

const ConfirmationModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  height: 100vh;
  width: 100vw;

  background: ${({ theme }) => convertHexToRGBA(theme.colorPalette.primary.charcoal, 0.6)};

  z-index: 1000;
`;
const ConfirmationModalBodyWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  padding: 24px;
  border-radius: 5px;
  width: 90%;
  max-width: 420px;
`;
const Actions = styled.div`
  padding-top: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 100%;

  button {
    width: 140px;
  }
`;

export const useConfirmationModal = (handleConfirmCallback) => {
  const [ConfirmationModal, setConfirmationModal] = React.useState(null);

  const reset = () => {
    setConfirmationModal(null);
  };

  const handleConfirmation = (boundedParams) => () => {
    handleConfirmCallback(boundedParams);
    reset();
  };

  const handleCancel = () => {
    reset();
  };

  const onShowModal = (ModalBody, bindParams) => {
    const component = (
      <ConfirmationModalWrapper>
        <ConfirmationModalBodyWrapper>
          {ModalBody}
          <Actions>
            <FlatButton onClick={handleConfirmation(bindParams)}>Yes</FlatButton>
            <SkeletonButton onClick={handleCancel}>No</SkeletonButton>
          </Actions>
        </ConfirmationModalBodyWrapper>
      </ConfirmationModalWrapper>
    );
    setConfirmationModal(component);
  };

  return {
    ConfirmationModal,
    onShowModal,
  };
};
