import React, { useMemo } from 'react';
import { logNetRequest } from '../../utils/usefulFuncs';
import { useSearchParams } from 'react-router-dom';
import { sendApiRequest } from '../../services/api';

export const usePreselectedTickers = () => {
  const [searchParams] = useSearchParams();

  const [complete, setComplete] = React.useState(false);
  const [tickers, setTickers] = React.useState([]);

  const [retrieveCompanyIds, setRetrieveCompanyIds] = React.useState(null);
  const [tickerParam, setTickerParam] = React.useState(null);

  const fetchTickerData = async (id) => {
    // let URL = `v1/genai_tickers_search?company_id=${id}`;
    let URL = `v1/genai_tickers_search?query=${id}`;
    const { status, data, error } = await sendApiRequest('get', URL);
    logNetRequest(status, data, id);
    if (status === 200) {
      let found = null;
      data.forEach((r) => {
        if (r.symbol === id.toUpperCase()) {
          found = r;
        }
      });
      if (found !== null) {
        setTickers([tickerParam.toUpperCase()]);
        setComplete(true);
      } else {
        setComplete(true);
      }
    }
    return null;
  };

  React.useEffect(() => {
    if (retrieveCompanyIds) {
      Promise.all(retrieveCompanyIds.map((id) => fetchTickerData(id))).then((responses) => {
        const tickers = responses.filter((r) => r !== null).map((r) => r.symbol);
        setTickers(tickers);
        setComplete(true);
      });
    }
  }, [retrieveCompanyIds]);

  React.useEffect(() => {
    if (tickerParam !== null) {
      fetchTickerData(tickerParam);
    }
  }, [tickerParam]);

  React.useEffect(() => {
    const checkForTickerParams = () => {
      const param = searchParams.get('ticker');
      if (param) {
        setTickerParam(param.split(',')[0]);
      } else {
        setComplete(true);
      }
    };
    checkForTickerParams();

    // const checkForParams = () => {
    //   const param = searchParams.get('cid');
    //   if (param) {
    //     const nonDups = filterDuplicates(param.split(','));
    //     setRetrieveCompanyIds(nonDups);
    //   } else {
    //     setComplete(true);
    //   }
    // };
    // checkForParams();
  }, []);

  return useMemo(() => {
    return {
      tickers,
      complete,
    };
  }, [tickers, complete]);
};
