import React from 'react';
import styled from 'styled-components';
import { Body5, Container, Page, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../hooks';
import { CollapseButton } from '../../components/buttons/CollapseButton';
import { useUploadStatus } from '../../hooks/features/useUploadStatus';

const Wrapper = styled.div`
  padding-top: 16px;
`;
const SectionWrapper = styled.div`
  padding: 0 16px 16px 16px;
`;
const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const SectionHeadingWrapper = styled.div`
  padding: 2px 0 8px 0;
  text-transform: uppercase;

  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
`;
const DocRowWrapper = styled.tr`
  padding: 8px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;
const DocRowCell = styled.td`
  padding: 8px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const DocRowPending = ({ doc }) => {
  const colorTheme = useColorTheme();
  return (
    <DocRowWrapper>
      <DocRowCell style={{ width: '100%' }}>
        <Body5>{doc.title}</Body5>
      </DocRowCell>
      <DocRowCell>
        <Container row verticallyCenter left={24} right={8}>
          <Container right={8}>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} color={colorTheme.border} />
          </Container>
          <Body5 isLowContrast>Processing...</Body5>
        </Container>
      </DocRowCell>
    </DocRowWrapper>
  );
};

const DocRowCompleted = ({ doc }) => {
  // const colorTheme = useColorTheme();
  return (
    <DocRowWrapper>
      <DocRowCell style={{ width: '100%' }}>
        <Body5>{doc.title}</Body5>
      </DocRowCell>
      <DocRowCell style={{ width: '100%' }}>
        <Body5></Body5>
      </DocRowCell>
    </DocRowWrapper>
  );
};

const Section = ({ name, docs, startingOpen }) => {
  const [collapsed, setCollapsed] = React.useState(!startingOpen);

  if (!docs || docs.length === 0) {
    return null;
  }

  return (
    <SectionWrapper>
      <SectionHeading>
        <SectionHeadingWrapper>{name}</SectionHeadingWrapper>
        <CollapseButton isCollapsed={collapsed} setCollapsed={setCollapsed} />
      </SectionHeading>
      {collapsed ? null : (
        <table>
          {docs.map((d) =>
            name === 'Completed' ? (
              <DocRowCompleted key={d.id} doc={d} />
            ) : name === 'Errored' ? (
              <DocRowCompleted key={d.id} doc={d} />
            ) : (
              <DocRowPending key={d.id} doc={d} />
            )
          )}
        </table>
      )}
    </SectionWrapper>
  );
};

export function DocumentUploadStatus() {
  const colorTheme = useColorTheme();
  const { isInitializing, data } = useUploadStatus();

  if (isInitializing) {
    return (
      <Page>
        <Wrapper>
          <Container row verticallyCenter>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            <Container left={8}>
              <Body5>Checking document uploads...</Body5>
            </Container>
          </Container>
        </Wrapper>
      </Page>
    );
  }

  const { completed, pending, errored } = data;

  if (completed.length === 0 && pending.length === 0 && errored.length === 0) {
    return null;
    return (
      <Page>
        <Wrapper>
          <Container bottom={80}>
            <Body5>You have no newly initiated manual uploads.</Body5>
          </Container>
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page>
      <Wrapper>
        <Section name={'Pending'} docs={pending} startingOpen />
        <Section name={'Completed'} docs={completed} />
        <Section name={'Errored'} docs={errored} startingOpen />
      </Wrapper>
    </Page>
  );
}
