import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { useRecentNewsContextData } from './useRecentDocsAndNews';
import { logFormSubmit } from '../../utils/usefulFuncs';

const ACTIONS = {
  init: 'init',
  update: 'u',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.init: {
      return action.payload;
    }

    case ACTIONS.update: {
      const section = action.payload;
      const nextState = [];
      state?.forEach((s) => {
        if (s.heading !== section.heading) {
          nextState.push(s);
        } else {
          nextState.push(section);
        }
      });
      return nextState;
    }

    default:
      throw new Error();
  }
}

export const useMultiplePrompts = (docs, sections, model, day, tool) => {
  const [state, dispatch] = React.useReducer(reducer, sections, () => sections);

  const newsContext = useRecentNewsContextData(day);

  React.useEffect(() => {
    const fetch = async (section, model) => {
      const { prompt, heading } = section;

      const form = {
        question: prompt,
        model,
        user_mode: tool,
        doc_list: docs.map((d) => d.id),
        need_context: true,
      };

      if (newsContext?.length > 0) {
        form.extra_context = newsContext;
      }
      logFormSubmit(form);
      const URL = `v1/genai_qa/stream`;
      const response = await sendApiRequest('post', URL, form);
      handle400Statuses(response?.status);

      if (response?.status === 200) {
        dispatch({
          type: ACTIONS.update,
          payload: {
            ...section,
            resultId: response?.data?.id,
          },
        });
      }
    };

    if (docs) {
      sections.forEach((s, i) => {
        setTimeout(() => {
          fetch(s, model);
        }, 1 + i * 700);
      });
    }
  }, [model, docs, newsContext]);

  return state;
};
