import styled from 'styled-components';
import { useColorTheme } from '../../../hooks';
import React from 'react';
import { TransparentFlatButton } from '../../../components/buttons';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import { ResultOnly } from './AnswerLine';

const ContextCTAWrapper = styled.div`
  position: relative;
  padding-top: 16px;
`;
const ContextResultWrapper = styled.div`
  position: relative;
  padding-top: 16px;
`;
const ContextButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const HideSourcesWrapper = styled.div`
  position: absolute;
  bottom: -38px;
  right: 0;
`;

export const Context = ({ result }) => {
  const colorTheme = useColorTheme();
  const [show, setShow] = React.useState(false);

  const hc = () => {
    setShow(true);
  };
  const handleHide = () => {
    setShow(false);
  };

  if (!show) {
    return (
      <ContextCTAWrapper>
        <ContextButtonWrapper>
          <TransparentFlatButton onClick={hc}>See Sources</TransparentFlatButton>
        </ContextButtonWrapper>
      </ContextCTAWrapper>
    );
  }

  if (result === 'Working...' || !result) {
    return (
      <Container top={16}>
        <Container verticallyCenter bottom={24}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={12}>
            <Body5>Working...</Body5>
          </Container>
        </Container>
      </Container>
    );
  }
  return (
    <ContextResultWrapper>
      <ResultOnly result={result} />
      <HideSourcesWrapper>
        <TransparentFlatButton onClick={handleHide}>Hide Sources</TransparentFlatButton>
      </HideSourcesWrapper>
    </ContextResultWrapper>
  );
};
