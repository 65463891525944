import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../libs/nvstr-common-ui.es';
import { TransparentFlatButton } from '../../../components/buttons';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import SelectedDocument from './SelectedDocument';

const Wrapper = styled.div``;
const SelectedDocsRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
const AddAllButtonWrapper = styled.div`
  padding: 3px 0 0 8px;

  button {
    height: 42px;
    padding: 2px 16px;
  }
`;
const RemoveAllButtonWrapper = styled.div`
  padding: 3px 0 0 8px;

  button {
    padding: 2px 16px;
  }
`;
const DocSelectionActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SelectedDocuments = ({ selectedDocs, onSelectDocumentsClick, onAddAllDocs, onSelectedDocsChange }) => {
  const clearDocs = () => onSelectedDocsChange([]);
  const handleRemoveClick = (filenameOrFilenames) => {
    if (Array.isArray(filenameOrFilenames)) {
      onSelectedDocsChange(selectedDocs.filter((d) => !filenameOrFilenames.includes(d.filename)));
    } else {
      onSelectedDocsChange(selectedDocs.filter((d) => d.filename !== filenameOrFilenames));
    }
  };

  return (
    <Wrapper>
      {selectedDocs.length > 0 ? (
        <Container bottom={24}>
          <SelectedDocsRowWrapper>
            {selectedDocs?.map((d) => (
              <SelectedDocument key={d?.id} doc={d} handleRemoveClick={handleRemoveClick} selectedDocs={selectedDocs} />
            ))}
          </SelectedDocsRowWrapper>
        </Container>
      ) : null}
      <DocSelectionActionsWrapper>
        {!!onSelectDocumentsClick && <SkeletonButton onClick={onSelectDocumentsClick}>Select Documents</SkeletonButton>}
        {onAddAllDocs && (
          <AddAllButtonWrapper>
            <TransparentFlatButton onClick={onAddAllDocs}>Add All</TransparentFlatButton>
          </AddAllButtonWrapper>
        )}
        {selectedDocs.length > 0 && (
          <RemoveAllButtonWrapper>
            <TransparentFlatButton onClick={clearDocs}>Remove All</TransparentFlatButton>
          </RemoveAllButtonWrapper>
        )}
      </DocSelectionActionsWrapper>
    </Wrapper>
  );
};
