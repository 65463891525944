import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { ExtraWideBody } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { scrollToTop } from '../utils/usefulFuncs';
import { useColorTheme } from '../hooks';
import { CSVUploader } from '../containers/DigitalAnalyst/CSVUploader';
import { useBuildChartVisualizationData } from '../utils/dataVisualization';
import { SkeletonButton } from '../components/buttons/SkeletonButton';
import { Modal } from '../components/layout/Modal';
import { ModalCloseButton } from '../components/buttons/ModalCloseButton';
import { File } from '../components/UI/File';
import { FactorVisualizationScatterplot } from '../containers/DigitalAnalyst/FactorVisualizationScatterplot';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const VisualizationWrapper = styled.div`
  overflow: scroll;
  padding: 24px;
`;

function Page() {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <ExtraWideBody withFooter collapseHeight>
        <Container>
          <BackArrowButton onClick={onGoBack} left={'36px'} />
        </Container>
        <VisualizationWrapper>
          <FactorVisualizationChart />
        </VisualizationWrapper>
      </ExtraWideBody>
    </PageWrapper>
  );
}

const Wrapper = styled.div``;

const ContentsWrapper = styled.div``;

const FileVisualization = React.memo(({ file, onShowModal, onDismissModal, onRemoveFile }) => {
  const colorTheme = useColorTheme();
  const data = useBuildChartVisualizationData(file);

  if (data === null) {
    return (
      <ContentsWrapper>
        <Container>
          <File file={file} onRemove={onRemoveFile} />
        </Container>
        <Container top={16}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
        </Container>
      </ContentsWrapper>
    );
  }

  const { prompt } = data;

  return (
    <ContentsWrapper>
      <Container>
        <File file={file} onRemove={onRemoveFile} />
      </Container>
      <Container>
        <Container top={24} left={16}>
          <Body5>{prompt}</Body5>
        </Container>
      </Container>
      <Container top={24} bottom={24}>
        <FactorVisualizationScatterplot data={data} onShowModal={onShowModal} onDismissModal={onDismissModal} />
      </Container>
    </ContentsWrapper>
  );
});

function FactorVisualizationChart() {
  const colorTheme = useColorTheme();
  const isLoading = false; // when API is ready
  const [files, setFiles] = React.useState([]);

  const [isAddFileActive, setIsAddFileActive] = React.useState(false);
  const [modalText, setModalText] = React.useState(null);

  const onUpload = React.useCallback((d) => {
    if (files.length > 0) {
      setFiles([...files, ...d]);
    } else {
      setFiles(d);
    }

    setIsAddFileActive(false);
  }, []);

  const onAddFileClick = React.useCallback(() => {
    setIsAddFileActive(true);
  }, []);

  const onRemoveFile = React.useCallback((d) => {
    const fileName = d.name;
    setFiles(files.filter((f) => f.name !== fileName));
  }, []);
  const onShowModal = React.useCallback((v) => {
    setModalText(v);
  }, []);
  const onDismissModal = React.useCallback(() => setModalText(null), []);

  if (isLoading) {
    return (
      <Wrapper>
        <Container row centerAll bottom={200} top={96}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={8}>
            <Body5>Loading Data...</Body5>
          </Container>
        </Container>
      </Wrapper>
    );
  }

  if (files.length === 0) {
    return (
      <Wrapper>
        <Container top={48}>
          <h3>Upload Files</h3>
        </Container>
        <CSVUploader files={files} onUpload={onUpload} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isAddFileActive ? (
        <Container top={48} bottom={48}>
          <Container>
            <h3>Upload Files</h3>
          </Container>
          <CSVUploader files={files} onUpload={onUpload} />
        </Container>
      ) : (
        <Container>
          <Container top={48} bottom={24}>
            <SkeletonButton onClick={onAddFileClick}>+ Add File</SkeletonButton>
          </Container>
        </Container>
      )}
      {modalText && (
        <Modal onDismiss={onDismissModal}>
          <ModalCloseButton onClick={onDismissModal} />
          <Container vertical={24}>
            <Body5>{modalText}</Body5>
          </Container>
          <Container textCenter>
            <SkeletonButton fullWidth onClick={onDismissModal}>
              Dismiss
            </SkeletonButton>
          </Container>
        </Modal>
      )}
      {files.map((file, i) => {
        return (
          <Container key={i} top={24} style={{ display: 'contents' }}>
            <FileVisualization
              file={file}
              onRemoveFile={onRemoveFile}
              onShowModal={onShowModal}
              onDismissModal={onDismissModal}
            />
          </Container>
        );
      })}
    </Wrapper>
  );
}

export default Page;
