import { Body1, Body5, Container } from '../../../libs/nvstr-common-ui.es';
import React from 'react';
import styled, { css } from 'styled-components';
import { convertHexToRGBA } from '../../../libs/nvstr-utils.es';
import { DOVISH_COLOR, HAWKISH_COLOR } from '../../../constants';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import { Modal } from '../../../components/layout/Modal';
import { CloseButton } from '../../../components/buttons/CloseButton';

const SentenceWrapper = styled.div`
  position: relative;

  ${({ Score, theme }) =>
    css`
      display: inline;
      border: 1px solid ${({ theme }) => theme.themeColors.component};

      font-size: 14px;
      line-height: 20px;

      &:hover {
        border: 1px solid ${({ theme }) => theme.themeColors.text};
        border-radius: 1px;
      }

      background: ${Score === 0
        ? 'transparent'
        : Score > 0
        ? convertHexToRGBA(HAWKISH_COLOR, Score)
        : convertHexToRGBA(DOVISH_COLOR, Score * -1)};
    `}

  ${({ $IsHighlighted }) =>
    $IsHighlighted
      ? css`
          border: 3px solid ${({ theme }) => theme.themeColors.text};
          border-radius: 1px;
        `
      : ''}
`;

const HoverStatsCloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    height: 14px;
    width: 14px;
  }
  path {
    fill: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;
const HoverStatsWrapper = styled.div`
  position: absolute;
  top: -38px;
  right: 0px;
  min-width: 110px;
  text-align: center;

  padding: 8px 4px 8px 4px;
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};

  color: ${({ theme }) => theme.themeColors.buttonText} !important;
  z-index: ${({ $ForceTop }) => ($ForceTop ? 10 : 5)} !important;

  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;

const HoverStats = ({ index, enabled, score, showSetScoreCTA, onSetScoreClick, dismissHighlight }) => {
  if (!enabled) {
    return null;
  }

  const handleSetScore = () => {
    onSetScoreClick(index);
  };

  return (
    <HoverStatsWrapper $ForceTop={showSetScoreCTA}>
      {showSetScoreCTA && (
        <HoverStatsCloseButtonWrapper>
          <CloseButton onClick={dismissHighlight} />
        </HoverStatsCloseButtonWrapper>
      )}
      <Body5 bold>Score: {score}</Body5>
      {showSetScoreCTA && (
        <Container top={16}>
          <TransparentFlatButton onClick={handleSetScore}>Set Score</TransparentFlatButton>
        </Container>
      )}
    </HoverStatsWrapper>
  );
};

const generateScores = () => {
  const increment = 0.1;
  const startingValue = -1;
  const endingValue = 1;
  const scores = [];
  let v = startingValue;
  while (v <= endingValue) {
    scores.push(parseFloat(v.toFixed(1)));
    v = v + increment;
  }
  return scores;
};

const ScoreValuesWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr; /* Adjust sizes as needed */
`;
const ScoreSelectButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-bottom: 8px;

  height: 28px;
  width: 140px;

  background: transparent;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
  cursor: pointer;

  transition: all 150ms;

  &:hover {
    * {
      color: ${({ theme }) => theme.themeColors.buttonText} !important;
    }
    background: ${({ theme }) => theme.themeColors.text};
  }

  &.selected {
    * {
      color: ${({ theme }) => theme.themeColors.buttonText} !important;
    }
    background: ${({ theme }) => theme.themeColors.text};
  }
`;
const ScoreSelectColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 28px;
  margin-bottom: 8px;
`;

const lookup = {
  [-1]: 'Dovish',
  [0]: 'Neutral',
  [1]: 'Hawkish',
};
const ScoreSelectColumn = ({ value }) => {
  const key = lookup[value];
  if (!key) {
    return <ScoreSelectColumnWrapper></ScoreSelectColumnWrapper>;
  }
  return (
    <ScoreSelectColumnWrapper>
      <Body5>{key}</Body5>
    </ScoreSelectColumnWrapper>
  );
};
const ScoreSelectButton = ({ value, isSelected, onSelect }) => {
  return (
    <ScoreSelectButtonWrapper className={isSelected ? 'selected' : ''} onClick={() => onSelect(value)}>
      <Body5 bold>{value}</Body5>
    </ScoreSelectButtonWrapper>
  );
};
const ScoreValues = ({ value, onSelect }) => {
  const [scores] = React.useState(generateScores());

  return (
    <ScoreValuesWrapper>
      <Container>
        {scores.map((s) => (
          <ScoreSelectColumn key={s} value={s} />
        ))}
      </Container>
      <Container>
        {scores.map((s) => (
          <ScoreSelectButton key={s} value={s} isSelected={s === value} onSelect={onSelect} />
        ))}
      </Container>
    </ScoreValuesWrapper>
  );
};

const SetScoreModal = ({ score, statement, index, setUserScore, onDismiss }) => {
  const [selectedScore, setSelectedScore] = React.useState(null);

  const onScoreSelect = (v) => {
    setSelectedScore(v);
  };

  const onSubmit = () => {
    setUserScore(selectedScore);
    onDismiss();
  };

  return (
    <Modal modalProps={{ noPadding: true }}>
      <Container>
        <Container row verticallyCenter spaceBetween>
          <Container right={48}>
            <Body1 bold>Set Hawkish/Dovish Score</Body1>
          </Container>
          <CloseButton onClick={onDismiss} />
        </Container>
        <Container top={16}>
          <Body5 bold>Previous Score: {score}</Body5>
        </Container>

        <Container top={24} right={24}>
          <Body5>{statement}</Body5>
        </Container>

        <Container top={24}>
          <ScoreValues value={selectedScore} onSelect={onScoreSelect} />
        </Container>

        <Container top={24} style={{ textAlign: 'center' }}>
          <FlatButton fullWidth onClick={onSubmit}>
            Submit
          </FlatButton>
        </Container>
      </Container>
    </Modal>
  );
};

export const SentimentSentence = ({ data, index, isHighlighted, enableHoverStats }) => {
  const ref = React.useRef(null);

  const [lockHighlight, setLockHighlight] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const [isSettingScore, setIsSettingScore] = React.useState(false);
  const [forcedScore, setForcedScore] = React.useState(null);

  const { statement, speaker, score, statement_index } = data;
  const userScore = forcedScore === null ? score : forcedScore;

  React.useEffect(() => {
    if (isHighlighted) {
      const element = ref.current;
      if (element) {
        // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [isHighlighted]);

  const handleHoverStart = () => {
    setIsHovering(true);
  };
  const handleSentenceClick = () => {
    if (lockHighlight) {
      setIsHovering(false);
      setLockHighlight(false);
    } else {
      setIsHovering(false);
      setLockHighlight(true);
    }
  };

  const onSetScoreClick = () => {
    setIsSettingScore(true);
  };

  return (
    <>
      {isSettingScore && (
        <SetScoreModal
          score={userScore}
          index={index}
          statement={statement}
          setUserScore={setForcedScore}
          onDismiss={() => setIsSettingScore(false)}
        />
      )}
      <SentenceWrapper
        id={'statement-' + index}
        $IsHighlighted={isHighlighted || lockHighlight}
        ref={ref}
        Score={parseFloat(userScore)}
        onClick={handleSentenceClick}
        onMouseEnter={handleHoverStart}
        onMouseLeave={() => setIsHovering(false)}
      >
        <HoverStats
          index={index}
          enabled={(isHovering || lockHighlight) && enableHoverStats}
          dismissHighlight={() => setLockHighlight(false)}
          score={userScore}
          // showSetScoreCTA={lockHighlight}
          onSetScoreClick={onSetScoreClick}
        />
        <Body5> </Body5>
        <Body5>{statement}</Body5>
      </SentenceWrapper>
    </>
  );
};
