import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { ScreenerTool } from '../containers/DigitalAnalyst/ScreenerTool';
import { scrollToTop } from '../utils/usefulFuncs';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;

function ResearchScreener() {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <ScreenerTool onGoBack={onGoBack} isResearchMode />
      </Body>
    </PageWrapper>
  );
}

export default ResearchScreener;
