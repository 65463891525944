import React from 'react';
import styled, { withTheme } from 'styled-components';

const Base = styled('button')`
  cursor: pointer;
  user-select: none;
  transition: color 300ms;
  color: ${(props) => props.hoverColor};
  opacity: ${(props) => props.opacity};
  background: transparent;
  border: none;

  div {
    color: ${(props) => props.color};
    opacity: ${(props) => props.opacity};
  }

  &:hover {
    color: ${(props) => props.hoverColor};
    opacity: ${(props) => props.hoverOpacity};

    div {
      color: ${(props) => props.hoverColor};
      opacity: ${(props) => props.hoverOpacity};
    }
  }
`;

const TextButton = (props) => {
  const { theme, children, isDarkMode, isLowContrast, onClick } = props;
  const { themes } = theme;
  const lightTheme = themes['light-professional'];
  const darkTheme = themes['dark-professional'];

  const themedTextColor = isDarkMode ? darkTheme.text : lightTheme.text;
  const themedHoverColor = themedTextColor;
  const opacity = isLowContrast ? 0.8 : 1;
  return (
    <Base color={themedTextColor} hoverColor={themedHoverColor} opacity={opacity} hoverOpacity={1} onClick={onClick}>
      {children}
    </Base>
  );
};

export default withTheme(TextButton);
