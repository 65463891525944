import { handle400Statuses, sendApiRequest } from './api';
import { docsActionTypes } from '../constants/actionTypes';

export const fetchMacroDocs = () => {
  return async (dispatch) => {
    const response = await sendApiRequest('get', 'v1/genai_tickers_filenames?topic=macro', null, null);
    const { status, data } = response;
    handle400Statuses(status);
    if (status === 200 && data && typeof data === 'object') {
      const macroDocs = data['0_FED'] || [];
      if (Array.isArray(macroDocs)) {
        dispatch({
          type: docsActionTypes.setMacroDocs,
          payload: macroDocs,
        });
      }
    }
    return response;
  };
};
