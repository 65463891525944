import React from 'react';
import styled from 'styled-components';

const LinkWrapper = styled.a`
  text-decoration: none;
  font-weight: bold;
`;

export const SupportEmail = () => {
  return <LinkWrapper href={'mailto:ai@tornado.com'}>support</LinkWrapper>;
};
