import React from 'react';
import { breakpoints, SCREEN_SIZES } from '../../libs/nvstr-utils.es';

const convertToInt = (b) => parseFloat(b.split('px')[0]);

const convertWidthToBreakpoint = (width) => {
  if (isNaN(width) || width === 0) return null;
  if (width > convertToInt(breakpoints.desktop)) {
    return SCREEN_SIZES.desktop;
  }
  if (width > convertToInt(breakpoints.table)) {
    return SCREEN_SIZES.tablet;
  }
  return SCREEN_SIZES.mobile;
};

export const useScreenSize = (config = {}) => {
  const [breakpoint, setBreakpoint] = React.useState(convertWidthToBreakpoint(window?.innerWidth));

  React.useEffect(() => {
    const handleResize = () => {
      const newBreakpoint = convertWidthToBreakpoint(window?.innerWidth);
      if (newBreakpoint !== breakpoint) {
        setBreakpoint(newBreakpoint);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return breakpoint;
};

export const useWindowSize = (config = {}) => {
  const { minusHeaderNavbar } = config;
  const [height, setHeight] = React.useState(window?.innerHeight);
  const [width, setWidth] = React.useState(window?.innerWidth);

  const setHeightIfChanged = React.useCallback(
    (currentHeight) => {
      const selectedHeight = minusHeaderNavbar ? currentHeight - 60 : currentHeight;
      if (height !== selectedHeight) {
        setHeight(selectedHeight);
      }
    },
    [height]
  );

  const setWidthIfChanged = React.useCallback(
    (currentWidth) => {
      if (width !== currentWidth) {
        setWidth(currentWidth);
      }
    },
    [width]
  );

  React.useEffect(() => {
    const handleResize = () => {
      setHeightIfChanged(window?.innerHeight);
      setWidthIfChanged(window?.innerWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [height, width]);

  return { height, width };
};

export default useWindowSize;
