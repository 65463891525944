import { watchlistActions } from '../constants/actionTypes';

const defaultState = {
  watchlist: [],
  isLoading: true,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case watchlistActions.update: {
      return {
        watchlist: action.payload,
        isLoading: false,
      };
    }
    case watchlistActions.add: {
      return {
        watchlist: [...state.watchlist, ...action.payload],
      };
    }

    case watchlistActions.remove: {
      return {
        watchlist: state.watchlist.filter((w) => w.company_id !== action.payload),
      };
    }

    default:
      return state;
  }
}
