import React from 'react';

const ChevronDown = ({ color }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" fill={color} />
    </svg>
  );
};

export default ChevronDown;
