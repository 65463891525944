import React from 'react';
import { useLocation } from 'react-router-dom';
import { uniqueIdGenerator } from '../../utils/usefulFuncs';

const locationStoreListenerIdGenerator = uniqueIdGenerator();

class LocationStore {
  constructor() {
    this.listeners = {};

    try {
      this.pathname = window.location.pathname;
    } catch (e) {
      this.pathname = null;
    }
  }

  getPathname = () => this.pathname;

  setPathname = (n) => {
    this.pathname = n;
    this._emitChange(n);
  };

  setListener = (id, cb) => {
    this.listeners[id] = cb;
  };

  removeListener = (id) => {
    delete this.listeners[id];
  };

  _emitChange = (n) => {
    const listeners = Object.keys(this.listeners);
    listeners.forEach((id) => {
      this.listeners[id](n);
    });
  };
}

const instance_LocationStore = new LocationStore();

export const usePathnameSafe = () => {
  const [pathname, setPathname] = React.useState(instance_LocationStore.getPathname());

  React.useEffect(() => {
    const id = locationStoreListenerIdGenerator();
    const onChange = (p) => {
      setPathname(p);
    };
    instance_LocationStore.setListener(id, onChange);

    return () => {
      instance_LocationStore.removeListener(id);
    };
  }, []);

  return pathname;
};

export const useLocationUpdate = () => {
  const location = useLocation();
  React.useEffect(() => {
    instance_LocationStore.setPathname(location.pathname);
  }, [location.pathname]);

  return null;
};
