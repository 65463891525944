import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './utils/store';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, useLocation } from 'react-router-dom';
import { matchRoutes, useNavigationType } from 'react-router';

const SENTRY_DSN = 'https://6571b5ae5b5b4a12a9d7c803bc405a44@o158945.ingest.sentry.io/4505550777679872';
Sentry.init({
  dsn: SENTRY_DSN,
  enabled: !window.location.host.includes('localhost'),
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/tornado\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

// strict mode double renders the env leading to double logs for dev errors, its kind of annoying with little value unless you are actively debugging a complex state error
const ENABLE_STRICT_MODE = false;

let Entry;
if (ENABLE_STRICT_MODE) {
  Entry = (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  Entry = <App />;
}
root.render(Entry);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
