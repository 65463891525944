import React from 'react';
import styled from 'styled-components';
import { convertHexToRGBA } from '../../libs/nvstr-utils.es';

const Wrapper = styled.div``;
const TabHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  padding: 8px 16px 28px 0;
`;
const TabWrapper = styled.div`
  margin-left: -4px;

  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.06em;

  padding: 12px 0;
  width: 140px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.1)};

    // color: ${({ theme }) => theme.themeColors.buttonText} !important;
    //
    // * {
    //   color: ${({ theme }) => theme.themeColors.buttonText} !important;
    // }
  }

  &.active {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;

    color: ${({ theme }) => theme.themeColors.buttonText} !important;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText} !important;
    }
  }

  @media (max-width: 500px) {
    width: 104px;

    font-size: 11px;
    line-height: 14px;
  }
`;
const TabBodyWrapper = styled.div``;
const ComponentWrapper = styled.div`
  ${({ Active }) => (Active ? 'display: block;' : 'display: none;')}
`;

const Tab = ({ name, index, isActive, onClick }) => {
  const hc = () => {
    onClick(index);
  };
  return (
    <TabWrapper className={isActive ? 'active' : 'inactive'} onClick={hc}>
      {name}
    </TabWrapper>
  );
};

export const ColumnTabs = ({ tabNames, BodyComponents }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  React.useEffect(() => {
    setActiveTabIndex(0);
  }, [BodyComponents]);

  const onTabClick = (i) => setActiveTabIndex(i);
  return (
    <Wrapper>
      <TabHeader>
        {tabNames.map((n, i) => (
          <Tab key={n} name={n} index={i} isActive={i === activeTabIndex} onClick={onTabClick} />
        ))}
      </TabHeader>
      <TabBodyWrapper>
        {BodyComponents.map((c, i) => (
          <ComponentWrapper key={i} Active={i === activeTabIndex}>
            {c}
          </ComponentWrapper>
        ))}
      </TabBodyWrapper>
    </Wrapper>
  );
};
