import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { ExtraWideBody } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { scrollToTop } from '../utils/usefulFuncs';
import { useColorTheme } from '../hooks';
import { CSVUploader } from '../containers/DigitalAnalyst/CSVUploader';
import { FactorVisualizationTable } from '../containers/DigitalAnalyst/FactorVisualizationTable';
import { useBuildVisualizationData } from '../utils/dataVisualization';
import { SkeletonButton } from '../components/buttons/SkeletonButton';
import Close from '../assets/icons/svgs/Close';
import { FONT } from '../constants/app';
import { Modal } from '../components/layout/Modal';
import { ModalCloseButton } from '../components/buttons/ModalCloseButton';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const VisualizationWrapper = styled.div`
  overflow: scroll;
  padding: 24px;
`;

function Page() {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <ExtraWideBody withFooter collapseHeight>
        <Container>
          <BackArrowButton onClick={onGoBack} left={'36px'} />
        </Container>
        <VisualizationWrapper>
          <FactorVisualization />
        </VisualizationWrapper>
      </ExtraWideBody>
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  display: contents;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 0 8px 0 12px;

  height: 40px;
  border-radius: 5px;

  background: ${({ theme }) => theme.themeColors.lowContrastBorder};

  font-family: ${() => FONT.normal};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.themeColors.text};

  max-width: 600px;

  svg {
    height: 14px;
    width: 14px;

    fill: ${({ theme }) => theme.themeColors.text};
  }

  cursor: pointer;
  transition: background-color 140ms;
  box-sizing: content-box;
`;

const StickyWrapper = styled.div`
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  left: 0;
  z-index: 2;
  max-width: 600px;
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;

  svg {
    height: 16px !important;
    width: 16px !important;
  }
`;

const CloseButton = styled.div`
  padding: 0 0 0 8px;

  svg {
    margin-top: 1px;

    height: 8px;
    width: 8px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const ContentsWrapper = styled.div`
  display: contents;
`;

const File = ({ onRemove, file }) => {
  const filename = file.name + '.csv';

  const hc = () => onRemove(file);

  return (
    <FileWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
      </svg>
      <Container left={8}>{filename}</Container>
      <CloseButton onClick={hc}>
        <Close />
      </CloseButton>
    </FileWrapper>
  );
};

const FileVisualization = React.memo(({ file, onShowModal, onDismissModal, onRemoveFile }) => {
  const colorTheme = useColorTheme();
  const data = useBuildVisualizationData(file);

  if (data === null) {
    return (
      <ContentsWrapper>
        <StickyWrapper>
          <File file={file} onRemove={onRemoveFile} />
        </StickyWrapper>
        <Container top={16}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
        </Container>
      </ContentsWrapper>
    );
  }

  const { prompt } = data;

  return (
    <ContentsWrapper>
      <StickyWrapper>
        <File file={file} onRemove={onRemoveFile} />
      </StickyWrapper>
      <StickyWrapper>
        <Container top={24} left={16}>
          <Body5>{prompt}</Body5>
        </Container>
      </StickyWrapper>
      <Container top={24} bottom={24}>
        <FactorVisualizationTable data={data} onShowModal={onShowModal} onDismissModal={onDismissModal} />
      </Container>
    </ContentsWrapper>
  );
});

function FactorVisualization() {
  const colorTheme = useColorTheme();
  const isLoading = false; // when API is ready
  const [files, setFiles] = React.useState([]);

  const [isAddFileActive, setIsAddFileActive] = React.useState(false);
  const [modalText, setModalText] = React.useState(null);

  const onUpload = React.useCallback(
    (d) => {
      if (files.length > 0) {
        setFiles([...files, ...d]);
      } else {
        setFiles(d);
      }

      setIsAddFileActive(false);
    },
    [files]
  );

  const onAddFileClick = React.useCallback(() => {
    setIsAddFileActive(true);
  }, []);

  const onRemoveFile = React.useCallback((d) => {
    const fileName = d.name;
    setFiles(files.filter((f) => f.name !== fileName));
  }, []);
  const onShowModal = React.useCallback((v) => {
    setModalText(v);
  }, []);
  const onDismissModal = React.useCallback(() => setModalText(null), []);

  if (isLoading) {
    return (
      <Wrapper>
        <Container row centerAll bottom={200} top={96}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          <Container left={8}>
            <Body5>Loading Data...</Body5>
          </Container>
        </Container>
      </Wrapper>
    );
  }

  if (files.length === 0) {
    return (
      <Wrapper>
        <Container top={48}>
          <h3>Upload Files</h3>
        </Container>
        <CSVUploader files={files} onUpload={onUpload} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isAddFileActive ? (
        <>
          <Modal onDismiss={() => setIsAddFileActive(false)}>
            <ModalCloseButtonWrapper>
              <CloseButton onClick={() => setIsAddFileActive(false)}>
                <Close />
              </CloseButton>
            </ModalCloseButtonWrapper>
            <Container top={24} bottom={48} style={{ minWidth: '50vw' }}>
              <Container>
                <h3>Upload Files</h3>
              </Container>
              <CSVUploader files={files} onUpload={onUpload} />
            </Container>
          </Modal>
          <Container top={48} />
        </>
      ) : (
        <StickyWrapper>
          <Container top={48} bottom={24}>
            <SkeletonButton onClick={onAddFileClick}>+ Add File</SkeletonButton>
          </Container>
        </StickyWrapper>
      )}
      {modalText && (
        <Modal onDismiss={onDismissModal}>
          <ModalCloseButton onClick={onDismissModal} />
          <Container vertical={24}>
            <Body5>{modalText}</Body5>
          </Container>
          <Container textCenter>
            <SkeletonButton fullWidth onClick={onDismissModal}>
              Dismiss
            </SkeletonButton>
          </Container>
        </Modal>
      )}
      {files.map((file, i) => {
        return (
          <Container key={i} top={24} style={{ display: 'contents' }}>
            <FileVisualization
              file={file}
              onRemoveFile={onRemoveFile}
              onShowModal={onShowModal}
              onDismissModal={onDismissModal}
            />
          </Container>
        );
      })}
    </Wrapper>
  );
}

export default Page;
