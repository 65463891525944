import React from 'react';
import { BASEURL } from '../../services/network';
import { logError, logger } from '../../utils/usefulFuncs';

export const useStreamingResult = (resultId, isCitationStream) => {
  const [result, setResult] = React.useState('');
  const [fullContext, setFullContext] = React.useState(null);
  const [context, setContext] = React.useState(null);
  const [streamEnd, setStreamEnd] = React.useState(false);
  const [error, setError] = React.useState(null);

  const getStreamingResponse = async (resultId, status) => {
    if (!resultId) {
      return null;
    }

    const URL = `${BASEURL}/api/v1/genai_qa/stream_events?id=${resultId}`;
    const source = new EventSource(URL, {
      withCredentials: true,
    });

    source.addEventListener('open', function (event) {
      try {
        // logger('stream open');
        if (!status.isCanceled) {
          setResult('Working...');
          setContext(null);
          setStreamEnd(false);
        }
      } catch (e) {
        logError('error occurred in stream', e);
      }
    });
    source.addEventListener('error', function (event) {
      try {
        setError('Something went wrong.');
      } catch (e) {
        logError('error occurred in stream', e);
      }
    });
    source.addEventListener('message', function (event) {
      try {
        const { data } = event;
        const streamData = JSON.parse(data);
        const { answer } = streamData;
        if (answer?.length > 0) {
          if (!status.isCanceled) {
            setResult(answer);
          }
        }
      } catch (e) {
        logError('error occurred in stream', e);
      }
    });
    source.addEventListener('complete', function (event) {
      try {
        // logger('stream complete');
        const { data } = event;
        const streamData = JSON.parse(data);
        const { response } = streamData;
        const { answer, context, context_with_all_headers: fullContext } = response;
        if (!status.isCanceled) {
          if (answer?.length > 0) {
            setResult(answer);
            setContext(context);
            setFullContext(fullContext);
            setStreamEnd(true);
          }
        }
      } catch (e) {
        logError('error occurred in stream', e);
      }
      source.close();
    });

    return source;
  };

  React.useEffect(() => {
    let status = { isCanceled: false };
    const streamSource = getStreamingResponse(resultId, status);
    return () => {
      try {
        status.isCanceled = true;
        if (streamSource && streamSource.close) {
          streamSource.close();
        }
      } catch (e) {
        console.error(e);
      }
    };
  }, [resultId]);

  return {
    result,
    context,
    fullContext,
    error,
    streamEnd,
  };
};
