import { useNavigate } from 'react-router';
import React from 'react';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { logFormSubmit, scrollToTop } from '../../utils/usefulFuncs';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { Body1, Body5, CheckCircle, Container } from '../../libs/nvstr-common-ui.es';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { TickerSelect } from '../DigitalAnalyst/components/TickerSelect';
import { FormState } from '../../components/UI/FormState';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import styled from 'styled-components';
import { useCurrentUserToolsAvailable } from '../../hooks/user/useCurrentUser';
import { AI_TOOLS, UPLOAD_DOC_TYPE_OPTIONS } from '../../constants';
import { FED_SPEAKERS } from '../../constants/fedSpeakers';
import { SearchAutocomplete } from '../DigitalAnalyst/components/SearchAutocomplete';
import { ROUTES } from '../../constants/routes';

const ARE_MULTIPLE_TICKERS_ENABLED = false;

const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 300px;
    width: 100%;
    padding: 16px;
  }
`;
const Label = styled.div`
  padding-bottom: 8px;
`;
const EnterManuallyBtnWrapper = styled.div`
  button {
    padding: 0px 12px;
  }
`;
const CheckWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

export const TextUploadForm = () => {
  const navigate = useNavigate();
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isSpeakerTaggingEnabled = toolsAvailable.includes(AI_TOOLS.SPEAKER_TAGGING);
  const isCompanyTaggingEnabled = toolsAvailable.includes(AI_TOOLS.COMPANY_TAGGING);

  const [tickers, setTickers] = React.useState([]);
  const [name, setName] = React.useState('');
  const [textContent, setTextContent] = React.useState('');
  const [speakerTag, setSpeakerTag] = React.useState('');
  const [companyTag, setCompanyTag] = React.useState('');
  const [docType, setDocType] = React.useState('');

  const [isEnterManuallyToggled, setIsEnterManuallyToggled] = React.useState(false);
  const [showForm, setShowForm] = React.useState(true);
  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const onSpeakerTagChange = (e) => {
    setSpeakerTag(e.target.value);
  };

  const onUploadAnotherClick = () => {
    setShowForm(true);
    setTickers([]);
    setName('');
    setTextContent('');
    setFormError(null);
  };
  const onQueryItClick = () => {
    const encodedName = encodeURIComponent(name);
    navigate(`${ROUTES.docProcessingToDA}?filename=${encodedName}`);
  };
  const onAddTicker = (t) => setTickers([t]);
  const onRemoveTicker = (ticker) => {
    setTickers([]);
  };
  const onNameChange = (e) => {
    const v = e.target.value;
    setName(v);
  };
  const handleCompanyTagSelect = (v) => {
    setCompanyTag(v);
  };
  const handleDocTypeSelect = (e) => {
    setDocType(e.target.value);
  };
  const onTextContentChange = (e) => setTextContent(e.target.value);

  const onSubmit = async () => {
    if (textContent === '') {
      setFormError('You must enter content');
      return;
    }

    TrackingEvent.create('Uploaded Digital Analyst Content', {
      Tickers: tickers,
      Name: name,
    });
    let ticker = ARE_MULTIPLE_TICKERS_ENABLED ? tickers : tickers[0];
    const form = {
      ticker: ticker?.length === 0 ? null : ticker,
      name,
      data: textContent,
    };
    if (speakerTag && speakerTag.length > 0) {
      form.speaker = speakerTag;
    }
    if (companyTag) {
      form.doc_tag_id = companyTag.id;
    }
    if (docType) {
      form.doc_type = docType;
    }
    logFormSubmit(form);

    setFormError(false);
    setIsSubmittingToApi(true);
    scrollToTop();

    const URL = `v1/genai_process_user_doc`;
    const { status, data, error } = await sendApiRequest('post', `${URL}`, form);
    setIsSubmittingToApi(false);
    handle400Statuses(status);
    if (status === 200) {
      setFormError(false);
      setShowForm(false);
    } else {
      setFormError(data?.error || error || 'Something went wrong, try again.');
    }
  };

  return (
    <Container>
      {isSubmittingToApi ? (
        <Container top={166} bottom={166} centerAll>
          <Container>
            <Container centerAll>
              <Spinner />
            </Container>
            <Container top={24} left={12}>
              <Body5>Working...</Body5>
            </Container>
          </Container>
        </Container>
      ) : showForm ? (
        <FormWrapper>
          {isSpeakerTaggingEnabled ? (
            <Container top={24}>
              <Container bottom={8} spaceBetween row>
                <Label>
                  <Body5>Speaker Tag</Body5>
                </Label>
                <EnterManuallyBtnWrapper>
                  {isEnterManuallyToggled ? (
                    <TransparentFlatButton onClick={() => setIsEnterManuallyToggled(false)}>
                      Use Dropdown
                    </TransparentFlatButton>
                  ) : (
                    <TransparentFlatButton onClick={() => setIsEnterManuallyToggled(true)}>
                      Enter Manually
                    </TransparentFlatButton>
                  )}
                </EnterManuallyBtnWrapper>
              </Container>
              {isEnterManuallyToggled ? (
                <input value={speakerTag} placeholder={'Optional...'} onChange={onSpeakerTagChange} />
              ) : (
                <select value={speakerTag} onChange={onSpeakerTagChange}>
                  <option value={''}>Please select...</option>
                  {FED_SPEAKERS.map((n, i) => (
                    <option key={n}>{n}</option>
                  ))}
                </select>
              )}
            </Container>
          ) : (
            <Container top={24}>
              <TickerSelect
                onAddTicker={onAddTicker}
                onRemoveTicker={onRemoveTicker}
                tickers={tickers}
                multipleTickersEnabled={ARE_MULTIPLE_TICKERS_ENABLED}
              />
            </Container>
          )}
          {isCompanyTaggingEnabled && (
            <Container top={24}>
              <Label>
                <Body5>Company Tag</Body5>
              </Label>
              <SearchAutocomplete onSelect={handleCompanyTagSelect} enableCreateTag />
            </Container>
          )}

          <Container top={24}>
            <Label>
              <Body5>Document Type</Body5>
            </Label>
            <select value={docType} onChange={handleDocTypeSelect}>
              <option value={''}>Optional...</option>
              {UPLOAD_DOC_TYPE_OPTIONS.map((o) => (
                <option key={o}>{o}</option>
              ))}
            </select>
          </Container>

          <Container top={24}>
            <Label>
              <Body5>Document Name</Body5>
            </Label>
            <input name={'name'} onChange={onNameChange} value={name} />
          </Container>

          <Container top={24}>
            <Label>
              <Body5>Document Text</Body5>
            </Label>
            <textarea onChange={onTextContentChange} value={textContent} />
          </Container>

          <Container top={24}>
            <Container centerAll>
              <FormState error={formError} />
            </Container>
            <SubmitButtonWrapper>
              <FlatButton onClick={onSubmit} fullWidth>
                Submit
              </FlatButton>
            </SubmitButtonWrapper>
          </Container>
        </FormWrapper>
      ) : (
        <Container top={48}>
          <Container row verticallyCenter>
            <CheckWrapper>
              <CheckCircle />
            </CheckWrapper>
            <Container left={16}>
              <Container>
                <Body1 bold>Upload Complete</Body1>
              </Container>
              <Body1>The document will be available to query shortly.</Body1>
            </Container>
          </Container>
          <Container top={48} row>
            <FlatButton onClick={onQueryItClick}>Use This Doc</FlatButton>
            <Container left={16}>
              <SkeletonButton onClick={onUploadAnotherClick}>Upload Another</SkeletonButton>
            </Container>
          </Container>
        </Container>
      )}
    </Container>
  );
};
