import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../components/buttons/CloseButton';
import { applicationActionTypes } from '../../constants/actionTypes';

const Wrapper = styled.div`
  position: fixed;

  top: 72px;
  left: 16px;
  right: 16px;

  border-radius: 4px;
  padding: 4px;
  background: ${({ theme }) => theme.themeColors.error};

  * {
    fill: ${({ theme }) => theme.themeColors.buttonText} !important;
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }

  z-index: 100000000;
`;

// let messageAutoDismiss;
const defaultTimeLength = 3000;
const showDebugDismissTimer = false;

export const ApplicationErrorMessageBanner = () => {
  const dispatch = useDispatch();

  const [messageDismissTimer, setMessageDismissTimer] = React.useState(defaultTimeLength);
  const errorBanner = useSelector((state) => state.application.errorBanner);

  React.useEffect(() => {
    if (errorBanner?.message) {
      setMessageDismissTimer(defaultTimeLength);
    }
  }, [errorBanner]);

  React.useEffect(() => {
    let timeout = null;
    let timeoutStep = 1000;
    if (timeoutStep > messageDismissTimer) {
      timeoutStep = messageDismissTimer;
    }
    if (timeoutStep > 0) {
      timeout = setTimeout(() => {
        const nextTime = messageDismissTimer - timeoutStep;
        setMessageDismissTimer(nextTime);
      }, timeoutStep);
    } else {
      dispatch({
        type: applicationActionTypes.hideErrorBanner,
      });
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [messageDismissTimer]);

  if (errorBanner === null) {
    return null;
  }

  const onDismiss = () =>
    dispatch({
      type: applicationActionTypes.hideErrorBanner,
    });

  const { message, config } = errorBanner;
  return (
    <Wrapper>
      <Container row spaceBetween verticallyCenter left={4}>
        {showDebugDismissTimer && (
          <Container>
            <Body5>{messageDismissTimer} ms</Body5>
          </Container>
        )}
        <Body5>{message}</Body5>
        <Container left={8}>
          <CloseButton onClick={onDismiss} size={12} />
        </Container>
      </Container>
    </Wrapper>
  );
};
