import { applicationActionTypes } from '../constants/actionTypes';
import { logError } from '../utils/usefulFuncs';

const defaultState = {
  staticCopy: null,

  advancedSettingsFields: null,
  advancedSettings: {},

  isLoadingCustomLogo: true,
  customLogo: null,
  customFavi: null,
  customTitle: null,

  errorBanner: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case applicationActionTypes.setCustomClientLogo: {
      if (action.payload === null)
        return {
          ...state,
          isLoadingCustomLogo: false,
        };

      const { logo, favi, title } = action.payload;
      return {
        ...state,
        isLoadingCustomLogo: false,
        customLogo: logo,
        customFavi: favi,
        customTitle: title,
      };
    }
    case applicationActionTypes.setStaticCopy: {
      return {
        ...state,
        staticCopy: action.payload,
      };
    }

    case applicationActionTypes.setAdvancedSettings: {
      return {
        ...state,
        advancedSettings: {
          ...state.advancedSettings,
          ...action.payload,
        },
      };
    }

    case applicationActionTypes.showAdvancedSettings: {
      const fields = action.payload;
      if (!fields || !Array.isArray(fields)) {
        logError('Missing fields for advanced settings to show');
        return state;
      }

      return {
        ...state,
        advancedSettingsFields: fields,
      };
    }

    case applicationActionTypes.hideAdvancedSettings: {
      return {
        ...state,
        advancedSettingsFields: null,
      };
    }

    case applicationActionTypes.showErrorBanner: {
      return {
        ...state,
        errorBanner: action.payload,
      };
    }

    case applicationActionTypes.hideErrorBanner: {
      return {
        ...state,
        errorBanner: null,
      };
    }

    default:
      return state;
  }
}
