import React from 'react';
import styled from 'styled-components';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { gptModels } from '../../constants';
import { Modal } from '../../components/layout/Modal';
import { Body1, Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../components/buttons/CloseButton';
import { DocumentSelection } from './DocumentSelection';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { applicationActionTypes } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { useSentimentAnalysis } from '../../hooks/features/useSentimentAnalysis';
import { useSentimentRecords } from '../../hooks/features/useSentimentRecords';
import { PublicSentimentAnalysis } from './PublicSentimentAnalysis';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const defaultAdvancedSettingsFields = [AdvancedSettingsFields.model, AdvancedSettingsFields.maxContextLength];
const defaultAdvancedSettings = {
  model: gptModels.GPT_4_PREVIEW_1106,
};

const Heading = styled.div`
  padding: 36px 0 0 0;

  h5 {
    padding-right: 8px;
  }
`;

const SelectedDocumentWrapper = styled.div``;
const IconWrapper = styled.div`
  margin-right: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CloseButtonWrapper = styled.div`
  margin-left: 8px;

  svg {
    padding-top: 1px;
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const ActionWrapper = styled.div`
  text-align: center;
  padding: 36px 0 0 0;
`;

function SelectedDocument({ doc, handleRemove }) {
  if (!doc) return null;

  const { filename } = doc;
  return (
    <SelectedDocumentWrapper>
      <Container row verticallyCenter>
        <IconWrapper>
          <DocumentIcon />
        </IconWrapper>
        <Container>
          <Body5>{filename}</Body5>
        </Container>
        <CloseButtonWrapper>
          <CloseButton onClick={handleRemove} />
        </CloseButtonWrapper>
      </Container>
    </SelectedDocumentWrapper>
  );
}

function PublicSentimentDocSelectTool({ onGoBack }) {
  const dispatch = useDispatch();

  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: true });
  const [isSelectingDocs, setIsSelectingDocs] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState([]);
  const [docInAnalysis, setDocInAnalysis] = React.useState(null);

  const { isLoading, data, chartData, metadata, error, onSubmit, onClear } = useSentimentAnalysis();
  const { data: docs, error: recordsError } = useSentimentRecords();

  // Set Adv Options
  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  const onReset = () => {
    setSelectedDocs([]);
    setDocInAnalysis(null);
    onClear();
  };

  const handleAddDocument = (v) => {
    setSelectedDocs([v]);
    setDocInAnalysis(null);
    setIsSelectingDocs(false);
  };

  const handleRemoveDocument = () => {
    setSelectedDocs([]);
  };

  const onSelectDocumentsClick = () => setIsSelectingDocs(true);

  const onGetAnalysis = () => {
    const selected = selectedDocs[0];
    if (!selected) return;

    setDocInAnalysis(selected);
    onSubmit(selected);
  };

  return (
    <Wrapper>
      {isSelectingDocs && (
        <Modal onDismiss={() => setIsSelectingDocs(false)} modalProps={modalProps}>
          <Container row verticallyCenter spaceBetween>
            <Container bottom={4}>
              <Body1 bold>Select Document Sources</Body1>
            </Container>
            <CloseButton onClick={() => setIsSelectingDocs(false)} />
          </Container>
          <Container top={24}>
            <DocumentSelection
              docs={docs}
              onSelect={handleAddDocument}
              onRemoveSelect={handleRemoveDocument}
              selectedDocs={selectedDocs}
              onClose={() => setIsSelectingDocs(false)}
              singleDocMode
            />
          </Container>
        </Modal>
      )}

      <Page width={'1400px'}>
        <BackArrowButton onClick={selectedDocs.length === 0 ? onGoBack : onReset} />
        <Heading>
          <H5>Fed Sentiment</H5>
          <AdvancedSettingsButton fields={defaultAdvancedSettingsFields} />
        </Heading>
        <Container top={24} bottom={24}>
          <SelectedDocument doc={selectedDocs[0]} handleRemove={handleRemoveDocument} />
          <Container>
            {selectedDocs.length === 0 ? (
              <Container top={24}>
                <FlatButton onClick={onSelectDocumentsClick}>+ Select Document</FlatButton>
              </Container>
            ) : (
              <Container row top={16}>
                <TransparentFlatButton onClick={onSelectDocumentsClick}>Change Document</TransparentFlatButton>
              </Container>
            )}
          </Container>
          {selectedDocs.length === 0 ? null : (
            <ActionWrapper>
              <FlatButton fullWidth onClick={onGetAnalysis}>
                Analyze Sentiment
              </FlatButton>
            </ActionWrapper>
          )}
        </Container>
      </Page>
      {error && (
        <Page width={'1400px'}>
          <Container vertical={16}>
            <Container>
              <Body1 bold>An error occurred analyzing this document.</Body1>
            </Container>
            <Container top={16} bottom={48}>
              <Body5>{error}</Body5>
            </Container>
          </Container>
        </Page>
      )}
      {data && (
        <PublicSentimentAnalysis
          data={data}
          chartData={chartData}
          metadata={metadata}
          doc={docInAnalysis}
          isLoading={isLoading}
          handleReset={onReset}
        />
      )}
    </Wrapper>
  );
}

export default PublicSentimentDocSelectTool;
