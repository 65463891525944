import React from 'react';

const actionTypes = {
  TOGGLE_SECTION_EXPANSION_STATE: 'tses',
  SET_SECTION_EXPANSION_STATE: 'sses',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SECTION_EXPANSION_STATE: {
      const sectionId = action.payload;
      if (state.expandedSectionIds.includes(sectionId)) {
        return {
          ...state,
          expandedSectionIds: state.expandedSectionIds.filter((id) => sectionId !== id),
        };
      }

      return {
        ...state,
        expandedSectionIds: [...state.expandedSectionIds, sectionId],
      };
    }
    case actionTypes.SET_SECTION_EXPANSION_STATE: {
      const { sectionId, value } = action.payload;
      if (value) {
        return {
          ...state,
          expandedSectionIds: [...state.expandedSectionIds, sectionId],
        };
      }
      return {
        ...state,
        expandedSectionIds: state.expandedSectionIds.filter((id) => sectionId !== id),
      };
    }

    default:
      return state;
  }
};

const initialState = {
  expandedSectionIds: [],
};

const useSectionExpandedState = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { expandedSectionIds } = state;

  const setSectionState = (sectionId, value) => {
    const action = {
      type: actionTypes.SET_SECTION_EXPANSION_STATE,
      payload: { sectionId, value },
    };
    dispatch(action);
  };
  const setSectionStateRef = React.useRef(setSectionState);

  const toggleSectionState = (sectionId) => {
    const action = {
      type: actionTypes.TOGGLE_SECTION_EXPANSION_STATE,
      payload: sectionId,
    };
    dispatch(action);
  };
  const toggleSectionStateRef = React.useRef(toggleSectionState);
  return {
    expandedSectionIds,
    toggleSectionState: toggleSectionStateRef,
    setSectionState: setSectionStateRef,
  };
};

export default useSectionExpandedState;
