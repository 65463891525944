import React from 'react';
import styled from 'styled-components';
import { Body5, Body7, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import { logNetRequest } from '../../../utils/usefulFuncs';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { useColorTheme } from '../../../hooks';
import { handle400Statuses, sendApiRequest } from '../../../services/api';

const SearchAutocompleteWrapper = styled.div``;

const ResultsWrapper = styled.div`
  position: absolute;
  top: 4px;
  left: 0px;

  border-radius: 5px;
  min-width: 50vw;
  max-width: 600px;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};

  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);

  button {
    padding: 4px 0px;
  }
`;
const ResultsPositionWrapper = styled.div`
  position: relative;
  z-index: 5;
`;

const ACTIONS = {
  updateQuery: 'updateQ',
  updateResults: 'ur',
  showResults: 'showResults',
  clearResults: 'clearResults',
  hideResults: 'hr',
};

const initialState = {
  query: '',
  isQueryRunning: false,
  results: null,
  noResults: false,
  showResults: false,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.updateQuery: {
      return {
        ...state,
        query: action.payload,
        isQueryRunning: true,
      };
    }
    case ACTIONS.updateResults: {
      const { query: queryRan, results } = action.payload;
      if (state.query === queryRan) {
        return {
          ...state,
          results,
          noResults: results.length === 0,
          showResults: true,
          isQueryRunning: false,
        };
      }
      return state;
    }
    case ACTIONS.clearResults: {
      return initialState;
    }
    case ACTIONS.showResults: {
      return {
        ...state,
        showResults: true,
      };
    }
    case ACTIONS.hideResults: {
      return {
        ...state,
        showResults: false,
      };
    }
    default:
      throw new Error('missing case statement, search auto complete');
  }
}

const ResultWrapper = styled.div`
  &:last-child {
    border: none;
  }

  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;
  transition: 200ms all;

  &:hover {
    background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  }
`;

const Result = ({ value, onSelect }) => {
  const { name } = value;
  const hc = () => {
    onSelect(value);
  };
  return (
    <ResultWrapper onClick={hc}>
      <Body5>{name}</Body5>
    </ResultWrapper>
  );
};

function checkHasExactMatch(query, results) {
  if (!results) return false;
  if (!query) return false;

  let has = false;
  results.forEach((r) => {
    if (r.name === query) {
      has = true;
    }
  });
  return has;
}

const InputWrapper = styled.div`
  position: relative;

  input {
    outline: none;
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.themeColors.text};
    margin: 0;
    padding: 7px 0 7px 8px;
    width: 100%;
  }
`;
const QueryStateWrapper = styled.div`
  position: absolute;
  right: 8px;
  width: 100px;
  top: 0;
  height: 40px;
`;
const LoadingIconWrapper = styled.div`
  padding-top: 10px;
  padding-right: 16px;
  opacity: 0.2;
`;
const CloseButtonWrapper = styled.div`
  padding-top: 6px;

  svg {
    height: 13px;
    width: 13px;
  }
`;
const QueryState = ({ isQueryRunning, showClearButton, onClearClick }) => {
  const colorTheme = useColorTheme();

  return (
    <QueryStateWrapper>
      <Container row alignRight>
        {isQueryRunning && (
          <LoadingIconWrapper>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} color={colorTheme.text} />
          </LoadingIconWrapper>
        )}
        {showClearButton && (
          <CloseButtonWrapper>
            <CloseButton onClick={onClearClick} />
          </CloseButtonWrapper>
        )}
      </Container>
    </QueryStateWrapper>
  );
};

const TagPosWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 8px;
  z-index: 10;
`;
const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0 8px;
  background: ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;

  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;
const DeleteTagWrapper = styled.div`
  padding-left: 16px;

  svg {
    height: 10px;
    width: 10px;

    path {
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
const Tag = ({ value, onRemoveTag }) => {
  if (!value) return null;

  return (
    <TagPosWrapper>
      <TagWrapper>
        <Body7 bold>{value.name}</Body7>
        <DeleteTagWrapper>
          <CloseButton onClick={onRemoveTag} />
        </DeleteTagWrapper>
      </TagWrapper>
    </TagPosWrapper>
  );
};

export const SearchAutocomplete = ({ onSelect, enableCreateTag, autoFocus }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  const [value, setValue] = React.useState('');
  const [tag, setTag] = React.useState(null);

  const getResults = async (query) => {
    const URL = `v1/genai_doc_tags?query=${query}`;
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data);
    handle400Statuses(status);
    if (status === 200) {
      dispatch({
        type: ACTIONS.updateResults,
        payload: {
          query,
          results: data.doc_tags,
        },
      });
    } else {
      // TODO:
    }
  };

  React.useEffect(() => {
    if (value !== '') {
      dispatch({
        type: ACTIONS.updateQuery,
        payload: value,
      });
      getResults(value);
    }
    if (value === '') {
      dispatch({
        type: ACTIONS.clearResults,
      });
    }
  }, [value]);

  const onChange = (e) => {
    setTag(null);
    setValue(e.target.value);
  };

  const onFocus = () => {
    if (query !== '') {
      dispatch({
        type: ACTIONS.showResults,
      });
    }
  };

  const onBlur = () => {
    setTimeout(() => {
      dispatch({
        type: ACTIONS.hideResults,
      });
    }, 300);
  };

  const onCreateTag = async () => {
    const URL = `v1/genai_doc_tags`;
    const form = {
      name: value,
    };
    const { status, data, error } = await sendApiRequest('post', URL, form);
    logNetRequest(status, data, error);
    if (status === 200) {
      setTag(data);
      onSelect(data);
      dispatch({
        type: ACTIONS.hideResults,
      });
    } else {
      if (data?.error === 'Doc tag with that name or symbol already exists') {
        // TODO: error message
      } else {
        // TODO: error message
      }
    }
  };

  const onClear = () => {
    dispatch({
      type: ACTIONS.clearResults,
    });
    setValue('');
    setTag(null);
  };

  const onSelectTag = (v) => {
    setTag(v);
    onSelect(v);
    dispatch({
      type: ACTIONS.hideResults,
    });
  };
  const onRemoveTag = () => {
    setTag(null);
  };

  const { query, results, noResults, showResults, isQueryRunning } = state;
  const hasExactMatch = checkHasExactMatch(query, results);
  return (
    <SearchAutocompleteWrapper>
      <InputWrapper>
        <input
          value={tag === null ? value : ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={tag !== null}
          autoFocus={autoFocus}
        />
        <Tag value={tag} onRemoveTag={onRemoveTag} />
        <QueryState isQueryRunning={isQueryRunning} onClearClick={onClear} showClearButton={value.length > 0} />
      </InputWrapper>
      <ResultsPositionWrapper>
        {showResults && (
          <ResultsWrapper>
            {noResults && (
              <Container>
                <Container bottom={8} left={16} top={16}>
                  <Body5>No results found for "{query}"</Body5>
                </Container>
              </Container>
            )}
            {results.map((r) => (
              <Result key={r.id} value={r} onSelect={onSelectTag} />
            ))}
            {!hasExactMatch && enableCreateTag && (
              <ResultWrapper onClick={onCreateTag}>
                <Body7 bold>+ CREATE NEW TAG</Body7>
              </ResultWrapper>
            )}
          </ResultsWrapper>
        )}
      </ResultsPositionWrapper>
    </SearchAutocompleteWrapper>
  );
};
