import React from 'react';
import { Body1, Body5, Container, Page } from '../libs/nvstr-common-ui.es';
import { colorPalette, CUSTOMIZE_COLOR_THEME_KEYS, getContrastTheme } from '../libs/nvstr-utils.es';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { _getThemesFromStorage, colorThemeNameDisplayName, saveColorTheme } from '../utils/themes';
import { setItemToStorage, STORAGE_KEYS } from '../utils/storage';
import { colorThemeActions } from '../constants/actionTypes';
import { CloseButton } from '../components/buttons/CloseButton';
import { FlatButton } from '../components/buttons';
import { useColorTheme } from '../hooks';
import { SkeletonButton } from '../components/buttons/SkeletonButton';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { COLOR_THEME_NAMES } from '../constants/app';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SketchPickerWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -120px;
  z-index: 4;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
  border-radius: 5px;
  background: #fff;

  .sketch-picker {
    width: 250px !important;
    box-shadow: none !important;

    * {
      color: ${() => colorPalette.primary.charcoal} !important;
    }

    .flexbox-fix:nth-child(2) {
      div + :last-of-type {
        display: none !important;
      }
    }

    .flexbox-fix:last-child {
      display: none !important;
    }
  }
`;
const ColorSelectionRowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 320px;
  width: 100%;

  padding-top: 8px;

  &:first-child {
    padding-top: 0px;
  }
`;
const Label = styled.div`
  font-size: 14px;
`;
const ColorThemeBuilderFormWrapper = styled.div``;
const Input = styled.input`
  font-size: 14px;
  padding: 8px;
  width: 120px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  color: ${({ value, theme }) =>
    getContrastTheme(value, { dark: theme.colorPalette.primary.oak, light: theme.colorPalette.primary.charcoal }) ||
    theme.themeColors.text};
`;
const CloseButtonWrapper = styled.div`
  cursor: pointer;

  * {
    z-index: 6;
  }

  svg {
    height: 12px;
    width: 12px;
    opacity: 0.5;
  }

  path {
    fill: #000 !important;
  }
`;
const ColorPickerToolHeader = styled.div`
  background: ${({ previewColor }) => previewColor};
  border-radius: 2px;

  width: 100%;
  height: 40px;

  display: flex;
  justify-content: flex-end;
`;
const ActionsWrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
`;

const ColorSelectionRow = ({ name, keyName, value, isEditing, setIsEditing }) => {
  const dispatch = useDispatch();

  const [previewColor, setPreviewColor] = React.useState(null);

  React.useEffect(() => {
    if (value) {
      setPreviewColor(value);
    }
  }, [value]);

  const handleChange = ({ hex }) => {
    setPreviewColor(hex);
  };

  const handleChangeComplete = ({ hex }) => {
    setPreviewColor(null);
    dispatch({
      type: colorThemeActions.setCustomColorThemeColorDefinition,
      payload: {
        [keyName]: hex,
      },
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    dispatch({
      type: colorThemeActions.setCustomColorThemeColorDefinition,
      payload: {
        [keyName]: value,
      },
    });
  };

  return (
    <ColorSelectionRowWrapper>
      <Label>{name}</Label>
      <Input
        style={{ background: value }}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsEditing(keyName)}
      />
      {isEditing && (
        <SketchPickerWrapper>
          <Container top={10} horizontal={10}>
            <Container row spaceBetween verticallyCenter bottom={8}>
              <Body5 color={colorPalette.primary.charcoal}>Color Selector</Body5>
              <CloseButtonWrapper>
                <CloseButton onClick={() => setIsEditing(null)} />
              </CloseButtonWrapper>
            </Container>
            <ColorPickerToolHeader previewColor={previewColor}></ColorPickerToolHeader>
          </Container>
          <SketchPicker color={previewColor || value} onChange={handleChange} onChangeComplete={handleChangeComplete} />
        </SketchPickerWrapper>
      )}
    </ColorSelectionRowWrapper>
  );
};

const ColorThemeBuilderForm = () => {
  const customTheme = useSelector((state) => state.colorTheme.customTheme);

  const [urlEntry, setUrlEntry] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(null);

  return (
    <ColorThemeBuilderFormWrapper>
      <Container top={36} bottom={36}>
        {CUSTOMIZE_COLOR_THEME_KEYS.map((k) => (
          <ColorSelectionRow
            key={k}
            name={colorThemeNameDisplayName[k]}
            keyName={k}
            value={customTheme[k]}
            isEditing={isEditing === k}
            setIsEditing={setIsEditing}
          />
        ))}
      </Container>
    </ColorThemeBuilderFormWrapper>
  );
};

const ThemeDisplayRow = ({ name, themeColors, refreshSavedThemes }) => {
  const dispatch = useDispatch();

  const handleLoadClick = () => {
    dispatch({
      type: colorThemeActions.setCustomColorThemeColorDefinition,
      payload: {
        ...themeColors,
      },
    });
    setItemToStorage(STORAGE_KEYS.PREVIOUS_CUSTOM_THEME, name);
  };
  const handleShareClick = () => {
    const themeExport = {
      [name]: themeColors,
    };
  };
  const handleRemoveClick = () => {
    let storedColorThemes = window.localStorage.getItem('colorThemes');
    const themes = JSON.parse(storedColorThemes);
    delete themes[name];
    window.localStorage.setItem('colorThemes', JSON.stringify(themes));
    refreshSavedThemes();
  };

  return (
    <Container bottom={16} row verticallyCenter>
      <Container>
        <Body5>{name}</Body5>
      </Container>
      <Container left={24}>
        <FlatButton small onClick={handleLoadClick}>
          Load
        </FlatButton>
      </Container>
      <Container left={8}>
        <FlatButton small onClick={handleShareClick}>
          Share
        </FlatButton>
      </Container>
      <Container left={8}>
        <FlatButton small onClick={handleRemoveClick}>
          Delete
        </FlatButton>
      </Container>
    </Container>
  );
};

const SavedColorThemes = ({ themesInStorage, refreshSavedThemes }) => {
  if (Object.keys(themesInStorage).length === 0) {
    return <Container>No custom themes are saved in storage.</Container>;
  }
  return (
    <Container>
      {Object.entries(themesInStorage).map((t) => (
        <ThemeDisplayRow key={t[0]} name={t[0]} themeColors={t[1]} refreshSavedThemes={refreshSavedThemes} />
      ))}
    </Container>
  );
};

export const ThemeBuilderForm = () => {
  const dispatch = useDispatch();
  const customTheme = useSelector((state) => state.colorTheme.customTheme);
  const currentColorTheme = useColorTheme(true);

  const [colorThemeName, setColorThemeName] = React.useState('');

  const [showForm, setShowForm] = React.useState(false);
  const [showLoadThemes, setShowLoadThemes] = React.useState(false);

  const [themesInStorage, setThemesInStorage] = React.useState(_getThemesFromStorage());

  const setCustomColorScheme = (theme) => {
    dispatch({
      type: colorThemeActions.set,
      payload: theme,
    });
  };

  const resetToDefault = () => {
    dispatch({
      type: colorThemeActions.resetCustomColorTheme,
    });
  };

  const handleColorThemeNameChange = (e) => {
    const value = e.target.value;
    setColorThemeName(value);
  };

  const onLoadThemesClick = () => {
    setShowForm(false);
    setShowLoadThemes(true);
    setThemesInStorage(_getThemesFromStorage());
  };

  const onBuildThemesClick = () => {
    setShowForm(true);
    setShowLoadThemes(false);
  };

  const refreshSavedThemes = () => {
    setThemesInStorage(_getThemesFromStorage());
  };

  const handleEnableCustomTheme = () => {
    setCustomColorScheme(COLOR_THEME_NAMES.custom);
    setItemToStorage(STORAGE_KEYS.USING_CUSTOM_THEME, 'true');
    setItemToStorage(STORAGE_KEYS.PREVIOUS_CUSTOM_THEME, null);
  };

  const handleDisableCustomTheme = () => {
    setCustomColorScheme(COLOR_THEME_NAMES.lightProfessional);
    setItemToStorage(STORAGE_KEYS.USING_CUSTOM_THEME, 'false');
    setItemToStorage(STORAGE_KEYS.PREVIOUS_CUSTOM_THEME, null);
  };

  return (
    <Page>
      <Container>
        <Container>
          <Body1 bold>Color Theme Builder</Body1>
          <Container top={8}>
            <Body5>Define your own color theme</Body5>
          </Container>
        </Container>
        <Container top={24}>
          {currentColorTheme === COLOR_THEME_NAMES.custom ? (
            <FlatButton onClick={handleDisableCustomTheme}>Disable Custom Theme</FlatButton>
          ) : (
            <FlatButton onClick={handleEnableCustomTheme}>Enable Custom Theme</FlatButton>
          )}
        </Container>
        <Container row top={16}>
          <Container>
            <SkeletonButton onClick={onLoadThemesClick}>Load Saved Theme</SkeletonButton>
          </Container>
          <Container left={16}>
            <SkeletonButton onClick={onBuildThemesClick}>Build Theme</SkeletonButton>
          </Container>
        </Container>
      </Container>
      <Container top={36}>
        {showForm && (
          <Container>
            <Container row>
              <Label>Color Theme Name:</Label>
              <Container left={8}>
                <input value={colorThemeName} onChange={handleColorThemeNameChange} />
              </Container>
            </Container>
            <Container top={36}>
              <ColorThemeBuilderForm />
            </Container>
            <Container top={36}>
              <ActionsWrapper>
                <Container>
                  <SkeletonButton transparent fullWidth onClick={() => saveColorTheme(colorThemeName, customTheme)}>
                    Save Color Theme
                  </SkeletonButton>
                </Container>
                <Container top={8}>
                  <FlatButton transparent fullWidth onClick={() => resetToDefault()}>
                    Reset To Default
                  </FlatButton>
                </Container>
              </ActionsWrapper>
            </Container>
          </Container>
        )}
        {showLoadThemes && (
          <Container top={24}>
            <SavedColorThemes themesInStorage={themesInStorage} refreshSavedThemes={refreshSavedThemes} />
          </Container>
        )}
      </Container>
    </Page>
  );
};

export const ThemeBuilder = () => {
  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <ThemeBuilderForm />
      </Body>
    </PageWrapper>
  );
};
