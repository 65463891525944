import React, { useMemo } from 'react';
import { fetchDoc } from './useDoc';
import { filterDuplicates } from '../../utils/usefulFuncs';
import { useSearchParams } from 'react-router-dom';

export const usePreselectedDocs = () => {
  const [searchParams] = useSearchParams();

  const [complete, setComplete] = React.useState(false);
  const [preSelectedDocs, setPreSelectedDocs] = React.useState([]);

  // const [isGettingPreselectedDocs, setIsGettingPreselectedDocs] = React.useState(false);
  const [retrieveDocIds, setRetrieveDocIds] = React.useState(null);

  React.useEffect(() => {
    if (retrieveDocIds) {
      Promise.all(retrieveDocIds.map((id) => fetchDoc(id))).then((responses) => {
        const docs = responses.filter((r) => r.doc).map((r) => r.doc);
        setPreSelectedDocs(docs);
        // setIsGettingPreselectedDocs(false);
        setComplete(true);
      });
    }
  }, [retrieveDocIds]);

  React.useEffect(() => {
    const checkForPreselectDocParams = () => {
      const param = searchParams.get('doc-ids');
      if (param) {
        // setIsGettingPreselectedDocs(true);
        const nonDups = filterDuplicates(param.split(','));
        setRetrieveDocIds(nonDups);
      } else {
        setComplete(true);
      }
    };
    checkForPreselectDocParams();
  }, []);

  return useMemo(() => {
    return {
      preSelectedDocs,
      complete,
    };
  }, [preSelectedDocs, complete]);
};
