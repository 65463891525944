import React from 'react';
import Rating from './Rating';
import { Container } from '../../libs/nvstr-common-ui.es';
import { sendApiRequest } from '../../services/api';
import { logFormSubmit } from '../../utils/usefulFuncs';

export const ResultRatingForm = ({ resultId }) => {
  const onSaveRating = async (rating, comment) => {
    if (!resultId) {
      console.error('no result id');
      return null;
    }

    const form = { rating, id: resultId };
    if (comment && comment !== '') {
      form.comment = comment;
    }

    logFormSubmit(form);
    const URL = `v1/genai_rating`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    if (status === 200) {
      return { ok: true };
    } else {
      return { errorMessage: data?.error || error || 'Something went wrong, try again.' };
    }
  };

  if (!resultId) return null;

  return (
    <Container top={24}>
      <Rating resultId={resultId} onSaveRating={onSaveRating} />
    </Container>
  );
};
