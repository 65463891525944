import React from 'react';
import { handle400Statuses, sendMultipartApiRequest } from '../../services/api';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { logger, logNetRequest, scrollToTop, sleep } from '../../utils/usefulFuncs';
import * as Sentry from '@sentry/react';
import { UPLOADED_DOC_TYPES } from '../../constants';

const initialState = {
  files: [],
};

const ACTIONS = {
  startUpload: 'startUpload',
  updateFileProgress: 'ufp',
  clear: 'clear',
};

function updateFile(file, files) {
  return files.map((f) => {
    if (f.name === file.name) {
      return file;
    } else {
      return f;
    }
  });
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.startUpload: {
      return {
        ...state,
        files: action.payload,
      };
    }

    case ACTIONS.updateFileProgress: {
      const file = action.payload;
      return {
        ...state,
        files: updateFile(file, state.files),
      };
    }
    case ACTIONS.clear: {
      return {
        ...state,
        files: [],
      };
    }

    default:
      throw new Error();
  }
}

export const useUploadFiles = (type, config = {}) => {
  const [state, uploadingFilesProgressDispatch] = React.useReducer(reducer, initialState, () => initialState);

  const [error, setError] = React.useState(null);
  const [isComplete, setIsComplete] = React.useState(false);

  const reset = async () => {
    setError(null);
    setIsComplete(false);
    uploadingFilesProgressDispatch({
      type: ACTIONS.clear,
    });
  };
  const uploadSingleFile = async (file, ticker, additionalParams = {}) => {
    let formData = new FormData();

    const { speakerTag, companyTag, docType } = additionalParams;

    const tickerNormalized = ticker?.length === 0 ? null : ticker;
    if (tickerNormalized) {
      formData.append('ticker', tickerNormalized);
    }

    formData.append('name', file.name);

    if (speakerTag && speakerTag.length > 0) {
      formData.append('speaker', speakerTag);
    }
    if (companyTag) {
      formData.append('doc_tag_id', companyTag.id);
    }
    if (docType) {
      const formatDocType = (dt) => {
        if (UPLOADED_DOC_TYPES.includes(dt)) {
          return 'Uploaded ' + dt;
        }
        return dt;
      };
      formData.append('doc_type', formatDocType(docType));
    }

    formData.append(type === 'xls' ? 'document' : type, file.file);

    let URL = `v1/genai_process_user_${type}`;
    if (config.ocrDoc || config.adobeOcrDoc) {
      URL = 'v1/genai_process_user_ocr_pdf';
    }
    if (['pptx', 'docx'].includes(type)) {
      URL = 'v1/genai_process_user_ms_docs';
    }
    if (config.adobeOcrDoc) {
      formData.append('use_adobe', true);
    }
    if (config.fedDoc) {
      URL = 'v1/genai_process_user_fed_transcript_pdf';
    }
    uploadingFilesProgressDispatch({
      type: ACTIONS.updateFileProgress,
      payload: { ...file, additionalParams, status: null, errorMessage: null },
    });

    await sleep(300);
    logger(URL, formData);

    const response = await sendMultipartApiRequest('post', URL, formData);

    uploadingFilesProgressDispatch({
      type: ACTIONS.updateFileProgress,
      payload: {
        ...file,
        additionalParams,
        status: response?.status,
        errorMessage:
          response?.status !== 200 ? response?.data?.error || 'Something went wrong, please try again.' : null,
      },
    });

    logNetRequest(response?.status, response?.data, response?.error);

    if (response?.status !== 200) {
      const scope = new Sentry.Scope();
      scope.setTag('error', response?.status);
      scope.setTag('message', response?.data?.error);
      Sentry.captureException(new Error('Error uploading document'), scope);
    }
    return response;
  };

  const uploadMultipleFiles = (files, ticker, additionalParams) => {
    uploadingFilesProgressDispatch({
      type: ACTIONS.startUpload,
      payload: files,
    });

    const requests = files.map((f) => uploadSingleFile(f, ticker, additionalParams));
    Promise.all(requests).then((responses) => {
      const success = responses.every((r) => r.status === 200);
      if (success) {
        setTimeout(() => {
          setIsComplete(true);
        }, 600);
      }
    });
  };

  const uploadFiles = async (files, ticker, additionalParams) => {
    if (files.length === 0) {
      setError('Add a file before attempting to upload');
      return;
    }

    TrackingEvent.create('Uploaded Digital Analyst Content', {
      Tickers: [ticker],
      'File Count': files.length,
      'Is PDF': true,
    });

    setError(null);
    scrollToTop();
    if (files.length === 1) {
      uploadingFilesProgressDispatch({
        type: ACTIONS.startUpload,
        payload: files,
      });
      const { status, data, error } = await uploadSingleFile(files[0], ticker, additionalParams);
      handle400Statuses(status);
      if (status === 200) {
        setTimeout(() => {
          setIsComplete(true);
        }, 800);
      }
    } else {
      uploadMultipleFiles(files, ticker, additionalParams);
    }
  };

  const retryUpload = (file, ticker, additionalParams = {}) => {
    uploadFiles([file], ticker, additionalParams);
  };

  return {
    uploadFiles,
    retryUpload,
    uploadingFilesProgress: state.files,
    error,
    isComplete,
    reset,
  };
};
