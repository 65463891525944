import React from 'react';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';

const makeRecordsIntoDocs = (records, documentLookup) => {
  return records.map((r) => ({
    ...r,
    doc_type: 'Sentiment Record',
    filename: 'Sentiment - ' + r.doc_ids.map((id) => r.document_metadata[id].filename).join(', '),
  }));
};

export const useSentimentRecords = () => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const init = async () => {
      const URL = `v1/genai_fed_sentiment_records_get`;
      const { status, data, error } = await sendApiRequest('get', URL);
      logNetRequest(URL, status, data);
      if (status === 200) {
        setData(makeRecordsIntoDocs(data.records, data.records.document_metadata));
      } else {
        setError('Something went wrong, please try again.');
      }
    };
    init();
  }, []);
  return { data, error };
};
