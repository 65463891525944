import React from 'react';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 4px 4px;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    73% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  svg {
    height: 24px;
    width: 24px;

    animation: pulse 2s infinite;
  }

  path {
    fill: ${({ theme }) => theme.themeColors.error};
  }
`;

const IconWrapper = styled.div`
  svg {
    height: 24px;
    width: 24px;
  }

  path {
    fill: ${({ theme }) => theme.themeColors.error};
  }
`;

export const LiveIndicator = () => {
  return (
    <Wrapper>
      <IconWrapper>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12.5,5A7.5,7.5 0 0,0 5,12.5A7.5,7.5 0 0,0 12.5,20A7.5,7.5 0 0,0 20,12.5A7.5,7.5 0 0,0 12.5,5M7,10H9A1,1 0 0,1 10,11V12C10,12.5 9.62,12.9 9.14,12.97L10.31,15H9.15L8,13V15H7M12,10H14V11H12V12H14V13H12V14H14V15H12A1,1 0 0,1 11,14V11A1,1 0 0,1 12,10M16,10H18V11H16V14H18V15H16A1,1 0 0,1 15,14V11A1,1 0 0,1 16,10M8,11V12H9V11" />
        </svg>
      </IconWrapper>

      <Container bottom={2} left={8}>
        <Body5 bold>Transcript is updating in real-time</Body5>
      </Container>
    </Wrapper>
  );
};
