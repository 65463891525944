import React from 'react';
import { Body1, Body5, Container, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../../components/buttons';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { SpeakerDocumentSelection } from '../SpeakerDocumentSelection';
import { DocumentSelection } from '../DocumentSelection';
import { Modal } from '../../../components/layout/Modal';
import { AI_TOOLS, DOC_SELECT_MODES } from '../../../constants';
import { CompanyTagDocumentSelection } from '../CompanyTagDocumentSelection';
import { useCurrentUserToolsAvailable } from '../../../hooks/user/useCurrentUser';
import { useColorTheme } from '../../../hooks';
import { TrackingEvent } from '../../../libs/nvstr-utils.es';
import { TickerDocumentSelection } from './TickerDocumentSelection';
import { PresetDocumentSelection } from './PresetDocumentSelection';
import styled from 'styled-components';
import { GRID_MODES } from '../../gridMode';

const CompanyTagDocumentSelectionWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border} !important;
  }
`;

const selectDocFilters = (docMode) => {
  if (docMode === GRID_MODES.fed) {
    return { isFedMode: true };
  }
  if (docMode === GRID_MODES.credit) {
    return { isCreditMode: true };
  }
  if (docMode === GRID_MODES.misc) {
    return { isQueryMode: true };
  }
  if (docMode === GRID_MODES.ppm) {
    return { isPPMMode: true };
  }
};

const useTrackView = () => {
  React.useEffect(() => {
    TrackingEvent.create('View Document Selection Modal', {});
  }, []);

  return null;
};

const useTrackMode = (docSelectMode) => {
  React.useEffect(() => {
    TrackingEvent.create('Document Selection Mode', {
      Mode: docSelectMode,
    });
  }, [docSelectMode]);

  return null;
};

const DocumentSelectionModes = ({ docSelectModes, activeDocSelectMode, onModeChange }) => {
  return (
    <Container row style={{ gap: '8px' }}>
      {activeDocSelectMode !== DOC_SELECT_MODES.ticker && docSelectModes.includes(DOC_SELECT_MODES.ticker) && (
        <FlatButton onClick={() => onModeChange(DOC_SELECT_MODES.ticker)}>Search by ticker</FlatButton>
      )}
      {activeDocSelectMode !== DOC_SELECT_MODES.docType && docSelectModes.includes(DOC_SELECT_MODES.docType) && (
        <FlatButton onClick={() => onModeChange(DOC_SELECT_MODES.docType)}>Search by document type</FlatButton>
      )}
      {activeDocSelectMode !== DOC_SELECT_MODES.speaker && docSelectModes.includes(DOC_SELECT_MODES.speaker) && (
        <FlatButton onClick={() => onModeChange(DOC_SELECT_MODES.speaker)}>Search by Speaker</FlatButton>
      )}
      {activeDocSelectMode !== DOC_SELECT_MODES.companyTag && docSelectModes.includes(DOC_SELECT_MODES.companyTag) && (
        <FlatButton onClick={() => onModeChange(DOC_SELECT_MODES.companyTag)}>Search by Company Tag</FlatButton>
      )}
    </Container>
  );
};

export const NewDocumentSelectionModal = ({
  defaultActiveMode,
  docMode,
  docSelectModes,
  selectedDocs,
  prevActionCache,
  handleAddDocumentSpeakerSearch,
  handleAddDocument,
  handleRemoveDocument,
  onDismiss,
  onAction,
}) => {
  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: true });

  const [docTypePresetParams] = React.useState({
    docMode,
  });
  const [docSelectMode, setDocSelectMode] = React.useState(defaultActiveMode || docSelectModes[0]);

  useTrackView();
  useTrackMode(docSelectModes);

  const onModeChange = (mode) => setDocSelectMode(mode);

  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <Container row verticallyCenter spaceBetween>
        <Container row verticallyCenter spaceBetween fullWidth>
          <Container>
            <Body1 bold>Select Document</Body1>
          </Container>
          <DocumentSelectionModes
            activeDocSelectMode={docSelectMode}
            docSelectModes={docSelectModes}
            onModeChange={onModeChange}
          />
        </Container>
        <Container top={4} left={16}>
          <CloseButton onClick={onDismiss} />
        </Container>
      </Container>
      <Container top={24}>
        {docSelectMode === DOC_SELECT_MODES.ticker && (
          <TickerDocumentSelection
            onSelect={handleAddDocument}
            onRemoveSelect={handleRemoveDocument}
            selectedDocs={selectedDocs}
            prevActionCache={prevActionCache}
            onAction={onAction}
          />
        )}
        {docSelectMode === DOC_SELECT_MODES.speaker && (
          <SpeakerDocumentSelection
            onSelect={handleAddDocumentSpeakerSearch}
            onRemoveSelect={handleRemoveDocument}
            selectedDocs={selectedDocs}
          />
        )}
        {docSelectMode === DOC_SELECT_MODES.docType && (
          <Container top={36}>
            <PresetDocumentSelection
              docMode={docMode}
              presetParams={docTypePresetParams}
              handleAddDocument={handleAddDocument}
              handleRemoveDocument={handleRemoveDocument}
              selectedDocs={selectedDocs}
              onDismiss={onDismiss}
            />
          </Container>
        )}
        {docSelectMode === DOC_SELECT_MODES.companyTag && (
          <CompanyTagDocumentSelectionWrapper>
            <CompanyTagDocumentSelection
              selectedDocs={selectedDocs}
              onSelect={handleAddDocument}
              onRemoveSelect={handleRemoveDocument}
            />
          </CompanyTagDocumentSelectionWrapper>
        )}
      </Container>
    </Modal>
  );
};

export const DocumentSelectionModal = ({
  onDismiss,
  docSelectModes,
  docs,
  isLoadingDocs,
  tickers,
  selectedDocs,
  handleAddDocumentSpeakerSearch,
  handleAddDocument,
  handleRemoveDocument,
}) => {
  const colorTheme = useColorTheme();
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isCompanyTaggingEnabled = toolsAvailable.includes(AI_TOOLS.COMPANY_TAGGING);
  const [modalProps] = React.useState({ maximizeWidth: true, forceHeight: true });

  const [docSelectMode, setDocSelectMode] = React.useState(docSelectModes[0]);

  useTrackView();
  useTrackMode(docSelectModes);

  if (isLoadingDocs && docSelectMode === DOC_SELECT_MODES.docType) {
    return (
      <Modal onDismiss={onDismiss} modalProps={modalProps}>
        <Container>
          <Container row verticallyCenter spaceBetween fullWidth>
            <Container>
              <Body1 bold>Select Document</Body1>
            </Container>
            <Container bottom={1} left={16}>
              <CloseButton onClick={onDismiss} />
            </Container>
          </Container>
          <Container row verticallyCenter top={48} bottom={99}>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            <Container left={16}>
              <Body5>Loading...</Body5>
            </Container>
          </Container>
        </Container>
      </Modal>
    );
  }

  return (
    <Modal onDismiss={onDismiss} modalProps={modalProps}>
      <Container row verticallyCenter spaceBetween>
        <Container row verticallyCenter spaceBetween fullWidth>
          <Container>
            <Body1 bold>Select Document</Body1>
          </Container>
          <Container row style={{ gap: '8px' }}>
            {docSelectMode !== DOC_SELECT_MODES.docType && (
              <FlatButton onClick={() => setDocSelectMode(DOC_SELECT_MODES.docType)}>
                Search by document type
              </FlatButton>
            )}
            {docSelectMode !== DOC_SELECT_MODES.speaker && (
              <FlatButton onClick={() => setDocSelectMode(DOC_SELECT_MODES.speaker)}>Search by Speaker</FlatButton>
            )}
            {docSelectMode !== DOC_SELECT_MODES.companyTag && isCompanyTaggingEnabled && (
              <FlatButton onClick={() => setDocSelectMode(DOC_SELECT_MODES.companyTag)}>
                Search by Company Tag
              </FlatButton>
            )}
          </Container>
        </Container>
        <Container top={4} left={16}>
          <CloseButton onClick={onDismiss} />
        </Container>
      </Container>
      <Container top={24}>
        {docSelectMode === DOC_SELECT_MODES.ticker && <></>}
        {docSelectMode === DOC_SELECT_MODES.speaker && (
          <SpeakerDocumentSelection
            onSelect={handleAddDocumentSpeakerSearch}
            onRemoveSelect={handleRemoveDocument}
            selectedDocs={selectedDocs}
          />
        )}
        {docSelectMode === DOC_SELECT_MODES.docType && (
          <DocumentSelection
            tickers={tickers}
            docs={docs}
            onSelect={handleAddDocument}
            onRemoveSelect={handleRemoveDocument}
            selectedDocs={selectedDocs}
            onClose={onDismiss}
          />
        )}
        {docSelectMode === DOC_SELECT_MODES.companyTag && (
          <CompanyTagDocumentSelection
            selectedDocs={selectedDocs}
            onSelect={handleAddDocument}
            onRemoveSelect={handleRemoveDocument}
          />
        )}
      </Container>
    </Modal>
  );
};
