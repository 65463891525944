import React from 'react';
import styled from 'styled-components';
import { FlatButton } from '../../components/buttons';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import Context from 'react-redux/lib/components/Context';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';

const Wrapper = styled.div`
  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 40px;
    width: 100%;
    padding: 16px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 16px;
`;

const RatingCircleGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const Circle = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;

  border: 2px solid ${({ theme }) => theme.themeColors.border};
  background-color: ${({ theme, filled }) => (filled ? theme.themeColors.primaryCtaButton : 'transparent')};
  cursor: pointer;
`;

const Label = styled.div`
  //
`;
const ErrorWrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.error};
  }
`;
const RatingSaveButtonWrapper = styled.div`
  padding-left: 16px;

  button,
  div {
    height: 25px !important;
  }

  button {
    padding: 0 16px !important;
  }
`;

const circles = [1, 1, 1, 1, 1];

const shouldFill = (index, rating) => {
  return index < rating;
};
export default function Rating({ resultId, onSaveRating }) {
  const [savedResultId, setSavedResultId] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [comment, setComment] = React.useState('');
  const [commentEntryEnabled, setCommentEntryEnabled] = React.useState(false);

  const [rating, setRating] = React.useState(0);
  const [hoverRating, setHoverRating] = React.useState(0);

  const handleClick = (i) => {
    if (isSaving) return;
    setRating(i + 1);
  };
  const handleHoverEnter = (i) => {
    if (isSaving) return;
    setHoverRating(i + 1);
  };
  const handleHoverExit = (i) => {
    if (isSaving) return;
    setHoverRating(0);
  };
  const onSave = async () => {
    setError(null);
    setIsSaving(true);
    const { ok, errorMessage } = await onSaveRating(rating, comment);
    setIsSaving(false);
    if (ok) {
      setSavedResultId(resultId);
    } else {
      setError(errorMessage);
    }
  };

  const handleEnableComment = () => {
    setCommentEntryEnabled(true);
  };

  const handleCommentChange = (e) => {
    const v = e?.target?.value;
    setComment(v || '');
  };

  if (resultId === savedResultId) return null;

  return (
    <Wrapper>
      <Label>
        <Body5 bold>Rate this answer:</Body5>
      </Label>
      <RatingWrapper>
        <RatingCircleGroup>
          {circles.map((c, i) => (
            <Circle
              key={i}
              filled={shouldFill(i, hoverRating > 0 ? hoverRating : rating)}
              onClick={() => handleClick(i)}
              onMouseEnter={() => handleHoverEnter(i)}
              onMouseLeave={() => handleHoverExit(i)}
            />
          ))}
        </RatingCircleGroup>
        {rating > 0 && !commentEntryEnabled && (
          <RatingSaveButtonWrapper>
            <SkeletonButton onClick={handleEnableComment}>Add Comment</SkeletonButton>
          </RatingSaveButtonWrapper>
        )}
        {commentEntryEnabled && (
          <Container left={16} fullWidth>
            <textarea autoFocus value={comment} onChange={handleCommentChange} />
          </Container>
        )}
        {rating > 0 && (
          <RatingSaveButtonWrapper>
            {isSaving ? (
              <FlatButton disabled onClick={() => null}>
                Saving...
              </FlatButton>
            ) : (
              <FlatButton onClick={onSave}>Save</FlatButton>
            )}
          </RatingSaveButtonWrapper>
        )}
      </RatingWrapper>
      {error && (
        <Container top={8} left={2}>
          <ErrorWrapper>
            <Body5>{error}</Body5>
          </ErrorWrapper>
        </Container>
      )}
    </Wrapper>
  );
}
