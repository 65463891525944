export const handleTickerGrouping = (data, ticker, currentUserId) => {
  if (!ticker) return data;

  const userUploadedKeys = Object.keys(data).filter((k) => k === currentUserId.toString());
  const keys = Object.keys(data).filter((k) => k !== currentUserId.toString());
  const extraTickerKeys = keys.filter((k) => k !== ticker);

  const userUploaded = {};
  if (userUploadedKeys.length === 1) {
    const k = userUploadedKeys[0];
    userUploaded[k] = data[k];
  }

  const tickerData = {
    [ticker]: data[ticker],
  };
  if (extraTickerKeys.length > 0) {
    extraTickerKeys.forEach((k) => {
      const docs = data[k];
      docs.forEach((d) => {
        tickerData[ticker].push(d);
      });
    });
  }

  const result = {
    ...userUploaded,
    ...tickerData,
  };
  return result;
};
