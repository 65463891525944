import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';

const initialState = {
  isInitializing: true,
  data: null,
};

const ACTIONS = {
  receiveData: 'rd',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.receiveData: {
      return {
        ...state,
        isInitializing: false,
        data: action.payload,
      };
    }

    default:
      throw new Error();
  }
}

function groupData(data) {
  const completed = [];
  const pending = [];
  const errored = [];
  data.forEach((d) => {
    const { title, status } = d;
    if (status === 'Completed') {
      completed.push(d);
    } else if (status === 'In progress') {
      pending.push(d);
    } else {
      if (status === 'Errored') {
        errored.push(d);
      }
    }
  });
  return { completed, pending, errored };
}

const POLL_DELAY = 10000;

export function useUploadStatus() {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  React.useEffect(() => {
    const componentStatus = { isClosed: false };

    const fetch = async () => {
      const URL = `v1/genai_user_crawl_jobs_get`;
      const { status, data } = await sendApiRequest('get', URL);
      handle400Statuses(status);
      logNetRequest(status, data);
      if (status === 200) {
        const groupedData = groupData(data);
        dispatch({
          type: ACTIONS.receiveData,
          payload: groupedData,
        });
      }

      if (!componentStatus.isClosed) {
        setTimeout(() => {
          fetch();
        }, POLL_DELAY);
      }
    };

    fetch();
    return () => {
      componentStatus.isClosed = true;
    };
  }, []);

  return state;
}
