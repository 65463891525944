import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { scrollToTop } from '../utils/usefulFuncs';
import NewQueryTool from '../containers/DigitalAnalyst/NewQueryTool';
import { AI_TOOLS } from '../constants';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

function WebDigitalAnalyst() {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <NewQueryTool onGoBack={onGoBack} isWebMode tool={AI_TOOLS.WEBSITE_DOCS} />
      </Body>
    </PageWrapper>
  );
}

export default WebDigitalAnalyst;
