import React from 'react';
import { useSelector } from 'react-redux';

export const useTableAnalysis = () => {
  const state = useSelector((state) => state.gridTableAnalysis);
  const { favoriteQuestions, prompts, selectedDocuments, tableRows } = state;

  return React.useMemo(() => {
    return {
      favoriteQuestions,
      prompts,
      selectedDocuments,
      tableRows,
    };
  }, [favoriteQuestions, prompts, selectedDocuments, tableRows]);
};
