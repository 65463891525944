import React from 'react';
import styled from 'styled-components';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import {
  formatDocAggregationData,
  formatSpeakerAggregationData,
  formatSpeakerByDocAggregationData,
} from '../../utils/dataFormatters/sentiment';
import { DocSentimentChart } from '../charts/DocSentimentChart';
import { FlatButton } from '../../components/buttons';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { SpeakerDocSentimentChart } from '../charts/SpeakerDocSentimentChart';

const Wrapper = styled.div`
  //overflow: hidden;
`;
const SpeakerWrapper = styled.div`
  //overflow: hidden;
`;
const SpeakerRowWrapper = styled.div`
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CHART_KEYS = {
  MEAN: 'mean',
  STD_DEVIATION: 'std_dev',
  MIN: 'min',
  MAX: 'max',
};

const KeySelectButtonWrapper = styled.div`
  button {
    width: 148px;
    padding: 8px 10px;
    * {
      font-size: 10px !important;
    }
  }
`;
const valueDisplay = (value) => {
  switch (value) {
    case CHART_KEYS.MEAN: {
      return 'Mean';
    }
    case CHART_KEYS.STD_DEVIATION: {
      return 'Standard Deviation';
    }
    default: {
      return value;
    }
  }
};
const KeySelectButton = ({ onClick, value, isActive }) => {
  const hc = () => {
    onClick(value);
  };
  if (isActive) {
    return (
      <KeySelectButtonWrapper>
        <FlatButton onClick={() => null}>{valueDisplay(value)}</FlatButton>
      </KeySelectButtonWrapper>
    );
  }
  return (
    <KeySelectButtonWrapper>
      <SkeletonButton onClick={hc}>{valueDisplay(value)}</SkeletonButton>
    </KeySelectButtonWrapper>
  );
};

const ValueSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const ValueSelector = ({ onClick, activeValue, keys }) => {
  return (
    <ValueSelectorWrapper>
      {keys.map((k) => (
        <KeySelectButton key={k} isActive={k === activeValue} value={k} onClick={onClick} />
      ))}
    </ValueSelectorWrapper>
  );
};

const DocAggregation = ({ formattedData }) => {
  const [keys] = React.useState([CHART_KEYS.MEAN, CHART_KEYS.STD_DEVIATION]);
  const [activeKey, setActiveKey] = React.useState(CHART_KEYS.MEAN);

  if (!formattedData) return null;

  const chartData = formattedData[activeKey];

  const onKeyChange = (v) => setActiveKey(v);

  if (!chartData) {
    return null;
  }
  return (
    <Wrapper>
      <DocSentimentChart data={chartData} activeKey={valueDisplay(activeKey)} />
      <Container top={24} left={24}>
        <ValueSelector activeValue={activeKey} onClick={onKeyChange} keys={keys} />
      </Container>
      {null && (
        <Container top={24}>
          <Body5>{JSON.stringify(formattedData)}</Body5>
        </Container>
      )}
    </Wrapper>
  );
};

const SpeakerByDocAggregation = ({ formattedData }) => {
  const [keys] = React.useState([CHART_KEYS.MEAN, CHART_KEYS.STD_DEVIATION]);
  const [activeKey, setActiveKey] = React.useState(CHART_KEYS.MEAN);

  if (!formattedData) return null;

  const chartData = formattedData[activeKey];

  const onKeyChange = (v) => setActiveKey(v);

  if (!chartData) {
    return null;
  }
  return (
    <Wrapper>
      <SpeakerDocSentimentChart data={chartData} activeKey={valueDisplay(activeKey)} />
      <Container top={24} left={24}>
        <ValueSelector activeValue={activeKey} onClick={onKeyChange} keys={keys} />
      </Container>
      {null && (
        <Container top={24}>
          <Body5>{JSON.stringify(formattedData)}</Body5>
        </Container>
      )}
    </Wrapper>
  );
};

const useAggregations = (metadata) => {
  const [speakerByDocAggregation, setSpeakerByDocAggregation] = React.useState(null);
  const [docAggregation, setDocAggregation] = React.useState(null);

  React.useEffect(() => {
    if (metadata) {
      const docAggregation = formatDocAggregationData(metadata);
      setDocAggregation(docAggregation);
      const speakerByDocAggregation = formatSpeakerByDocAggregationData(metadata);
      setSpeakerByDocAggregation(speakerByDocAggregation);
    }
  }, [metadata]);

  return {
    docAggregation,
    speakerByDocAggregation,
    // speakerAggregationAcrossAllDocs,
  };
};

export const SentimentAggregations = ({ metadata }) => {
  const { speakerAggregationAcrossAllDocs, speakerByDocAggregation, docAggregation } = useAggregations(metadata);

  return (
    <Wrapper>
      <Container>
        <DocAggregation formattedData={docAggregation} />
      </Container>
      <Container top={24}>
        <SpeakerByDocAggregation formattedData={speakerByDocAggregation} />
      </Container>
    </Wrapper>
  );
};
