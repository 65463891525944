import React from 'react';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';

function SpeechRow({ data }) {
  return (
    <Container bottom={16}>
      <Body5>{data}</Body5>
    </Container>
  );
}

function TranscriptRow({ body }) {
  console.log(body);
  const { name, speech, session } = body;
  return (
    <Container bottom={8}>
      <Container bottom={16}>
        <Body5 bold>{name}</Body5>
      </Container>
      <Container>
        {typeof speech === 'string' ? (
          <SpeechRow data={speech} />
        ) : (
          speech.map((s, i) => <SpeechRow data={s} key={`${name}-speech-${i}`} />)
        )}
      </Container>
    </Container>
  );
}

export function TranscriptReader({ transcript }) {
  return (
    <Container>
      {transcript.map((b, i) => (
        <TranscriptRow key={i + '-' + b.session} body={b} />
      ))}
    </Container>
  );
}
