import React from 'react';
import styled from 'styled-components';
import { FONT } from '../../constants/app';
import { convertHexToRGBA, createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { isEven } from '../../utils/dataVisualization';

const TableWrapper = styled.div`
  table {
    th,
    td {
      color: ${({ theme }) => theme.themeColors.text};
      border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
      border-radius: 1px;
    }

    th {
      font-size: 12px;
      line-height: 14px;
      font-family: ${() => FONT.bold};
      min-width: 120px;
      padding: 0 8px;
    }

    td {
      padding: 8px;
      font-size: 12px;
      line-height: 14px;
      font-family: ${() => FONT.normal};

      &.sticky-column {
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        left: -24px; /* Adjust this based on your layout, especially if you have a container with padding or margin */
        background-color: white; /* Ensure the text is readable over the content underneath */
        z-index: 2; /* Make sure it's above other content */
      }
    }
  }
`;

const CommentIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommentWrapper = styled.td`
  svg {
    height: 14px;
    width: 14px;

    fill: ${({ theme }) => theme.themeColors.text};
  }

  transition: all 140ms;
  border-radius: 2px;

  &.enable-hover-effect:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};

    path {
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;

const Comment = ({ value, showModal }) => {
  const hc = () => {
    showModal(value);
  };

  if (value === '') return <CommentWrapper></CommentWrapper>;
  if (value === 'explanation') {
    return (
      <CommentWrapper>
        <CommentIconWrapper>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10Z" />
          </svg>
        </CommentIconWrapper>
      </CommentWrapper>
    );
  }

  return (
    <>
      <CommentWrapper onClick={hc} className={'enable-hover-effect'}>
        <CommentIconWrapper>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10Z" />
          </svg>
        </CommentIconWrapper>
      </CommentWrapper>
    </>
  );
};

const scoreToBackgroundColor = (value, themeColors) => {
  const baseColor = '#3de400'; // colorPalette.secondary.brightGreen;
  return convertHexToRGBA(baseColor, Math.min(0.7, value));
  // switch (value) {
  //   case 1: {
  //     return convertHexToRGBA(baseColor, 0.7);
  //   }
  //   case 2: {
  //     return convertHexToRGBA(baseColor, 0.3);
  //   }
  //   case 3: {
  //     return convertHexToRGBA(baseColor, 0.1);
  //   }
  //   case 4: {
  //     return convertHexToRGBA(baseColor, 0.01);
  //   }
  //   default:
  //     return 'transparent';
  // }
};

const ScoreWrapper = styled.td`
  font-family: ${() => FONT.normal};
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  background-color: ${({ theme, Value }) => scoreToBackgroundColor(Value, theme.themeColors)};
`;

const Score = ({ value }) => {
  if (value === '') return <ScoreWrapper>{value}</ScoreWrapper>;
  if (value === 'score') return <ScoreWrapper>{value}</ScoreWrapper>;
  if (typeof value === 'string' && value.length > 5) return <ScoreWrapper>'N/A'</ScoreWrapper>;
  if (isNaN(value)) return <ScoreWrapper>{value}</ScoreWrapper>;

  const parsedValue = Number.isInteger(value) ? parseInt(value, 10) : parseFloat(value);
  return <ScoreWrapper Value={parsedValue}>{parsedValue.toString()}</ScoreWrapper>;
};

function cellClassNameFromIndex(i) {
  if (i === 0) return 'sticky-column';
  return '';
}

function Cell({ value, index, showModal }) {
  if (index === 0) {
    if (value === '') return <td className={cellClassNameFromIndex(index)}></td>;
    if (value === 'timestamp') return <td className={cellClassNameFromIndex(index)}>Timestamp</td>;
    return (
      <td className={cellClassNameFromIndex(index)}>{formatLocalizedDateTime('api', createTimeInstance(value))}</td>
    );
  }

  if (isEven(index)) {
    return <Comment value={value} showModal={showModal} />;
  }

  return <Score value={value} />;
}

export const FactorVisualizationTable = React.memo(({ data, onShowModal, onDismissModal }) => {
  const showIndex = false;

  if (!data) {
    return null;
  }

  const { header, dataRows } = data;

  return (
    <TableWrapper>
      <table>
        <thead></thead>
        <tbody>
          {
            <tr>
              <th></th>
              {header.map((d, i) => (
                <th key={i} colSpan={2}>
                  {showIndex ? d + ': ' + i * 2 : d}
                </th>
              ))}
            </tr>
          }
          {dataRows.map((r, i) => (
            <tr key={'row' + i}>
              {r.map((d, i) => (
                <Cell key={i} index={i} value={d} showModal={onShowModal} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  );
});
