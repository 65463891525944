import React from 'react';
import styled from 'styled-components';
import { BaseButton, BaseButtonWrapper } from './BaseButton';

export const BaseSkeletonButtonWrapper = styled.div`
  button {
    padding: 12px 24px;
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
    max-width: ${({ $fullWidth }) => ($fullWidth ? '420px' : 'auto')};
  }
`;

export const SkeletonButtonWrap = styled(BaseSkeletonButtonWrapper)`
  ${BaseButtonWrapper} {
    background: transparent;
    border: ${({ theme }) => `1px solid ${theme.themeColors.primaryCtaButton}`};

    * {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }

    :hover {
      opacity: 0.8;
    }
  }
`;

export const DisabledButtonWrap = styled(BaseSkeletonButtonWrapper)`
  ${BaseButtonWrapper} {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};

    opacity: 0.45;
    cursor: not-allowed;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }

    :hover {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

export const SkeletonButton = (props) => {
  const { children, buttonProps, disabled, onClick, fullWidth } = props;

  if (disabled) {
    return (
      <DisabledButtonWrap $fullWidth={fullWidth}>
        <BaseButton {...buttonProps} onClick={onClick}>
          <b>{children}</b>
        </BaseButton>
      </DisabledButtonWrap>
    );
  }

  return (
    <SkeletonButtonWrap $fullWidth={fullWidth}>
      <BaseButton {...buttonProps} onClick={onClick}>
        <b>{children}</b>
      </BaseButton>
    </SkeletonButtonWrap>
  );
};

SkeletonButton.defaultProps = {
  buttonProps: {},
};
