import React from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')`
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
`;

const InvisibleButton = (props) => {
  const { children, height, width, onClick } = props;

  return (
    <Wrapper height={height} width={width} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default InvisibleButton;
