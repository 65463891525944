import React from 'react';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { FlatButton } from '../../components/buttons';
import { useNavigate } from 'react-router';
import { signUpEmail } from '../../services/authentication';
import { Spinner } from '../../components/UI/LoadingSpinner';

const Wrapper = styled.div`
  //
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
`;
const ButtonWrapper = styled.div`
  //
  margin: 0 6px 0 8px;

  button {
    padding: 8px 24px;
  }
`;
const ErrorWrapper = styled.div`
  padding: 8px 0 0 0;
  * {
    color: ${({ theme }) => theme.themeColors.error};
  }
`;
const Input = styled.input`
  flex: 1;

  padding: 12px 16px;
  font-size: 16px;
  line-height: 18px;
  border: none !important;
  outline: none;
`;
const SubmittingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;

  border: 1px solid ${({ theme }) => theme.themeColors.text};
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border-radius: 5px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

export const EmailSignUp = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  const userSignUpComplete = () => {
    navigate(`signup?email=${value}`);
  };
  const userSignUpError = (errorMessage) => {
    setError(errorMessage);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    if (value === '' || !value.includes('@')) {
      setError('Please enter an email');
      return;
    }
    setError(null);
    setIsSubmitting(true);
    await signUpEmail({ email: value, userSignUpComplete, userSignUpError });
  };
  return (
    <Wrapper>
      {isSubmitting ? (
        <SubmittingWrapper>
          <Spinner />
        </SubmittingWrapper>
      ) : (
        <>
          <InputWrapper>
            <Input placeholder={'Enter email...'} value={value} onChange={handleChange} />
            <ButtonWrapper>
              <FlatButton onClick={handleSubmit}>Submit</FlatButton>
            </ButtonWrapper>
          </InputWrapper>
          {error && (
            <ErrorWrapper>
              <Body5>{error}</Body5>
            </ErrorWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};
