import React from 'react';
import { PageCard, PageCardWrapper } from '../components/layout/PageCardWrapper';
import { SplitPage } from '../components/layout/SplitPage';
import { PublicPageMessaging } from '../components/UI/PublicPageMessaging';
import styled from 'styled-components';
import { SignInForm } from '../containers/authentication/SignInForm';
import { useSearchParams } from 'react-router-dom';
import { Container } from '../libs/nvstr-common-ui.es';
import { useLocationUpdate } from '../hooks/application/useLocationSafe';

const SessionExpiredNotice = styled.div`
  padding: 16px;
  font-size: 14px;
  font-weight: 700;

  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  color: ${({ theme }) => theme.themeColors.text};

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;
const SignInFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

const SessionExpired = () => {
  return (
    <Container bottom={16}>
      <SessionExpiredNotice>Session Expired, please sign in again.</SessionExpiredNotice>
    </Container>
  );
};

function SignIn() {
  const [showSessionExpired, setShowSessionExpired] = React.useState(false);
  const [searchParams] = useSearchParams();

  useLocationUpdate();

  React.useEffect(() => {
    const sessionExpiredParam = searchParams.get('sessionexpired');
    if (sessionExpiredParam === '1') {
      setShowSessionExpired(true);
    }
  }, []);

  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging />}
      RightComponent={
        <SignInFormWrapper>
          {showSessionExpired && <SessionExpired />}
          <PageCard>
            <SignInForm />
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
}

export default SignIn;
