import { Container } from '../../libs/nvstr-common-ui.es';
import Close from '../../assets/icons/svgs/Close';
import React from 'react';
import styled from 'styled-components';
import { FONT } from '../../constants/app';

const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 0 8px 0 12px;

  height: 40px;
  border-radius: 5px;

  background: ${({ theme }) => theme.themeColors.lowContrastBorder};

  font-family: ${() => FONT.normal};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.themeColors.text};

  max-width: 600px;

  svg {
    height: 14px;
    width: 14px;

    fill: ${({ theme }) => theme.themeColors.text};
  }

  cursor: pointer;
  transition: background-color 140ms;
  box-sizing: content-box;
`;

const CloseButton = styled.div`
  padding: 0 0 0 8px;

  svg {
    margin-top: 1px;

    height: 8px;
    width: 8px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const File = ({ onRemove, file }) => {
  const filename = file.name + '.csv';

  const hc = () => onRemove(file);

  return (
    <FileWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
      </svg>
      <Container left={8}>{filename}</Container>
      <CloseButton onClick={hc}>
        <Close />
      </CloseButton>
    </FileWrapper>
  );
};
