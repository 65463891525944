import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { Body1 } from '../../libs/nvstr-common-ui.es';
import React from 'react';

export const DateDisplay = ({ date: params_date }) => {
  const date = typeof params_date === 'string' ? createTimeInstance(params_date) : params_date;
  const isToday = createTimeInstance().isSame(date, 'day');
  const isYest = createTimeInstance().subtract(1, 'day').isSame(date, 'day');
  if (isToday) {
    return <Body1 bold>Today</Body1>;
  }
  if (isYest) {
    return <Body1 bold>Yesterday</Body1>;
  }
  return <Body1 bold>{formatLocalizedDateTime('day-date', date)}</Body1>;
};
